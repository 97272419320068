import * as c from './constants';

const initialState = {
  loading: false,
  data: null,
};


const actionHandlers = {
  [c.GET_CURRENT_ACCOOUNT_START]: (state) => {
    return {
      ...state,
      error: null,
      loading: true,
    }
  },
  [c.GET_CURRENT_ACCOUNT_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      data: action.currentItem,
      error: null
    }
  },
  [c.GET_CURRENT_ACCOUNT_FAIL]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error
    }
  },
}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
