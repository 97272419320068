import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../../Provider/actions';
import Providers from './Providers';

class ProvidersContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { actions: { getAll } } = this.props;
    getAll();
  }


  render() {
    const { data } = this.props;
    return (
      <Providers
        {...this.props}
        data={data}
      />
    )
  }
}

const mapStateToProps = state => {
  return state.Front.Provider
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedProviders = connect(mapStateToProps, mapDispatchToProps)(ProvidersContainer);

export { ConnectedProviders as Providers };

