export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const GET_CURRENT_USER_START = 'GET_CURRENT_USER_START';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAIL = 'GET_USERS_GET_CURRENT_USER_FAILFAIL';

export const RESET_CURRENT_USER_START = 'RESET_CURRENT_USER_START';

export const SUBMIT_USER_START = 'SUBMIT_USER_START';
export const SUBMIT_USER_SUCCESS = 'SUBMIT_USER_SUCCESS';
export const SUBMIT_USER_FAIL = 'SUBMIT_USER_FAIL';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
