import client from '../../../../client';

const apiSlug = 'comments';

function getAll({ applicationId,  limit, offset }) {
  return client.get(`${apiSlug}?offset=${offset}&limit=${limit}&applicationId=${applicationId}`);
}

function add({ data }) {
    return client.post(apiSlug, data);
}

export {
  getAll,
  add
};
