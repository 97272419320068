import React, { useEffect } from 'react';
import {
    Button,
    Row,
    FormGroup,
    Label,
    InputGroup,
    Form,
    Col,
    Input
} from 'reactstrap';
import useForm from 'react-hook-form';
import * as yup from 'yup';

const formSchema = {
    code: yup.string().required('Code is required'),
};

const VerifyCodeForm = ({verifyMFA, token}) => {

    const { register, handleSubmit, clearError, setValue, errors } = useForm({
        validationSchema: yup.object().shape(formSchema),
    });

    const handleInputChange = (event) => {
        const { target } = event;
        let name = target.name;
        const value =  target.value
        setValue(name, value)
        clearError(name)
    }  


    const onSubmit = data => {
        verifyMFA({code: data.code, token});
    }

    useEffect(() => {
        register({name: 'code'});
    }, [register]);


    return (
        <>
        <Row>
            <Col lg="12">
                <h4 className="text-center mb-4">          
                    Two Factor Authentication
                </h4>
                <h5> Verify your identity </h5>
                <p style={{fontSize: 14}}>
                Open the Google Authenticator app on your device to view your authentication code and enter it here to verify your identity
                </p>
            </Col>
        </Row>
        <Row>
        <Col lg="12" className="text-left">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col lg="12">
                        <FormGroup className="form-item">
                            <Label>Two Factor Code<span className="form-error">*</span></Label>
                            <InputGroup className="input-group-alternative">
                                <Input
                                    className="form-control-alternative"
                                    name="code"
                                    type="text"
                                    onChange={handleInputChange}
                                    />
                            </InputGroup>
                            {errors.code && (<span className="form-error">{errors.code.message}</span>)}
                            
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" className="text-center mt-1">
                    <Button
                        color="primary"
                        size="lg"
                        type="submit"
                        >
                            Verify code
                    </Button>
                    </Col>
                </Row>
            </Form>
        </Col>
    </Row>
    </>
    );
}

export default VerifyCodeForm;