import * as c from './constants';

const initialState = {
  submittingMessage: false,
  error: null,
  message: {fullName: '', email: '', country: 'Australia', mobile: '', message: ''},
  messageSubmitted: false 
};


const actionHandlers = { 
  [c.SUBMIT_MESSAGE_START]: (state) => {
    return {
      ...state,
      submittingMessage: true,
      error: null,
    }
  },
  [c.SUBMIT_MESSAGE_SUCCESS]: (state) => {
    return {
      ...state,
      submittingMessage: false,
    }
  },
  [c.SUBMIT_MESSAGE_FAIL]: (state, action) => {
    return {
      ...state,
      submittingMessage: false,
      error: action.error
    }
  },
}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
