import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Label
  } from "reactstrap";
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';
import Select from 'react-select';

import Header from "../../../Headers/SlimHeader";

const formSchema = yup.object().shape({
  currency: yup.string().required('Currency is required'),
  amount: yup.string().required('Amount is required'),
});

const currencyOptions = [
  {
    label: 'NRP',
    value: 'nrp'
  }
];
const ExchangeRateForm = (props) => {
    const { currentItem, submittingForm, history } = props;
    const [formData, setFormData] = useState(currentItem);
    const { register, handleSubmit, errors, setValue, reset, clearError } = useForm({
      validationSchema: formSchema,
      defaultValues: currentItem,
    });

    useEffect(() => {
      reset(currentItem);
    }, [currentItem, reset]);

    const onSubmit = async (updatedItem) => {
      const { submit } = props;
      submit({data: {...currentItem, ...updatedItem}});
    }

    const handleInputChange = (event) => {
      const { target } = event;
      const name = target.name;
      const value = target.value;
      setValue([name], value, true);
      setFormData({...formData, [name]: value});
      clearError();
    }

    const selectInput = (selected, name) => {
      let value;
      if(selected && selected.value) {
        value = selected.value;
      }
      else {
        value = selected ? selected.map(item => item.value): null;
      }
      setValue([name], value);
      setFormData({...formData, [name]: value});
      clearError();
  }

    useEffect(() => {
      register({name: 'currency'});
      register({name: 'amount'});
  }, [register]);

    useEffect(() => {
      reset(currentItem);
      setFormData(currentItem);
    }, [currentItem, reset]);

    return (
        <>
            <Header />
            <Container fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-white shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                        <h3 className="mb-0">{currentItem.id ? 'Edit exchange rate' : 'Add new exchange rate'}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="grey"
                        onClick={history.goBack}
                        size="sm"
                      >
                        Back
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="pl-lg-4">

                    <Row>
                        <Col lg="12">
                          <FormGroup row>
                            <Label for="currency" sm={12} className="form-control-label">Currency <span  className="text-danger">*</span></Label>
                            <Col sm={12}>
                                <Select isClearable={true} placeholder="Select currency..." value={currencyOptions.find(item => item.value === formData.currency)} options={currencyOptions} name="currency" onChange={(event) => selectInput(event, 'currency')} />
                                {errors.currency && <span className="formError">{errors.currency.message}</span>}
                            </Col>
                        </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                              >
                                Amount <span  className="text-danger">*</span>
                              </label>
                              
                              <Input
                                  value={formData.amount}
                                  className="form-control-alternative"
                                  type="text"
                                  name="amount"
                                  onChange={handleInputChange}
                                  />
                                  {errors.amount && <span className='formError'>{errors.amount.message}</span>}
                            </FormGroup>
                          </Col>
                      </Row>

                     
                     
                      <Row>
                          <Col lg="12">
                          <hr className="my-4" />
                            <div className="text-right">
                                <Button
                                    color="grey"
                                    type="button"
                                    onClick={history.goBack}
                                    >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={submittingForm? true : false}
                                    >
                                    {currentItem.id ? 'UPDATE' : "ADD"}
                                </Button>
                               
                            </div>
                          </Col>
                      </Row>
                    </div>
                   
                    </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        </>
    );
}

export default withRouter(ExchangeRateForm);