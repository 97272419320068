export const GET_PROVIDERS_START = 'GET_PROVIDERS_START';
export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS';
export const GET_PROVIDERS_FAIL = 'GET_PROVIDERS_FAIL';

export const GET_CURRENT_PROVIDER_START = 'GET_CURRENT_PROVIDER_START';
export const GET_CURRENT_PROVIDER_SUCCESS = 'GET_CURRENT_PROVIDER_SUCCESS';
export const GET_CURRENT_PROVIDER_FAIL = 'GET_CURRENT_PROVIDER_FAIL';

export const CLEAR_CURRENT_PROVIDER = 'CLEAR_CURRENT_PROVIDER';

export const SUBMIT_PROVIDER_START = 'SUBMIT_PROVIDER_START';
export const SUBMIT_PROVIDER_SUCCESS = 'SUBMIT_PROVIDER_SUCCESS';
export const SUBMIT_PROVIDER_FAIL = 'SUBMIT_PROVIDER_FAIL';

export const DELETE_PROVIDER_START = 'DELETE_PROVIDER_START';
export const DELETE_PROVIDER_SUCCESS = 'DELETE_PROVIDER_SUCCESS';
export const DELETE_PROVIDER_FAIL = 'DELETE_PROVIDER_FAIL';