import * as c from './constants';
import * as applicationService from './services';

const exportStart = () => ({
    type: c.EXPORT_APPLICATION_START,
  });
  const exportSuccess = () => ({
    type: c.EXPORT_APPLICATION_SUCCESS,
  });
  const exportFail = ({ error }) => ({
    type: c.EXPORT_APPLICATION_FAIL,
    error,
  });
  export const submit = ({ data }) => async (dispatch) => {
    dispatch(exportStart());
    try {
      const response  = await applicationService.exportApplication({ data });
      window.location.href = response.data.url; 
      dispatch(exportSuccess());
    } catch (error) {
      dispatch(exportFail({ error }));
    }
  };
  