import client from '../../../client';

const apiSlug = `applications`;

function makePayment({applicationId, type, paymentToken}) {
    return client.post(`${apiSlug}/${applicationId}/make-payment`, {paymentToken, type});
}


function getPaymentStatus({applicationId, token}) {
    return client.get(`${apiSlug}/${applicationId}/token/${token}/payment-status`);
}



export { makePayment, getPaymentStatus };