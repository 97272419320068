import React, { useState, useEffect } from 'react';
import {  Button,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Modal,
  Row,
  Col,
  Label
} from "reactstrap";
import useForm from 'react-hook-form';
import * as yup from 'yup';


const formSchema = {
    firstName: yup.string().required('First name field is required.'),
    email: yup.string().required('Email field is required.').email('Email address is not valid.'), 
};


const EmailQuote  = ({submit, submittingForm, showModal, type}) =>{
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { register, handleSubmit, clearError, setValue, errors } = useForm({
      validationSchema: yup.object().shape(formSchema),
      defaultValues: {firstName: '', email: ''},
  });

    const toggleModal = () => {
      setIsModalOpen(!isModalOpen);
    }

  const handleInputChange = (event) => {
      const { target } = event;
      let name = target.name;
      const value = target.value
      setValue(name, value)
      clearError(name)
  }  

  const onSubmit = data => {
    submit({data: {...data, pageUrl: window.location.href}});
  }

  useEffect(() => {
      register({name: 'firstName'});
      register({name: 'email'});
  }, [register]);

  useEffect(() => {
    setIsModalOpen(showModal);
  }, [showModal])
   

    return (
      <>
        {/* <Row>
            <Col lg="12" className="text-center mb-4">
              <Button onClick={toggleModal} className="btn-icon btn-3 btn-email-quote" color={type === 'oshc' ? 'primary' : 'secondary'} outline type="button">
                <span className="btn-inner--icon">
                  <i className="ni ni-email-83" />
                </span>
                <span className="btn-inner--text">Email this quote</span>
              </Button>
            </Col>
          </Row> */}

          <Modal
            className="modal-dialog-centered"
            isOpen={isModalOpen}
            
          >
            <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header">
              <h2 className="modal-title" id="exampleModalLabel">
                Email me this quote
              </h2>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={()=>setIsModalOpen(!isModalOpen)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Enter your name and email address and we'll email you this quote to your email address.
              </p>

              <Row>
                  <Col lg="12">
                      <FormGroup className="form-item">
                          <Label>First name<span className="form-error">*</span></Label>
                          <InputGroup className="input-group-alternative">
                              <Input
                                  className="form-control-alternative"
                                  name="firstName"
                                  type="text"
                                  onChange={handleInputChange}
                                  />
                          </InputGroup>
                          {errors.firstName && (<span className="form-error">{errors.firstName.message}</span>)}
                          
                      </FormGroup>
                  </Col>
              </Row>

              <Row>
                <Col lg="12">
                    <FormGroup className="form-item">
                        <Label>Email address<span className="form-error">*</span></Label>
                        <InputGroup className="input-group-alternative">
                            <Input
                                className="form-control-alternative"
                                name="email"
                                type="email"
                                onChange={handleInputChange}
                                />
                        </InputGroup>
                        {errors.email && (<span className="form-error">{errors.email.message}</span>)}
                    </FormGroup>
                </Col>
              </Row>

             

            </div>
            <div className="modal-footer" style={{borderTop:'1px solid #e9ecef'}}>
              <Button
                color="link"
                data-dismiss="modal"
                type="button"
               
                onClick={toggleModal}
              >
                Close
              </Button>
              <Button color={type === 'oshc' ? 'primary' : 'secondary'}  disabled={submittingForm}  type="submit">
                Email this quote
              </Button>
            </div>
            </Form>
          </Modal>
      </>
    );
}

export default EmailQuote;