import React from 'react';
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
} from "reactstrap";
import moment from 'moment';
import AnchorLink from 'react-anchor-link-smooth-scroll';


const Contact =  () => {
  return (
    <>
      <footer className="footer">
          <Container>
            <Row className="d-flex row-grid">
              <Col xs={6} md={6} lg="3" className="col-footer">
                <h5 className="mb-2 heading">
                    QUICK LINKS
                </h5>
                <ul className="list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about-us">About Us</Link></li>
                    <li><a href="/">Insurance Providers</a></li>
                    <li><AnchorLink href='/faqs'>FAQs</AnchorLink></li>
                    <li><a href="/reviews">Reviews</a></li>
                    <li><AnchorLink href='#contact-form'>Contact Us</AnchorLink></li>
                    <li><a href="/auth/login">Agent</a></li>
                </ul>
              </Col>
              <Col xs={6} md={6} lg="3" className="col-footer" style={{marginTop: 0}}>
                <h5 className="mb-2 heading">
                    OTHERS
                </h5>
                <ul className="list-unstyled">
                    <li><Link to="/complaints-policy">Complaints Policy</Link></li>
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                    <li><a rel="noopener noreferrer" href="https://insurance-compare-prod.s3-ap-southeast-2.amazonaws.com/assets/documents/Data_breach_preparation_and_response.pdf" target="_blank">Data breach preparation
and response</a></li>
                </ul>
              </Col>
              <Col lg="3" className="col-footer">
                <h5 className="mb-2 heading">
                    HEALTH COVERS
                </h5>
                <ul className="list-unstyled">
                    <li><Link to="/cover-type/oshc">OSHC</Link></li>
                    {/* <li><Link to="/cover-type/ovhc">OVHC</Link></li> */}
                </ul>
              </Col>

              <Col lg="3" className="col-footer">
                <h5 className="mb-2 heading">
                    GET IN TOUCH
                </h5>
                <p>
                Let's get in touch on any of these platforms. 
                </p>
                <p>
                    <a href="https://www.facebook.com/oshccompare" target="_blank" rel="noopener noreferrer" className="footer-link"><i className="fa fa-facebook-square"></i></a>
                </p>
              </Col>
            </Row>
          </Container>
        </footer>
        <footer className="footer-darker py-1">
            <Container>
                <Row className="d-flex">
                    <Col lg="12" className="text-center">
                        <small>Copyright {moment().format('YYYY')}, All rights reserved</small>
                    </Col>
                    
                </Row>
            </Container>
        </footer>
    </>
  );
}

export default Contact;
