import React, { useState, useEffect } from 'react';
import {
    Button,
    FormGroup,
    Form,
    Input,
    Row,
    Col
  } from "reactstrap";
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';
import * as yup from 'yup';
import moment from 'moment-timezone';
import Select from 'react-select';

const formSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().required('Email is required').email('Invalid email address'),
})


const MyProfileForm = (props) => {
    const { currentItem, submittingForm } = props;
   const timezoneOptions = moment.tz.names().map(timezone => ({value: timezone, label: timezone}));
    const { register, handleSubmit, errors, reset, setValue } = useForm({
      validationSchema: formSchema,
      defaultValues: currentItem,
    });
   
    const [formData, setFormData] = useState(currentItem);


    useEffect(() => {
      reset(currentItem);
      setFormData(currentItem);
    }, [currentItem, reset, setFormData]);

    const onSubmit = async (updatedItem) => {
      const { submit } = props;
      submit({data: {...currentItem, ...updatedItem, timezone: formData.timezone}});
    }

    const handleInputChange = (event) => {
      const { target } = event;
      const name = target.name;
      const value = target.value;
      setFormData({...formData, [name]:  value});
      setValue([name], value);
    }

    const selectInput = (selected, name) => {
      setFormData({...formData, [name]: selected.value});
      setValue([name], selected.value, true);
    }

    useEffect(() => {
      register({name: 'timezone'});
  }, [register]);
   
    return (
        <>
           
           
       
          <Form role="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="pl-lg-4">
              
              <Row>
                <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                      >
                        First name
                      </label>
                      
                      <Input
                          className="form-control-alternative"
                          type="text"
                          name="firstName"
                          innerRef={register}
                          onChange={handleInputChange}
                          />
                          {errors.firstName && (<span className="form-error">{errors.lastName.message}</span>)}
                    </FormGroup>

                  </Col>
              </Row>

              <Row>
                <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                      >
                        Last name
                      </label>
                      
                      <Input
                          className="form-control-alternative"
                          type="text"
                          name="lastName"
                          innerRef={register}
                          onChange={handleInputChange}
                          />
                        {errors.lastName && (<span className="form-error">{errors.lastName.message}</span>)}
                    </FormGroup>
                  </Col>
              </Row>

              <Row>
                <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                      >
                        Email
                      </label>
                      
                      <Input
                          className="form-control-alternative"
                          type="text"
                          name="email"
                          innerRef={register}
                          onChange={handleInputChange}
                          />
                          {errors.email && (<span className="form-error">{errors.email.message}</span>)}
                    </FormGroup>
                  </Col>
              </Row>

              <Row>
                        <Col lg="12">
                          <FormGroup >

                              <FormGroup>
                                <label
                                  className="form-control-label"
                                >
                                  Time zone <span  className="text-danger">*</span>
                                </label>

                                <Select
                                    value={timezoneOptions.find(timezone => timezone.value === formData.timezone)}
                                    name="timezone"
                                    options={timezoneOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={event => selectInput(event, 'timezone')}
                                />
                                
                              </FormGroup>


                             {errors.timezone && (<div className="text-danger">{errors.timezone.message}</div>)}
                              
                          </FormGroup>
                        </Col>
              </Row>

              {
                !formData.id && (
                  <Row>
                    <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            Password
                          </label>
                          
                          <Input
                              className="form-control-alternative"
                              type="password"
                              name="password"
                              innerRef={register({required: true})}
                              onChange={handleInputChange}
                              />
                        </FormGroup>
                      </Col>
                  </Row>
                )
              }

              <Row>
                  <Col lg="12">
                  <hr className="my-4" />
                    <div className="text-right">
                        <Button
                            color="primary"
                            type="submit"
                            disabled={submittingForm? true : false}
                            >
                           UPDATE
                        </Button>
                        
                    </div>
                  </Col>
              </Row>
            </div>
            
            </Form>
        
        </>
    );
}

export default withRouter(MyProfileForm);