import cogoToast from 'cogo-toast';

import * as c from './constants';
import * as service from './services';

const exportStart = () => ({
    type: c.EXPORT_LEAD_START,
  });
  const exportSuccess = () => ({
    type: c.EXPORT_LEAD_SUCCESS,
  });
  const exportFail = ({ error }) => ({
    type: c.EXPORT_LEAD_FAIL,
    error,
  });
  export const submit = ({ data }) => async (dispatch) => {
    dispatch(exportStart());
    try {
      const response  = await service.exportLead({ data });
      window.location.href = response.data.url; 
      dispatch(exportSuccess());
    } catch (error) {
      cogoToast.error('Failed to export leads!');
      dispatch(exportFail({ error }));
    }
  };
  