import React from 'react';
import { Container, Row, Col, Card, CardBody,  } from "reactstrap";

import { OSHCForm, OVHCForm } from '../../CoverType';


const Hero = ( props ) => {
  const { type } = props;


  return (
    <div className="position-relative">
          <section className="section section-shaped">
            <div className={type === 'oshc' ? "shape shape-primary" : "shape shape-secondary" }>
              &nbsp;
            </div>
            <Container className="shape-container d-flex align-items-center">
              <div className="col px-0">
                <Row>
                  <Col lg="12">
                      <Card className="shadow shadow-lg--hover mt-4">
                          <CardBody>
                            {
                              type === 'oshc' && (<OSHCForm isQuotePage={true}  />)
                            }
                            {
                              type === 'ovhc' && (<OVHCForm isQuotePage={true} />)
                            }
                          </CardBody>
                      </Card>
                    </Col>
                </Row>
              </div>
            </Container>
          </section>
        </div>
  );
}

export default Hero;
