import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions'
import FAQs from './FAQs';

class FAQsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { formSuccess: false}
  }

  componentDidMount() {
    this.props.actions.getAll(); 
  }

  render() {
    const { data } = this.props;
    
    return (
      <FAQs
        {...this.props}
        data={data}
      />
    )
  }
}

const mapStateToProps = state => {
  return state.Front.Faq
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedFAQs = connect(mapStateToProps, mapDispatchToProps)(FAQsContainer);

export { ConnectedFAQs as FAQs };

