import React from "react";

import {Container } from "reactstrap";

class SlimHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-gradient-info pb-5 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              &nbsp;
            </div>
          </Container>
        </div>
      </>
    );
  }
}
export default SlimHeader;