import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions';
import * as accountActions from '../../Account/actions';


import UserForm from './UserForm';

class UserFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { actions: { getCurrent, resetCurrentItem, getAccounts } } = this.props;
    if (id) {
      getCurrent({ id });
    } else {
      resetCurrentItem();
    }
    getAccounts({ limit: 1000, offset: 0, filter: {} });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
      const {history}  = this.props;
      history.push('/admin/users');
    }
  }


  render() {
    const {
      currentItem, loggedInUser, accounts, actions: { submit },
    } = this.props;


    return (
      <UserForm
        {...this.props}
        currentItem={currentItem}
        submit={submit}
        accounts={accounts}
        loggedInUser={loggedInUser}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Admin.User,
  accounts: state.Admin.Account.data,
  loggedInUser: state.Admin.AuthUser.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actions, getAccounts: accountActions.getAll }, dispatch),
});


const ConnectedUserForm = connect(mapStateToProps, mapDispatchToProps)(UserFormContainer);

export { ConnectedUserForm as UserForm };
