import React from 'react';
import {
    Button,
    Row,
    Col,
} from 'reactstrap';
import VerifyCodeForm from './VerifyCodeForm';

const MFA = ({barcode, currentUser, generateCode, enableMFA, removeMFA}) => {



    const handleGenerateCode = (event) => {
        event.preventDefault();
        generateCode()
    }
    
    return (
        <>
        <h2>Two Factor Authentication</h2>

        {
            currentUser.is2FAEnabled ? (
                <>
                    <p>2FA is enabled for your account</p>
                    <Row>
                        <Col lg="12" className="mb-4">
                        <Button onClick={removeMFA}>Remove 2FA</Button>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    {
                        barcode ?  (
                        <>
                            
                            <img src={barcode} alt="" />
                            <p>Scan this barcode with your authenticator app.</p>
                            <VerifyCodeForm enableMFA={enableMFA} />
                        </>
                        ) : (
                            <>
                            {
                                currentUser.secrect2FA ? (
                                <>
                                    {
                                        <>
                                        <Row>
                                            <Col lg="12" className="mb-4">
                                            <Button onClick={handleGenerateCode}>Regenerate QR Code</Button>
                                            </Col>
                                        </Row>
                                        <VerifyCodeForm enableMFA={enableMFA} />
                                    </>
                                    }
                                    
                                </>
                                ) : (
                                    <Row>
                                        <Col lg="12">
                                        <Button onClick={handleGenerateCode}>Show QR code</Button>
                                        </Col>
                                    </Row>
                                )
                            }
                            
                            </>
                        )
                    }
                </>
            )
        }
        </>
    );
}

export default MFA;