import React from 'react';
import { Container, Row, Col } from "reactstrap";

import { Steps } from '../common';
import { ReviewForm } from './ReviewForm';

const Payment = (props) => {
  const { coverType } = props.match.params;
  const { application } = props;
  window.scrollTo(0, 0);
  
  return (
    <main>
        <Steps currentStep={4} type={coverType}/>
            <section className="section">
                <Container>
                    <Row>
                        <Col lg="12">
                            <h2>Application completed</h2>
                            <h5>Your transaction reference number is:  <strong>{application.referenceNo}</strong> </h5>
                            <p>You are almost done. We have received your application. You will receive your certificate in your email soon. If you don't receive your cover certificate in 24 hours please talk to our support or contact us at <a href="mailto:support@oshccompare.com.au">support@oshccompare.com.au</a></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                        <hr />
                        </Col>
                    </Row>

                    <ReviewForm {...props} />
                </Container>
            </section>
       
      </main>
  );
}

export default Payment;
