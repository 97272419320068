import React from 'react';
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import useForm from 'react-hook-form';

import VerifyCodeForm from './VerifyCodeForm';

const LogIn = (props) => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async ({ email, password }) => {
    const { logIn } = props;
    logIn({ email, password });
  };

  const { loginError, loggingIn, is2FAEnabled, verifyMFA, token } = props;
  return (
    <>
      {
        is2FAEnabled ? (
          <>
            
            <VerifyCodeForm verifyMFA={verifyMFA} token={token} />
          </>
        ) : (
          <>
            <h4 className="text-center mb-4">
                      AGENT LOGIN
            </h4>
            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
              {
                              !loggingIn && loginError && (
                              <Alert color="danger">
                                <strong>Login Error</strong>
                                {' '}
      Invalid email and password
                              </Alert>
                              )
                          }
              <FormGroup className={errors.email ? 'mb-3 has-danger' : 'mb-3'}>
                <label
                  className="form-control-label"
                >
                              Email
                </label>
                <InputGroup className="input-group-alternative">
                  <Input type="email" name="email" innerRef={register({ required: true })} />
                </InputGroup>
              </FormGroup>
              <FormGroup className={errors.email && 'has-danger'}>
                <label
                  className="form-control-label"
                >
                                  Password
                </label>
                <InputGroup className="input-group-alternative">
                  <Input
                    type="password"
                    autoComplete="off"
                    name="password"
                    innerRef={register({ required: true })}
                  />
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button
                  className="my-4"
                  color="secondary"
                  type="submit"
                >
                          Sign in
                </Button>
              </div>
              <div className=" text-center">
                <small><Link to="/auth/forgot-password">Forgot password ? </Link></small>
              </div>
            </Form>
          </>
        )
      }
    </>
  );
};

export default LogIn;
