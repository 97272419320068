const getHealthCoverUrl = function({currentAccount, application}) {
    let healthCoverUrl;
    if(currentAccount) {
      const healthCoverId = application.HealthCover && application.HealthCover.id;
      if(healthCoverId && currentAccount.healthCoverUrls) {
        const healthCoverUrls = JSON.parse(currentAccount.healthCoverUrls);
        healthCoverUrl = healthCoverUrls.find(item => item.healthCoverId === healthCoverId);
      }
    }
    return healthCoverUrl;
}

export {
    getHealthCoverUrl
}