import React, { useState, useEffect } from 'react';
import { HotTable } from '@handsontable/react';
import Select from 'react-select';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
  } from "reactstrap";
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';
import 'handsontable/dist/handsontable.full.css';

import Header from "../../../Headers/SlimHeader";

const categories = [
  {
    id: 'Budget Cover',
    value: 'Budget Cover',
  },
  {
    id: 'Standard Cover',
    value: 'Standard Cover',
  },
  {
    id: 'Mid Cover',
    value: 'Mid Cover',
  },
  {
    id: 'Top Cover',
    value: 'Top Cover',
  }
];
const HealthCoverForm = (props) => {
    const { register, handleSubmit, setValue, errors } = useForm();
    const { currentHealthCover, submittingForm, history, healthCoverTypes, providers } = props;
    const [formData, setFormData] = useState(currentHealthCover);

    
    useEffect(() => {
      setFormData(currentHealthCover);
    }, [currentHealthCover, setFormData]);

    const onSubmit = async (updatedHealthCover) => {
      
      const { submitHealthCoverForm } = props;
      submitHealthCoverForm({healthCover: {...currentHealthCover, ...updatedHealthCover, premium: formData.premium}});
    }


    const handleInputChange = (event) => {
      const { target } = event;
      const name = target.name;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      setFormData({...formData, [name]:  value})
    }

    const selectInput = (selected, name) => {
      const value = selected?  selected.id: '';
      setFormData({...formData, [name]: value});
      setValue([name], value, true);
    }

    const handleSheetChange = (change, source) => {
      if(source === 'loadData') return;
      if(source === 'edit') {

      }
      else if (source === 'CopyPaste.paste') {
        let data = []
        
        change.forEach(cell => {
          if(!data[cell[0]]) {
            data[cell[0]] = [];
          }
          data[cell[0]][cell[1]] = cell[3]
        });
       setFormData({...formData, 'premium':  data} );
       setValue('premium', data);
      }
    }

    const {id, name, agentUrl, api, isMonthlyOnly, monthlyPrice, healthCoverTypeId, providerId, isActive, premium, category, isPublic, isPrivate, offer, excess} = formData;
    
    return (
        <>
            <Header />
            <Container fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-white shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                        <h3 className="mb-0">{id ? 'Edit health cover' : 'Add new health cover'}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="grey"
                        onClick={history.goBack}
                        size="sm"
                      >
                        Back
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >
                              Name
                            </label>
                            <div className={errors.name && "has-danger"}>
                              <Input
                                className="form-control-alternative"
                                name="name"
                                type="text"
                                innerRef={register({required: true})}
                                value={name}
                                onChange={handleInputChange}
                              />
                            </div>
                            {errors.name && (<small className="formError">This field is required</small>)}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <FormGroup >
                            <label
                              className="form-control-label"
                            >
                              Excess
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="excess"
                              type="text"
                              innerRef={register}
                              value={excess}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <FormGroup className={errors.healthCoverTypeId && "has-danger" }>
                            <label
                              className="form-control-label"
                            >
                              Health Cover type
                            </label>

                            <Select
                                value={healthCoverTypes && healthCoverTypes.find(item => item.id === healthCoverTypeId)}
                                getOptionLabel={option =>`${option.name} (${option.fullName})`}
                                getOptionValue={option =>`${option.id}`}
                                name="healthCoverTypeId"
                                options={healthCoverTypes}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                ref={() => register({name: 'healthCoverTypeId', required: true})}
                                onChange={event => selectInput(event, 'healthCoverTypeId')}
                            />
                            {errors.healthCoverTypeId && (<small className="formError">This field is required</small>)}
                            
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <FormGroup className={errors.providerId && "has-danger" }>
                            <label
                              className="form-control-label"
                            >
                              Provider
                            </label>

                            <Select
                                value={providers && providers.find(item => item.id === providerId)}
                                name="providerId"
                                getOptionLabel={option =>`${option.name}`}
                                getOptionValue={option =>`${option.id}`}
                                options={providers}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                ref={() => register({name: 'providerId', required: true})}
                                onChange={event => selectInput(event, 'providerId')}
                            />
                             {errors.providerId && (<small className="formError">This field is required</small>)}
                            
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <FormGroup className={errors.providerId && "has-danger" }>
                            <label
                              className="form-control-label"
                            >
                              Category
                            </label>

                            <Select
                                value={categories && categories.find(item => item.category === category)}
                                name="category"
                                options={categories}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                ref={() => register({name: 'category'})}
                                onChange={event => selectInput(event, 'category')}
                                isClearable={true}
                                getOptionLabel={option =>`${option.value}`}
                                getOptionValue={option =>`${option.id}`}
                            />
                            
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <FormGroup >
                            <label
                              className="form-control-label"
                            >
                              Agent url
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="agentUrl"
                              type="text"
                              innerRef={register}
                              value={agentUrl}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >
                              API
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="api"
                              type="text"
                              innerRef={register}
                              value={api}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <FormGroup>

                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              name="isMonthlyOnly"
                              ref={register}
                              checked={isMonthlyOnly}
                              onChange={handleInputChange}
                              id="isMonthlyOnly"
                            />
                            <label className="custom-control-label" htmlFor="isMonthlyOnly">
                              Is monthly only
                            </label>
                          </div>
                            
                           
                          </FormGroup>
                        </Col>
                      </Row>

                      {
                        isMonthlyOnly && (
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                >
                                  Monthly price
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  name="monthlyPrice"
                                  type="text"
                                  innerRef={register}
                                  value={monthlyPrice}
                                  onChange={handleInputChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        )
                      }

<Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >
                              Offer
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="offer"
                              type="textarea"
                              innerRef={register}
                              value={offer}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>


                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >
                             Premium
                            </label>
                           
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <HotTable 
                            stretchH='all'
                            licenseKey='non-commercial-and-evaluation' 
                            data={premium} 
                            colHeaders={true} 
                            rowHeaders={true}  
                            afterChange={(change, source) => handleSheetChange(change, source)}
                            name='premium'
                            innerRef={register}
                            height="500"/>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" className="mt-3">
                          <FormGroup>

                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              name="isPublic"
                              ref={register}
                              checked={isPublic}
                              onChange={handleInputChange}
                              id="isPublic"
                            />
                            <label className="custom-control-label" htmlFor="isPublic">
                              Is Public
                            </label>
                          </div>
                            
                           
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" className="mt-3">
                          <FormGroup>

                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              name="isPrivate"
                              ref={register}
                              checked={isPrivate}
                              onChange={handleInputChange}
                              id="isPrivate"
                            />
                            <label className="custom-control-label" htmlFor="isPrivate">
                              Is Private
                            </label>
                          </div>
                            
                           
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" className="mt-3">
                          <FormGroup>

                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              name="isActive"
                              ref={register}
                              checked={isActive}
                              onChange={handleInputChange}
                              id="isActive"
                            />
                            <label className="custom-control-label" htmlFor="isActive">
                              Is Active
                            </label>
                          </div>
                            
                           
                          </FormGroup>
                        </Col>
                      </Row>
                      
                     
                      <Row>
                          <Col lg="12">
                          <hr className="my-4" />
                            <div className="text-right">
                                <Button
                                    color="grey"
                                    type="button"
                                    onClick={history.goBack}
                                    >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={submittingForm? true : false}
                                    >
                                    {id ? 'UPDATE' : "ADD"}
                                </Button>
                               
                            </div>
                          </Col>
                      </Row>
                    </div>
                   
                    </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        </>
    );
}

export default withRouter(HealthCoverForm);