import * as c from './constants';

const initialState = {
  loggingIn: false,
  loginError: null,
  token: null,
  loggingOut: false,
  logoutError: null,
  is2FAEnabled: false,
  verifyingMFAError: null,
  verifyingMFA: false
};

const actionHandlers = {
  [c.LOGIN_START]: (state) => {
    return {
      ...state,
      loggingIn: true,
    }
  },
  [c.LOGIN_SUCCESS]: (state, action) => {
    return {
      ...state,
      loggingIn: false,
      token: action.token,
      is2FAEnabled: action.user.is2FAEnabled,
      loginError: null,
    }
  },
  [c.LOGIN_FAIL]: (state, action) => {
    return {
      ...state,
      loggingIn: false,
      token: null,
      loginError: action.error,
    }
  },
  [c.LOGOUT_START]: (state) => {
    return {
      ...state,
      loggingOut: true,
    }
  },
  [c.LOGOUT_SUCCESS]: (state) => {
    return {
      ...state,
      token: null,
      loggingOut: false,
    }
  },
  [c.LOGOUT_FAIL]: (state, action) => {
    return {
      ...state,
      loggingOut: false,
      logoutError: action.error,
    }
  },

  [c.VERIFY_MFA_START]: (state) => {
    return {
      ...state,
      verifyingMFA: true,
      verifyingMFAError: null
    }
  },
  [c.VERIFY_MFA_SUCCESS]: (state, action) => {
    return {
      ...state,
      verifyingMFA: false,
    }
  },
  [c.VERIFY_MFA_FAIL]: (state, action) => {
    return {
      ...state,
      verifyingMFA: false,
      verifyingMFAError: action.error,
    }
  },
}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
