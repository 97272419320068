import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Hero = () => (
  <div className="position-relative">
    <section className="section section-lg  section-shaped">
      <div className="shape shape-style-1 shape-default">
              &nbsp;
      </div>
      <Container className="shape-container d-flex align-items-center py-0">
        <div className="col px-0">
          <Row className="align-items-center justify-content-center">
            <Col className="text-center" lg="8">
              <h1 className="text-white">Privacy Policy</h1>
              <p className="lead text-white">
                        
              </p>
            </Col>
          </Row>
        </div>
      </Container>
      {/* SVG separator */}
      <div className="separator separator-bottom separator-skew zindex-100">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="fill-white"
            points="2560 0 2560 200 0 200"
          />
        </svg>
      </div>
    </section>
  </div>
);

export default Hero;
