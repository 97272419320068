import * as c from './constants';
import * as ProviderService from './services';


const getProvidersStart = () => ({
  type: c.GET_PROVIDERS_START
})

const getProvidersSuccess = ({providers}) => ({
  type: c.GET_PROVIDERS_SUCCESS,
  providers,
})

const getProvidersFail = ({error}) => ({
  type: c.GET_PROVIDERS_FAIL,
  error,
})

export const getAllProviders = () => async (dispatch) => {
  dispatch(getProvidersStart())
  try {
      const {data} = await ProviderService.getAllProviders();
      
      dispatch(getProvidersSuccess({providers: data.data}));
  } catch (error) {
    dispatch(getProvidersFail({error}))
  }
}


const clearCurrentProviderSuccess = () => ({
  type: c.CLEAR_CURRENT_PROVIDER
})
export const clearCurrentProvider = () => async (dispatch) => {
  dispatch(clearCurrentProviderSuccess());
}



const submitProviderStart = () => ({
  type: c.SUBMIT_PROVIDER_START
})
const submitProviderSuccess = ({provider}) => ({
  type: c.SUBMIT_PROVIDER_SUCCESS,
  provider,
})
const submitProviderFail = ({error}) => ({
  type: c.SUBMIT_PROVIDER_FAIL,
  error,
})
export const submitProviderForm = ({provider}) => async (dispatch) => {
  dispatch(submitProviderStart())
  try {
      let response;
      
      if(provider.id) {
        response = await ProviderService.updateProvider({provider});
      }
      else {
        response = await ProviderService.addProvider({provider});
      }
      
      const {data} = response;
      
      dispatch(submitProviderSuccess({provider: data.data}));
  } catch (error) {
    dispatch(submitProviderFail({error}))
  }
}


const deleteProviderStart = () => ({
  type: c.DELETE_PROVIDER_START
})
const deleteProviderSuccess = ({id}) => ({
  type: c.DELETE_PROVIDER_SUCCESS,
  id,
})
const deleteProviderFail = ({error}) => ({
  type: c.DELETE_PROVIDER_FAIL,
  error,
})
export const deleteProvider = ({id}) => async (dispatch) => {
  dispatch(deleteProviderStart())
  try {
      await ProviderService.deleteProvider({id});
      dispatch(deleteProviderSuccess({id}));
  } catch (error) {
    dispatch(deleteProviderFail({error}))
  }
}



const getCurrentStart = () => ({
  type: c.GET_CURRENT_PROVIDER_START
})

const getCurrentSuccess = ({currentItem}) => ({
  type: c.GET_CURRENT_PROVIDER_SUCCESS,
  currentItem,
})

const getCurrentFail = ({error}) => ({
  type: c.GET_CURRENT_PROVIDER_FAIL,
  error,
})

export const getCurrent = ({id}) => async (dispatch) => {
  dispatch(getCurrentStart());
  try {
      const { data } = await ProviderService.getOne({id});
      
      dispatch(getCurrentSuccess({currentItem: data.data}));
  } catch (error) {
    dispatch(getCurrentFail({error}))
  }
}