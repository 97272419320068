import * as c from './constants';
import * as FeatureCategoryService from './services';

const getAllStart = () => ({
  type: c.GET_FEATURE_CATEGORIES_START
})

const getAllSuccess = ({data}) => ({
  type: c.GET_FEATURE_CATEGORIES_SUCCESS,
  data,
})

const getAllFail = ({error}) => ({
  type: c.GET_FEATURE_CATEGORIES_FAIL,
  error,
})

export const getAll = () => async (dispatch) => {
  dispatch(getAllStart())
  try {
      const {data} = await FeatureCategoryService.getAll();
      dispatch(getAllSuccess({data: data.data}));
  } catch (error) {
    dispatch(getAllFail({error}))
  }
}



const clearCurrentSuccess = () => ({
  type: c.CLEAR_CURRENT_FEATURE_CATEGORY
})
export const clearCurrent = () => async (dispatch) => {
  dispatch(clearCurrentSuccess());
}



const submitStart = () => ({
  type: c.SUBMIT_FEATURE_CATEGORY_START
})
const submitSuccess = ({data}) => ({
  type: c.SUBMIT_FEATURE_CATEGORY_SUCCESS,
  data,
})
const submitFail = ({error}) => ({
  type: c.SUBMIT_FEATURE_CATEGORY_FAIL,
  error,
})
export const submit = ({data}) => async (dispatch) => {
  dispatch(submitStart())
  try {
      let response;
      
      if(data.id) {
        response = await FeatureCategoryService.update({data});
      }
      else {
        response = await FeatureCategoryService.add({data});
      }
      
      dispatch(submitSuccess({data: response.data.data}));
  } catch (error) {
    dispatch(submitFail({error}))
  }
}


const deleteStart = () => ({
  type: c.DELETE_FEATURE_CATEGORY_START
})
const deleteSuccess = ({id}) => ({
  type: c.DELETE_FEATURE_CATEGORY_SUCCESS,
  id,
})
const deleteFail = ({error}) => ({
  type: c.DELETE_FEATURE_CATEGORY_FAIL,
  error,
})
export const deleteItem = ({id}) => async (dispatch) => {
  dispatch(deleteStart())
  try {
      await FeatureCategoryService.del({id});
      dispatch(deleteSuccess({id}));
  } catch (error) {
    dispatch(deleteFail({error}))
  }
}


const getCurrentStart = () => ({
  type: c.GET_CURRENT_FEATURE_CATEGORY_START
})

const getCurrentSuccess = ({currentItem}) => ({
  type: c.GET_CURRENT_FEATURE_CATEGORY_SUCCESS,
  currentItem,
})

const getCurrentFail = ({error}) => ({
  type: c.GET_CURRENT_FEATURE_CATEGORY_FAIL,
  error,
})

export const getCurrent = ({id}) => async (dispatch) => {
  dispatch(getCurrentStart());
  try {
      const { data } = await FeatureCategoryService.getOne({id});
      
      dispatch(getCurrentSuccess({currentItem: data.data}));
  } catch (error) {
    dispatch(getCurrentFail({error}))
  }
}