import React from 'react';
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Row,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
  } from "reactstrap";
  import { Alert } from "reactstrap";
  import { Link } from "react-router-dom";


import Header from "../../Headers/SlimHeader";

const HealthCoverType = (props) =>{
    const { data } = props;
    
    return (
        <>
             <Header />
             <Container fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">All Health Cover types</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Full Name</th>
                      <th scope="col">Slug</th>
                      <th scope="col">Supported Visa</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data && data.map(healthCoverType => (
                        <tr key={healthCoverType.id}>
                          <th scope="row">
                            {healthCoverType.name}
                          </th>
                          <td>{healthCoverType.fullName}</td>
                          <td>
                            {healthCoverType.slug}
                          </td>
                          
                          <td>
                      {healthCoverType.supportedVisas.map(supportedVisa => <div key={supportedVisa}>{supportedVisa}</div>)}
                          </td>
                          <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-dark"
                                    role="button"
                                    href="#"
                                    size="sm"
                                    color=""
                                    onClick={e => e.preventDefault()}
                                  >
                                    <i className="fa fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    <Link 
                                      className="dropdown-item"
                                      to={`/admin/health-cover-type/${healthCoverType.id}/edit`}
                                    >
                                      Edit
                                    </Link>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                </Table>
                <CardFooter className="py-4">
                  
                {
                  (!data || !data.length) && (
                      <Alert color="info">
                        <strong>No data</strong> You don't have Health Cover Types
                      </Alert>
                  )
                }
                    
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        </>
    );
}

export default HealthCoverType;