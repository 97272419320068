import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Col,
  DropdownItem,
  Badge,
} from 'reactstrap';
import { Alert } from 'reactstrap';
import Avatar from 'react-avatar';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';

import DeleteModal from '../../DeleteModal';
import Header from '../../Headers/SlimHeader';
import { ExportLead } from '../../Components';
import { Search } from '../../common';
import { Utils } from '../../../Common';
import moment from 'moment';

const Lead = (props) => {
  const { total, data, deleteItem, authUser, getAll, submit } = props;
  const [deleteItemId, setDeleteItemId] = useState();
  const [filter, setFilter] = useState({});
  const limit = 10;
  const pageCount = Math.ceil(total / limit);

  const confirmDelete = ({ event, id }) => {
    event.preventDefault();
    setDeleteItemId(id);
  };

  const del = () => {
    deleteItem({ id: deleteItemId });
    setDeleteItemId(null);
  };

  const paginationChange = (data) => {
    getAll({ limit, offset: data.selected, filter });
  };

  const updateStatus = ({ event, id, status }) => {
    event.preventDefault();
    submit({ data: { id, status } });
  };

  const filterStatus = (event) => {
    setFilter({status: event && event.value});
    getAll({ limit, offset: 0, filter: {status: event && event.value} });
  }

  return (
    <>
      <Header />
      <Search getAll={getAll} />
      <Container fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col sm={6}>
                    <h3 className="mb-0">All Leads</h3>
                    
                  </Col>
                  {(authUser.role === 'agent' ||
                    authUser.role === 'superadmin') && (
                    <ExportLead authUser={authUser} />
                  )}
                </Row>
                <Row >
                <Col sm={10} ></Col>
                    <Col sm={2} style={{marginTop: 10}}>
                    <Select
                        placeholder="Filter by status..."
                        name="status"       
                        options={[
                          {
                            label: 'In progress',
                            value: 'inProgress'
                          },
                          {
                            label: 'Certificate issued',
                            value: 'certificateIssued'
                          }
                        ]}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        clerable
                        onChange={event => filterStatus(event)}
                        isClearable={true}
                    />
                    </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">Name</th>
                    <th scope="col">Health Cover Details</th>
                    <th scope="col">Account</th>
                    <th scope="col">Premium</th>
                    {
                      authUser.role === 'superadmin' && (
                        <>
                          <th scope="col">Commission</th>
                          <th scope="col">Agent Commission</th>
                        </>
                      )
                    }
                
               
                    <th scope="col">Policy No</th>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">Added by</th>
                    <th scope="col">Status</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((lead) => (
                      <tr key={lead.id}>
                        <td style={{ width: 40 }}>
                          <Avatar
                            name={`${lead.firstName} ${lead.lastName}`}
                            size="40"
                            round={true}
                          />
                        </td>
                        <th>
                          {lead.firstName} {lead.lastName} <br />
                          {lead.email} <br />
                          {lead.mobile}
                        </th>
                        <td>
                          <strong>Start Date:</strong> {moment(lead.startDate).format('DD/MM/YYYY')}
                          <br />
                          <strong>End Date:</strong> {moment(lead.endDate).format('DD/MM/YYYY')}
                          <br />
                          <strong>Client Type:</strong> {lead.clientType} <br />
                          <strong>Health Cover:</strong> {lead.HealthCover.name} <br />
                          <strong>Provider:</strong> {lead.HealthCover.Provider.name} <br />
                        </td>
                        <td>{lead.Account ? lead.Account.name : '-'}</td>
                        <td>${lead.premium}</td>
                        {
                        authUser.role === 'superadmin' && (
                          <>
                            <td>
                              {Utils.getOSHCApplicationProviderCommission({
                                      premium: lead.premium,
                                      HealthCover: lead.HealthCover,
                                    })}
                            </td>
                            <td>
                                {
                                  Utils.getApplicationCommission({
                                    premium: lead.premium,
                                    Account: lead.Account,
                                    HealthCover: lead.HealthCover,
                                  })
                                }
                            </td>
                          </>
                        )
                      }
                        <td>{lead.policyNo}</td>
                        <td style={{ width: 40 }}>
                          {lead.User && (
                            <Avatar
                              name={`${lead.User.firstName} ${lead.User.lastName}`}
                              size="40"
                              round={true}
                            />
                          )}
                        </td>
                        <th>
                          {lead.User && (
                            <>
                              {lead.User.firstName} {lead.User.lastName} <br />
                              {lead.User.email}
                            </>
                          )}
                        </th>
                        <td>
                          {lead.status === 'inProgress' && (
                            <Badge color="info">In progress</Badge>
                          )}
                          {lead.status === 'insuranceRequested' && (
                            <Badge color="warning">Insurance requested</Badge>
                          )}
                          {lead.status === 'certificateIssued' && (
                            <Badge color="success">Certificate issued</Badge>
                          )}
                        </td>

                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-dark"
                              role="button"
                              href="#"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fa fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {lead.status === 'inProgress' && (
                                <DropdownItem
                                  onClick={(event) =>
                                    updateStatus({
                                      event,
                                      id: lead.id,
                                      status: 'insuranceRequested',
                                    })
                                  }
                                >
                                  Insurance requested
                                </DropdownItem>
                              )}
                              <a
                                className="dropdown-item"
                                href={`/admin/leads/${lead.id}`}
                              >
                                Details
                              </a>

                              {authUser.role === 'superadmin' && (
                                <DropdownItem
                                  onClick={(event) =>
                                    confirmDelete({ event, id: lead.id })
                                  }
                                >
                                  Delete
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {total > limit && (
                  <ReactPaginate
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={10}
                    pageRangeDisplayed={5}
                    onPageChange={paginationChange}
                    containerClassName={'pagination justify-content-end mb-0'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    activeClassName={'active'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    previousLabel="«"
                    nextLabel="»"
                  />
                )}

                {(!data || !data.length) && (
                  <Alert color="info">
                    <strong>No data</strong> You don't have any data yet!
                  </Alert>
                )}

                {deleteItemId && (
                  <DeleteModal
                    showModal={deleteItemId}
                    toggleModal={() => setDeleteItemId()}
                    confirmAction={del}
                    title="Delete this lead?"
                    details="Are you sure you want to delete this lead?"
                  />
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Lead;
