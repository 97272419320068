import client from '../../../../client';

const apiSlug = 'leads';

function exportLead({ data }) {
  return client.post(`${apiSlug}/export`, data);
}


export {
  exportLead
};
