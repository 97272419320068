import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import * as applicationActions from '../../Application/actions';
import * as actions from '../../Review/actions';
import ReviewForm from './ReviewForm';

class ReviewFormContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {formSuccess: false}
  }

  componentDidMount() {
    const {  applicationId } = this.props.match.params;
    const { actions: { getApplication } } = this.props;
    if(applicationId) {
      getApplication({id: applicationId});
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.submittingReview && !this.props.submittingReview) {
      this.setState({formSuccess: true});
    }
  }



  render() {
    const { formSuccess } = this.state;
    const { review, application,  actions: { submitReview }, currentAccount } = this.props;
    if(application && application.status === "certificateIssued") {
      return <Redirect to='/my-cover' />
    }

    return (
      <ReviewForm
        {...this.props}
        formSuccess={formSuccess}
        submitReview={submitReview}
        review={review}
        application={application}
        currentAccount={currentAccount}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
      ...state.Front.Review,
      ...state.Front.Application,
      currentAccount: state.Front.CurrentAccount.data
  }
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators({...actions, getApplication: applicationActions.getApplication}, dispatch)
    }
  }
  

const ConnectedReviewForm = connect(mapStateToProps, mapDispatchToProps)(ReviewFormContainer);

export { ConnectedReviewForm as ReviewForm };

