import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions'
import * as healthCoverTypeActions from '../../HealthCoverType/actions'
import FeatureCategoryForm from './FeatureCategoryForm';

class FeatureCategoryFormContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {}
      }

      clearCurrent() {
        const { actions: {clearCurrent} } = this.props;
        clearCurrent();
      }

      componentDidMount() {
        const {  id } = this.props.match.params;
        const { actions: { getCurrent, getAllHealthCoverTypes }} = this.props;
        if(id) {
          getCurrent({id});
        }
        else {
          this.clearCurrent()
        }
        getAllHealthCoverTypes();
      }

      componentDidUpdate(prevProps) {
        if(prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
          const {history}  = this.props;
          history.push('/admin/feature-categories');
        }
      }
      
      render() {
        const { currentItem, healthCoverTypes,  actions: { submit } } = this.props;
        return (
          <FeatureCategoryForm
            {...this.props}
            currentItem={currentItem}
            submit={submit}
            healthCoverTypes={healthCoverTypes}
          />
        )
      }
}

const mapStateToProps = state => {
    return {
      ...state.Admin.FeatureCategory,
      healthCoverTypes: state.Admin.HealthCoverType.data,
    }
  }
  
  const mapDispatchToProps = dispatch => {
      return {
        actions: bindActionCreators({...actions, getAllHealthCoverTypes:healthCoverTypeActions.getAllHealthCoverTypes}, dispatch)
      }
    }
    
  
  const ConnectedFeatureCategoryForm = connect(mapStateToProps, mapDispatchToProps)(FeatureCategoryFormContainer);
  
  export { ConnectedFeatureCategoryForm as FeatureCategoryForm };