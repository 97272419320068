import * as c from './constants';

const typesOption = [
  { value: 'Single', label: 'Single' },
  { value: 'Couple', label: 'Couple' },
  { value: 'Family', label: 'Family' },
];

const childrenOption = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
];

var d = new Date();
var year = d.getFullYear();
var month = d.getMonth();
var day = d.getDate();

const initialState = {
  updatingApplication: false,
  applicationError: null,
  typesOption,
  childrenOption,
  loadingCurrent: false,
  downloading: false,
  error: null,
  application: {
    id: null,
    coverType: null,
    clientType: typesOption[0].value,
    children: childrenOption[0].value,
    startDate: new Date(),
    endDate: new Date(year + 1, month, day - 1),
    HealthCover: {},
    premium: null,
    title: '',
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    email: '',
    confirmEmail: '',
    mobile: '',
    country: '',
    passportNumber: '',
    visaType: '',
    college: '',
    studentNumber: '',
    australianAddress: '',
    chk1: false,
    chk2: false,
    chk3: false,
    healthCoverId: '',
    partnerTitle: '',
    partnerFirstName: '',
    partnerLastName: '',
    partnerGender: '',
    partnerDateOfBirth: '',
    currentHealthProviderNumber: '',
    currentHealthProviderName: '',
  },
};

const actionHandlers = {
  [c.UPDATE_APPLICATION_START]: (state) => {
    return {
      ...state,
      applicationError: null,
      updatingApplication: true,
    };
  },
  [c.UPDATE_APPLICATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      updatingApplication: false,
      application: { ...state.application, ...action.application },
    };
  },
  [c.UPDATE_APPLICATION_FAIL]: (state, action) => {
    return {
      ...state,
      updatingApplication: false,
      applicationError: action.error,
    };
  },

  [c.GET_CURRENT_APPLICATION_START]: (state) => {
    return {
      ...state,
      applicationError: null,
      loadingCurrent: true,
    };
  },
  [c.GET_CURRENT_APPLICATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      loadingCurrent: false,
      application: { ...state.application, ...action.currentItem },
    };
  },
  [c.GET_CURRENT_APPLICATION_FAIL]: (state, action) => {
    return {
      ...state,
      loadingCurrent: false,
      applicationError: action.error,
    };
  },

  [c.CLEAR_CURRENT_APPLICATION]: (state) => {
    return {
      ...state,
      application: initialState.application,
    };
  },

  [c.DOWNLOAD_APPLICATION_INVOICE_START]: (state) => {
    return {
      ...state,
      error: null,
      downloading: true,
    };
  },
  [c.DOWNLOAD_APPLICATION_INVOICE_SUCCESS]: (state, action) => {
    return {
      ...state,
      downloading: false,
    };
  },
  [c.DOWNLOAD_APPLICATION_INVOICE_FAIL]: (state, action) => {
    return {
      ...state,
      downloading: false,
      error: action.error,
    };
  },
};

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
};
