import * as c from './constants';
import * as HealthCoverService from './services';


const getHealthCoversStart = () => ({
  type: c.GET_HEALTH_COVERS_START
})

const getHealthCoversSuccess = ({healthCovers}) => ({
  type: c.GET_HEALTH_COVERS_SUCCESS,
  healthCovers,
})

const getHealthCoversFail = ({error}) => ({
  type: c.GET_HEALTH_COVERS_FAIL,
  error,
})

export const getAllHealthCovers = ({limit, offset, filter}) => async (dispatch) => {
  dispatch(getHealthCoversStart())
  try {
      const {data} = await HealthCoverService.getAllHealthCovers({limit, offset, filter});
      
      dispatch(getHealthCoversSuccess({healthCovers: data.data}));
  } catch (error) {
    dispatch(getHealthCoversFail({error}))
  }
}


const clearCurrentHealthCoverSuccess = () => ({
  type: c.CLEAR_CURRENT_HEALTH_COVER
})
export const clearCurrentHealthCover = () => async (dispatch) => {
  dispatch(clearCurrentHealthCoverSuccess());
}



const submitHealthCoverStart = () => ({
  type: c.SUBMIT_HEALTH_COVER_START
})
const submitHealthCoverSuccess = ({healthCover}) => ({
  type: c.SUBMIT_HEALTH_COVER_SUCCESS,
  healthCover,
})
const submitHealthCoverFail = ({error}) => ({
  type: c.SUBMIT_HEALTH_COVER_FAIL,
  error,
})
export const submitHealthCoverForm = ({healthCover}) => async (dispatch) => {
  dispatch(submitHealthCoverStart())
  try {
      let response;
      
      if(healthCover.id) {
        response = await HealthCoverService.updateHealthCover({healthCover});
      }
      else {
        response = await HealthCoverService.addHealthCover({healthCover});
      }
      
      const {data} = response;
      
      
      dispatch(submitHealthCoverSuccess({healthCover: data.data}));
  } catch (error) {
    dispatch(submitHealthCoverFail({error}))
  }
}


const deleteHealthCoverStart = () => ({
  type: c.DELETE_HEALTH_COVER_START
})
const deleteHealthCoverSuccess = ({id}) => ({
  type: c.DELETE_HEALTH_COVER_SUCCESS,
  id,
})
const deleteHealthCoverFail = ({error}) => ({
  type: c.DELETE_HEALTH_COVER_FAIL,
  error,
})
export const deleteHealthCover = ({id}) => async (dispatch) => {
  dispatch(deleteHealthCoverStart())
  try {
      await HealthCoverService.deleteHealthCover({id});
      dispatch(deleteHealthCoverSuccess({id}));
  } catch (error) {
    dispatch(deleteHealthCoverFail({error}))
  }
}



const getCurrentStart = () => ({
  type: c.GET_CURRENT_HEALTH_COVER_START
})

const getCurrentSuccess = ({currentItem}) => ({
  type: c.GET_CURRENT_HEALTH_COVER_SUCCESS,
  currentItem,
})

const getCurrentFail = ({error}) => ({
  type: c.GET_CURRENT_HEALTH_COVER_FAIL,
  error,
})

export const getCurrent = ({id}) => async (dispatch) => {
  dispatch(getCurrentStart());
  try {
      const { data } = await HealthCoverService.getOne({id});
      
      dispatch(getCurrentSuccess({currentItem: {...data.data, premium: JSON.parse(data.data.premium)}}));
  } catch (error) {
    dispatch(getCurrentFail({error}))
  }
}