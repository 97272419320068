import React from 'react';

import { Contact, FooterMenu } from '.';

const Footer = () => {
  return (
    <>
        <Contact />
        <FooterMenu />
    </>
  );
}

export default Footer;
