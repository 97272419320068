import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Card,  Button, Table, Row, Col,   PopoverBody, UncontrolledPopover  } from "reactstrap";
import { useHistory } from "react-router-dom";

import { Loader } from '../../../Common';
import { EmailQuote } from '../../common';

const ComparisionTableOSHC = (props) => {
  const history = useHistory();
  const { quotes, featureCategories, loadingQuotes } = props;
  const { coverType } = props.match.params


  const buyNow = ({item}) => {
    if(coverType === 'oshc') {
      const { updateApplication }  = props;

      updateApplication({application: {HealthCover: item.HealthCover, premium: item.premium, healthCoverId: item.HealthCover.id}});
      history.push(`/cover-type/${coverType}/application`);
    }
    else {
      window.location.href = item.agentUrl;
    }
  }
  if(loadingQuotes) return (
    <Container style={{width: '100%', maxWidth: 1400,}}>
      <Row className="text-center mt-lg mb-lg">
          <Col lg="12">
            <Loader />
          </Col>
      </Row>
    </Container>
  );
  if(!quotes) return null;
  
  return (
    <section className="py-sm">
        <Container style={{width: '100%', maxWidth: '100%'}}>
        <EmailQuote type="oshc" />
        
        

        <Table bordered responsive className="d-none d-sm-block">
            <tbody>
                <tr>
                <th rowSpan={2} style={{backgroundColor: '#f9f9f9'}}>
                  <div  style={{
                    height: '203px',
                    fontSize: '28px',
                    width: '250px'
                  }}>
                    Features
                  </div>
                </th>
                {
                  quotes.map(item => (<td className="text-center" key={item.HealthCover.id}>
                    <img alt='' width={100} src={item.HealthCover.Provider.logo} /> <div className="mt-2"><h3>{item.HealthCover.name}</h3></div>
                    {
                      item.HealthCover.offer && (<div style={{whiteSpace: 'normal'}}><strong><span style={{color: 'red'}}>OFFER:</span> {item.HealthCover.offer}</strong></div>)
                    }
                    </td>))
                }
                </tr>
                <tr>
                {
                  quotes.map((item, index) => (<td className="text-center" style={{width: '16%'}} key={`${index}_${item.HealthCover.id}`}><h2>${item.premium} { ((item.HealthCover.name === "ahm") || (item.HealthCover.name === "Medibank ")) ? '*' : '' }</h2> 
                      <Button
                        className={coverType === 'oshc' ? "bg-gradient-primary" : "bg-gradient-secondary"}
                        color={coverType === 'oshc' ? 'primary' : 'secondary'}
                        size="md"
                        onClick={() => buyNow({item})}
                      >
                      BUY NOW
                      </Button>
                  </td>))
                }
                </tr>
                {
                  featureCategories && featureCategories.map((featureCategory, parentIndex) => (
                    <Fragment key={featureCategory.id}>
                    <tr>
                      <th colSpan={quotes.length + 1} style={{backgroundColor: '#f9f9f9'}}>
                        <span className="mr-1" style={{fontSize: 16, fontWeight: 'bold'}}>{featureCategory.name}</span>
                        {
                            featureCategory.description && (
                              <>
                                <i id={`tooltip_feature_description_${parentIndex}`} className="fa fa-question-circle"></i> 
                                <UncontrolledPopover trigger="hover" placement="top" target={`tooltip_feature_description_${parentIndex}`}>
                                  <PopoverBody dangerouslySetInnerHTML={{__html: featureCategory.description}}>
                                   
                                  </PopoverBody>
                                </UncontrolledPopover> 
                              </>
                            )
                          }
                      </th>
                    </tr>
                    {
                      featureCategory.Features.map((feature, index) => (
                        <tr key={feature.id}>
                          <th style={{whiteSpace: 'normal', backgroundColor: '#f9f9f9'}}><span className="mr-1">{feature.name}</span>
                          {
                            feature.details && (
                              <>
                                <i id={`tooltip_feature_name_${index}_${parentIndex}`} className="fa fa-question-circle"></i> 
                                <UncontrolledPopover trigger="hover" placement="top" target={`tooltip_feature_name_${index}_${parentIndex}`}>
                                  <PopoverBody dangerouslySetInnerHTML={{__html: feature.details}}>
                                    
                                  </PopoverBody>
                                </UncontrolledPopover> 
                              </>
                            )
                          }
                          
                          </th>
                          {
                            quotes.map(quote => (
                              <td key={quote.HealthCover.id} className='text-center'>
                                {
                                  feature.HealthCoverFeatures.map(healthCoverFeature => {
                                    return (quote.HealthCover.id === healthCoverFeature.HealthCover.id) ? (
                                    <span key={healthCoverFeature.id} className="text-center" dangerouslySetInnerHTML={{__html: healthCoverFeature.text}}>
                                      
                                    </span>) : null
                                  })
                                }
                              </td>
                            ))
                            
                          }
                        </tr>
                      ))
                    }
                   </Fragment>
                  ))
                }
                <tr>
                  <td style={{backgroundColor: '#f9f9f9'}}>&nbsp;</td>
                {
                  quotes.map((item, index) => (<td className="text-center" key={`${index}_${item.HealthCover.id}`}>
                      <Button
                        className={coverType === 'oshc' ? "bg-gradient-primary" : "bg-gradient-secondary"}
                        color={coverType === 'oshc' ? 'primary' : 'secondary'}
                        size="md"
                        onClick={() => buyNow({item})}
                      >
                      BUY NOW
                      </Button>
                  </td>))
                }
                </tr>
            </tbody>
            </Table>
            

            {
              quotes.map(item => (
                <Card className="p-sm mb-sm text-center d-block d-sm-none" key={item.HealthCover.id}>
                  <div><img alt='' width={150} src={item.HealthCover.Provider.logo} /> </div>
                  <div className="mt-2"><strong>{item.HealthCover.name}</strong></div>
                  <div>
                      <h2>${item.premium}</h2> 
                     <Button
                        className={coverType === 'oshc' ? "bg-gradient-primary" : "bg-gradient-secondary"}
                        color={coverType === 'oshc' ? 'primary' : 'secondary'}
                        size="md"
                        onClick={() => buyNow({item})}
                      >
                      BUY NOW
                      </Button>
                  </div>
                 </Card>))
               
            }

        <div className="text-center form-info p-2">* Prices shown are indicative only, and there may be slight variation of cents in policy premium amount and paid amount due to rounding off.</div> 

        </Container>
    </section>
  );
}

export default withRouter(ComparisionTableOSHC);
