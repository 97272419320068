import React from 'react';
import {  
    Container,
    Row,
    Col 
} from "reactstrap";

const Providers  = (props) => {
  const { data } = props;

  return (
    <section className="section bg-secondary">
        <Container>
            <Row className="justify-content-center text-center">
                <Col lg="12">
                  <h2 className="display-3 text-white">Providers we compare</h2>
                  {
                      data && data.map(provider => <img  key={provider.id} src={provider.logo} style={{width: 100, margin: 15}} alt={provider.name} />)
                  }
                </Col>
              </Row>
        </Container>
    </section>
  );
}

export default Providers;
