import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions'
import Contact from './Contact';

class ContactContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { formSuccess: false}
  }

  componentDidUpdate(prevProps) {
    if(prevProps.submittingMessage && !this.props.submittingMessage) {
      this.setState({formSuccess: true});
    }
  }

  render() {
    const { message, currentAccount, actions: { submitMessage } } = this.props;
    const { formSuccess } = this.state;
    
    return (
      <Contact
        {...this.props}
        message={message}
        submitMessage={submitMessage}
        formSuccess={formSuccess}
        currentAccount={currentAccount}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.Front.Message,
    currentAccount: state.Front.CurrentAccount.data
  }
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedContact = connect(mapStateToProps, mapDispatchToProps)(ContactContainer);

export { ConnectedContact as Contact };

