export const GET_REVIEWS_START = 'GET_REVIEWS_START';
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_FAIL = 'GET_REVIEWS_FAIL';

export const DELETE_REVIEW_START = 'DELETE_REVIEW_START';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_FAIL = 'DELETE_REVIEW_FAIL';

export const SUBMIT_REVIEW_START = 'SUBMIT_REVIEW_START';
export const SUBMIT_REVIEW_SUCCESS = 'SUBMIT_REVIEW_SUCCESS';
export const SUBMIT_REVIEW_FAIL = 'SUBMIT_REVIEW_FAIL';
