import React from 'react';

import { ApplicationFormOSHC, ApplicationFormOVHC } from '.';
import { Steps, Utility } from '../common';

const Application = (props) => {
  const { coverType } = props.match.params;
  const { currentAccount, application } = props;

  return (
    <main>
      <Steps currentStep={2} type={coverType} />

      {Utility.getHealthCoverUrl({ currentAccount, application }) ||
      !currentAccount ? (
        <ApplicationFormOVHC {...props} />
      ) : (
        <>
          {coverType === 'oshc' ? (
            <ApplicationFormOSHC {...props} />
          ) : (
            <ApplicationFormOVHC {...props} />
          )}
        </>
      )}
    </main>
  );
};

export default Application;
