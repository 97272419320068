import * as c from './constants';

const initialState = {
  loadingAll: false,
  error: null,
  data: [],
  deletingItem: false,
  submittingForm: false
};


const actionHandlers = {
  [c.GET_REVIEWS_START]: (state) => {
    return {
      ...state,
      loadingAll: true,
    }
  },
  [c.GET_REVIEWS_SUCCESS]: (state, action) => {
    return {
      ...state,
      loadingAll: false,
      data: action.data,
      total: action.total
    }
  },
  [c.GET_REVIEWS_FAIL]: (state, action) => {
    return {
      ...state,
      loadingAll: false,
      error: action.error
    }
  },

  [c.DELETE_REVIEW_START]: (state) => {
    return {
      ...state,
      deletingItem: true
    }
  },
  [c.DELETE_REVIEW_SUCCESS]: (state, action) => {
    return {
      ...state,
      deletingItem: false,
      data: state.data.filter(item => item.id !== action.id)
    }
  },
  [c.DELETE_REVIEW_FAIL]: (state) => {
    return {
      ...state,
      deletingItem: false
    }
  },


  [c.SUBMIT_REVIEW_START]: (state) => ({
    ...state,
    submittingForm: true,
  }),
  [c.SUBMIT_REVIEW_SUCCESS]: (state, action) => {
    const alreadyExists = state.data.find((item) => item.id === action.data.id);
    let udpatedData;
    if (!alreadyExists) {
      udpatedData = [action.data, ...state.data];
    } else {
      udpatedData = state.data.map((item) => {
        if (item.id === action.data.id) {
          return action.data;
        }
        return item;
      });
    }

    return {
      ...state,
      submittingForm: false,
      data: udpatedData,
      currentItem: initialState.currentItem,
    };
  },
  [c.SUBMIT_REVIEW_FAIL]: (state, action) => ({
    ...state,
    submittingForm: false,
    error: action.error,
  }),

}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
