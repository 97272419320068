import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../../Views/AuthUser/actions';
import * as logInActions from '../../../Auth/LogIn/actions';
import AdminNavbar  from './AdminNavbar';

class AdminNavbarContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { actions: { getAuthUser} } = this.props;
    getAuthUser();
  }
  
  render() {
    const { loggingOut, logoutError, actions: {deleteAuthUser, logOut}, authUser,  } = this.props;
    if(!authUser) return null;
    return (
      <AdminNavbar
        {...this.props}
        deleteAuthUser={deleteAuthUser}
        user={authUser}
        loggingOut={loggingOut}
        logoutError={logoutError}
        logOut={logOut}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.Admin.AuthUser,
    token: state.Auth.Login.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({...actions, logOut: logInActions.logOut}, dispatch)
  }
}


const ConnectedAdminNavbar = connect(mapStateToProps, mapDispatchToProps)(AdminNavbarContainer)

export { ConnectedAdminNavbar as AdminNavbar }

