const getApplicationCommission = ({premium, Account, HealthCover}) => {
    if(Account && Account.commissions) {
        const commissions = JSON.parse(Account.commissions);
        let percentage = 0;
        commissions.forEach(commission => {
            if(commission.healthCoverId === HealthCover.id) {
                percentage = commission.commission;
            }
        });

        return `$${(premium * percentage / 100).toFixed(2)}`;
    }
    return '-';
    
}

const getOSHCApplicationProviderCommission = ({premium, HealthCover}) => {
    if(HealthCover.Provider.oshcCommission) {
        const percentage = parseFloat(HealthCover.Provider.oshcCommission);
        return `$${(premium * percentage / 100).toFixed(2)}`;
    }
    return '-';
    
}


const getOVHCApplicationProviderCommission = ({premium, HealthCover}) => {
    if(HealthCover.Provider.oshcCommission) {
        const percentage = parseFloat(HealthCover.Provider.oshcCommission);
        return `$${(premium * percentage / 100).toFixed(2)}`;
    }
    return '-';
    
}

const getApplicationTotal = ({premium, Account, HealthCover}) => {
    if(Account.commissions) {
        const commissions = JSON.parse(Account.commissions);
        let percentage = 0;
        commissions.forEach(commission => {
            if(commission.healthCoverId === HealthCover.id) {
                percentage = commission.commission;
            }
        });
        const commission = (premium * percentage / 100).toFixed(2);

        return `$${(premium - commission).toFixed(2)}`;
    }
    return '-';
    
}

export {
    getApplicationCommission,
    getOSHCApplicationProviderCommission,
    getOVHCApplicationProviderCommission,
    getApplicationTotal
}