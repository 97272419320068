export const GET_HEALTH_COVER_FEATURES_START = 'GET_HEALTH_COVER_FEATURES_START';
export const GET_HEALTH_COVER_FEATURES_SUCCESS = 'GET_HEALTH_COVER_FEATURES_SUCCESS';
export const GET_HEALTH_COVER_FEATURES_FAIL = 'GET_HEALTH_COVER_FEATURES_FAIL';

export const GET_CURRENT_HEALTH_COVER_FEATURE_START = 'GET_CURRENT_HEALTH_COVER_FEATURE_START';
export const GET_CURRENT_HEALTH_COVER_FEATURE_SUCCESS = 'GET_CURRENT_HEALTH_COVER_FEATURE_SUCCESS';
export const GET_CURRENT_HEALTH_COVER_FEATURE_FAIL = 'GET_CURRENT_HEALTH_COVER_FEATURE_FAIL';

export const CLEAR_CURRENT_HEALTH_COVER_FEATURE = 'CLEAR_CURRENT_HEALTH_COVER_FEATURE';

export const SUBMIT_HEALTH_COVER_FEATURE_START = 'SUBMIT_HEALTH_COVER_FEATURE_START';
export const SUBMIT_HEALTH_COVER_FEATURE_SUCCESS = 'SUBMIT_HEALTH_COVER_FEATURE_SUCCESS';
export const SUBMIT_HEALTH_COVER_FEATURE_FAIL = 'SUBMIT_HEALTH_COVER_FEATURE_FAIL';

export const DELETE_HEALTH_COVER_FEATURE_START = 'DELETE_HEALTH_COVER_FEATURE_START';
export const DELETE_HEALTH_COVER_FEATURE_SUCCESS = 'DELETE_HEALTH_COVER_FEATURE_SUCCESS';
export const DELETE_HEALTH_COVER_FEATURE_FAIL = 'DELETE_HEALTH_COVER_FEATURE_FAIL';
