import * as c from './constants';
import * as MessageService from './services';

const getAllStart = () => ({
  type: c.GET_MESSAGES_START
})

const getAllSuccess = ({data, total}) => ({
  type: c.GET_MESSAGES_SUCCESS,
  data,
  total
})

const getAllFail = ({error}) => ({
  type: c.GET_MESSAGES_FAIL,
  error,
})

export const getAll = ({limit, offset}) => async (dispatch) => {
  dispatch(getAllStart())
  try {
      const {data} = await MessageService.getAll({limit, offset});
      dispatch(getAllSuccess({data: data.data, total: data.total}));
  } catch (error) {
    dispatch(getAllFail({error}))
  }
}

const getCurrentStart = () => ({
  type: c.GET_CURRENT_MESSAGE_START
})

const getCurrentSuccess = ({currentItem}) => ({
  type: c.GET_CURRENT_MESSAGE_SUCCESS,
  currentItem,
})

const getCurrentFail = ({error}) => ({
  type: c.GET_CURRENT_MESSAGE_FAIL,
  error,
})

export const getCurrent = ({id}) => async (dispatch) => {
  dispatch(getCurrentStart())
  try {
      const { data } = await MessageService.getOne({id});
      
      dispatch(getCurrentSuccess({currentItem: data.data}));
  } catch (error) {
    dispatch(getCurrentFail({error}))
  }
}


const submitStart = () => ({
  type: c.SUBMIT_MESSAGE_START
})
const submitSuccess = ({data}) => ({
  type: c.SUBMIT_MESSAGE_SUCCESS,
  data,
})
const submitFail = ({error}) => ({
  type: c.SUBMIT_MESSAGE_FAIL,
  error,
})
export const submitForm = ({data}) => async (dispatch) => {
  dispatch(submitStart())
  try {
      let response;
      
      if(data.id) {
        response = await MessageService.update({data});
        dispatch(submitSuccess({data: response.data.data}));
      }
  } catch (error) {
    dispatch(submitFail({error}))
  }
}


const deleteStart = () => ({
  type: c.DELETE_MESSAGE_START
})
const deleteSuccess = ({id}) => ({
  type: c.DELETE_MESSAGE_SUCCESS,
  id,
})
const deleteFail = ({error}) => ({
  type: c.DELETE_MESSAGE_FAIL,
  error,
})
export const deleteItem = ({id}) => async (dispatch) => {
  dispatch(deleteStart())
  try {
      await MessageService.del({id});
      dispatch(deleteSuccess({id}));
  } catch (error) {
    dispatch(deleteFail({error}))
  }
}

