import * as c from './constants';

const initialState = {
  loadingHealthCoverTypes: false,
  error: null,
  data: [],
  currentHealthCoverType: null,
  updatingCurrentHalthCoverType: false,
  healthCoverTypeUpdated: false,
};

const actionHandlers = {
  [c.GET_HEALTH_COVER_TYPE_START]: (state) => {
    return {
      ...state,
      healthCoverTypeUpdated: false,
      loadingHealthCoverTypes: true,
    }
  },
  [c.GET_HEALTH_COVER_TYPE_SUCCESS]: (state, action) => {
    return {
      ...state,
      loadingHealthCoverTypes: false,
      data: action.healthCoverTypes.map(healthCoverType => {
        return {...healthCoverType, supportedVisas: JSON.parse(healthCoverType.supportedVisas)}
      }),
    }
  },
  [c.GET_HEALTH_COVER_TYPE_FAIL]: (state, action) => {
    return {
      ...state,
      loadingHealthCoverTypes: false,
      error: action.error
    }
  },

  [c.GET_CURRENT_HEALTH_COVER_TYPE_START]: (state) => {
    return {
      ...state,
      lodingCurrent: true,
      error: null
    }
  },
  [c.GET_CURRENT_HEALTH_COVER_TYPE_SUCCESS]: (state, action) => {
    return {
      ...state,
      lodingCurrent: false,
      currentHealthCoverType: {...action.currentItem, supportedVisas: JSON.parse(action.currentItem.supportedVisas)},
      error: null
    }
  },
  [c.GET_CURRENT_HEALTH_COVER_TYPE_FAIL]: (state, action) => {
    return {
      ...state,
      lodingCurrent: false,
      error: action.error
    }
  },


  [c.UPDATE_HEALTH_COVER_TYPE_START]: (state) => {
    return {
      ...state,
      updatingCurrentHalthCoverType: true,
    }
  },
  [c.UPDATE_HEALTH_COVER_TYPE_SUCCESS]: (state, action) => {
    const updatedDate = state.data.map(item => {
      if(item.id === action.healthCoverType.id) {
        return {...action.healthCoverType, supportedVisas: JSON.parse(action.healthCoverType.supportedVisas)};
      }
      else {
        return item;
      }
    });
    return {
      ...state,
      currentHealthCoverType: {...action.healthCoverType, supportedVisas: JSON.parse(action.healthCoverType.supportedVisas)},
      updatingCurrentHalthCoverType: false,
      healthCoverTypeUpdated: true,
      data: updatedDate
    }
  },
  [c.UPDATE_HEALTH_COVER_TYPE_FAIL]: (state, action) => {
    return {
      ...state,
      updatingCurrentHalthCoverType: false,
      error: action.error
    }
  },

}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
