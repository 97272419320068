import React, { useEffect, useState } from 'react';
import {
  Form, 
  FormGroup, 
  Label, 
  InputGroup, 
  Button,
  Row,
  Col,
  PopoverBody, 
  UncontrolledPopover
} from 'reactstrap';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import useForm from 'react-hook-form';
import { withRouter } from 'react-router-dom';

import { StyledDropdown } from '../../common';
import { typesOption, childrenOption } from './constants';


const schema = yup.object().shape({
  clientType: yup.string().required(),
  children: yup.string().required(),
  startDate: yup.date().required(),
});

const OVHCForm = (props) => {
  const { isQuotePage, application, match: { params: { coverType } } } = props;
  const [formData, setFormData] = useState(application);

  const {
    register, handleSubmit, setValue, errors,
  } = useForm({
    validationSchema: schema,
    defaultValues: application,
  });

  useEffect(() => {
    setFormData(application);
  }, [application]);

  const onSubmit = (data) => {
    const { updateApplication, history } = props;
    const startDate = JSON.stringify(data.startDate);
    updateApplication({
      application: {
        clientType: data.clientType,
        children: data.children,
        startDate: data.startDate,
        coverType,
      },
    });
    history.push(`/cover-type/ovhc/quote/?clientType=${data.clientType}&children=${data.children}&startDate=${startDate}`);
  };

  const handleChange = (selectedOption, name) => {
    setValue([name], selectedOption.value);
    const changeData = { [name]: selectedOption.value };
    if (name === 'clientType') {
      let childrenCount;
      if (selectedOption.value === 'Single' || selectedOption.value === 'Couple') {
        childrenCount = '0';
      } else {
        childrenCount = '1';
      }
      changeData.children = childrenCount;
      setValue('children', childrenCount);
    }
    setFormData({ ...formData, ...changeData });
  };

  const handleDateChange = (selectedDate, name) => {
    setFormData({ ...formData, [name]: selectedDate });
    setValue([name], selectedDate);
  };

  useEffect(() => {
    register({ name: 'clientType' });
    register({ name: 'children' });
    register({ name: 'startDate' });
  }, [register]);

  return (
    <>
    <Row>
        <Col lg="12" >
          <h4>Comparison of OVHC</h4>
        </Col>
      </Row>
    <Row>
        <Col lg="12" className="text-right">
            <span className="form-info"> <span className="form-error">*</span> Indicates required field</span>
        </Col>
      </Row>
    <Form onSubmit={handleSubmit(onSubmit)} className={isQuotePage && 'quote-form'}>
      
      <FormGroup className="form-item">
        <Label>Type<span className="form-error">*</span></Label>
        <InputGroup className="input-group-alternative">

          <StyledDropdown
            selected={typesOption.find((item) => item.value === formData.clientType)}
            onChange={(selected) => handleChange(selected, 'clientType')}
            name="clientType"
            options={typesOption}
            placeholder="Select type"
          />
        </InputGroup>

      </FormGroup>

      <FormGroup className="form-item">
        <Label>Children</Label>
        <InputGroup className="input-group-alternative disable">
          <StyledDropdown
            selected={childrenOption.find((item) => item.value === formData.children)}
            name="children"
            isDisabled={formData.clientType === 'Single' || formData.clientType === 'Couple'}
            onChange={(selected) => handleChange(selected, 'children')}
            options={childrenOption}
            placeholder="Select childrens"
          />
        </InputGroup>
      </FormGroup>

      <FormGroup className="form-item">
        <Label>Policy start date <span className="form-error mr-1">*</span>
        <i id="tooltip_policy_end_date" className="fa fa-question-circle"></i> 
          <UncontrolledPopover placement="top" target="tooltip_policy_end_date">
            <PopoverBody>
            Policy start date should be your last visa end date plus one day.
            </PopoverBody>
          </UncontrolledPopover>
        </Label>
        <InputGroup className="input-group-alternative">
            <DatePicker
              selected={formData.startDate}
              onChange={(selectedDate) =>
                handleDateChange(selectedDate, 'startDate')
              }
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="dd/MM/yyyy"
              className="date-input"
            />
          </InputGroup>
        {errors.startDate && (<small className="form-error">This field is required</small>)}
      </FormGroup>

      <div className="form-action">
        <Button
          block
          className="bg-gradient-secondary"
          color="secondary"
          size="lg"
          type="submit"
        >
                Get quote now
        </Button>
      </div>

    </Form>
  </>
  );
};

export default withRouter(OVHCForm);
