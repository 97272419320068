import React from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Table,
  } from "reactstrap";
import { withRouter } from 'react-router-dom';

import Header from "../../../Headers/SlimHeader";
import { useEffect } from 'react';

const MessageDetails = (props) => {
    const { currentItem, submitForm, history } = props;

    useEffect(() => {
      if(!currentItem.isRead) {
        submitForm({data: {...currentItem, isRead: true}})
      }
    }, [currentItem, submitForm])
    
    if(!currentItem) return null;

    return (
        <>
            <Header />
            <Container fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-white shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                        <h3 className="mb-0">
                          Message details
                        </h3>
                    </Col>
                   
                    <Col className="text-right" xs="4">
                      <Button
                        color="grey"
                        onClick={history.goBack}
                        size="sm"
                      >
                        Back
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                     
                      <Row>
                        <Col lg="12">
                          <Card>
                        <Table className="align-items-center table-flush" responsive>
                          <tbody>
                            <tr>
                              <th width="200">Full name</th>
                              <td>{currentItem.fullName}</td>
                            </tr>
                            <tr>
                              <th>Email</th>
                              <td>{currentItem.email}</td>
                            </tr>
                            <tr>
                              <th>Mobile</th>
                              <td>{currentItem.mobile}</td>
                            </tr>
                            <tr>
                              <th>Country</th>
                              <td>{currentItem.country}</td>
                            </tr>
                            <tr>
                              <th>Message</th>
                              <td style={{whiteSpace: 'unset', fontSize: 18}}><div>
                              {currentItem.message}
                                </div></td>
                            </tr>
                            
                          </tbody>
                        </Table>
                        </Card>
                        </Col>
                        
                      </Row>

                    
                    </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        </>
    );
}

export default withRouter(MessageDetails);