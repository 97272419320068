import React from 'react';
import Select from 'react-select';


const StyledDropdown = (props) => {
   const { selected, onChange, innerRef, name, placeholder, options, getOptionLabel, getOptionValue, isDisabled } = props;

    const customStyles = {
        container: () => ({
            flexGrow: 1,
            padding: 5,
            backgroundColor: props.isDisabled ? '#eeeeee' : '#ffffff',
            borderRadius: 5
        }),
        control: () =>({
            border: 0,
        }),
        indicatorsContainer: () => ({
            display: 'none'
        }),
        placeholder: () => ({
            fontSize: 16,
            color: '#adb5bd'
        }),
        singleValue: () => ({
            fontSize: 16,
            color: '#000000',
        })
      }

    return (
        <Select {...props} 
            isDisabled={isDisabled} 
            value={selected} 
            onChange={onChange} 
            ref={innerRef} 
            name={name} 
            placeholder={placeholder} 
            options={options} 
            styles={customStyles}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            isOptionDisabled={(option) => option.disabled === true}
            />
    );
}

export default StyledDropdown;