import * as c from './constants';
import * as QuoteService from './services';


const getQuoteStart = () => ({
  type: c.GET_QUOTES_START
})

const getQuotesSuccess = ({quotes, featureCategories}) => ({
  type: c.GET_QUOTES_SUCCESS,
  quotes,
  featureCategories
})

const getQuotesFail = ({error}) => ({
  type: c.GET_QUOTES_FAIL,
  error,
})

export const getQuotes = ({ coverType,  clientType, children, startDate, endDate}) => async (dispatch) => {
  dispatch(getQuoteStart())
  try {
      const {data} = await QuoteService.getQuotes({coverType,  clientType, children, startDate, endDate});
      dispatch(getQuotesSuccess({ quotes: data.data.quotes, featureCategories: data.data.featureCategories }));
  } catch (error) {
    dispatch(getQuotesFail({error}))
  }
}