import * as c from './constants';
import * as ActivityService from './services';

const getAllStart = () => ({
  type: c.GET_ACTIVITIES_START,
});

const getAllSuccess = ({ data, total }) => ({
  type: c.GET_ACTIVITIES_SUCCESS,
  data,
  total,
});

const getAllFail = ({ error }) => ({
  type: c.GET_ACTIVITIES_FAIL,
  error,
});

export const getAll = ({ applicationId, limit, offset }) => async (dispatch) => {
  dispatch(getAllStart());
  try {
    const { data } = await ActivityService.getAll({ applicationId, limit, offset });
    dispatch(getAllSuccess({ data: data.data, total: data.total }));
  } catch (error) {
    dispatch(getAllFail({ error }));
  }
};
