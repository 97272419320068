import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
  Col
} from 'reactstrap';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';
import "react-datepicker/dist/react-datepicker.css";
import * as Datetime from 'react-datetime';
import Select from 'react-select';

import { StyledDropdown } from '../../../../../Front/common';

const formSchema = yup.object().shape({
  policyNo: yup.string().required('Policy number is required'),
  premium: yup.string().required('Premium is required'),
  startDate: yup.string().required('Start date is required'),
  endDate: yup.string().required('End date is required'),
});


const LeadForm = (props) => {
  const {
    currentItem, submittingForm, authUser, accounts
  } = props;
  const [certificateError, setCertificateError] = useState(!Boolean(currentItem.certificate));
  const accountOptions = accounts.map((account) => ({
    value: account.id,
    label: `${account.name} (${account.address})`,
  }));
  const [formData, setFormData] = useState(currentItem);
  const paymentModeOptions = [
    {label: 'Directly pay to institution', value: '1'},
    {label: 'NBT', value: '2'},
    {label: 'EFT', value: '3'}
  ];
  const {
    register, handleSubmit, errors, setValue, reset, clearError
  } = useForm({
    validationSchema: formSchema,
    defaultValues: {...currentItem, healthCover: currentItem.HealthCover.name, policyNo: currentItem.policyNo, premium: currentItem.premium},
  });


  useEffect(() => {
    reset({...currentItem, healthCover: currentItem.HealthCover.name});
  }, [currentItem, reset]);

  const onSubmit = async (updatedItem) => {
    const { submit } = props;
    if(!currentItem.certificate) {
      setCertificateError(true);
    }
    else {
      submit({ data: { id: currentItem.id, policyNo: updatedItem.policyNo, premium: updatedItem.premium, accountId: formData.accountId, startDate: formData.startDate, endDate: formData.endDate, remarks: formData.remarks }});
    }
    
  };

  const handleInputChange = (event) => {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    setFormData({...formData, [name]: value});
    setValue([name], value, true);
  };

  const handleDateChange = (selectedDate, name) => {
    setFormData({...formData, [name]: selectedDate});
    setValue(name, selectedDate);
    clearError(name)
  }  

  const handleSelectChange = async (selectedOption, name) => {
    const value = selectedOption.value ? selectedOption.value : selectedOption.name;
    setValue(name, value);
    clearError(name)
  };

  const selectInput = (selected, name) => {
    let value;
    
    if (selected && selected.value) {
      value = selected.value;
    } else {
      value = selected ? JSON.stringify(selected) : null;
    }

    setValue([name], value);
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    register({name: 'startDate'});
    register({name: 'endDate'});
    register({name: 'accountId'});
}, [register, currentItem]);

  return (
    <Form role="form" onSubmit={handleSubmit(onSubmit)}>
      <div >
      {
        // certificateError && (<span className="formError">Certificate file is required</span>)
      }

      {authUser.role === 'superadmin' && (
          <Row>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">
                  Account
                </label>

                <Select
                  value={
                    accountOptions &&
                    accountOptions.find(
                      (item) => item.value === formData.accountId,
                    )
                  }
                  name="accountId"
                  options={accountOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(event) =>
                    selectInput(event, 'accountId')
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        )}


      <FormGroup>
        <label
          className="form-control-label"
        >
              First name <span  className="text-danger">*</span>
        </label>

        <Input
          className="form-control-alternative"
          innerRef={register}
          type="text"
          name="firstName"
          disabled={true}
          onChange={handleInputChange}
        />
      </FormGroup>

      <FormGroup>
        <label
          className="form-control-label"
        >
              Last name <span  className="text-danger">*</span>
        </label>

        <Input
          className="form-control-alternative"
          innerRef={register}
          type="text"
          name="lastName"
          disabled={true}
          onChange={handleInputChange}
        />
      </FormGroup>

      <FormGroup>
        <label
          className="form-control-label"
        >
              Email address <span  className="text-danger">*</span>
        </label>

        <Input
          className="form-control-alternative"
          innerRef={register}
          type="text"
          name="email"
          disabled={true}
          onChange={handleInputChange}
        />
      </FormGroup>

      <FormGroup>
        <label
          className="form-control-label"
        >
              Mobile <span  className="text-danger">*</span>
        </label>

        <Input
          className="form-control-alternative"
          innerRef={register}
          type="text"
          name="mobile"
          disabled={true}
          onChange={handleInputChange}
        />
      </FormGroup>


      <FormGroup>
        <label
          className="form-control-label"
        >
              Client type  <span  className="text-danger">*</span>
        </label>

        <Input
          className="form-control-alternative"
          innerRef={register}
          type="text"
          name="clientType"
          disabled={true}
          onChange={handleInputChange}
        />
      </FormGroup>

      <FormGroup>
        <label
          className="form-control-label"
        >
              Children  <span  className="text-danger">*</span>
        </label>

        <Input
          className="form-control-alternative"
          innerRef={register}
          type="number"
          name="children"
          disabled={true}
          onChange={handleInputChange}
        />
      </FormGroup>


      <FormGroup>
        <label
          className="form-control-label"
        >
              Health Cover  <span  className="text-danger">*</span>
        </label>

        <Input
          className="form-control-alternative"
          innerRef={register}
          type="text"
          name="healthCover"
          disabled={true}
          onChange={handleInputChange}
        />
      </FormGroup>

      {
        currentItem.certificateIssuedAt && (
          <FormGroup>
            <label
              className="form-control-label"
            >
                  Certificate issued at  <span  className="text-danger">*</span>
            </label>
    
            <Input
              className="form-control-alternative"
              innerRef={register}
              type="text"
              name="certificateIssuedAt"
              disabled={true}
              onChange={handleInputChange}
            />
          </FormGroup>
        )
      }

      <FormGroup className="form-item">
      <label
          className="form-control-label"
        >Start date <span style={{fontSize: 12, color: '#cccccc'}}>(DD-MM-YYYY)</span><span className="form-error">*</span></label>
          <InputGroup className="input-group-alternative">
              <Datetime
                  inputProps={{
                    placeholder: "Pick date"
                  }}
                  className="date-time-input"
                  timeFormat={false}
                  closeOnSelect={true}
                  name="startDate"
                  value={formData.startDate ? moment(formData.startDate).tz('Australia/Sydney').format("DD-MM-YYYY"): null}
                  dateFormat="DD-MM-YYYY"
                  onChange={selectedDate => handleDateChange(selectedDate, 'startDate')}
                  />
          </InputGroup>
          {errors.startDate && (<span className="form-error">Start date is required</span>)}
      </FormGroup>

      <FormGroup className="form-item">
        <label
          className="form-control-label"
        >End date <span style={{fontSize: 12, color: '#cccccc'}}>(DD-MM-YYYY)</span><span className="form-error">*</span></label>
          <InputGroup className="input-group-alternative">
              <Datetime
                  inputProps={{
                    placeholder: "Pick date"
                  }}
                  className="date-time-input"
                  timeFormat={false}
                  closeOnSelect={true}
                  name="endDate"
                  value={formData.endDate ? moment(formData.endDate).tz('Australia/Sydney').format("DD-MM-YYYY"): null}
                  dateFormat="DD-MM-YYYY"
                  onChange={selectedDate => handleDateChange(selectedDate, 'endDate')}
                  />
          </InputGroup>
          {errors.endDate && (<span className="form-error">End date is required</span>)}
      </FormGroup>

      <FormGroup>
          <label
            className="form-control-label"
          >
                Premium <span  className="text-danger">*</span>
          </label>

          <Input
            className="form-control-alternative"
            innerRef={register({required: true})}
            type="text"
            name="premium"
            onChange={handleInputChange}
          />
          {errors.premium && <span className="formError">{errors.premium.message}</span>}
        </FormGroup>

        <FormGroup className="form-item">
            <Label>Payment mode</Label>
            <InputGroup className="input-group-alternative">
              <StyledDropdown
                selected={paymentModeOptions.find((item) => item.value === formData.paymentMode)}
                onChange={(selected) => handleSelectChange(selected, 'paymentMode')} 
                name="paymentMode"
                options={paymentModeOptions}
                placeholder='Select payment mode' />
              </InputGroup>
              {errors.healthCoverId && (<span className="form-error">{errors.healthCoverId.message}</span>)}
            
        </FormGroup>
        
        


      <FormGroup>
          <label
            className="form-control-label"
          >
                Policy no <span  className="text-danger">*</span>
          </label>

          <Input
            className="form-control-alternative"
            innerRef={register({required: true})}
            type="text"
            name="policyNo"
            onChange={handleInputChange}
          />
          {errors.policyNo && <span className="formError">{errors.policyNo.message}</span>}
        </FormGroup>

        <FormGroup>
                        <label
                        className="form-control-label"
                        >
                        Remarks
                        </label>
                        <Input
                            className="form-control-alternative"
                            rows="4"
                            innerRef={register}
                            type="textarea"
                            name="remarks"
                            onChange={handleInputChange}
                            />
                    </FormGroup>
        
        {
          certificateError && (<div className="formError">
            Please upload certificate first to update.
          </div>)
        }

        <div className="text-right" style={{marginTop: 15}}>
              <Button
                color="primary"
                type="submit"
                disabled={!!submittingForm || certificateError}
              >
                UPDATE
              </Button>

        </div>

      </div>

    </Form>
  );
};

export default withRouter(LeadForm);
