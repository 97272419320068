import * as c from './constants';
import * as providerService from './services';

const getAllStart = () => ({
    type: c.GET_PROVIDERS_START
  })
  
  const getAllSuccess = ({data}) => ({
    type: c.GET_PROVIDERS_SUCCESS,
    data,
  })
  
  const getAllFail = ({error}) => ({
    type: c.GET_PROVIDERS_FAIL,
    error,
  })
  
  export const getAll = () => async (dispatch) => {
    dispatch(getAllStart())
    try {
        const {data} = await providerService.getAll();
       
        dispatch(getAllSuccess({data: data.data}));
    } catch (error) {
      dispatch(getAllFail({error}))
    }
  }
  