import React from 'react';

import { Features } from '../common';
import Hero from './Hero';

const CoverType = (props) => {
  const { coverType } = props.match.params;

  return (
    <main>
      <Hero type={coverType} />
      <Features coverType={coverType} />
      
    </main>
  );
};

export default CoverType;
