import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../../Review/actions';
import Reviews from './Reviews';

class ReviewsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {formSuccess: false}
  }

  componentDidMount() {
    const { actions: { getAll } } = this.props;
    getAll();
  }


  render() {
    const { data } = this.props;
    return (
      <Reviews
        {...this.props}
        data={data}
      />
    )
  }
}

const mapStateToProps = state => {
  return state.Front.Review
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedReviews = connect(mapStateToProps, mapDispatchToProps)(ReviewsContainer);

export { ConnectedReviews as Reviews };

