import React, {Fragment, useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import { Container, ButtonGroup,  Button, Table, Row, Col, PopoverBody, UncontrolledPopover  } from "reactstrap";
import { useHistory } from "react-router-dom";

import { Loader } from '../../../Common';
import { EmailQuote } from '../../common';

const categories = [
  'Budget Cover',
  'Standard Cover',
  'Mid Cover',
  'Top Cover'
]

const getFilteredQuote = (quotes, currentCategory) => {
  const filteredQuotes =  quotes.filter(quote => quote.HealthCover.category === currentCategory );
    const finalQuotes = [];
    const slimList = []
    for(var i = 0; i < filteredQuotes.length; i++) {
      const currentQuote = filteredQuotes[i];
    
      const currentQuoteName = currentQuote.HealthCover.name;
      if(slimList.includes(currentQuoteName)) {
        finalQuotes[slimList.indexOf(currentQuoteName)].push({...currentQuote, current: false});
      }
      else {
        finalQuotes.push([{...currentQuote, current: true}]);
        slimList.push(currentQuoteName);
      }
    }
    
    return finalQuotes;
}

const ComparisionTableOVHC = (props) => {
  const history = useHistory();
  const { quotes, loadingQuotes, featureCategories } = props;
  const [currentCategory, setCurrentCategory] = useState(categories[0]);
  const [filteredQuotes, setFilteredQuotes] = useState(getFilteredQuote(quotes, currentCategory));
  const { coverType } = props.match.params


  const buyNow = ({item}) => {
    const { updateApplication }  = props;

    updateApplication({application: {HealthCover: item.HealthCover, premium: item.premium, healthCoverId: item.HealthCover.id}});
    history.push(`/cover-type/${coverType}/application`);
  }

  useEffect(() => {
    setFilteredQuotes(getFilteredQuote(quotes, currentCategory));
  },[quotes, currentCategory]);

  const switchExcess = (a, b) => {
   
    const updatedQuotes = [...filteredQuotes];
    for(var i = 0; i < updatedQuotes.length; i++) {
      const item = updatedQuotes[i];
      for(var j = 0; j < item.length; j++) {
        if(updatedQuotes[i][j].current) {
          if(updatedQuotes[i].length > 1) {
            updatedQuotes[i][j].current = false;
          }
        }
        
        if(j===b) {
          if(updatedQuotes[i][j]) {
            updatedQuotes[i][j].current = true;
          }
        }
      }
    }
    
    setFilteredQuotes(updatedQuotes);
  }

  if(loadingQuotes) return (
    <Container style={{width: '100%', maxWidth: 1400,}}>
      <Row className="text-center mt-lg mb-lg">
          <Col lg="12">
            <Loader />
          </Col>
      </Row>
    </Container>
  );


  if(!filteredQuotes) return null;
  
  return (
    <section className="py-sm">
        <Container style={{width: '100%', maxWidth: '100%'}}>
        <EmailQuote type="oshc" />
        <Container>
          <Row className="mb-4">
            <Col style={{textAlign: 'center'}}>
            <ButtonGroup>
              {
                categories.map(category => (
                  <Button   key={category} onClick={() => setCurrentCategory(category)}  color="normal" active={category === currentCategory}>{category}</Button>
                ))
              }
            </ButtonGroup>
            </Col>
          </Row>
        </Container>
        

        <Table bordered responsive className="d-none d-sm-block">
            <tbody>
                <tr>
                <th rowSpan={2} style={{backgroundColor: '#f9f9f9'}}>
                  <div  style={{
                    height: '203px',
                    fontSize: '28px',
                    width: '250px'
                  }}>
                    Features <br />
                    <span style={{fontSize: 16, fontWeight: 'normal', opacity: 0.7, whiteSpace: 'break-spaces'}}>
                    The price and features are reflective only. For more details please check the cover summary or provider website.

                    </span>

                  </div>
                </th>
                {
                  filteredQuotes.map(item => {
                    const currentItem = item.find(i => i.current);
                    
                    return (<td className="text-center" key={currentItem.HealthCover.id}>
                    <img alt='' width={100} src={currentItem.HealthCover.Provider.logo} /> <div className="mt-2"><strong>{currentItem.HealthCover.name}</strong></div></td>);

                  })
                }

                
                </tr>
                <tr>
                {
                  filteredQuotes.map((currentItem, index) => {
                    const item = currentItem.find(i => i.current);
                    return (<td className="text-center" style={{width: '16%'}} key={`${index}_${item.HealthCover.id}`}><h2>${item.premium}/month { ((item.HealthCover.name === "ahm") || (item.HealthCover.name === "Medibank ")) ? '*' : '' }</h2> 
                    <div style={{marginBottom: 10, height: 50}}>
                    <ButtonGroup>
                    {
                      (currentItem.length > 0) && currentItem.map((quoteItem, i) => (
                        <Button active={quoteItem.current ? true : false} key={`btn_${i}`}  onClick={() => switchExcess(index, i)}  color="normal">{quoteItem.HealthCover.excess === "0" ? 'NO EXCESS' : `${quoteItem.HealthCover.excess} EXCESS`}</Button>
                      ))
                    }
                    </ButtonGroup>
                    </div>
                    <Button
                      className={coverType === 'oshc' ? "bg-gradient-primary" : "bg-gradient-secondary"}
                      color={coverType === 'oshc' ? 'primary' : 'secondary'}
                      size="md"
                      onClick={() => buyNow({item})}
                    >
                    BUY NOW
                    </Button>
                </td>);
                  })
                }
                </tr>
                {
                  featureCategories && featureCategories.map((featureCategory, parentIndex) => (
                    <Fragment key={featureCategory.id}>
                    <tr>
                      <th colSpan={filteredQuotes.length + 1} style={{backgroundColor: '#f9f9f9'}}>
                        <span className="mr-1" style={{fontSize: 16, fontWeight: 'bold'}}>{featureCategory.name}</span>
                        {
                            featureCategory.description && (
                              <>
                                <i id={`tooltip_feature_description_${parentIndex}`} className="fa fa-question-circle"></i> 
                                <UncontrolledPopover trigger="hover" placement="top" target={`tooltip_feature_description_${parentIndex}`}>
                                  <PopoverBody dangerouslySetInnerHTML={{__html: featureCategory.description}}>
                                   
                                  </PopoverBody>
                                </UncontrolledPopover> 
                              </>
                            )
                          }
                      </th>
                    </tr>
                    {
                      featureCategory.Features.map((feature, index) => (
                        <tr key={feature.id}>
                          <th style={{whiteSpace: 'normal', backgroundColor: '#f9f9f9'}}><span className="mr-1">{feature.name}</span>
                          {
                            feature.details && (
                              <>
                                <i id={`tooltip_feature_name_${index}_${parentIndex}`} className="fa fa-question-circle"></i> 
                                <UncontrolledPopover trigger="hover" placement="top" target={`tooltip_feature_name_${index}_${parentIndex}`}>
                                  <PopoverBody dangerouslySetInnerHTML={{__html: feature.details}}>
                                    
                                  </PopoverBody>
                                </UncontrolledPopover> 
                              </>
                            )
                          }
                          
                          </th>
                          {
                            filteredQuotes.map(current => {
                              const quote = current.find(i => i.current);
                              return (
                                <td key={quote.HealthCover.id} className='text-center'>
                                  {
                                    feature.HealthCoverFeatures.map(healthCoverFeature => {
                                      return (quote.HealthCover.id === healthCoverFeature.HealthCover.id) ? (
                                      <div key={healthCoverFeature.id}><span  className="text-center" dangerouslySetInnerHTML={{__html: healthCoverFeature.text}}></span>

{
                            healthCoverFeature.details && (
                              <>
                                &nbsp; <i id={`f${healthCoverFeature.id}`} className="fa fa-question-circle"></i> 
                                <UncontrolledPopover trigger="hover" placement="top" target={`f${healthCoverFeature.id}`}>
                                  <PopoverBody dangerouslySetInnerHTML={{__html: healthCoverFeature.details}}>
                                    
                                  </PopoverBody>
                                </UncontrolledPopover> 
                              </>
                            )
                          }
                                        
                                      </div>) : null
                                    })
                                  }
                                </td>
                              );
                            })
                            
                          }
                        </tr>
                      ))
                    }
                   </Fragment>
                  ))
                }
                
                <tr>
                  <td style={{backgroundColor: '#f9f9f9'}}>&nbsp;</td>
                {
                  filteredQuotes.map((current, index) => {
                    const item = current.find(i => i.current);
                    return (<td className="text-center" key={`${index}_${item.HealthCover.id}`}>
                    <Button
                      className={coverType === 'oshc' ? "bg-gradient-primary" : "bg-gradient-secondary"}
                      color={coverType === 'oshc' ? 'primary' : 'secondary'}
                      size="md"
                      onClick={() => buyNow({item})}
                      key={item.id}
                    >
                    BUY NOW
                    </Button>
                </td>);
                  })
                }
                </tr> 
            </tbody>
            </Table>
            


        <div className="text-left form-info">* Prices shown are indicative only, and there may be slight variation of cents in policy premium amount and paid amount due to rounding off.</div> 
        </Container>
    </section>
  );
}

export default withRouter(ComparisionTableOVHC);
