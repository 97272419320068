export const GET_FAQS_START = 'GET_FAQS_START';
export const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS';
export const GET_FAQS_FAIL = 'GET_FAQS_FAIL';

export const GET_CURRENT_FAQ_START = 'GET_CURRENT_FAQ_START';
export const GET_CURRENT_FAQ_SUCCESS = 'GET_CURRENT_FAQ_SUCCESS';
export const GET_CURRENT_FAQ_FAIL = 'GET_CURRENT_FAQ_FAIL';

export const RESET_CURRENT_FAQ_START = 'RESET_CURRENT_FAQ_START';

export const SUBMIT_FAQ_START = 'SUBMIT_FAQ_START';
export const SUBMIT_FAQ_SUCCESS = 'SUBMIT_FAQ_SUCCESS';
export const SUBMIT_FAQ_FAIL = 'SUBMIT_FAQ_FAIL';

export const DELETE_FAQ_START = 'DELETE_FAQ_START';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const DELETE_FAQ_FAIL = 'DELETE_FAQ_FAIL';
