import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import moment from 'moment';

import * as actions from '../actions';
import ApplicationFormOVHC from './ApplicationFormOVHC';
import { Utility } from '../../common';

class ApplicationFormContainerOVHC extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  goToLeadsPage() {
    setTimeout(() => {
      window.location.href = '/admin/leads';
    }, 1000);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.updatingApplication &&
      !this.props.updatingApplication &&
      !this.props.applicationError
    ) {
      const { application, currentAccount } = this.props;

      if (currentAccount && currentAccount.name === 'Demo') {
        const healthCoverProvider =
          application.HealthCover.Provider.name.trim();
        if (
          healthCoverProvider === 'Medibank' ||
          healthCoverProvider === 'ahm'
        ) {
          let scope = 'S';
          switch (application.clientType) {
            case 'Single':
              scope = 'S';
              break;
            case 'Couple':
              scope = 'D';
              break;
            case 'Family':
              scope = 'F';
              break;
            default:
          }
          const data = {
            requestDate: moment().format('DD/MM/YYYY'),
            requestTime: moment().format('HH:mm:ss'),
            fundId: healthCoverProvider === 'Medibank' ? 'M' : 'A',
            origin: 'AU',
            affiliateDetails: {
              id: healthCoverProvider === 'Medibank' ? '98308' : '90684',
              externalRef: application.id,
            },
            quoteDetails: {
              coverStartDate: moment(application.startDate).format(
                'DD/MM/YYYY',
              ),
              coverEndDate: moment(application.endDate).format('DD/MM/YYYY'),
              scope,
              quoteId: '',
            },
            coverDetails: {
              individuals: [
                {
                  individual: {
                    type: application.gender === 'Male' ? 'M' : 'F',
                    dateOfBirth: application.dateOfBirth,
                    title: application.title,
                    firstName: application.firstName,
                    lastname: application.lastName,
                    gender: application.gender,
                    passportNumber: application.passportNumber,
                    passportCountry: '',
                  },
                },
              ],
              contactDetails: {
                email: application.email,
                contactLocations: [{
                  contactLocation: {
                    mobile: application.mobile
                  }
                }]
              }
            }
          };
          axios.defaults.withCredentials = true;
          let self = this;
          axios({
            method: 'POST',
            url:
              application.HealthCover.name === 'Medibank Essential'
                ? 'https://www.medibankoshc.com.au/bin/oshc/affiliates/v1/join'
                : 'https://www.ahmoshc.com.au/bin/oshc/affiliates/v1/join',
            data: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json',
            },
          }).then(function (response) {
            window.open(`${response.headers['target-location']}`, '_blank');
            self.goToLeadsPage();
          });
        }
      } else {
        if (Utility.getHealthCoverUrl({ currentAccount, application })) {
          const healthCoverUrl = Utility.getHealthCoverUrl({
            currentAccount,
            application,
          });
          let agentUrl = healthCoverUrl.agentUrl;
          if(agentUrl.indexOf('https') === -1) {
            agentUrl = `https://${agentUrl}`;
          }
          window.open(`${agentUrl}`, '_blank');
        } else {
          window.open(`${application.HealthCover.agentUrl}`, '_blank');
        }
        this.goToLeadsPage();
      }
    }
  }

  render() {
    const {
      application,
      currentAccount,
      actions: { updateApplication },
    } = this.props;
    return (
      <ApplicationFormOVHC
        {...this.props}
        application={{
          ...application,
          coverType: application.coverType,
          accountId: currentAccount && currentAccount.id,
        }}
        updateApplication={updateApplication}
        currentAccount={currentAccount}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.Front.Application,
    currentAccount: state.Front.CurrentAccount.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

const ConnectedApplicationFormOVHC = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationFormContainerOVHC);

export { ConnectedApplicationFormOVHC as ApplicationFormOVHC };
