import React from "react";
import { Route, Switch } from "react-router-dom";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
  } from "reactstrap";
  
  import { LogIn, ForgotPassword, ResetPassword } from '../views/Auth';

class Admin extends React.Component {
    render() {
        return (
            <main>
                <section className="section section-shaped section-lg" style={{height: '100vh'}}>
                    <div className="shape bg-gradient-primary" >
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                   
                    </div>
                    <Container className="pt-lg-md">
                    <Row className="justify-content-center">
                        <Col lg="5">
                            <div className="mb-4 text-center">
                            <img
                                style={{width: 300, height: 'auto'}}
                                alt="OSHC Compare"
                                src={require('../assets/images/brand/logo-white.png')}
                                />
                            </div>
                            <Card className="shadow border-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <Switch>
                                        <Route  path="/auth/reset-password/token/:id" component={ResetPassword} />
                                        <Route  path="/auth/forgot-password" component={ForgotPassword} />
                                        <Route path="/" component={LogIn} />
                                    </Switch>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    </Container>
                </section>
                </main>
        );
    }
 }

 export default Admin;