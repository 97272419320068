import cogoToast from 'cogo-toast';

import * as c from './constants';
import * as ApplicationService from './services';

const getAllStart = () => ({
  type: c.GET_APPLICATIONS_START
})

const getAllSuccess = ({data, total}) => ({
  type: c.GET_APPLICATIONS_SUCCESS,
  data,
  total
})

const getAllFail = ({error}) => ({
  type: c.GET_APPLICATIONS_FAIL,
  error,
})

export const getAll = ({limit, offset, filter}) => async (dispatch) => {
  dispatch(getAllStart())
  try {
      const {data} = await ApplicationService.getAll({limit, offset, filter});
      
      dispatch(getAllSuccess({data: data.data, total: data.total}));
  } catch (error) {
    dispatch(getAllFail({error}))
  }
}



const getStatsStart = () => ({
  type: c.GET_APPLICATION_STATS_START
})

const getStatsSuccess = ({stats}) => ({
  type: c.GET_APPLICATION_STATS_SUCCESS,
  stats,
})

const getStatsFail = ({error}) => ({
  type: c.GET_APPLICATION_STATS_FAIL,
  error,
})

export const getStats = ({filter}) => async (dispatch) => {
  dispatch(getStatsStart())
  try {
      const {data} = await ApplicationService.getStats({filter});
      
      dispatch(getStatsSuccess({stats: data.data}));
  } catch (error) {
    dispatch(getStatsFail({error}))
  }
}


const getCurrentStart = () => ({
  type: c.GET_CURRENT_APPLICATION_START
})

const getCurrentSuccess = ({currentItem}) => ({
  type: c.GET_CURRENT_APPLICATION_SUCCESS,
  currentItem,
})

const getCurrentFail = ({error}) => ({
  type: c.GET_CURRENT_APPLICATION_FAIL,
  error,
})

export const getCurrent = ({id}) => async (dispatch) => {
  dispatch(getCurrentStart())
  try {
      const { data } = await ApplicationService.getOne({id});
      
      dispatch(getCurrentSuccess({currentItem: data.data}));
  } catch (error) {
    dispatch(getCurrentFail({error}))
  }
}


const submitStart = () => ({
  type: c.SUBMIT_APPLICATION_START
})
const submitSuccess = ({data}) => ({
  type: c.SUBMIT_APPLICATION_SUCCESS,
  data,
})
const submitFail = ({error}) => ({
  type: c.SUBMIT_APPLICATION_FAIL,
  error,
})
export const submit = ({data}) => async (dispatch) => {
  dispatch(submitStart())
  try {
      
      let response = await ApplicationService.update({data});
     
      dispatch(submitSuccess({data: response.data.data}));
  } catch (error) {
    dispatch(submitFail({error}))
  }
}


const deleteStart = () => ({
  type: c.DELETE_APPLICATION_START
})
const deleteSuccess = ({id}) => ({
  type: c.DELETE_APPLICATION_SUCCESS,
  id,
})
const deleteFail = ({error}) => ({
  type: c.DELETE_APPLICATION_FAIL,
  error,
})
export const deleteItem = ({id}) => async (dispatch) => {
  dispatch(deleteStart())
  try {
      await ApplicationService.del({id});
      dispatch(deleteSuccess({id}));
  } catch (error) {
    dispatch(deleteFail({error}))
  }
}


const deleteCertificteStart = () => ({
  type: c.DELETE_CERTIFICATE_START
})
const deleteCertificateSuccess = ({data}) => ({
  type: c.DELETE_CERTIFICATE_SUCCESS,
  currentItem: data,
})
const deleteCertificateFail = ({error}) => ({
  type: c.DELETE_CERTIFICATE_FAIL,
  error,
})
export const deleteCertificate = ({id}) => async (dispatch) => {
  dispatch(deleteCertificteStart())
  try {
      let response =  await ApplicationService.delCertificate({id});
      dispatch(deleteCertificateSuccess({data: response.data.data}));
  } catch (error) {
    dispatch(deleteCertificateFail({error}))
  }
}


const assignUserStart = () => ({
  type: c.ASSIGN_USER_START
})
const assignUserSuccess = ({data}) => ({
  type: c.ASSIGN_USER_SUCCESS,
  data,
})
const assignUserFail = ({error}) => ({
  type: c.ASSIGN_USER_FAIL,
  error,
})
export const assignUser = ({id, assigneeId}) => async (dispatch) => {
  dispatch(assignUserStart())
  try {
      
      let response =  await ApplicationService.assignUser({id, assigneeId});
      cogoToast.success('User assigned to application successfully!');
      dispatch(assignUserSuccess({data: response.data.data}));      
  } catch (error) {
    cogoToast.error('There was an error assigning user to application!');
    dispatch(assignUserFail({error}))
  }
}

