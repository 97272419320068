import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions';
import * as activityActions from '../../Activity/actions';
import ApplicationDetails from './ApplicationDetails';

class ApplicationDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const {
      actions: { getCurrent, getAllActivities },
    } = this.props;
    if (id) {
      getCurrent({ id });
      getAllActivities({ applicationId: id, offset: 0, limit: 100 });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      const { id } = this.props.match.params;
      const {
        actions: { getCurrent, getAllActivities },
      } = this.props;
      if (id) {
        getCurrent({ id });
        getAllActivities({ applicationId: id, offset: 0, limit: 100 });
      }
    }
  }

  render() {
    const {
      currentItem,
      authUser,
      deletingCertificate,
      activities,
      actions: { deleteCertificate, getCurrent },
    } = this.props;

    if (!currentItem) return null;
    return (
      <ApplicationDetails
        {...this.props}
        currentItem={currentItem}
        getCurrent={getCurrent}
        activities={activities}
        authUser={authUser}
        deleteCertificate={deleteCertificate}
        deletingCertificate={deletingCertificate}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Admin.Application,
  activities: state.Admin.Activity.data,
  authUser: state.Admin.AuthUser.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { ...actions, getAllActivities: activityActions.getAll },
    dispatch,
  ),
});

const ConnectedApplicationDetails = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationDetailsContainer);

export { ConnectedApplicationDetails as ApplicationDetails };
