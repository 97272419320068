import React from 'react';

import Features from './Features';
import Hero from './Hero';

const AboutUs = (props) => {
  return (
    <main>
      <Hero />
      <Features />
    </main>
  );
}

export default AboutUs;
