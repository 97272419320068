import * as c from './constants';

const initialState = {
  loadingProviders: false,
  error: null,
  data: [],
  currentProvider: { name: '', intro: '', logo: ''},
  submittingForm: false,
  deletingProvider: false
};


const actionHandlers = {
  [c.GET_PROVIDERS_START]: (state) => {
    return {
      ...state,
      loadingProviders: true,
    }
  },
  [c.GET_PROVIDERS_SUCCESS]: (state, action) => {
    
    return {
      ...state,
      loadingProviders: false,
      data: action.providers,
    }
  },
  [c.GET_PROVIDERS_FAIL]: (state, action) => {
    return {
      ...state,
      loadingProviders: false,
      error: action.error
    }
  },


  [c.GET_CURRENT_PROVIDER_START]: (state) => {
    return {
      ...state,
      loadingCurrent: true,
      error: null
    }
  },
  [c.GET_CURRENT_PROVIDER_SUCCESS]: (state, action) => {
    return {
      ...state,
      loadingCurrent: false,
      currentProvider: action.currentItem,
      error: null
    }
  },
  [c.GET_CURRENT_PROVIDER_FAIL]: (state, action) => {
    return {
      ...state,
      loadingCurrent: false,
      error: action.error
    }
  },

  [c.CLEAR_CURRENT_PROVIDER]: (state) => {
    return {
      ...state,
      currentProvider: initialState.currentProvider
    }
  },

  [c.SUBMIT_PROVIDER_START]: (state) => {
    return {
      ...state,
      submittingForm: true,
    }
  },
  [c.SUBMIT_PROVIDER_SUCCESS]: (state, action) => {
    const alreadyExists = state.data.find(item => item.id === action.provider.id);
    let udpatedData;
    if(!alreadyExists) {
      udpatedData = [action.provider, ...state.data];
    }
    else {
      udpatedData = state.data.map(item => {
        if(item.id === action.provider.id) {
          return action.provider;
        }
        else {
          return item;
        }
      });
    }

    return {
      ...state,
      submittingForm: false,
      data: udpatedData,
      currentProvider: initialState.currentProvider
    }
  },
  [c.SUBMIT_PROVIDER_FAIL]: (state, action) => {
    return {
      ...state,
      submittingForm: false,
      error: action.error
    }
  },

  [c.DELETE_PROVIDER_START]: (state) => {
    return {
      ...state,
      deletingProvider: true
    }
  },
  [c.DELETE_PROVIDER_SUCCESS]: (state, action) => {
    return {
      ...state,
      deletingProvider: false,
      data: state.data.filter(item => item.id !== action.id)
    }
  },
  [c.DELETE_PROVIDER_FAIL]: (state) => {
    return {
      ...state,
      deletingProvider: false
    }
  },
}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
