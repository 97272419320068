import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions';
import * as featureActions from '../../Feature/actions';
import * as healthCoverActions from '../../HealthCover/actions';
import HealthCoverFeatureForm from './HealthCoverFeatureForm';

class HealthCoverFeatureFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const {
      actions: {
        getCurrent, clearCurrent, getAllFeatures, getAllHealthCovers,
      },
    } = this.props;
    if (id) {
      getCurrent({ id });
    } else {
      clearCurrent();
    }
    getAllFeatures({ limit: 1000, offset: 0 });
    getAllHealthCovers({ limit: 1000, offset: 0, filter: {} });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
      const {history}  = this.props;
      history.push('/admin/health-cover-features');
    }
  }


  render() {
    const {
      currentItem, actions: { submit }, feature, healthCover,
    } = this.props;

    return (
      <HealthCoverFeatureForm
        {...this.props}
        currentItem={currentItem}
        submit={submit}
        features={feature.data}
        healthCovers={healthCover.data}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Admin.HealthCoverFeature,
  feature: state.Admin.Feature,
  healthCover: state.Admin.HealthCover,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actions, getAllFeatures: featureActions.getAll, getAllHealthCovers: healthCoverActions.getAllHealthCovers }, dispatch),
});


const ConnectedHealthCoverFeatureForm = connect(mapStateToProps, mapDispatchToProps)(HealthCoverFeatureFormContainer);

export { ConnectedHealthCoverFeatureForm as HealthCoverFeatureForm };
