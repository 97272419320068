import * as c from './constants';

const initialState = {
  submittingReview: false,
  error: null,
  data: [],
  loadingAll: false,
  review: {rating: 5, review: ''},
};


const actionHandlers = { 
    [c.GET_PROVIDERS_START]: (state) => {
        return {
            ...state,
            loadingAll: true,
        }
    },
    [c.GET_PROVIDERS_SUCCESS]: (state, action) => {
        return {
            ...state,
            loadingAll: false,
            data: action.data,
        }
    },
    [c.GET_PROVIDERS_FAIL]: (state, action) => {
        return {
            ...state,
            loadingAll: false,
            error: action.error
        }
    },
}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
