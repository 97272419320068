import client from '../../../client';

const apiSlug = `reviews`;

function add({review}) {
    return client.post(apiSlug, review);
}

function getAll() {
    return client.get(`${apiSlug}/?approved=true`);
}


export { add, getAll };