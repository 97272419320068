import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cogoToast from 'cogo-toast';

import * as actions from './actions'
import Lead from './Lead';

class LeadContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.actions.getAll({limit: 10, offset: 0, filter:{}}); 
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
      cogoToast.success('Lead updated successfully!');
    }
  }

  render() {
    const { data, total, actions: { deleteItem, getAll, submit } } = this.props;
    return (
      <Lead
        {...this.props}
        data={data}
        deleteItem={deleteItem}
        getAll={getAll}
        total={total}
        submit={submit}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.Admin.Lead,
    authUser: state.Admin.AuthUser.authUser
  }
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedLead = connect(mapStateToProps, mapDispatchToProps)(LeadContainer);

export { ConnectedLead as Lead };

