import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

import { reducer as LoginReducer } from './views/Auth/LogIn';
import { reducer as ForgotPasswordReducer } from './views/Auth/ForgotPassword';
import { reducer as ResetPasswordReducer } from './views/Auth/ResetPassword';

import { reducer as HealthCoverTypeReducer } from './views/Admin/Views/HealthCoverType';
import { reducer as ProviderReducer } from './views/Admin/Views/Provider';
import { reducer as HealthCoverReducer } from './views/Admin/Views/HealthCover';
import { reducer as QuoteReducer } from './views/Front/Quote';
import { reducer as FrontMessageReducer } from './views/Front/common/Footer/Contact';
import { reducer as FrontFaqReducer } from './views/Front/common/Footer/FAQs';
import { reducer as FrontReviewReducer } from './views/Front/Review';
import { reducer as MyCoverReducer } from './views/Front/MyCover';
import { reducer as CurrentAccountReducer } from './views/Front/CurrentAccount';
import { reducer as FrontHealthCoverTypeReducer } from './views/Front/HealthCoverType';
import { reducer as FrontProviderReducer } from './views/Front/Provider';
import { reducer as FrontPaymentReducer } from './views/Front/Payment';
import { reducer as EmailQuoteReducer } from './views/Front/common/EmailQuote';

import { reducer as ApplicationReducer } from './views/Front/Application';
import { reducer as FeatureCategoryReducer } from './views/Admin/Views/FeatureCategory';
import { reducer as FeatureReducer } from './views/Admin/Views/Feature';
import { reducer as HealthCoverFeatureReducer } from './views/Admin/Views/HealthCoverFeature';
import { reducer as AdminApplicationReducer } from './views/Admin/Views/Application';
import { reducer as UserReducer } from './views/Admin/Views/User';
import { reducer as AccountReducer } from './views/Admin/Views/Account';
import { reducer as AdminMessageReducer } from './views/Admin/Views/Message';
import { reducer as AdminFaqReducer } from './views/Admin/Views/Faq';
import { reducer as AdminReviewReducer } from './views/Admin/Views/Review';
import { reducer as AdminLeadReducer } from './views/Admin/Views/Lead';
import { reducer as AuthUserReducer } from './views/Admin/Views/AuthUser';
import { reducer as ActivityReducer } from './views/Admin/Views/Activity';
import { reducer as ApplicationCommentReducer } from './views/Admin/Views/ApplicationComment';
import { reducer as ReportReducer } from './views/Admin/Views/Report';
import { reducer as ExportApplicationReducer } from './views/Admin/Components/ExportApplication';
import { reducer as ExchangeRateReducer } from './views/Admin/Views/ExchangeRate';
import { reducer as PurchaseOrderReducer } from './views/Admin/Views/PurchaseOrder';

const rootReducer = combineReducers({
  Auth: combineReducers({
    Login: LoginReducer,
    ForgotPassword: ForgotPasswordReducer,
    ResetPassword: ResetPasswordReducer,
  }),
  Front: combineReducers({
    Message: FrontMessageReducer,
    Faq: FrontFaqReducer,
    Review: FrontReviewReducer,
    MyCover: MyCoverReducer,
    CurrentAccount: CurrentAccountReducer,
    HealthCoverType: FrontHealthCoverTypeReducer,
    Provider: FrontProviderReducer,
    Payment: FrontPaymentReducer,
    Application: ApplicationReducer,
    Quote: QuoteReducer,
    EmailQuote: EmailQuoteReducer
  }),
  Admin: combineReducers({
    AuthUser: AuthUserReducer,
    HealthCoverFeature: HealthCoverFeatureReducer,
    FeatureCategory: FeatureCategoryReducer,
    Feature: FeatureReducer,
    HealthCover: HealthCoverReducer,
    Provider: ProviderReducer,
    HealthCoverType: HealthCoverTypeReducer,
    Application: AdminApplicationReducer,
    User: UserReducer,
    Account: AccountReducer,
    Message: AdminMessageReducer,
    Faq: AdminFaqReducer,
    Review: AdminReviewReducer,
    Lead: AdminLeadReducer,
    Activity: ActivityReducer,
    ApplicationComment: ApplicationCommentReducer,
    Report: ReportReducer,
    ExportApplication: ExportApplicationReducer,
    ExchangeRate: ExchangeRateReducer,
    PurchaseOrder: PurchaseOrderReducer
  }),
});

// This just allows for multiple middlewares to be integrated with redux
const middleware = [];
middleware.push(thunk);

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
// const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middleware)))
const store = createStore(rootReducer, applyMiddleware(...middleware));


// const persistor = persistStore(store);

export default { store };
