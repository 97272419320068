import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Col,
  DropdownItem,
  Badge,
} from 'reactstrap';
import { Alert } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import Avatar from 'react-avatar';
import moment from 'moment-timezone';

import DeleteModal from '../../DeleteModal';
import { ExportApplication, Assign } from '../../Components';
import { Loader, Utils } from '../../../Common';

const ApplicationGrid = ({
  data,
  updateStatus,
  authUser,
  confirmDelete,
  total,
  limit,
  pageCount,
  paginationChange,
  deleteItemId,
  setDeleteItemId,
  del,
  title,
  status,
  offset,
  loadingAll,
  unArchive,
  isArchived = false,
}) => {
  const [assignApplication, setAssignApplication] = useState(null);

  const showAssignModal = ({ event, application }) => {
    event.preventDefault();
    setAssignApplication(application);
  };

  return (
    <Row>
      <div className="col">
        <Card className="shadow">
          <CardHeader className="border-0">
            <Row>
              <Col sm={6}>
                <h3 className="mb-0">{title} </h3>
              </Col>
              {(authUser.role === 'agent' || authUser.role === 'superadmin') &&
                !(status === 'isArchived') && (
                  <ExportApplication authUser={authUser} />
                )}
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col" width="40">
                  Applicant
                </th>

                <th scope="col">Account</th>

                <th scope="col">Date</th>
                <th scope="col">Premium</th>
                {
                  authUser.role === 'superadmin' && (
                    <th scope="col">Commission</th>
                  )
                }
                
                <th scope="col">Agent Commission</th>
                <th scope="col">Total</th>
                <th scope="col">Type</th>
                <th scope="col">Health Cover</th>
                <th scope="col">Assignee</th>
                <th scope="col">Added By</th>
                <th scope="col">Status</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map(
                  (
                    {
                      id,
                      ip,
                      userAgent,
                      User,
                      referenceNo,
                      createdAt,
                      firstName,
                      lastName,
                      email,
                      premium,
                      clientType,
                      certificate,
                      Account,
                      HealthCover,
                      status,
                      certificateIssuedAt,
                      isArchived,
                      StartedBy
                    },
                    index,
                  ) => (
                    <tr key={id}>
                      <td>
                        <a href={`/admin/applications/${id}/details`}>
                          <div style={{ display: 'flex' }}>
                            <Avatar
                              name={`${firstName} ${lastName}`}
                              size="40"
                              round={true}
                            />
                            <div className="ml-2">
                              <strong>{referenceNo}</strong>
                              <br />
                              <strong>
                                {firstName} {lastName}{' '}
                              </strong>
                              <br />
                              {email}
                            </div>
                          </div>
                        </a>
                      </td>

                      <td>
                        {Account ? Account.name.substring(0, 10) : '-'}{' '}
                        {Account && Account.name.length > 10 && '...'}
                      </td>

                      <td>
                        {certificateIssuedAt
                          ? moment(certificateIssuedAt)
                              .tz(authUser.timezone)
                              .format('DD MMM YYYY')
                          : moment(createdAt)
                              .tz(authUser.timezone)
                              .format('DD MMM YYYY')}
                      </td>
                      <td>${premium}</td>
                      {
                        authUser.role === 'superadmin' && (
                          <td>
                            {Utils.getOSHCApplicationProviderCommission({
                                    premium,
                                    HealthCover,
                                  })}
                          </td>
                        )
                      }
                      
                      <td>
                        {Account
                          ? Utils.getApplicationCommission({
                              premium,
                              Account,
                              HealthCover,
                            })
                          : '-'}
                      </td>
                      <td>
                        {Account
                          ? Utils.getApplicationTotal({
                              premium,
                              Account,
                              HealthCover,
                            })
                          : '-'}
                      </td>
                      <td>{clientType}</td>
                      <td className="text-center">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={HealthCover.Provider.logo}
                            width={40}
                            alt={HealthCover.Provider.name}
                          />
                          <div className="ml-2">{HealthCover.name}</div>
                        </div>
                      </td>
                      <td>
                        {User && (
                          <>
                            <div style={{ display: 'flex' }}>
                              <Avatar
                                name={`${User.firstName} ${User.lastName}`}
                                size="40"
                                round={true}
                              />
                              <div className="ml-2">
                                <strong>
                                  {User.firstName} {User.lastName}{' '}
                                </strong>
                                <br />
                                {User.email
                                  ? User.email.substring(0, 15)
                                  : '-'}{' '}
                                {User.email && User.email.length > 15 && '...'}
                              </div>
                            </div>
                          </>
                        )}
                      </td>
                      <td>
                        {StartedBy && (
                          <>
                            <div style={{ display: 'flex' }}>
                              <Avatar
                                name={`${StartedBy.firstName} ${StartedBy.lastName}`}
                                size="40"
                                round={true}
                              />
                              <div className="ml-2">
                                <strong>
                                  {StartedBy.firstName} {StartedBy.lastName}{' '}
                                </strong>
                                <br />
                                {StartedBy.email
                                  ? StartedBy.email.substring(0, 15)
                                  : '-'}{' '}
                                {StartedBy.email && StartedBy.email.length > 15 && '...'}
                              </div>
                            </div>
                          </>
                        )}
                      </td>
                      <td>
                        {status === 'inProgress' && (
                          <Badge color="info">In progress</Badge>
                        )}
                        {status === 'paymentMade' && (
                          <Badge color="danger">Payment made</Badge>
                        )}
                        {status === 'insuranceRequested' && (
                          <Badge color="warning">Insurance requested</Badge>
                        )}
                        {status === 'certificateIssued' && (
                          <Badge color="success">Certificate issued</Badge>
                        )}
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-dark"
                            role="button"
                            href="#"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fa fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <a
                              className="dropdown-item"
                              href={`/admin/applications/${id}/details`}
                            >
                              Details
                            </a>
                            {status === 'inProgress' &&
                              User &&
                              User.id === authUser.id && (
                                <>
                                  <DropdownItem
                                    onClick={(event) =>
                                      updateStatus({
                                        event,
                                        id,
                                        status: 'paymentMade',
                                      })
                                    }
                                  >
                                    Mark as paid
                                  </DropdownItem>
                                </>
                              )}

                            {status === 'inProgress' && (
                              <>
                                <DropdownItem
                                  onClick={() =>
                                    window.open(
                                      `${window.location.origin}/cover-type/oshc/application/${id}/payment`,
                                    )
                                  }
                                >
                                  Record payment
                                </DropdownItem>
                              </>
                            )}

                            {status === 'paymentMade' &&
                              User &&
                              User.id === authUser.id &&
                              (authUser.role === 'superadmin' ||
                                authUser.role === 'staff') && (
                                <DropdownItem
                                  onClick={(event) =>
                                    updateStatus({
                                      event,
                                      id,
                                      status: 'insuranceRequested',
                                    })
                                  }
                                >
                                  Insurance requested
                                </DropdownItem>
                              )}

                            {certificate && (
                              <>
                                <DropdownItem href={certificate}>
                                  Download policy certificate
                                </DropdownItem>
                              </>
                            )}

                            {status !== 'certificateIssued' &&
                              !isArchived &&
                              (authUser.role === 'superadmin' ||
                                authUser.role === 'staff') && (
                                <DropdownItem
                                  onClick={(event) =>
                                    showAssignModal({
                                      event,
                                      application: {
                                        id,
                                        firstName,
                                        lastName,
                                        email,
                                        premium,
                                        clientType,
                                      },
                                    })
                                  }
                                >
                                  Assign
                                </DropdownItem>
                              )}

                            {authUser.role === 'superadmin' && !isArchived && (
                              <>
                                <DropdownItem divider />
                                <DropdownItem
                                  onClick={(event) =>
                                    confirmDelete({ event, id })
                                  }
                                >
                                  Archive
                                </DropdownItem>
                              </>
                            )}

                            {authUser.role === 'superadmin' && isArchived && (
                              <>
                                <DropdownItem divider />
                                <DropdownItem
                                  onClick={(event) => unArchive({ event, id })}
                                >
                                  Unarchive
                                </DropdownItem>
                              </>
                            )}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ),
                )}
              <tr>
                <td colSpan={13} style={{ height: 100 }}></td>
              </tr>
            </tbody>
          </Table>
          <CardFooter className="py-4">
            <Row>
              <Col lg="3">
                {data.length ? (
                  <small>
                    {limit * offset + 1} - {limit * offset + data.length} of{' '}
                    {total}
                  </small>
                ) : null}
              </Col>
              <Col lg="9">
                {total > limit && (
                  <ReactPaginate
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={10}
                    pageRangeDisplayed={5}
                    onPageChange={(data) =>
                      paginationChange({ data, status, isArchived })
                    }
                    containerClassName={'pagination justify-content-end mb-0'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    activeClassName={'active'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    previousLabel="«"
                    nextLabel="»"
                  />
                )}
              </Col>
            </Row>

            {loadingAll ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                {(!data || !data.length) && (
                  <Alert color="info">
                    <strong>No data</strong> You don't have any data yet!
                  </Alert>
                )}
              </>
            )}

            {deleteItemId && (
              <DeleteModal
                type="ARCHIVE"
                showModal={deleteItemId}
                toggleModal={() => setDeleteItemId()}
                confirmAction={del}
                title="Archive this application?"
                details="Are you sure you want to archive this application?"
              />
            )}

            {assignApplication && (
              <Assign
                setAssignApplication={setAssignApplication}
                application={assignApplication}
              />
            )}
          </CardFooter>
        </Card>
      </div>
    </Row>
  );
};

export default ApplicationGrid;
