import cogoToast from 'cogo-toast';

import * as c from './constants';
import * as authUserService from './services';

const getAuthUserStart = () => ({
  type: c.GET_AUTH_USER_START
})

const getAuthUserSuccess = ({authUser}) => ({
  type: c.GET_AUTH_USER_SUCCESS,
  authUser,
})

const geAuthUserFail = ({error}) => ({
  type: c.GET_AUTH_USER_FAIL,
  error,
})

export const getAuthUser = () => async (dispatch) => {
  dispatch(getAuthUserStart());
  try {
      const { data } = await authUserService.one();
      dispatch(getAuthUserSuccess({authUser: data.data}));
  } catch (error) {
    dispatch(geAuthUserFail({error}))
  }
}


const deleteAuthUserStart = () => ({
  type: c.DELETE_AUTH_USER_START
})

const deleteAuthUserSuccess = () => ({
  type: c.DELETE_AUTH_USER_SUCCESS,
})

const deleteAuthUserFail = ({error}) => ({
  type: c.DELETE_AUTH_USER_FAIL,
  error,
})

export const deleteAuthUser = () => async (dispatch) => {
  dispatch(deleteAuthUserStart());
  try {
      dispatch(deleteAuthUserSuccess());
  } catch (error) {
    console.log("Logout fail with error: " + error)
    dispatch(deleteAuthUserFail({error}));
  }
}



const submitStart = () => ({
  type: c.SUBMIT_AUTH_USER_START
})
const submitSuccess = ({data}) => ({
  type: c.SUBMIT_AUTH_USER_SUCCESS,
  data,
})
const submitFail = ({error}) => ({
  type: c.SUBMIT_AUTH_USER_FAIL,
  error,
})
export const submit = ({data}) => async (dispatch) => {
  dispatch(submitStart())
  try {
      let response;
      response = await authUserService.update({data});
      
      dispatch(submitSuccess({data: response.data.data}));
  } catch (error) {
    dispatch(submitFail({error}))
  }
}

const generateCodeStart = () => ({
  type: c.GENERATE_CODE_START
})

const generateCodeSuccess = ({barcode}) => ({
  type: c.GENERATE_CODE_SUCCESS,
  barcode,
})

const generateCodeFail = ({error}) => ({
  type: c.GENERATE_CODE_FAIL,
  error,
})

export const generateCode = () => async (dispatch) => {
  dispatch(generateCodeStart());
  try {
      const response = await authUserService.generateCode();
      dispatch(generateCodeSuccess({barcode: response.data.data}));
  } catch (error) {
    dispatch(generateCodeFail({error}))
  }
}


const emfaStart = () => ({
  type: c.ENABLE_MFA_START
})

const emfaSuccess = ({user}) => ({
  type: c.ENABLE_MFA_SUCCESS,
  user
})

const emfaFail = ({error}) => ({
  type: c.ENABLE_MFA_FAIL,
  error,
})

export const enableMFA = ({code}) => async (dispatch) => {
  dispatch(emfaStart());
  try {
      const {data} = await authUserService.enableMFA({code});
      cogoToast.success('Two factor authentication is enabled for your account.');
      dispatch(emfaSuccess({user: data.data}));
  } catch (error) {
    cogoToast.error('Invalid verification code');
    dispatch(emfaFail({error}))
  }
}


const removeMFAStart = () => ({
  type: c.REMOVE_MFA_START
})

const removeMFASuccess = ({user}) => ({
  type: c.REMOVE_MFA_SUCCESS,
  user
})

const removeMFAFail = ({error}) => ({
  type: c.REMOVE_MFA_FAIL,
  error,
})

export const removeMFA = ({code}) => async (dispatch) => {
  dispatch(removeMFAStart());
  try {
      const {data} = await authUserService.removeMFA({code});
      cogoToast.success('Two factor authentication is removed for your account.');
      dispatch(removeMFASuccess({user: data.data}));
  } catch (error) {
    cogoToast.error('Could not remove 2FA');
    dispatch(removeMFAFail({error}))
  }
}