import client from '../../../../client';

const apiSlug = `current-user`;


function one() {
    return client.get(`${apiSlug}`);
}

function update({data}) {
    return client.put(`${apiSlug}/${data.id}`, data);
}


function generateCode() {
    return client.post(`${apiSlug}/generate-code`);
}

function enableMFA({code}) {
    return client.post(`${apiSlug}/enable-mfa`, {code});
}

function removeMFA() {
    return client.post(`${apiSlug}/remove-mfa`);
}



export { one, update, generateCode, enableMFA, removeMFA };