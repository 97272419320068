import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions'
import EmailQuote from './EmailQuote';

class EmailQuoteContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: null
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
      this.setState({showModal: false});
    }
  }

  render() {
    const { submittingForm, type, actions: {submit} } = this.props;
    const { showModal } = this.state;
    
    return (
      <EmailQuote
        {...this.props}
        submit={submit}
        submittingForm={submittingForm}
        showModal={showModal}
        type={type}
      />
    )
  }
}

const mapStateToProps = state => {
  return state.Front.EmailQuote
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedEmailQuote = connect(mapStateToProps, mapDispatchToProps)(EmailQuoteContainer);

export { ConnectedEmailQuote as EmailQuote };

