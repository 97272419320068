import * as c from './constants';
import * as ApplicationCommentService from './services';

const getAllStart = () => ({
  type: c.GET_COMMENTS_START,
});

const getAllSuccess = ({ data, total }) => ({
  type: c.GET_COMMENTS_SUCCESS,
  data,
  total,
});

const getAllFail = ({ error }) => ({
  type: c.GET_COMMENTS_FAIL,
  error,
});

export const getAll = ({ applicationId, limit, offset }) => async (dispatch) => {
  dispatch(getAllStart());
  try {
    const { data } = await ApplicationCommentService.getAll({ applicationId, limit, offset });
    dispatch(getAllSuccess({ data: data.data, total: data.total }));
  } catch (error) {
    dispatch(getAllFail({ error }));
  }
};



const submitStart = () => ({
    type: c.SUBMIT_COMMENT_START,
  });
  const submitSuccess = ({ data }) => ({
    type: c.SUBMIT_COMMENT_SUCCESS,
    data,
  });
  const submitFail = ({ error }) => ({
    type: c.SUBMIT_COMMENT_FAIL,
    error,
  });
  export const submit = ({ data }) => async (dispatch) => {
    dispatch(submitStart());
    try {
      const response = await ApplicationCommentService.add({ data });
  
      dispatch(submitSuccess({ data: response.data.data }));
    } catch (error) {
      dispatch(submitFail({ error }));
    }
  };
  