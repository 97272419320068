import * as c from './constants';
import * as healthCoverTypeService from './services';

const getAllStart = () => ({
    type: c.GET_HEALTH_COVER_TYPES_START
  })
  
  const getAllSuccess = ({data}) => ({
    type: c.GET_HEALTH_COVER_TYPES_SUCCESS,
    data,
  })
  
  const getAllFail = ({error}) => ({
    type: c.GET_HEALTH_COVER_TYPES_FAIL,
    error,
  })
  
  export const getAll = () => async (dispatch) => {
    dispatch(getAllStart())
    try {
        const {data} = await healthCoverTypeService.getAll();
        dispatch(getAllSuccess({data: data.data}));
    } catch (error) {
      dispatch(getAllFail({error}))
    }
  }
  