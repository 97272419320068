import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../Report/actions'
import Dashboard from './Dashboard';

class DashboardContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
   const { actions: { getReport }} = this.props;
   getReport();
  }

  render() {
    const { report } = this.props;
    return (
      <Dashboard
        {...this.props}
        report={report}
      />
    )
  }
}

const mapStateToProps = state => {
  return state.Admin.Report
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedDashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);

export { ConnectedDashboard as Dashboard };

