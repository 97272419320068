import React from 'react';
import {
    Container,
    Row,
    Col
} from 'reactstrap';

import Hero from './Hero';

const PrivacyPolicy = (props) => {
    window.scrollTo(0, 0);
  return (
    <main>
      <Hero />

      <section className="section section-lg">
        <Container>
        <Row className="row-grid align-items-center">
            <Col className="order-md-2 nested-list" md="126">
                <ol>
                    <li> <h4>Privacy</h4>
                            <ol>
                               <li>
                                    At OSHC Compare, we respect the importance of Your privacy and we are committed to safeguarding Your personal information. We understand that how we collect, use, disclose and protect your information is important to you. In order to provide our Specific Services to You, we must collect personal information from You, and we will take all steps reasonable to ensure that Your personal information is treated securely and in accordance with this privacy policy (<strong>Privacy Policy</strong>). 
                               </li>
                               <li>
                                    OSHC Compare’s Privacy Policy sets outs out how we collect, use, disclose, store, and protect Your personal information.
                               </li>
                               <li>
                                    By providing personal information to OSHC Compare, You consent to the collection, use and disclosure of Your personal information in accordance with this Privacy Policy and any other arrangements that apply between OSHC Compare and You.
                               </li>
                               <li>
                                    OSHC Compare may change this Privacy Policy from time to time by publishing changes to it on the Website. OSHC Compare encourages You to check the Website periodically to ensure that You are aware of OSHC Compare’s current Privacy Policy.
                               </li>
                               <li>
                                By using the Website after changes have been made to the Privacy Policy, You are deemed to have consented to those changes.
                               </li>

                            </ol>
                    </li>
                    <li>
                        <h4>Definitions</h4>
                        <p>In this Privacy Policy:</p>
                        <ol>
                            <li>
                                <strong>personal information</strong> means information that can be associated with a specific person and can be used to identify that person and includes Your information and the types of information described in clause 3.1 of this Privacy Policy;
                            </li>
                            <li> <strong>Privacy Policy</strong> means this privacy policy (as amended from time to time);</li>
                            <li>
                                <strong>OSHC Compare</strong> or we or us means the ABN 41 639 195 725  (legal entity OSHC Options Pty Ltd); 
                            </li>
                            <li>
                                <strong>Specific Service</strong> means providing a web-based platform to purchase OSHC and OVHC insurance at your own discretion 
                            </li>
                            <li>
                                <strong>Website</strong> means the website provided by OSHC Compare which allows You to view or order or otherwise deal with services, products or goods provided or offered by OSHC Compare; which includes <a rel="noopener noreferrer" href="https://oshccompare.com.au/" target="_blank">https://oshccompare.com.au/</a> or any other web service, software, application or media managed by OSHC Compare or its agents or representatives;
                            </li>
                            <li>
                                <strong>You</strong> or <strong>Your</strong> means the person, firm or organisation (including you) entering into, or that has entered into, a contract, transaction or arrangement to obtain or make use of services, products or goods provided or offered by OSHC Compare or its agents or representatives.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h4>Collecting Personal Information</h4>
                        <ol>
                            <li>OSHC Compare may collect the following types of personal information:
                                    <ol>
                                        <li>name; </li>
                                        <li>gender;</li>
                                        <li>mailing or street address;</li>
                                        <li>email address;</li>
                                        <li>telephone number and other contact details;</li>
                                        <li>age or date of birth;</li>
                                        <li>passport or visa details;</li>
                                        <li>family member details;</li>
                                        <li>academic information (including transcripts, English language proficiency certificates);</li>
                                        <li>Your device ID, device type, geo-location information, computer and connection information, statistics on page views, traffic to and from the sites, ad data, IP address, standard web log information and page interaction information;</li>
                                        <li>details of the services, products and goods OSHC Compare has provided to You or that You have enquired about, including any additional information necessary to deliver those services, products and goods and respond to Your enquiries; </li>
                                        <li>any additional information relating to You that You provide to OSHC Compare directly through the Website or indirectly through Your use of the Website or through other websites or accounts from which You permit OSHC Compare to collect information;</li>
                                        <li>information You provide to OSHC Compare (or its agents or representatives) through customer surveys; </li>
                                        <li>personal information based on your activities on the Website; or </li>
                                        <li>any other personal information that may be required in order to facilitate Your dealings with OSHC Compare. </li>
                                    </ol>
                            </li>
                            <li>We will only collect sensitive personal information about You if we have Your express or implied consent or if the law otherwise permits it. </li>
                            <li>The types of sensitive information that we may collect include:
                                <ol>
                                    <li>racial or ethnic origin;</li>
                                    <li>political opinions;</li>
                                    <li>criminal record;</li>
                                    <li>financial situation (including income or renumeration details); or</li>
                                    <li>health and medical information relating to your current and previous health status, current and previous medical conditions and dietary requirements (where applicable).</li>
                                </ol>
                            </li>
                            <li>
                                OSHC Compare may collect these types of personal information either directly from You or from third parties who include, but are not limited to:
                                <ol>
                                    <li>EXPERT GROUP HOLDINGS PTY LTD (9 610 041 579)</li>
                                    <li>EEVS SYDNEY PTY LTD (ACN 610 026 045); and</li>
                                    <li>other persons related to OSHC Compare.</li>
                                </ol>
                            </li>
                            <li>OSHC Compare may collect personal information when You:   
                                <ol>
                                    <li>register on, or make use of, the Website; </li>
                                    <li>request a service, product or good from OSHC Compare; or</li>
                                    <li>communicate with OSHC Compare through correspondence, chats, email, or when You share information with OSHC Compare from other social applications, services or websites.</li>
                                </ol>
                            </li>
                            <li>
                                When You provide Your personal information to OSHC Compare to request a service or product from us (or our representatives or agents), Your contact details may be retained even if the transaction is not completed. These details are captured using a secure service. 
                            </li>
                            <li>
                            If You provide us with sensitive information, we will only retain such information if:
                                <ol>
                                    <li>You have consented to the collection of the information and it is reasonably necessary for, or directly related to, one of our functions, services or activities;</li>
                                    <li>collection of the information is required or authorised by or under an Australian law or a court/tribunal order, or </li>
                                    <li>collection of the information is authorised for other purposes permitted under the Privacy Act – this includes where we suspect that unlawful activity, or serious misconduct, relating to our functions, services or activities has been, is being or may be engaged in.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h4>Use of Personal Information</h4>
                        <ol>
                            <li>OSHC Compare may collect, hold, use and disclose Your personal information for the following purposes:
                                <ol>
                                    <li>to contact You;</li>
                                    <li>to conduct OSHC Compare’s business or provide You with services or products requested by You;</li>
                                    <li>to enable You to access and use the Website;</li>
                                    <li>to operate, protect, improve and optimise the Website, the business of OSHC Compare or related companies, and OSHC Compare’s user experience, such as to perform analytics, conduct research and for advertising and marketing;</li>
                                    <li>to send You service, support and administrative messages, reminders, technical notices, updates, security alerts;</li>
                                    <li>to send You information requested by You;</li>
                                    <li>the lodgment of language proficiency tests;</li>
                                    <li>providing relevant information, which may include sensitive personal information, to migration agents, educational institutions or bodies (including universities and colleges), Department of Home Affairs or such other similar bodies or institutions;</li>
                                    <li>to send You marketing and promotional messages and other information that may be of interest to You, including information sent by, or on behalf of, OSHC Compare business partners or related companies that OSHC Compare think You may find interesting;</li>
                                    <li>detect, investigate and prevent potentially unlawful acts or omissions, or acts or omissions with the potential to breach OSHC Compare’s terms and conditions, this Privacy Policy or other policies or arrangements between You and OSHC Compare;</li>
                                    <li>verify information for accuracy or completeness (including by way of verification with third parties);</li>
                                    <li>to comply with OSHC Compare’s legal obligations, resolve any disputes that OSHC Compare may have with any of OSHC Compare’s customers or You, and enforce OSHC Compare’s agreements with You or any third parties;</li>
                                    <li>to manage OSHC Compare’s relationship with You, including providing You with updates about the services or products that You have requested from us or responding to Your enquiries;</li>
                                    <li>for other purposes directed by You;</li>
                                    <li>for other purposes authorised under an Australian Law or to ensure that we comply with our obligations under European Union Member State law (if relevant).</li>
                                </ol>
                            </li>
                            <li>By providing OSHC Compare with Your personal information You consent to OSHC Compare (or any third party referred to in this Privacy Policy) using that information for the purposes referred to in the Privacy Policy.</li>
                        </ol>
                    </li>
                    <li>
                        <h4>Accessing your personal information</h4>
                        <ol>
                            <li>You can access the personal information OSHC Compare holds about You by contacting OSHC Compare using the information on the Website. OSHC Compare may need to verify Your identity when You request Your personal information.</li>
                            <li>Sometimes, OSHC Compare may not be able to provide You with access to all of Your personal information and, where this is the case, we will tell You why. Examples when access may be denied include, if:
                                <ol>
                                    <li>providing access would pose a serious and imminent threat to life or health of a person;</li>
                                    <li>providing access would create an unreasonable impact on the privacy of others;</li>
                                    <li>access would be unlawful;</li>
                                </ol>
                                or any other reason that is provided for under the Privacy Act 1988 (Cth).
                            </li>
                            <li>We will take all reasonable steps to provide access within 30 days of Your request.</li>
                            <li>We reserve the right to impose reasonable charges in relation to the following:
                                <ol>
                                    <li>photocopying; </li>
                                    <li>access to electronic databases;</li>
                                    <li>postage; or</li>
                                    <li>other costs of obtaining access to information.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h4>Correcting your personal information</h4>
                        <ol>
                            <li>If You think that any personal information OSHC Compare holds about You is inaccurate, please contact OSHC Compare and OSHC Compare will take reasonable steps to ensure that it is corrected.</li>
                            <li>You may request that OSHC Compare notify certain third parties of changes to Your personal information.</li>
                        </ol>
                    </li>
                    <li>
                        <h4>Failure to provide personal information?</h4>
                        <ol>
                            <li>You are not obliged to give us Your personal information.</li>
                            <li>If you choose not to provide us with the required personal information, we may be unable to provide you with the service or product requested.</li>
                        </ol>
                    </li>
                    <li>
                        <h4>Disclosure of Personal Information</h4>
                        <ol>
                            <li>OSHC Compare may disclose personal information for the purposes described in this Privacy Policy to:
                                <ol>
                                    <li>Department of Home Affairs and such other similar bodies or institutions;</li>
                                    <li>Universities, colleges, tafes and such other similar bodies or institutions;</li>
                                    <li>OSHC Compare’s employees and related bodies corporate; </li>
                                    <li>third party suppliers and service providers (including providers for the operation of OSHC Compare Website or OSHC Compare’s business or in connection with providing OSHC Compare’s products and services to You);</li>
                                    <li>contractors; </li>
                                    <li>professional advisers or consultants, dealers and agents; </li>
                                    <li>web hosting providers and IT systems administrators,</li>
                                    <li>debt collectors and payment systems operators (e.g. merchants receiving card payments); </li>
                                    <li>existing or potential agents, business partners or partners;</li>
                                    <li>sponsors or promoters of any competition;</li>
                                    <li>anyone to whom OSHC Compare’s assets or businesses (or any part of them) are transferred;</li>
                                    <li>specific third parties authorised by You to receive information held by OSHC Compare; or</li>
                                    <li>other persons, including government agencies, regulatory bodies and law enforcement agencies, or as required, authorised or permitted by law. </li>
                                </ol>
                            </li>
                            <li>OSHC Compare is affiliated with a range of third party businesses located overseas. It is likely that OSHC Compare will disclose personal information for the purposes referred to in this Privacy Policy to persons located overseas. The countries in which such persons may be located include (but are not limited to):
                                <ol>
                                    <li>Nepal; and</li>
                                    <li>such other countries referred to on our Website. </li>
                                </ol>
                            </li>
                            <li>Where You provide OSHC Compare with Your personal information for the purposes of a Specific Service on Your behalf or on behalf of another person, OSHC Compare may be required to provide such personal information to the such visa/immigration or enrolment/recruitment providers You have selected or that are required for the purposes of completing an application for a Specific Service or providing other services or products to You (<strong>Other Providers</strong>). OSHC Compare has limited (or no) control over how Other Providers use, disclose or store personal information. You must ensure that You understand and agree with the privacy practices or policies of Other Providers before You provide OSHC Compare with any of Your personal information.  </li>
                            <li>You acknowledge and agree that OSHC Compare provides Your personal information to Other Providers as Your agent and not as the principal holder and, although OSHC Compare will act reasonably in the protection of Your personal information,  OSHC Compare will not be required to ensure that Other Providers comply with applicable privacy laws, this Privacy Policy or otherwise be liable or accountable for Your personal information including without limitation the Other Providers’ use and disclosure of Your personal information. </li>
                        </ol>
                    </li>
                    <li>
                        <h4>Opting out</h4>
                        <ol>
                            <li>OSHC Compare or OSHC Compare’s business partners may send You direct marketing communications and information about OSHC Compare’s services and products. This may take the form of emails, SMS, mail or other forms of communication, in accordance with the Spam Act 2003 (Cth) and the Privacy Act 1988 (Cth). You may opt-out of receiving marketing materials from OSHC Compare by contacting OSHC Compare using the details set out on the Website.</li>
                            <li>You may opt out of receiving marketing, advertising and promotional notices, offers and communications from OSHC Compare by clicking the unsubscribe link at the bottom of any of OSHC Compare’s emails to You or by emailing OSHC Compare at <a href="https://oshccompare.com.au/" without rel="noopener noreferrer" target="_blank">https://oshccompare.com.au/</a>.</li>
                        </ol>
                    </li>
                    <li>
                        <h4>Using our Website and cookies</h4>
                        <ol>
                            <li>OSHC Compare may collect personal information about You when You use and access the Website.</li>
                            <li>While OSHC Compare does not use browsing information to identify You personally, we may record certain information about Your use of the Website, such as which pages You visit, the time and date of Your visit and the internet protocol address assigned to Your device.</li>
                            <li>OSHC Compare may also use 'cookies' or other similar tracking technologies on the Website that help OSHC Compare track Your website usage and remember Your preferences. Cookies are small files that store information on Your computer, TV, mobile phone or other device. They enable the entity that put the cookie on Your device to recognise You across different websites, services, devices and/or browsing sessions. You can disable cookies through Your internet browser but the Website may not work as intended for You if You do so.</li>
                            <li>By making use of the Website, You agree to OSHC Compare placing cookies on Your device and accessing them when you visit the Website in the future. </li>
                        </ol>
                    </li>
                    <li>
                        <h4>Links to other websites</h4>
                        <ol>
                            <li>The Website may contain links to third party websites, micro-sites or analytical tools (e.g. Google) not controlled or owned by OSHC Compare, such as reference, review, ancillary products and services sites or websites owned by related companies. </li>
                            <li>Links to these third party websites on the Website are provided solely for Your convenience (“<strong>Third Party Websites</strong>”). OSHC Compare does not warrant that the representations made on these Website are true and correct nor does OSHC Compare warrant the accuracy or safety of these websites.</li>
                            <li>It is Your responsibility to check the policies and practices of Third Party Websites before using them. OSHC Compare makes no representations or warranties in relation to the privacy practices of any Third Party Website.</li>
                            <li>You acknowledge and agree that:
                                <ol>
                                    <li>to the extent permitted by law, OSHC Compare is not be liable for any loss, expense or cost suffered by You as a result of Your use of Third Party Websites; and</li>
                                    <li>OSHC Compare is not responsible for the privacy policies or the content of any Third Party Website. </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h4>Security</h4>
                        <ol>
                            <li>OSHC Compare may hold Your personal information in either electronic or hard copy form. </li>
                            <li>OSHC Compare takes reasonable steps to protect Your personal information from misuse, interference and loss, as well as unauthorised access, modification or disclosure. For example, OSHC Compare has extensive firewall and intruder detection systems in place to constantly monitor online traffic and financial transactions. However, OSHC Compare cannot guarantee the security of Your personal information. You acknowledge that You provide OSHC Compare with Your personal information at Your own risk.</li>
                        </ol>
                    </li>
                    <li>
                        <h4>Making a complaint</h4>
                        <ol>
                            <li>If You think OSHC Compare has breached the Privacy Act 1988 (Cth), or You wish to make a complaint about the way OSHC Compare has handled Your personal information, You can contact OSHC Compare using the details set out on the Website. Please include Your name, email address and telephone number and clearly describe Your complaint. OSHC Compare will respond to You regarding Your complaint within a reasonable period of time and in accordance with its complaints policy. </li>
                            <li>If you are dissatisfied with OSHC Compare’s handling of the issue, you have the right to lodge a complaint with the Office of the Australia Information Commissioner (OAIC). Please see the website of OAIC for further details about this (<a rel="noopener noreferrer" href="http://www.oaic.gov.au" target="_blank">www.oaic.gov.au</a>).</li>
                            <li>A privacy complaint relates to any concern or dispute that You have with OSHC Compare’s privacy practices as it relates to Your personal information. This could include matters such as: 
                                <ol>
                                    <li>How personal information is collected;</li>
                                    <li>How personal information is stored;</li>
                                    <li>How this information is used or disclosed;</li>
                                    <li>How access is provided; and</li>
                                    <li>Any other matter covered by this privacy policy.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h4>Further information</h4>
                        <p>For further information about OSHC Compare’s Privacy Policy or practices, or to access or correct Your personal information, or make a complaint, please contact OSHC Compare using the following contact details:</p>
                        <p><a href="mailto:support@oshccompare.com.au">support@oshccompare.com.au</a></p>
                    </li>
                </ol>
            </Col>
        </Row>
        </Container>
     </section>
    </main>
  );
}

export default PrivacyPolicy;
