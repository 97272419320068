import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../../HealthCoverType/actions';
import VisaTypes from './VisaTypes';

class VisaTypesContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {formSuccess: false}
  }

  componentDidMount() {
    const { actions: { getAll } } = this.props;
    getAll();
  }


  render() {
    const { data, loadingAll, currentAccount } = this.props;
    return (
      <VisaTypes
        {...this.props}
        data={data}
        loadingAll={loadingAll}
        currentAccount={currentAccount}
      />
    )
  }
}

const mapStateToProps = state => {
  return {...state.Front.HealthCoverType, currentAccount: state.Front.CurrentAccount.data}
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedVisaTypes = connect(mapStateToProps, mapDispatchToProps)(VisaTypesContainer);

export { ConnectedVisaTypes as VisaTypes };

