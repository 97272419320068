import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Cookies } from "react-cookie";

import * as actions from '../views/Admin/Views/AuthUser/actions';
import Admin from './Admin';

class AdminContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.actions.getAuthUser();
  }

  componentDidUpdate() {
    const cookies = new Cookies(); 
    if (!cookies.get('token')) {
      window.location.href = '/auth/login';
    }
  }

  render() {
    const { authUser } = this.props;

    if (!authUser) return null;
    
    return (
      <Admin
        {...this.props}
        role={authUser.role}
        slug={authUser.accountId ? authUser.Account.slug : ''}
      />
    );
  }
}

const mapStateToProps = (state) => state.Admin.AuthUser;

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});


const ConnectedAdmin = connect(mapStateToProps, mapDispatchToProps)(AdminContainer);

export { ConnectedAdmin as Admin };
