import axios from 'axios';
import config from './config';
import { Cookies } from "react-cookie";

const cookies = new Cookies(); 
const token = cookies.get('token');

var axiosInstance = axios.create({
  baseURL: `${config.serverUrl}/api/`,
  headers: {'x-access-token':  token ? token : ''}
});

export default axiosInstance;