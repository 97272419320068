import client from '../../../../client';

const apiSlug = `providers`;

function getOne({id}) {
    return client.get(`${apiSlug}/${id}`);
}

function getAllProviders() {
    return client.get(`providers`);
}

function updateProvider({provider}) {
    return client.put(`providers/${provider.id}`, provider);
}

function addProvider({provider}) {
    return client.post(`providers/`, provider);
}

function deleteProvider({id}) {
    return client.delete(`providers/${id}`);
}


export { getAllProviders, addProvider,  updateProvider, deleteProvider, getOne };