import * as c from './constants';

const initialState = {
  loading: false,
  error: null,
  report: null,
};


const actionHandlers = {
  [c.GET_REPORT_START]: (state) => {
    return {
      ...state,
      loading: true,
    }
  },
  [c.GET_REPORT_SUCCESS]: (state, action) => {
    
    return {
      ...state,
      report: action.report,
      loading: false,
    }
  },
  [c.GET_REPORT_FAIL]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error
    }
  },
}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
