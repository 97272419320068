import * as c from './constants';
import * as ReviewService from './services';

const getAllStart = () => ({
  type: c.GET_REVIEWS_START
})

const getAllSuccess = ({data, total}) => ({
  type: c.GET_REVIEWS_SUCCESS,
  data,
  total
})

const getAllFail = ({error}) => ({
  type: c.GET_REVIEWS_FAIL,
  error,
})

export const getAll = ({limit, offset}) => async (dispatch) => {
  dispatch(getAllStart())
  try {
      const {data} = await ReviewService.getAll({limit, offset});
      dispatch(getAllSuccess({data: data.data, total: data.total}));
  } catch (error) {
    dispatch(getAllFail({error}))
  }
}


const deleteStart = () => ({
  type: c.DELETE_REVIEW_START
})
const deleteSuccess = ({id}) => ({
  type: c.DELETE_REVIEW_SUCCESS,
  id,
})
const deleteFail = ({error}) => ({
  type: c.DELETE_REVIEW_FAIL,
  error,
})
export const deleteItem = ({id}) => async (dispatch) => {
  dispatch(deleteStart())
  try {
      await ReviewService.del({id});
      dispatch(deleteSuccess({id}));
  } catch (error) {
    dispatch(deleteFail({error}))
  }
}



const submitStart = () => ({
  type: c.SUBMIT_REVIEW_START,
});
const submitSuccess = ({ data }) => ({
  type: c.SUBMIT_REVIEW_SUCCESS,
  data,
});
const submitFail = ({ error }) => ({
  type: c.SUBMIT_REVIEW_FAIL,
  error,
});
export const submit = ({ data }) => async (dispatch) => {
  dispatch(submitStart());
  try {
    let response;

    if (data.id) {
      response = await ReviewService.update({ data });
    } else {
      response = await ReviewService.add({ data });
    }

    dispatch(submitSuccess({ data: response.data.data }));
  } catch (error) {
    dispatch(submitFail({ error }));
  }
};