import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions'
import Account from './Account';

class AccountContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.actions.getAll({limit: 10, offset: 0, filter:{}}); 
  }

  render() {
    const { data, total, loggedInUser, actions: { deleteItem, getAll } } = this.props;
    return (
      <Account
        {...this.props}
        data={data}
        deleteItem={deleteItem}
        total={total}
        getAll={getAll}
        loggedInUser={loggedInUser}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.Admin.Account,
    loggedInUser: state.Admin.AuthUser.authUser
  }
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedAccount = connect(mapStateToProps, mapDispatchToProps)(AccountContainer);

export { ConnectedAccount as Account };

