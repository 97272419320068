import React, { useState } from 'react';
import { FormGroup, Button, Input, Row, Col, Form } from "reactstrap";
import Rating from 'react-rating';
import useForm from 'react-hook-form';
import * as yup from 'yup';


const formSchema = yup.object().shape({
    review: yup.string().required('Review is required'),
  });
  

const ReviewForm = ( props ) => {
    const { review, application, formSuccess } = props;
    const [rating, setRating] = useState(review.rating);

    const { register, handleSubmit, errors } = useForm({
        validationSchema: formSchema,
        defaultValues: review
      });

    const onSubmit = async (review) => {
        const { submitReview, currentAccount } = props;
        submitReview({review: {...review, rating, applicationId: application.id, accountId: currentAccount && currentAccount.id}});
    }
  
    return (
        <>
            <Row>
                <Col lg="8">
                    {
                        !formSuccess && (
                            <>
                                <h4>Please rate your experience</h4>
                                <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <Rating
                                    stop={5}
                                    emptySymbol={['fa fa-star-o star-active']}
                                    fullSymbol={['fa fa-star  star-active']}
                                    initialRating={rating}
                                    onChange={value => setRating(value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        className="form-control-alternative"
                                        cols="80"
                                        name="review"
                                        placeholder="Type your review..."
                                        rows="4"
                                        type="textarea"
                                        innerRef={register({required: true})}
                                    />
                                    {errors.review && <span className='form-error'>{errors.review.message}</span>}
                                </FormGroup>
                                <div>
                                    <Button
                                        className="bg-gradient-primary"
                                        color="primary"
                                        size="lg"
                                        type="submit"
                                        >
                                        Submit
                                    </Button>
                                </div>
                                </Form>
                            </>
                        )
                    }

                    {
                        formSuccess && (
                            <>
                                <h5>Thank you for your review</h5>
                                <p>Your review helps us improve our product.</p>
                            </>
                        )
                    }
                    
                </Col>
            </Row>
        </>
    );
}

export default ReviewForm;