export const UPDATE_APPLICATION_START = 'UPDATE_APPLICATION_START';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_FAIL = 'UPDATE_APPLICATION_FAIL';

export const GET_CURRENT_APPLICATION_START = 'GET_CURRENT_APPLICATION_START';
export const GET_CURRENT_APPLICATION_SUCCESS = 'GET_CURRENT_APPLICATION_SUCCESS';
export const GET_CURRENT_APPLICATION_FAIL = 'GET_CURRENT_APPLICATION_FAIL';

export const CLEAR_CURRENT_APPLICATION = 'CLEAR_CURRENT_APPLICATION';


export const DOWNLOAD_APPLICATION_INVOICE_START = 'DOWNLOAD_APPLICATION_INVOICE_START';
export const DOWNLOAD_APPLICATION_INVOICE_SUCCESS = 'DOWNLOAD_APPLICATION_INVOICE_SUCCESS';
export const DOWNLOAD_APPLICATION_INVOICE_FAIL = 'DOWNLOAD_APPLICATION_INVOICE_FAIL';