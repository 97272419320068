import React from 'react';

import Hero from "./Hero";
import { MyCoverForm } from './MyCoverForm';

const MyCover = (props) => {
  return (
    <main>
      <Hero />
      <MyCoverForm {...props} />
     
    </main>
  );
}

export default MyCover;
