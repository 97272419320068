import React from "react";
import { Row, Col} from "reactstrap";
import moment from 'moment';

class Footer extends React.Component {
  render() {
    return (
      <footer className="admin-footer">
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="12">
            <div className="copyright text-center text-xl-left text-muted">
    &copy; {moment().year()}{" "}
              <a
                className="font-weight-bold ml-1"
                href="/"
                rel="noopener noreferrer"
                target="_blank"
              >
                OSHC Compare
              </a>
            </div>
          </Col>
        </Row>
      </footer>
    );
  }
}

export default Footer;