import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as QueryString from 'query-string';
import cogoToast from 'cogo-toast';

import * as actions from '../actions';
import * as applicationActions from '../../Application/actions';

import PaymentForm from './PaymentForm';

class PaymentFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async() => {
    const params = QueryString.parse(window.location.search);
    if(params && params.token) {
      const { actions: {getPaymentStatus} } = this.props;
      await getPaymentStatus({applicationId: this.props.match.params.applicationId, token: encodeURIComponent(params.token)});
    }
  }

  componentDidUpdate =  (prevProps) => {
    if(prevProps.gettingPaymentStatus && !this.props.gettingPaymentStatus) {
      if(this.props.paymentStatus === 'Completed') {
        const { application, currentAccount} = this.props;
        if(currentAccount) {
          window.location.href = `/admin/applications`;
        }
        else {
          window.location.href = `/cover-type/oshc/application/${application.id}/confirmation`;
        }
      }
      else if(this.props.paymentStatus === 'Cancelled') {
        cogoToast.error('You seem to have cancelled the payment. Please use different payment type.');
      }
      else if(this.props.paymentStatus === 'Failed') {
        cogoToast.error('You payment failed. Please use different payment type.');
      }
      else if(this.props.paymentStatus === 'ReceiptUnverified') {
        cogoToast.error('Could not process your payment. Please use different payment type.');
      }
    }

    if (prevProps.submitting && !this.props.submitting && !this.props.error) {
      const { application, paymentType, paymentData, currentAccount } = this.props;
      if(paymentType === 'stripe') {
        if(currentAccount) {
          window.location.href = `/admin/applications`;
        }
        else {
          window.location.href = `/cover-type/oshc/application/${application.id}/confirmation`;
        }
      }
      else if(paymentType === 'poli') {
        window.location.href = paymentData.NavigateURL
      }
      else if(paymentType === 'bankTransfer') {
        if(currentAccount) { 
          window.location.href = `/admin/applications`;
        }
        else {
          window.location.href = `/cover-type/oshc/application/${application.id}/confirmation`;
        }
      }
    }
  }

  render() {
    const { submitting, coverType, gettingPaymentStatus, currentAccount,   actions: { makePayment, downloadInvoice } } = this.props;
    return (
      <PaymentForm
        {...this.props}
        makePayment={makePayment}
        submitting={submitting}
        downloadInvoice={downloadInvoice}
        coverType={coverType}
        gettingPaymentStatus={gettingPaymentStatus}
        currentAccount={currentAccount}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Front.Application,
  ...state.Front.Payment,
  currentAccount: state.Front.CurrentAccount.data
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({...actions, downloadInvoice: applicationActions.downloadInvoice}, dispatch),
});


const ConnectedPaymentForm = connect(mapStateToProps, mapDispatchToProps)(PaymentFormContainer);

export { ConnectedPaymentForm as PaymentForm };
