import client from '../../../../client';

const apiSlug = `feature-categories`;

function getOne({id}) {
    return client.get(`${apiSlug}/${id}`);
}

function getAll() {
    return client.get(apiSlug);
}

function update({data}) {
    return client.put(`${apiSlug}/${data.id}`, data);
}

function add({data}) {
    return client.post(apiSlug, data);
}

function del({id}) {
    return client.delete(`${apiSlug}/${id}`);
}


export { getAll, update,  add, del, getOne };