import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Card, Button, InputGroup, Input, Nav, NavItem, NavLink, Navbar, TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import useForm from 'react-hook-form';
import {CardNumberElement, CardExpiryElement, CardCvcElement, injectStripe} from 'react-stripe-elements';
import * as yup from 'yup';
import LoadingOverlay from 'react-loading-overlay';
import classnames from 'classnames';

import { CoverDetails, ProofOfPayment } from '../../common';


const formSchema = {
    name: yup.string().required('Name is required'),
    address_zip: yup.string().required('Post code is required')
};

const PaymentForm = ( props ) => {
    const { application, submitting, downloadInvoice, coverType, gettingPaymentStatus, currentAccount } = props;
    const [activeTab, setActiveTab] = useState('1');
    const [modal, setModal] = useState();
    const [error, setError] = useState(null);
    const { register, handleSubmit,  errors } = useForm({
        validationSchema: yup.object().shape(formSchema),
        defaultValues: {name: '', address_zip: ''}
    });

    const  onSubmit = (data) => {
        const { makePayment } = props;
        
        if (props.stripe) {
            props.stripe
            .createToken(data)
            .then((payload) => {
                if(!payload.error) {
                    makePayment({applicationId: application.id, type: 'stripe', paymentToken: payload.token.id});
                }
                else {
                    setError(payload.error);
                }
            })
        } else {
            console.log("Stripe.js hasn't loaded yet.");
        }
    };

    const payWithPoli = () =>{
        const { makePayment } = props;
        makePayment({applicationId: application.id, type: 'poli', paymentToken: ''});
    }



    const downloadInvoiceAction = ({event, currency}) => {
        event.preventDefault();
        downloadInvoice({id: application.id, currency});
    }

    useEffect(() => {
        register({name: 'name'});
        register({name: 'address_zip'});
    }, [register]);

    useEffect(() => {
        let active = '1';
        if(currentAccount) {
            if(currentAccount.paymentOptions.includes('poli')) {
                active = '2';
            }
            else if(currentAccount.paymentOptions.includes('australia_bank_transfer')) {
                active = '3';
            }
            else if(currentAccount.paymentOptions.includes('nepal_bank_transfer')) {
                active = '4';
            }
        }
        setActiveTab(active);
    }, [currentAccount]);

    const toggleModal = (type) => {
        setModal(type);
    } 

   
    return (
        <LoadingOverlay
            active={gettingPaymentStatus}
            spinner
            >
        <section className="section">
             <Container>
                   <Row>
                        <Col lg="8">
                            <Row>
                                <Col lg="6">
                                    <h2>Payment information</h2>
                                </Col>
                                <Col lg="6" className="text-right">
                                   <span className="form-info"> <span className="form-error">*</span> Indicates required field</span>
                                </Col>
                            </Row>
                            <Navbar style={{backgroundColor: '#0054a6', borderRadius: '5px'}}>
                                <Nav style={{width: '100%'}} tabs>
                                    {
                                        (!currentAccount || currentAccount.paymentOptions.includes('credit_card')) && (
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '1' })}
                                                    onClick={() => { setActiveTab('1') }}
                                                >
                                                    Credit Card
                                                </NavLink>
                                            </NavItem>
                                        )
                                    }
                                    {
                                        (!currentAccount || currentAccount.paymentOptions.includes('poli')) && (
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '2' })}
                                                    onClick={() => { setActiveTab('2') }}
                                                >
                                                    Poli
                                                </NavLink>
                                            </NavItem>
                                        )
                                    }
                                    {
                                        (!currentAccount || currentAccount.paymentOptions.includes('australia_bank_transfer')) && (
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '3' })}
                                                    onClick={() => { setActiveTab('3') }}
                                                >
                                                    Bank Transfer - Australia
                                                </NavLink>
                                            </NavItem>
                                        )
                                    }
                                    {
                                        (currentAccount && currentAccount.paymentOptions.includes('nepal_bank_transfer')) && (
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '4' })}
                                                    onClick={() => { setActiveTab('4') }}
                                                >
                                                    Bank Transfer - Nepal
                                                </NavLink>
                                            </NavItem>
                                        )
                                    }
                                </Nav>
                            
                            <TabContent activeTab={activeTab} style={{width: '100%'}}>
                            {
                                (!currentAccount || currentAccount.paymentOptions.includes('credit_card')) && (
                                    <TabPane tabId="1">
                                        <Card className="p-sm mb-sm" style={{borderRadius: '0 0 5px 5px', border: 0}}>
                                        <Form onSubmit={handleSubmit(onSubmit)} className="payment-form" >
                                        <Row>
                                            <Col md="6">
                                                <h4>Pay with credit card</h4>
                                            </Col>
                                            <Col md="6">
                                                <div className="powered-by">
                                                    <a href="https://stripe.com" rel="noopener noreferrer" target="_blank">
                                                        <img alt="Powered by stripe" src="https://insurance-compare.s3-ap-southeast-2.amazonaws.com/powered_by_stripe.png" />
                                                    </a> 
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup className="payment-form-item">
                                                    <Label>Name on card<span className="form-error">*</span></Label>
                                                    <InputGroup className="input-group-alternative">
                                                        <Input
                                                            className="form-control-alternative"
                                                            name="name"
                                                            type="text"
                                                            innerRef={register}
                                                            />
                                                        
                                                    </InputGroup>
                                                    {errors.name && (<span className="form-error">{errors.name.message}</span>)}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup className="payment-form-item">
                                                        <Label>Post code<span className="form-error">*</span></Label>
                                                        <InputGroup className="input-group-alternative">
                                                            <Input
                                                                className="form-control-alternative"
                                                                name="address_zip"
                                                                type="text"
                                                                innerRef={register}
                                                                />
                                                        </InputGroup>
                                                        {errors.address_zip && (<span className="form-error">{errors.address_zip.message}</span>)}
                                                    </FormGroup>
                                                
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                            <FormGroup className="payment-form-item">
                                            <Label> Card number<span className="form-error">*</span> </Label>
                                                <CardNumberElement/>
                                                {
                                                    error && error.code === "incomplete_number" && (<span className="form-error">{error.message}</span>)
                                                }
                                                {
                                                    error && error.code === "invalid_number" && (<span className="form-error">{error.message}</span>)
                                                }
                                                {
                                                    error && error.code === "card_declined" && (<span className="form-error">{error.message}</span>)
                                                }
                                            </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="6">
                                                <FormGroup className="payment-form-item">
                                                    <Label> Expiration date<span className="form-error">*</span></Label>
                                                    <CardExpiryElement/>
                                                    {
                                                        error && error.code === "incomplete_expiry" && (<span className="form-error">{error.message}</span>)
                                                    }
                                                    {
                                                        error && error.code === "invalid_expiry_year_past" && (<span className="form-error">{error.message}</span>)
                                                    }
                                                </FormGroup>
                                            </Col>
                                            
                                            <Col lg="6">
                                                <FormGroup className="payment-form-item">
                                                <Label> CVC<span className="form-error">*</span></Label>
                                                <CardCvcElement/>
                                                {
                                                    error && error.code === "incomplete_cvc" && (<span className="form-error">{error.message}</span>)
                                                }
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        
                                        <button disabled={submitting}>
                                            <span>MAKE PAYMENT - <span>A${application.premium}</span></span>
                                        </button>
                                    </Form>

                                    <div className="we-accept">
                                        <h5>We accept</h5>
                                        <img alt="Cards" src="https://insurance-compare.s3-ap-southeast-2.amazonaws.com/cards.png" />
                                    </div>
                                    </Card>
                                </TabPane>
                                )
                            }
                            {
                                (!currentAccount || currentAccount.paymentOptions.includes('poli')) && (
                                    <TabPane tabId="2">
                                        <Card className="p-sm mb-sm" style={{borderRadius: '0 0 5px 5px', border: 0}}>
                                                <Row>
                                                    <Col lg="6"><h4>Pay with POLi</h4></Col>
                                                    <Col lg="6">
                                                        <div className="powered-by">
                                                            <a href="http://www.polipayments.com/consumer">
                                                                <img src="https://resources.apac.paywithpoli.com/poli-logo-43.png" height="50" alt="POLi Logo"/>
                                                            </a>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <p style={{fontWeight: 'bold'}}>Pay with your bank account using Internet Banking</p>
                                                        <p>You will be redirected to your bank website to complete the payment.</p>
                                                        <p>
                                                            <a href="https://www.polipayments.com/buy" rel="noopener noreferrer" target="_blank">&raquo; Learn more about POLi</a> <br />
                                                            <a href="https://transaction.apac.paywithpoli.com/POLiFISupported.aspx?merchantcode=S6104738" rel="noopener noreferrer" target="_blank">&raquo; Available Banks</a> <br />
                                                        </p>

                                                        <p>
                                                            <Button
                                                                onClick={payWithPoli}
                                                                color="primary"
                                                                size="lg"
                                                                type="button"
                                                                > PAY WITH POLI - <span>A${parseFloat(application.premium) + 3}</span></Button> <br />
                                                                <small>Includes $3 transaction fee.</small>

                                                        </p>
                                                    </Col>
                                                </Row>
                                        </Card>
                                    </TabPane>
                                )
                            }

                            {
                                (!currentAccount || currentAccount.paymentOptions.includes('australia_bank_transfer')) && (
                                    <TabPane tabId="3">
                                        <Card className="p-sm mb-sm" style={{borderRadius: '0 0 5px 5px', border: 0}}>
                                            <Row>
                                                <Col md="12">
                                                        <h4>Pay by Bank Transfer with in Australia</h4>
                                                        <p>Transfer payment to the provided bank account and upload your proof of payment.  We will email your health certificate as soon as the payment is confirmed.</p>

                                                        <Button
                                                            color="primary"
                                                            size="lg"
                                                            outline
                                                            onClick={(event) => downloadInvoiceAction({event, currency: 'aud'})}
                                                            >
                                                                DOWNLOAD PAYMENT REFERENCE LETTER
                                                        </Button>

                                                        <Button
                                                            color="primary"
                                                            size="lg"
                                                            type="button"
                                                            onClick={(event) => toggleModal('proofOfPayment')}
                                                            >
                                                                Upload proof of payment 
                                                        </Button>


                                                    </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                )
                            }
                            {
                                (!currentAccount || currentAccount.paymentOptions.includes('nepal_bank_transfer')) && (
                                    <TabPane tabId="4">
                                        <Card className="p-sm mb-sm" style={{borderRadius: '0 0 5px 5px', border: 0}}>
                                            <Row>
                                                <Col md="12">
                                                        <h4>Pay by Bank Transfer with in Nepal</h4>
                                                        <p>Transfer payment to the provided bank account and upload your proof of payment.  We will email your health certificate as soon as the payment is confirmed.</p>

                                                        <Button
                                                            color="primary"
                                                            size="lg"
                                                            outline
                                                            onClick={(event) => downloadInvoiceAction({event, currency: 'nrp'})}
                                                            >
                                                                DOWNLOAD PAYMENT REFERENCE LETTER
                                                        </Button>

                                                        <Button
                                                            color="primary"
                                                            size="lg"
                                                            type="button"
                                                            onClick={(event) => toggleModal('proofOfPayment')}
                                                            >
                                                                Upload proof of payment 
                                                        </Button>


                                                    </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                )
                            }
                        </TabContent>
                        </Navbar>
                        </Col>
                        <Col lg="4">
                        <CoverDetails application={application} coverType={coverType} />
                        <div className="application-sidebar" >
                                <div className="sidebar-action">
                                    <Link to={`/cover-type/oshc/application/${application.id}`}>
                                      <i className="fa fa-edit"></i>
                                    </Link>
                                    
                                </div>
                                <div>
                                    <h5>Personal information</h5>
                                    <p>
                                        Title: <span>{application.title}</span> <br />
                                        First name: <span>{application.firstName}</span> <br />
                                        Last name: <span>{application.lastName}</span> <br />
                                        Gender: <span>{application.gender}</span> <br />
                                        Date of birth: <span>{application.dateOfBirth}</span> <br />
                                        Email address: <span>{application.email}</span> <br />
                                        Mobile: <span>{application.mobile}</span> <br />
                                        Country: <span>{application.country}</span> <br />
                                        Passport number: <span>{application.passportNumber}</span> <br />
                                        Visa type: <span>{application.visaType}</span> <br />
                                        School, college or university name: <span>{application.college || '-'}</span> <br />
                                        Student number: <span>{application.studentNumber || '-'}</span> <br />
                                        Australian address: <span>{application.australianAddress || '-'}</span> <br />
                                    </p>
                                    {
                                        (application.clientType === 'Couple' || application.clientType === 'Family') && application.ApplicationPartner && (
                                            <>
                                                <h5>Partner Information</h5>
                                                <p>
                                                    Title: <span>{application.ApplicationPartner.title}</span> <br />
                                                    First name: <span>{application.ApplicationPartner.firstName}</span> <br />
                                                    Last name: <span>{application.ApplicationPartner.lastName}</span> <br />
                                                    Gender: <span>{application.ApplicationPartner.gender}</span> <br />
                                                    Date of birth: <span>{application.ApplicationPartner.dateOfBirth}</span> <br />
                                                </p>
                                            </>
                                        )
                                    }
                                </div>
                            </div>

                            

                        </Col>
                   </Row>
              </Container>
        </section>
        {
            (modal === 'proofOfPayment') && (
                <ProofOfPayment application={application} setModal={setModal} submitting={submitting} makePayment={props.makePayment} />
            )
        }
        
        </LoadingOverlay>
    );
}

export default injectStripe(PaymentForm);