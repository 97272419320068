import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Cookies } from "react-cookie";

import * as loginActions from './actions';
import Login from './LogIn';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const cookies = new Cookies(); 
    const token = cookies.get("token");
    if (token) {
      window.location.href = '/admin';
    }
  }

  render() {
    const {is2FAEnabled,
      loginActions: { logIn, verifyMFA }, loginError, settingPasswordError, loggingIn, token,
    } = this.props;

    return (
      <Login
        {...this.props}
        logIn={logIn}
        loggingIn={loggingIn}
        loginError={loginError}
        token={token}
        settingPasswordError={settingPasswordError}
        is2FAEnabled={is2FAEnabled}
        verifyMFA={verifyMFA}
      />
    );
  }
}

const mapStateToProps = (state) => state.Auth.Login;

const mapDispatchToProps = (dispatch) => ({
  loginActions: bindActionCreators(loginActions, dispatch),
});


const ConnectedLogin = connect(mapStateToProps, mapDispatchToProps)(LoginContainer);

export { ConnectedLogin as LogIn };
