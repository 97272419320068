import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  Input,
  Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import useForm from 'react-hook-form';
import * as yup from 'yup';
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import {
  countries,
  titleOptions,
  genderOptions,
  visaTypesOption,
} from './constants';
import { CoverDetails, StyledDropdown } from '../../common';

const applicationFormSchema = {
  title: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  gender: yup.string().required(),
  dateOfBirth: yup.string().required(),
  email: yup.string().required(),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref('email'), null], 'Email must match'),
  mobile: yup
    .string()
    .required('Mobile is required')
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      'Invalid mobile number',
    ),
  country: yup.string().required(),
  passportNumber: yup.string().required(),
  visaType: yup.string().required(),
  chk1: yup.boolean().oneOf([true]),
  chk2: yup.boolean().oneOf([true]),
  chk3: yup.boolean().oneOf([true]),
};
// const applicationPartnerSchema = {
//     partnerTitle: yup.string().required(),
//     partnerFirstName: yup.string().required(),
//     partnerLastName: yup.string().required(),
//     partnerGender: yup.string().required(),
//     partnerDateOfBirth: yup.string().required(),
// }

const ApplicationFormOSHC = (props) => {
  const { application, updatingApplication } = props;
  let applicationChildrenSchema = {};

  Array.from(Array(Number(application.children)).keys()).forEach((index) => {
    applicationChildrenSchema[`children${index}Title`] = yup
      .string()
      .required();
    applicationChildrenSchema[`children${index}FirstName`] = yup
      .string()
      .required();
    applicationChildrenSchema[`children${index}LastName`] = yup
      .string()
      .required();
    applicationChildrenSchema[`children${index}Gender`] = yup
      .string()
      .required();
    applicationChildrenSchema[`children${index}DateOfBirth`] = yup
      .string()
      .required();
    if (!application.id) {
      application[`children${index}Title`] = '';
      application[`children${index}FirstName`] = '';
      application[`children${index}LastName`] = '';
      application[`children${index}Gender`] = '';
      application[`children${index}DateOfBirth`] = '';
    } else {
      application[`children${index}Title`] =
        application.ApplicationChildren[index].title;
      application[`children${index}FirstName`] =
        application.ApplicationChildren[index].firstName;
      application[`children${index}LastName`] =
        application.ApplicationChildren[index].lastName;
      application[`children${index}Gender`] =
        application.ApplicationChildren[index].gender;
      application[`children${index}DateOfBirth`] =
        application.ApplicationChildren[index].dateOfBirth;
    }
  });
  if (application.ApplicationPartner) {
    application['partnerTitle'] = application.ApplicationPartner.title;
    application['partnerFirstName'] = application.ApplicationPartner.firstName;
    application['partnerLastName'] = application.ApplicationPartner.lastName;
    application['partnerPassportNumber'] =
      application.ApplicationPartner.partnerPassportNumber;
    application['partnerGender'] = application.ApplicationPartner.gender;
    application['partnerDateOfBirth'] =
      application.ApplicationPartner.dateOfBirth;
  }
  const [formData, setFormData] = useState(application);

  const { register, handleSubmit, clearError, setValue, errors } = useForm({
    validationSchema:
      formData.clientType === 'Single'
        ? yup.object().shape(applicationFormSchema)
        : yup
            .object()
            .shape({ ...applicationFormSchema, ...applicationChildrenSchema }),
    defaultValues: application,
  });

  const handleSelectChange = async (selectedOption, name) => {
    const value = selectedOption.value
      ? selectedOption.value
      : selectedOption.name;
    const changeData = { [name]: value };
    setFormData({ ...formData, ...changeData });
    setValue(name, value);
    clearError(name);
  };

  const handleInputChange = (event) => {
    const { target } = event;
    let name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setFormData({ ...formData, [name]: value });
    setValue(name, value);
    clearError(name);
  };

  // const handleAddressChange = (data) => {
  //     const value = data.description;
  //     setFormData({...formData, 'australianAddress':  value })
  //     setValue('australianAddress', value);
  //     clearError('australianAddress')
  // }

  // var validDateOfBirth = ( current ) => {
  //     var today = Datetime.moment();
  //     return current.isBefore( today );
  // };

  const onSubmit = (data) => {
    const { updateApplication, currentAccount } = props;
    updateApplication({
      application: {
        ...application,
        coverType: 'oshc',
        ...data,
        startDate: moment(application.startDate).format('YYYY-MM-DD'),
        endDate: moment(application.endDate).format('YYYY-MM-DD'),
      },
      currentAccount,
    });
  };

  useEffect(() => {
    register({ name: 'title' });
    register({ name: 'firstName' });
    register({ name: 'lastName' });
    register({ name: 'gender' });
    register({ name: 'dateOfBirth' });
    register({ name: 'email' });
    register({ name: 'confirmEmail' });
    register({ name: 'mobile' });
    register({ name: 'country' });
    register({ name: 'passportNumber' });
    register({ name: 'visaType' });
    register({ name: 'college' });
    register({ name: 'studentNumber' });
    register({ name: 'australianAddress' });
    register({ name: 'chk1' });
    register({ name: 'chk2' });
    register({ name: 'chk3' });
    register({ name: 'currentHealthProviderName' });
    register({ name: 'currentHealthProviderNumber' });
    if (formData.clientType === 'Couple' || formData.clientType === 'Family') {
      register({ name: 'partnerTitle' });
      register({ name: 'partnerFirstName' });
      register({ name: 'partnerLastName' });
      register({ name: 'partnerPassportNumber' });
      register({ name: 'partnerGender' });
      register({ name: 'partnerDateOfBirth' });
    }
    if (parseInt(formData.children) > 0) {
      Array.from(Array(Number(formData.children)).keys()).forEach((index) => {
        register({ name: `children${index}Title` });
        register({ name: `children${index}FirstName` });
        register({ name: `children${index}LastName` });
        register({ name: `children${index}Gender` });
        register({ name: `children${index}DateOfBirth` });
      });
    }
  }, [register, formData.clientType, formData.children]);

  return (
    <section className="section">
      <Container>
        <Row>
          <Col lg="8">
            <Row>
              <Col lg="6">
                <h2>Personal Details</h2>
              </Col>
              <Col lg="6" className="text-right">
                <span className="form-info">
                  {' '}
                  <span className="form-error">*</span> Indicates required field
                </span>
              </Col>
            </Row>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col lg="3">
                  <FormGroup className="form-item">
                    <Label>
                      Title<span className="form-error">*</span>
                    </Label>
                    <InputGroup className="input-group-alternative">
                      <StyledDropdown
                        selected={titleOptions.find(
                          (item) => item.value === formData.title,
                        )}
                        onChange={(selected) =>
                          handleSelectChange(selected, 'title')
                        }
                        name="title"
                        options={titleOptions}
                        placeholder="Select"
                      />
                    </InputGroup>
                    {errors.title && (
                      <span className="form-error">Title is required</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup className="form-item">
                    <Label>
                      First name<span className="form-error">*</span>
                    </Label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        className="form-control-alternative"
                        name="firstName"
                        type="text"
                        value={formData.firstName}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                    {errors.firstName && (
                      <span className="form-error">First name is required</span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="form-item">
                    <Label>
                      Last name<span className="form-error">*</span>
                    </Label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        className="form-control-alternative"
                        name="lastName"
                        type="text"
                        value={formData.lastName}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                    {errors.lastName && (
                      <span className="form-error">Last name is required</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="6">
                  <FormGroup className="form-item">
                    <Label>
                      Gender<span className="form-error">*</span>
                    </Label>
                    <InputGroup className="input-group-alternative">
                      <StyledDropdown
                        selected={genderOptions.find(
                          (item) => item.value === formData.gender,
                        )}
                        onChange={(selected) =>
                          handleSelectChange(selected, 'gender')
                        }
                        name="gender"
                        options={genderOptions}
                        placeholder="Select"
                      />
                    </InputGroup>
                    {errors.gender && (
                      <span className="form-error">Gender is required</span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="form-item">
                    <Label>
                      Date of birth{' '}
                      <span style={{ fontSize: 12, color: '#cccccc' }}>
                        (DD/MM/YYYY)
                      </span>{' '}
                      <span className="form-error">*</span>
                    </Label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        className="form-control-alternative"
                        name="dateOfBirth"
                        type="text"
                        value={formData.dateOfBirth}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                    {errors.dateOfBirth && (
                      <span className="form-error">
                        Date of birth is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="6">
                  <FormGroup className="form-item">
                    <Label>
                      Email address<span className="form-error">*</span>
                    </Label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        className="form-control-alternative"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                    <span className="form-info">
                      We will send your certificate to this email. Please make
                      sure you have access to this email address.
                    </span>
                    {errors.email && (
                      <span className="form-error">
                        Email address is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="form-item">
                    <Label>
                      Confirm email address<span className="form-error">*</span>
                    </Label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        className="form-control-alternative"
                        name="confirmEmail"
                        type="email"
                        value={formData.confirmEmail}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                    {errors.confirmEmail && (
                      <span className="form-error">
                        {errors.confirmEmail.message}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="form-item">
                    <Label>
                      Mobile number<span className="form-error">*</span>
                    </Label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        className="form-control-alternative"
                        name="mobile"
                        type="text"
                        value={formData.mobile}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                    {errors.mobile && (
                      <span className="form-error">
                        {errors.mobile.message}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <hr />
                </Col>
              </Row>

              <Row>
                <Col lg="6">
                  <FormGroup className="form-item">
                    <Label>
                      Country of citizenship
                      <span className="form-error">*</span>
                    </Label>
                    <InputGroup className="input-group-alternative">
                      <StyledDropdown
                        selected={
                          formData.country
                            ? countries.find(
                                (item) => item.name === formData.country,
                              )
                            : ''
                        }
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => `${option.name}`}
                        onChange={(selected) =>
                          handleSelectChange(selected, 'country')
                        }
                        name="country"
                        options={countries}
                        placeholder="Select"
                      />
                    </InputGroup>
                    {errors.country && (
                      <span className="form-error">
                        Country of citizenship is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="form-item">
                    <Label>
                      Passport number<span className="form-error">*</span>
                    </Label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        className="form-control-alternative"
                        name="passportNumber"
                        type="text"
                        value={formData.passportNumber}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                    {errors.passportNumber && (
                      <span className="form-error">
                        Passport number is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="6">
                  <FormGroup className="form-item">
                    <Label>
                      Visa type<span className="form-error">*</span>
                    </Label>
                    <InputGroup className="input-group-alternative">
                      <StyledDropdown
                        selected={visaTypesOption.find(
                          (item) => item.value === formData.visaType,
                        )}
                        onChange={(selected) =>
                          handleSelectChange(selected, 'visaType')
                        }
                        name="visaType"
                        options={visaTypesOption}
                        placeholder="Select"
                      />
                    </InputGroup>
                    {errors.visaType && (
                      <span className="form-error">Visa type is required</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <hr />
                </Col>
              </Row>

              <Row>
                <Col lg="6">
                  <FormGroup className="form-item">
                    <Label>School, college or university name</Label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        className="form-control-alternative"
                        name="college"
                        type="text"
                        value={formData.college}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="form-item">
                    <Label>Student number</Label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        className="form-control-alternative"
                        name="studentNumber"
                        type="text"
                        value={formData.studentNumber}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <hr />
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <FormGroup className="form-item">
                    <Label>Australian address</Label>
                    <InputGroup className="input-group-alternative">
                      {/* <GooglePlacesAutocomplete
                                                onSelect={handleAddressChange}
                                                initialValue={formData.australianAddress}
                                                renderInput={(props) => (
                                                    <Input
                                                    value={formData.australianAddress}
                                                    className="form-control-alternative"
                                                    {...props}
                                                    />
                                                )}
                                                /> */}
                      <Input
                        className="form-control-alternative"
                        name="australianAddress"
                        type="text"
                        value={formData.australianAddress}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <hr />
                </Col>
              </Row>

              <Row>
                <Col lg="6">
                  <FormGroup className="form-item">
                    <Label>Current health provider name</Label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        className="form-control-alternative"
                        name="currentHealthProviderName"
                        type="text"
                        value={formData.currentHealthProviderName}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="form-item">
                    <Label>Current health provider number</Label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        className="form-control-alternative"
                        name="currentHealthProviderNumber"
                        type="text"
                        value={formData.currentHealthProviderNumber}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>

              {(formData.clientType === 'Couple' ||
                formData.clientType === 'Family') && (
                <>
                  <Row>
                    <Col lg="12">
                      <hr />
                    </Col>
                  </Row>
                  <div style={{ backgroundColor: '#efefef' }} className="p-sm">
                    <Row>
                      <Col lg="12">
                        <h5>Partner details</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="3">
                        <FormGroup className="form-item">
                          <Label>Title</Label>
                          <InputGroup className="input-group-alternative">
                            <StyledDropdown
                              selected={titleOptions.find(
                                (item) => item.value === formData.partnerTitle,
                              )}
                              onChange={(selected) =>
                                handleSelectChange(selected, 'partnerTitle')
                              }
                              name="partnerTitle"
                              options={titleOptions}
                              placeholder="Select"
                            />
                          </InputGroup>
                          {errors.partnerTitle && (
                            <span className="form-error">
                              Partner title is required
                            </span>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup className="form-item">
                          <Label>First name</Label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              name="partnerFirstName"
                              type="text"
                              value={formData.partnerFirstName}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                          {errors.partnerFirstName && (
                            <span className="form-error">
                              Partner first name is required
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="5">
                        <FormGroup className="form-item">
                          <Label>Last name</Label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              name="partnerLastName"
                              type="text"
                              value={formData.partnerLastName}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                          {errors.partnerLastName && (
                            <span className="form-error">
                              Partner last name is required
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="3">
                        <FormGroup className="form-item">
                          <Label>Gender</Label>
                          <InputGroup className="input-group-alternative">
                            <StyledDropdown
                              selected={genderOptions.find(
                                (item) => item.value === formData.partnerGender,
                              )}
                              onChange={(selected) =>
                                handleSelectChange(selected, 'partnerGender')
                              }
                              name="partnerGender"
                              options={genderOptions}
                              placeholder="Select"
                            />
                          </InputGroup>
                          {errors.partnerGender && (
                            <span className="form-error">
                              Partner gender is required
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup className="form-item">
                          <Label>
                            Date of birth{' '}
                            <span style={{ fontSize: 12, color: '#cccccc' }}>
                              (DD/MM/YYYY)
                            </span>
                          </Label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              name="partnerDateOfBirth"
                              type="text"
                              value={formData.partnerDateOfBirth}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                          {errors.partnerDateOfBirth && (
                            <span className="form-error">
                              Partner date of birth is required
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="5">
                        <FormGroup className="form-item">
                          <Label>Passport number</Label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              name="partnerPassportNumber"
                              type="text"
                              value={formData.partnerPassportNumber}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                          {errors.partnerPassportNumber && (
                            <span className="form-error">
                              Partner passport number is required
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </>
              )}

              {formData.clientType === 'Family' && formData.children > 0 && (
                <>
                  <div
                    style={{ backgroundColor: '#efefef' }}
                    className="p-sm mt-sm"
                  >
                    <Row>
                      <Col lg="12">
                        <h5>Children details</h5>
                      </Col>
                    </Row>
                    {Array.from(Array(Number(formData.children)).keys()).map(
                      (index) => (
                        <div key={`children${index}`}>
                          <Row>
                            <Col lg="3">
                              <FormGroup className="form-item">
                                <Label>
                                  Title<span className="form-error">*</span>
                                </Label>
                                <InputGroup className="input-group-alternative">
                                  <StyledDropdown
                                    selected={titleOptions.find(
                                      (item) =>
                                        item.value ===
                                        formData[`children${index}Title`],
                                    )}
                                    onChange={(selected) =>
                                      handleSelectChange(
                                        selected,
                                        `children${index}Title`,
                                      )
                                    }
                                    name={`children${index}Title`}
                                    options={titleOptions}
                                    placeholder="Select"
                                  />
                                </InputGroup>
                                {errors[`children${index}Title`] && (
                                  <span className="form-error">
                                    Children title is required
                                  </span>
                                )}
                              </FormGroup>
                            </Col>

                            <Col lg="4">
                              <FormGroup className="form-item">
                                <Label>
                                  First name
                                  <span className="form-error">*</span>
                                </Label>
                                <InputGroup className="input-group-alternative">
                                  <Input
                                    className="form-control-alternative"
                                    name={`children${index}FirstName`}
                                    type="text"
                                    value={
                                      formData[`children${index}FirstName`]
                                    }
                                    onChange={handleInputChange}
                                  />
                                </InputGroup>
                                {errors[`children${index}FirstName`] && (
                                  <span className="form-error">
                                    Children first name is required
                                  </span>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="5">
                              <FormGroup className="form-item">
                                <Label>
                                  Last name<span className="form-error">*</span>
                                </Label>
                                <InputGroup className="input-group-alternative">
                                  <Input
                                    className="form-control-alternative"
                                    name={`children${index}LastName`}
                                    type="text"
                                    value={formData[`children${index}LastName`]}
                                    onChange={handleInputChange}
                                  />
                                </InputGroup>
                                {errors[`children${index}LastName`] && (
                                  <span className="form-error">
                                    Children last name is required
                                  </span>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="3">
                              <FormGroup className="form-item">
                                <Label>
                                  Gender<span className="form-error">*</span>
                                </Label>
                                <InputGroup className="input-group-alternative">
                                  <StyledDropdown
                                    selected={genderOptions.find(
                                      (item) =>
                                        item.value ===
                                        formData[`children${index}Gender`],
                                    )}
                                    onChange={(selected) =>
                                      handleSelectChange(
                                        selected,
                                        `children${index}Gender`,
                                      )
                                    }
                                    name={`children${index}Gender`}
                                    options={genderOptions}
                                    placeholder="Select"
                                  />
                                </InputGroup>
                                {errors[`children${index}Gender`] && (
                                  <span className="form-error">
                                    Children gender is required
                                  </span>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup className="form-item">
                                <Label>
                                  Date of birth{' '}
                                  <span
                                    style={{ fontSize: 12, color: '#cccccc' }}
                                  >
                                    (DD/MM/YYYY)
                                  </span>
                                  <span className="form-error">*</span>
                                </Label>
                                <InputGroup className="input-group-alternative">
                                  <Input
                                    className="form-control-alternative"
                                    name={`children${index}DateOfBirth`}
                                    type="text"
                                    value={
                                      formData[`children${index}DateOfBirth`]
                                    }
                                    onChange={handleInputChange}
                                  />
                                </InputGroup>
                                {errors[`children${index}DateOfBirth`] && (
                                  <span className="form-error">
                                    Children date of birth is required
                                  </span>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="5">
                              <FormGroup className="form-item">
                                <Label>
                                  Passport Number{' '}
                                  <span className="form-error">*</span>
                                </Label>
                                <InputGroup className="input-group-alternative">
                                  <Input
                                    className="form-control-alternative"
                                    name={`children${index}PassportNumber`}
                                    type="text"
                                    value={
                                      formData[`children${index}PassportNumber`]
                                    }
                                    onChange={handleInputChange}
                                  />
                                </InputGroup>
                                {errors[`children${index}PassportNumber`] && (
                                  <span className="form-error">
                                    Children passport number is required
                                  </span>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>

                          {index + 1 < Number(formData.children) && (
                            <Row>
                              <Col lg="12">
                                <hr />
                              </Col>
                            </Row>
                          )}
                        </div>
                      ),
                    )}
                  </div>
                </>
              )}

              <Row>
                <Col lg="12">
                  <hr />
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id="chk1"
                      type="checkbox"
                      name="chk1"
                      checked={Boolean(formData.chk1)}
                      onChange={handleInputChange}
                    />
                    <label className="custom-control-label" htmlFor="chk1">
                      I confirm that I accept{' '}
                      <a href="/complaints-policy" target="_blank">
                        Complaints Policy
                      </a>{' '}
                      and{' '}
                      <a href="/privacy-policy" target="_blank">
                        Privacy policy
                      </a>
                      <span className="form-error">*</span>
                    </label>
                  </div>

                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id="chk2"
                      type="checkbox"
                      name="chk2"
                      checked={Boolean(formData.chk2)}
                      onChange={handleInputChange}
                    />
                    <label className="custom-control-label" htmlFor="chk2">
                      I confirm that I am the primary student visa holder
                      <span className="form-error">*</span>
                    </label>
                  </div>

                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id="chk3"
                      type="checkbox"
                      name="chk3"
                      checked={Boolean(formData.chk3)}
                      onChange={handleInputChange}
                    />
                    <label className="custom-control-label" htmlFor="chk3">
                      I declare all information provided in this Application
                      Form to be true and complete
                      <span className="form-error">*</span>
                    </label>
                  </div>

                  {(errors.chk1 || errors.chk2 || errors.chk3) && (
                    <span className="form-error">Required</span>
                  )}
                </Col>
              </Row>
              {Object.keys(errors).length ? (
                <Row>
                  <Col lg="12" className="text-right">
                    <div className="form-error">
                      Please make sure all required fields are filled.{' '}
                    </div>
                  </Col>
                </Row>
              ) : null}

              <Row>
                <Col lg="12">
                  <hr />
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <div className="form-action text-right">
                    <Link to="/cover-type/oshc/quote">Back</Link>
                    &nbsp;
                    <Button
                      className="bg-gradient-primary"
                      color="primary"
                      size="lg"
                      type="submit"
                      disabled={
                        Object.keys(errors).length > 0 || updatingApplication
                      }
                      style={{ marginLeft: 15 }}
                    >
                      Continue
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col lg="4">
            <CoverDetails application={application} coverType="oshc" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ApplicationFormOSHC;
