import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Col,
  DropdownItem,
  UncontrolledTooltip,
} from 'reactstrap';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactPaginate from 'react-paginate';
import cogoToast from 'cogo-toast';
import { Search } from '../../common';

import DeleteModal from '../../DeleteModal';
import Header from '../../Headers/SlimHeader';

const Account = (props) => {
  const { total, getAll, data, deleteItem, loggedInUser } = props;
  const [deleteItemId, setDeleteItemId] = useState();
  const limit = 10;
  const pageCount = Math.ceil(total / limit);

  const confirmDelete = ({ event, id }) => {
    event.preventDefault();
    setDeleteItemId(id);
  };

  const del = () => {
    deleteItem({ id: deleteItemId });
    setDeleteItemId(null);
  };

  const paginationChange = (data) => {
    getAll({ limit, offset: data.selected, filter: {} });
  };

  const fixSlug = (slug) => {
    return slug.replace('.', '');
  };

  return (
    <>
      <Header />
      <Search getAll={getAll} />
      <Container fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col sm={6}>
                    <h3 className="mb-0">All accounts</h3>
                  </Col>
                  <Col sm={6} className="text-right">
                    {
                      loggedInUser.role === 'superadmin' && (
                        <Button
                          className="btn-icon "
                          outline
                          size="md"
                          href="/admin/accounts/new"
                          color="primary"
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-fat-add" /> ADD
                          </span>
                        </Button>
                      )
                    }
                    
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Business Name</th>
                    <th scope="col">Contact Person Name</th>
                    <th scope="col">Website url</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map(
                      ({
                        id,
                        name,
                        email,
                        slug,
                        abn,
                        address,
                        country,
                        contactPersonName,
                      }) => (
                        <tr key={id}>
                          <th scope="row">
                            <Link to={`/admin/applications?accountId=${id}`}>
                              {name} <br />
                              {abn ? `ABN: ${abn}` : ''} <br />
                              {address} <br />
                              {country}
                            </Link>
                          </th>
                          <td>
                            {contactPersonName} <br />
                            <strong>{email}</strong>
                          </td>
                          <td>
                            <UncontrolledTooltip
                              delay={0}
                              placement="top"
                              target={`slug_${fixSlug(slug)}`}
                            >
                              Click to copy
                            </UncontrolledTooltip>
                            <CopyToClipboard
                              text={`${window.location.origin}/?agent=${fixSlug(
                                slug,
                              )}`}
                              onCopy={() =>
                                cogoToast.success('Copied successfully!')
                              }
                            >
                              <strong id={`slug_${fixSlug(slug)}`}>{`${
                                window.location.origin
                              }/?agent=${fixSlug(slug)}`}</strong>
                            </CopyToClipboard>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-dark"
                                role="button"
                                href="#"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fa fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                {
                                  loggedInUser.role === 'superadmin' && (
                                    <a
                                    className="dropdown-item"
                                    href={`/admin/accounts/${id}/edit`}
                                  >
                                    Edit
                                  </a>
                                  )
                                }
                               
                               {
                                 loggedInUser.role === 'superadmin' && (
                                  <a
                                    className="dropdown-item"
                                    href={`/admin/accounts/${id}/configure-commission`}
                                  >
                                    Configure Commission
                                  </a>
                                 )
                               }
                                
                                <a
                                  className="dropdown-item"
                                  href={`/admin/accounts/${id}/health-cover-urls`}
                                >
                                  Health Cover Url
                                </a>
                                {
                                  loggedInUser.role === 'superadmin'  && (
                                    <DropdownItem
                                      onClick={(event) =>
                                        confirmDelete({ event, id })
                                      }
                                    >
                                      Delete
                                    </DropdownItem>
                                  )
                                }
                                
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ),
                    )}
                  <tr>
                    <td colSpan={6} style={{ height: 100 }}></td>
                  </tr>
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {total > limit && (
                  <ReactPaginate
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={10}
                    pageRangeDisplayed={5}
                    onPageChange={paginationChange}
                    containerClassName={'pagination justify-content-end mb-0'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    activeClassName={'active'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    previousLabel="«"
                    nextLabel="»"
                  />
                )}

                {(!data || !data.length) && (
                  <Alert color="info">
                    <strong>No data</strong> You don't have any data yet!
                  </Alert>
                )}

                {deleteItemId && (
                  <DeleteModal
                    showModal={deleteItemId}
                    toggleModal={() => setDeleteItemId()}
                    confirmAction={del}
                    title="Delete this account?"
                    details="Are you sure you want to delete this account? All data related to this account will be deleted"
                  />
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Account;
