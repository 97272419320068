import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions'
import * as featureCategoryActions from '../../FeatureCategory/actions';
import FeatureForm from './FeatureForm';

class FeatureFormContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {}
      }
    

      clearCurrent() {
        const { actions: {clearCurrent} } = this.props;
        clearCurrent();
      }

      componentDidMount() {
        const {  id } = this.props.match.params;
        const { actions: {getCurrent,  getAllFeatureCategories }} = this.props;
        if(id) {
          getCurrent({id});
        }
        else {
          this.clearCurrent()
        }
        getAllFeatureCategories();
      }

      componentDidUpdate(prevProps) {
        if(prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
          const {history}  = this.props;
          history.push('/admin/features');
        }
      }

      
      render() {
        const { currentItem, actions: { submit }, featureCategory: {data}  } = this.props;
        
        return (
          <FeatureForm
            {...this.props}
            currentItem={currentItem}
            submit={submit}
            featureCategories={data}
          />
        )
      }
}

const mapStateToProps = state => {
    return {
      ...state.Admin.Feature,
      featureCategory: state.Admin.FeatureCategory
    }
  }
  
  const mapDispatchToProps = dispatch => {
      return {
        actions: bindActionCreators({...actions,  getAllFeatureCategories: featureCategoryActions.getAll }, dispatch)
      }
    }
    
  
  const ConnectedFeatureForm = connect(mapStateToProps, mapDispatchToProps)(FeatureFormContainer);
  
  export { ConnectedFeatureForm as FeatureForm };