export const GET_MESSAGES_START = 'GET_MESSAGES_START';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAIL = 'GET_MESSAGES_FAIL';

export const GET_CURRENT_MESSAGE_START = 'GET_CURRENT_MESSAGE_START';
export const GET_CURRENT_MESSAGE_SUCCESS = 'GET_CURRENT_MESSAGE_SUCCESS';
export const GET_CURRENT_MESSAGE_FAIL = 'GET_CURRENT_MESSAGE_FAIL';

export const SUBMIT_MESSAGE_START = 'SUBMIT_MESSAGE_START';
export const SUBMIT_MESSAGE_SUCCESS = 'SUBMIT_MESSAGE_SUCCESS';
export const SUBMIT_MESSAGE_FAIL = 'SUBMIT_MESSAGE_FAIL';

export const DELETE_MESSAGE_START = 'DELETE_MESSAGE_START';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAIL = 'DELETE_MESSAGE_FAIL';
