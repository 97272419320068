export const GET_AUTH_USER_START = 'GET_AUTH_USER_START';
export const GET_AUTH_USER_SUCCESS = 'GET_AUTH_USER_SUCCESS';
export const GET_AUTH_USER_FAIL = 'GET_AUTH_USER_FAIL';

export const DELETE_AUTH_USER_START = 'DELETE_AUTH_USER_START';
export const DELETE_AUTH_USER_SUCCESS = 'DELETE_AUTH_USER_SUCCESS';
export const DELETE_AUTH_USER_FAIL = 'DELETE_AUTH_USER_FAIL';

export const SUBMIT_AUTH_USER_START = 'SUBMIT_AUTH_USER_START';
export const SUBMIT_AUTH_USER_SUCCESS = 'SUBMIT_AUTH_USER_SUCCESS';
export const SUBMIT_AUTH_USER_FAIL = 'SUBMIT_AUTH_USER_FAIL';

export const GENERATE_CODE_START = 'GENERATE_CODE_START';
export const GENERATE_CODE_SUCCESS = 'GENERATE_CODE_SUCCESS';
export const GENERATE_CODE_FAIL = 'GENERATE_CODE_FAIL';


export const ENABLE_MFA_START = 'ENABLE_MFA_START';
export const ENABLE_MFA_SUCCESS = 'ENABLE_MFA_SUCCESS';
export const ENABLE_MFA_FAIL = 'ENABLE_MFA_FAIL';

export const REMOVE_MFA_START = 'REMOVE_MFA_START';
export const REMOVE_MFA_SUCCESS = 'REMOVE_MFA_SUCCESS';
export const REMOVE_MFA_FAIL = 'REMOVE_MFA_FAIL';