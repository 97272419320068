import cogoToast from 'cogo-toast';

import * as c from './constants';
import * as applicationService from './services';


const makePaymentStart = () => ({
  type: c.SUBMIT_PAYMENT_START
})

const makePaymentSuccess = ({paymentType, data}) => ({
  type: c.SUBMIT_PAYMENT_SUCCESS,
  paymentType,
  data
})

const makePaymentFail = ({error}) => ({
  type: c.SUBMIT_PAYMENT_FAIL,
  error,
})

export const makePayment = ({applicationId, type, paymentToken}) => async (dispatch) => {
  dispatch(makePaymentStart())
  try {
      const response = await applicationService.makePayment({applicationId, type, paymentToken});
      
      dispatch(makePaymentSuccess({paymentType: type, data: response.data.data}));
  } catch (error) {
    cogoToast.error('There was problem processing your payment!');
    dispatch(makePaymentFail({error}))
  }
}


const getPaymentStatusStart = () => ({
  type: c.GET_PAYMENT_STATUS_START
})

const getPaymentStatusSuccess = ({paymentStatus}) => ({
  type: c.GET_PAYMENT_STATUS_SUCCESS,
  paymentStatus: paymentStatus
})

const getPaymentStatusFail = ({error}) => ({
  type: c.GET_PAYMENT_STATUS_FAIL,
  error,
})

export const getPaymentStatus = ({applicationId, token}) => async (dispatch) => {
  dispatch(getPaymentStatusStart())
  try {
      const { data} = await applicationService.getPaymentStatus({applicationId, token});
     
      dispatch(getPaymentStatusSuccess({paymentStatus: data.data.paymentStatus}));
  } catch (error) {
    dispatch(getPaymentStatusFail({error}))
  }
}
