import * as c from './constants';

const initialState = {
  loadingAll: false,
  error: null,
  data: [],
  currentItem: {},
  submittingForm: false,
  deletingItem: false,
};


const actionHandlers = {
  [c.GET_MESSAGES_START]: (state) => {
    return {
      ...state,
      loadingAll: true,
    }
  },
  [c.GET_MESSAGES_SUCCESS]: (state, action) => {
    
    return {
      ...state,
      loadingAll: false,
      data: action.data,
      total: action.total
    }
  },
  [c.GET_MESSAGES_FAIL]: (state, action) => {
    return {
      ...state,
      loadingAll: false,
      error: action.error
    }
  },

  [c.GET_CURRENT_MESSAGE_START]: (state) => {
    return {
      ...state,
      loadingAll: true,
      error: null
    }
  },
  [c.GET_CURRENT_MESSAGE_SUCCESS]: (state, action) => {
    return {
      ...state,
      loadingAll: false,
      currentItem: action.currentItem,
      error: null
    }
  },
  [c.GET_CURRENT_MESSAGE_FAIL]: (state, action) => {
    return {
      ...state,
      loadingAll: false,
      error: action.error
    }
  },

  [c.SUBMIT_MESSAGE_START]: (state) => {
    return {
      ...state,
      submittingForm: true,
    }
  },
  [c.SUBMIT_MESSAGE_SUCCESS]: (state, action) => {
    
    return {
      ...state,
      submittingForm: false,
      data: state.data.map(item => {
        if(item.id === action.data.id) {
          return action.data;
        }
        else {
          return item;
        }
      }),
    }
  },
  [c.SUBMIT_MESSAGE_FAIL]: (state, action) => {
    return {
      ...state,
      submittingForm: false,
      error: action.error
    }
  },



  [c.DELETE_MESSAGE_START]: (state) => {
    return {
      ...state,
      deletingItem: true
    }
  },
  [c.DELETE_MESSAGE_SUCCESS]: (state, action) => {
    return {
      ...state,
      deletingItem: false,
      data: state.data.filter(item => item.id !== action.id)
    }
  },
  [c.DELETE_MESSAGE_FAIL]: (state) => {
    return {
      ...state,
      deletingItem: false
    }
  },
}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
