import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions';
import LeadDetails from './LeadDetails';

class LeadDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  componentDidMount() {
    const { id } = this.props.match.params;
    const { actions: { getCurrent } } = this.props;
    if (id) {
      getCurrent({ id });
    }
  }


  render() {
    const { currentItem, authUser, deletingCertificate, actions: {  deleteLeadCertificate, getCurrent } } = this.props;
    if (!currentItem) return null;
    return (
      <LeadDetails
        {...this.props}
        currentItem={currentItem}
        getCurrent={getCurrent}
        authUser={authUser}
        deleteLeadCertificate={deleteLeadCertificate}
        deletingCertificate={deletingCertificate}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Admin.Lead,
  authUser: state.Admin.AuthUser.authUser
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});


const ConnectedLeadDetails = connect(mapStateToProps, mapDispatchToProps)(LeadDetailsContainer);

export { ConnectedLeadDetails as LeadDetails };
