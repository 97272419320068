import React from 'react';

import Hero from "./Hero";
import VisaTypes from "./VisaTypes";
import Features from "./Features";
import {Providers, Reviews} from '../common';

const Landing = (props) => {
  // if(true) {
  //   return <Redirect to={{ pathname: '/cover-type/oshc' }} />
  // }
  return (
    <main>
      <Hero />
      <VisaTypes />
      <Features />
      <Providers />
      <Reviews />
    </main>
  );
}

export default Landing;
