import * as c from './constants';
import * as FaqService from './services';

const getAllStart = () => ({
  type: c.GET_FAQS_START
})

const getAllSuccess = ({data}) => ({
  type: c.GET_FAQS_SUCCESS,
  data,
})

const getAllFail = ({error}) => ({
  type: c.GET_FAQS_FAIL,
  error,
})

export const getAll = () => async (dispatch) => {
  dispatch(getAllStart())
  try {
      const {data} = await FaqService.getAll();
      dispatch(getAllSuccess({data: data.data}));
  } catch (error) {
    dispatch(getAllFail({error}))
  }
}
