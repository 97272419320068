import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions'
import ExchangeRate from './ExchangeRate';

class ExchangeRateContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.actions.getAll(); 
  }

  render() {
    const { data, actions: { deleteItem } } = this.props;
    return (
      <ExchangeRate
        {...this.props}
        data={data}
        deleteItem={deleteItem}
      />
    )
  }
}

const mapStateToProps = state => {
  return state.Admin.ExchangeRate
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedExchangeRate = connect(mapStateToProps, mapDispatchToProps)(ExchangeRateContainer);

export { ConnectedExchangeRate as ExchangeRate };

