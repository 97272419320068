export const GET_EXCHANGE_RATES_START = 'GET_EXCHANGE_RATES_START';
export const GET_EXCHANGE_RATES_SUCCESS = 'GET_EXCHANGE_RATES_SUCCESS';
export const GET_EXCHANGE_RATES_FAIL = 'GET_EXCHANGE_RATES_FAIL';

export const GET_CURRENT_EXCHANGE_RATE_START = 'GET_CURRENT_EXCHANGE_RATE_START';
export const GET_CURRENT_EXCHANGE_RATE_SUCCESS = 'GET_CURRENT_EXCHANGE_RATE_SUCCESS';
export const GET_CURRENT_EXCHANGE_RATE_FAIL = 'GET_CURRENT_EXCHANGE_RATE_FAIL';

export const RESET_CURRENT_EXCHANGE_RATE_START = 'RESET_CURRENT_EXCHANGE_RATE_START';

export const SUBMIT_EXCHANGE_RATE_START = 'SUBMIT_EXCHANGE_RATE_START';
export const SUBMIT_EXCHANGE_RATE_SUCCESS = 'SUBMIT_EXCHANGE_RATE_SUCCESS';
export const SUBMIT_EXCHANGE_RATE_FAIL = 'SUBMIT_EXCHANGE_RATE_FAIL';

export const DELETE_EXCHANGE_RATE_START = 'DELETE_EXCHANGE_RATE_START';
export const DELETE_EXCHANGE_RATE_SUCCESS = 'DELETE_EXCHANGE_RATE_SUCCESS';
export const DELETE_EXCHANGE_RATE_FAIL = 'DELETE_EXCHANGE_RATE_FAIL';
