import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../Application/actions';
import Payment from './Payment';

class PaymentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { applicationId } = this.props.match.params;
    const { actions: { getApplication } } = this.props;
    if (applicationId) {
      getApplication({ id: applicationId });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.application.id !== this.props.application.id) {
      const { application, currentAccount } = this.props;
      if (!application.HealthCover.id) {
        window.location.href = '/cover-type/oshc';
      } else if (application.status === 'paymentMade') {
        if(currentAccount) {
          window.location.href = `/admin/applications`;
        }
        else {
          window.location.href = `/cover-type/oshc/application/${application.id}/confirmation`;
        }
      } else if (application.status === 'certificateIssued') {
        window.location.href = '/my-cover';
      }
    }
  }

  render() {
    const { application } = this.props;
    
    return (
      <Payment
        {...this.props}
        application={application}
      />
    );
  }
}

const mapStateToProps = (state) => state.Front.Application;

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});


const ConnectedPayment = connect(mapStateToProps, mapDispatchToProps)(PaymentContainer);

export { ConnectedPayment as Payment };
