import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import * as actions from '../../actions';
import * as accountActions from '../../../Account/actions';
import LeadForm from './LeadForm';

class LeadFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { actions: { getCurrent, resetCurrentItem, getAccounts } } = this.props;

    if (id) {
      getCurrent({ id });
    } else {
      resetCurrentItem();
    }
    getAccounts({ limit: 1000, offset: 0, filter: {} });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
      cogoToast.success('Lead updated successfully!')
        .then(() => {
          window.location.href = '/admin/leads';
        }); 
    }
  }

  render() {
    const { currentItem, authUser, accounts, actions: { submit } } = this.props;
    return (
      <LeadForm
        {...this.props}
        currentItem={currentItem}
        submit={submit}
        authUser={authUser}
        accounts={accounts}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Admin.Lead,
  authUser: state.Admin.AuthUser.authUser,
  accounts: state.Admin.Account.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({...actions, getAccounts: accountActions.getAll}, dispatch),
});


const ConnectedLeadForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadFormContainer));

export { ConnectedLeadForm as LeadForm };
