import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Front } from '../views';
import { Admin } from "../layouts/AdminContainer";
import AuthLayout from "../layouts/Auth"

const Router = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/auth" render={props => <AuthLayout {...props} />} />
                <Route path="/admin" render={props => <Admin {...props} />} />
                <Route path="/">
                    <Front />
                </Route>
            </Switch>
        </BrowserRouter>
    );
  }

export default Router;