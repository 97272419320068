import React from 'react';
import {  Badge,
    Container,
    Row,
    Col 
} from "reactstrap";

const Features  = () => {
  return (
    <section className="section section-lg">
    <Container>
      <Row className="row-grid align-items-center">
        <Col className="order-md-2" md="6">
          <img
            alt="..."
            className="img-fluid floating"
            src={require("../../../../assets/images/theme/about-banner.png")}
          />
        </Col>
        <Col className="order-md-1" md="6">
          <div className="pr-md-5">
            <h3>Professional health cover provider agent</h3>
            <p>
              We make sure you get best health cover in resonable price. We compare major health cover provider in Australia.
            </p>
            <ul className="list-unstyled mt-5">
              <li className="py-2">
                <div className="d-flex align-items-center">
                  <div>
                    <Badge
                      className="badge-circle mr-3"
                      color="primary"
                    >
                      <i className="ni ni-check-bold" />
                    </Badge>
                  </div>
                  <div>
                    <p className="mb-0">
                     24/7 Support 
                    </p>
                  </div>
                </div>
              </li>
              <li className="py-2">
                <div className="d-flex align-items-center">
                  <div>
                    <Badge
                      className="badge-circle mr-3"
                      color="primary"
                    >
                      <i className="ni ni-check-bold" />
                    </Badge>
                  </div>
                  <div>
                    <p className="mb-0">Competitive price</p>
                  </div>
                </div>
              </li>
              <li className="py-2">
                <div className="d-flex align-items-center">
                  <div>
                    <Badge
                      className="badge-circle mr-3"
                      color="primary"
                    >
                      <i className="ni ni-check-bold" />
                    </Badge>
                  </div>
                  <div>
                    <p className="mb-0">
                    No Hidden Fees
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
  );
}

export default Features;
