import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions'
import HealthCoverType from './HealthCoverType';

class HealthCoverTypeContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.actions.getAllHealthCoverTypes(); 
  }

  render() {
    const { data } = this.props;
    return (
      <HealthCoverType
        {...this.props}
        data={data}
      />
    )
  }
}

const mapStateToProps = state => {
  return state.Admin.HealthCoverType
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedHealthCoverType = connect(mapStateToProps, mapDispatchToProps)(HealthCoverTypeContainer);

export { ConnectedHealthCoverType as HealthCoverType };

