import React from 'react';
import {
    Button,
    FormGroup,
    Form,
    Input,
    InputGroup,
  } from "reactstrap";
import { Link } from "react-router-dom";
import * as yup from 'yup';
import useForm from 'react-hook-form';

const formSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid email address'),
  password: yup.string().required('Password is required'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const ResetPassword = (props) => {
    const { successMessage, errorMessage } = props;
    const { register, handleSubmit, errors} = useForm({
      validationSchema: formSchema,
    });

    const onSubmit = async (data) => {
      const { submit, match: { params: {id}} } = props;
      submit({data: {...data, tokenreset: id }});
    }

    return (
       

  <>
        <h4 className="text-center mb-4">
          RESET YOUR PASSWORD
        </h4>
        {
          successMessage && (
            <>
              <p className="text-success">{successMessage}</p>
              <div className=" text-center">
                  <small><Link to="/auth/login">Back to Log In</Link></small>
              </div>
            </>
            )
        }
        {
          errorMessage && (
            <>
              <p className="text-danger">{errorMessage}</p>
              <div className=" text-center">
                  <small><Link to="/auth/forgot-password">Back to Forgot password</Link></small>
              </div>
            </>
          )
        }
        {
          !(successMessage || errorMessage) && (
            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup className="mb-3">
                <label
                  className="form-control-label"
                >
                  Email
                </label>
                <InputGroup className="input-group-alternative">
                  <Input name="email" innerRef={register} type="email" />
                </InputGroup>
                {errors.email && <span className='form-error'>{errors.email.message}</span>}
              </FormGroup>
    
              <FormGroup className="mb-3">
                <label
                  className="form-control-label"
                >
                  Password
                </label>
                <InputGroup className="input-group-alternative">
                  <Input name="password" innerRef={register} type="password" />
                </InputGroup>
                {errors.password && <span className='form-error'>{errors.password.message}</span>}
              </FormGroup>
    
              <FormGroup className="mb-3">
                <label
                  className="form-control-label"
                >
                  Confirm password
                </label>
                <InputGroup className="input-group-alternative">
                  <Input name="confirmPassword" innerRef={register} type="password" />
                </InputGroup>
                {errors.confirmPassword && <span className='form-error'>{errors.confirmPassword.message}</span>}
              </FormGroup>
              
              <div className="text-center">
                <Button
                  className="my-4"
                  color="secondary"
                  type="submit"
                >
                  Reset your password
                </Button>
              </div>
              <div className=" text-center">
                  <small><Link to="/auth/login">Back to Log In</Link></small>
              </div>
            </Form>
          )
        }
        
      </>
    )
  }

export default ResetPassword;