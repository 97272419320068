import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label, 
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';
import { FilePond  } from 'react-filepond';
import { getNames } from 'country-list';
import Select from 'react-select';
import moment from 'moment-timezone';

import Header from '../../../Headers/SlimHeader';
import config from '../../../../../config';

const data = getNames();
const countries = data.map((item) => ({
    value: item, label: item,
}));
const timezoneOptions = moment.tz.names().map(timezone => ({value: timezone, label: timezone}));
const paymentOptions = [
  {
    label: 'Credit card',
    value: 'credit_card'
  },
  {
    label: 'Poli',
    value: 'poli'
  },
  {
    label: 'Australia bank transfer',
    value: 'australia_bank_transfer'
  },
  {
    label: 'Nepal bank transfer',
    value: 'nepal_bank_transfer'
  }
];
const currencyOptions = [
  {
    label: 'AUD',
    value: 'aud'
  },
  {
    label: 'NRS',
    value: 'nrs'
  }
];

const formSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  country: yup.string().required('Country is required'),
});


const FeatureForm = (props) => {
  const {
    currentItem, submittingForm, history, healthCovers
  } = props;
  const [formData, setFormData] = useState(currentItem);
  const healthCoverOptions = healthCovers.map(healthCover => ({value: healthCover.id, label: healthCover.name}));

  const {
    register, handleSubmit, errors, setValue, reset, clearError
  } = useForm({
    validationSchema: formSchema,
    defaultValues: currentItem,
  });


  useEffect(() => {
    reset(currentItem);
    setFormData(currentItem);
  }, [currentItem, reset]);

  const onSubmit = async (updatedItem) => {
    const { submit } = props;
    submit({ data: { ...currentItem, ...updatedItem, commissions:  currentItem.commissions ? JSON.parse(currentItem.commissions): '' } });
  };


  const handleInputChange = (event) => {
    const { target } = event;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setFormData({...formData, [name]:  value});
    setValue([name], value);
  }

  const assignImage = (file) => {
      setFormData({...formData, logo: file});
      setValue('logo', file);
  }

    const selectInput = (selected, name) => {
      let value;
      if(selected && selected.value) {
        value = selected.value;
          
      }
      else {
        value = selected ? selected.map(item => item.value): null;
      }
      setValue([name], value);
      setFormData({...formData, [name]: value});
      clearError();
  }

  useEffect(() => {
      register({name: 'logo'});
      register({name: 'timezone'});
      register({name: 'paymentOptions'});
      register({name: 'country'});
      register({name: 'currency'});
      register({name: 'excludeHealthCovers'});
  }, [register]);

  return (
    <>
      <Header />
      <Container fluid>
        <Row>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-white shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{currentItem.id ? 'Edit account' : 'Add new account'}</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="grey"
                      onClick={history.goBack}
                      size="sm"
                    >
                        Back
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="pl-lg-4">

                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                                Business name <span  className="text-danger">*</span>
                          </label>

                          <Input
                            className="form-control-alternative"
                            innerRef={register}
                            type="text"
                            name="name"
                            onChange={handleInputChange}
                          />
                          {errors.name && <span className="formError">{errors.name.message}</span>}
                        </FormGroup>
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                                Contact Person Name <span  className="text-danger">*</span>
                          </label>

                          <Input
                            className="form-control-alternative"
                            innerRef={register}
                            type="text"
                            name="contactPersonName"
                            onChange={handleInputChange}
                          />
                          {errors.contactPersonName && <span className="formError">{errors.contactPersonName.message}</span>}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                                Email <span  className="text-danger">*</span>
                          </label>

                          <Input
                            className="form-control-alternative"
                            type="text"
                            name="email"
                            innerRef={register}
                            onChange={handleInputChange}
                          />
                          {errors.email && <span className="formError">{errors.email.message}</span>}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                                Bcc 
                          </label>

                          <Input
                            className="form-control-alternative"
                            type="text"
                            name="bcc"
                            innerRef={register}
                            onChange={handleInputChange}
                          />
                          <small>Seperate emails with comma</small>
                          {errors.bcc && <span className="formError">{errors.bcc.message}</span>}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                                Address <span  className="text-danger">*</span>
                          </label>

                          <Input
                            className="form-control-alternative"
                            type="text"
                            name="address"
                            innerRef={register}
                            onChange={handleInputChange}
                          />
                          {errors.address && <span className="formError">{errors.address.message}</span>}
                        </FormGroup>
                      </Col>
                    </Row>

                    

                      <Row>
                        <Col lg="12">
                          <FormGroup row>
                            <Label for="country" sm={12} className="form-control-label">Country <span  className="text-danger">*</span></Label>
                            <Col sm={12}>
                                <Select isClearable={true} placeholder="Select country..." value={countries.find(country => country.value === formData.country)} options={countries} name="country" onChange={(event) => selectInput(event, 'country')} />
                                {errors.country && <span className="formError">{errors.country.message}</span>}
                            </Col>
                        </FormGroup>
                        </Col>
                      </Row>


                      <Row>
                        <Col lg="12">
                          <FormGroup row>
                            <Label for="paymentOptions" sm={12} className="form-control-label">Payment options <span  className="text-danger">*</span></Label>
                            <Col sm={12}>
                                <Select isMulti={true} placeholder="Payment options..." value={paymentOptions.filter(item => formData.paymentOptions && formData.paymentOptions.includes(item.value))} options={paymentOptions} name="paymentOptions" onChange={(event) => selectInput(event, 'paymentOptions')} />
                                {errors.paymentOptions && <span className="formError">{errors.paymentOptions.message}</span>}
                            </Col>
                        </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <FormGroup row>
                            <Label for="timezone" sm={12} className="form-control-label">Timezone <span  className="text-danger">*</span></Label>
                            <Col sm={12}>
                                <Select isClearable={true} placeholder="Select timezone..." value={timezoneOptions.find(item => item.value === formData.timezone)} options={timezoneOptions} name="timezone" onChange={(event) => selectInput(event, 'timezone')} />
                                {errors.timezone && <span className="formError">{errors.timezone.message}</span>}
                            </Col>
                        </FormGroup>
                        </Col>
                      </Row>


                      <Row>
                        <Col lg="12">
                          <FormGroup row>
                            <Label for="currency" sm={12} className="form-control-label">Currency <span  className="text-danger">*</span></Label>
                            <Col sm={12}>
                                <Select isClearable={true} placeholder="Select currency..." value={currencyOptions.find(item => item.value === formData.currency)} options={currencyOptions} name="currency" onChange={(event) => selectInput(event, 'currency')} />
                                {errors.currency && <span className="formError">{errors.currency.message}</span>}
                            </Col>
                        </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                                ABN
                          </label>

                          <Input
                            className="form-control-alternative"
                            innerRef={register}
                            type="text"
                            name="abn"
                            onChange={handleInputChange}
                          />
                          {errors.abn && <span className="formError">{errors.abn.message}</span>}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                        <Col lg="12" className="mt-3">
                          <FormGroup>

                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              name="hasGST"
                              ref={register}
                              checked={formData.checkbox}
                              onChange={handleInputChange}
                              id="hasGST"
                            />
                            <label className="custom-control-label" htmlFor="hasGST">
                              Has GST
                            </label>
                          </div>
                            
                           
                          </FormGroup>
                        </Col>
                      </Row>
                      

                      <Row>
                        <Col lg="12">
                          <FormGroup row>
                            <Label for="excludeHealthCovers" sm={12} className="form-control-label">Exclude Health Covers</Label>
                            <Col sm={12}>
                                <Select isMulti={true} placeholder="Health covers..." value={healthCoverOptions.filter(item => formData.excludeHealthCovers && formData.excludeHealthCovers.includes(item.value))} options={healthCoverOptions} name="excludeHealthCovers" onChange={(event) => selectInput(event, 'excludeHealthCovers')} />
                                {errors.excludeHealthCovers && <span className="formError">{errors.excludeHealthCovers.message}</span>}
                            </Col>
                        </FormGroup>
                        </Col>
                      </Row>

                      <hr />

                      <Row>
                        <Col lg="12">
                          <FormGroup className={errors.logo && "has-danger" }>
                            <label
                              className="form-control-label"
                            >
                              Logo
                            </label>
                            <FilePond
                              allowMultiple={true}
                              server= {`${config.serverUrl}/api/images`}
                              accept="image/png, image/jpeg, image/gif"
                              onprocessfile={(error, file) => assignImage(file.serverId)}
                              />
                            
                            
                              {
                                formData.logo && (<img alt="" src={formData.logo} width={50} />)
                              }
                             
                             
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col lg="12" className="mt-3">
                          <FormGroup>

                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              name="isOSHCEnabled"
                              ref={register}
                              checked={formData.isOSHCEnabled}
                              onChange={handleInputChange}
                              id="isOSHCEnabled"
                            />
                            <label className="custom-control-label" htmlFor="isOSHCEnabled">
                              Enable OSHC
                            </label>
                          </div>
                            
                           
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" className="mt-3">
                          <FormGroup>

                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              name="isOVHCEnabled"
                              ref={register}
                              checked={formData.isOVHCEnabled}
                              onChange={handleInputChange}
                              id="isOVHCEnabled"
                            />
                            <label className="custom-control-label" htmlFor="isOVHCEnabled">
                              Enable OVHC
                            </label>
                          </div>
                            
                           
                          </FormGroup>
                        </Col>
                      </Row>


                    <Row>
                      <Col lg="12">
                        <hr className="my-4" />
                        <div className="text-right">
                          <Button
                            color="grey"
                            type="button"
                            onClick={history.goBack}
                          >
                                    Cancel
                          </Button>
                          <Button
                            color="primary"
                            type="submit"
                            disabled={!!submittingForm}
                          >
                            {currentItem.id ? 'UPDATE' : 'ADD'}
                          </Button>

                        </div>
                      </Col>
                    </Row>
                  </div>

                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(FeatureForm);
