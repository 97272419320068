import React from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import { AdminNavbar } from "../views/Admin/Navbars/AdminNavbar";
import AdminFooter from "../views/Admin/Footers/AdminFooter";
import Sidebar from "../views/Admin/Sidebar/Sidebar";
import "../assets/scss/admin/dashboard-react.scss";

import {
  Dashboard,
  Application, 
  ApplicationDetails,
  HealthCoverType, 
  EditHealthCoverType, 
  Provider, 
  ProviderForm, 
  HealthCover, 
  HealthCoverForm,
  FeatureCategory, 
  FeatureCategoryForm,
  Feature,
  FeatureForm,
  HealthCoverFeature,
  HealthCoverFeatureForm,
  User,
  UserForm,
  Account,
  AccountForm,
  ConfigureCommission,
  Message,
  MessageDetails,
  Faq,
  FaqForm,
  Review,
  MyProfile,
  Lead,
  ExchangeRate,
  ExchangeRateForm,
  HealthCoverUrl,
  LeadDetails,
  PurchaseOrder
 } from "../views/Admin";

const routes = [
    {
      path: "/cover-type/oshc",
      icon: "ni ni-align-center",
      name: "Compare OSHC",
      layout: "",
      owner: ['agent']
    },
    {
        path: "/dashboard",
        icon: "ni ni-tv-2",
        name: "Dashboard",
        layout: "/admin",
        owner: ['superadmin', 'agent', 'staff']
    },
    {
        path: "/applications",
        icon: "fa fa-graduation-cap",
        name: "OSHC Applications",
        layout: "/admin",
        owner: ['superadmin', 'agent', 'staff']
    },
    {
      path: "/leads",
      icon: "fa fa-briefcase",
      name: "Leads",
      layout: "/admin",
      owner: ['superadmin', 'agent']
  },
  {
      path: "/purchase-orders",
      icon: "ni ni-cart",
      name: "Purchase Orders",
      layout: "/admin",
      owner: ['superadmin']
  },
  {
      path: "/exchange-rates",
      icon: "fa fa-dollar",
      name: "Exchange rate",
      layout: "/admin",
      owner: ['superadmin', 'staff']
  },
    {
      path: "/health-cover-type",
      icon: "ni ni-books",
      name: "Health Cover type",
      layout: "/admin",
      owner: ['superadmin', 'editor']
    },
    {
      path: "/providers",
      icon: "ni ni-ambulance",
      name: "Providers",
      layout: "/admin",
      owner: ['superadmin','editor']
    },
    {
      path: "/health-covers",
      icon: "ni ni-archive-2",
      name: "Health Covers",
      layout: "/admin",
      owner: ['superadmin','editor']
    },
    {
      path: "/feature-categories",
      icon: "ni ni-collection",
      name: "Feature Categories",
      layout: "/admin",
      owner: ['superadmin', 'editor']
    },
    {
      path: "/features",
      icon: "ni ni-bullet-list-67",
      name: "Features",
      layout: "/admin",
      owner: ['superadmin', 'editor'] 
    },
    {
      path: "/health-cover-features",
      icon: "ni ni-delivery-fast",
      name: "Health Cover Features",
      layout: "/admin",
      owner: ['superadmin', 'editor'] 
    },
    {
      path: "/faqs",
      icon: "ni ni-support-16",
      name: "FAQs",
      layout: "/admin",
      owner: ['superadmin']
    },
    {
      path: "/reviews",
      icon: "ni ni-satisfied",
      name: "Reviews",
      layout: "/admin",
      owner: ['superadmin', 'staff']
    },
    {
      path: "/messages",
      icon: "ni ni-email-83",
      name: "Messages",
      layout: "/admin",
      owner: ['superadmin', 'agent', 'staff']
    },
    {
      path: "/accounts",
      icon: "ni ni-building",
      name: "Accounts",
      layout: "/admin",
      owner: ['superadmin', 'editor']
    },
    {
      path: "/users",
      icon: "ni ni-circle-08",
      name: "Users",
      layout: "/admin",
      owner: ['superadmin', 'agent']
    },
];

class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "OSHC Compare";
  };
  render() {
    if(this.props.slug && (routes[0].path.indexOf('agent') === -1)) {
      routes[0].path = `${routes[routes.length - 1].path}?agent=${this.props.slug}`;
    }
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}

          logo={{
            innerLink: "/admin",
            imgSrc: require("../assets/images/brand/logo.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            
            <Route path="/admin/dashboard" component={Dashboard} />
            <Route path="/admin/health-cover-type/:id/edit" component={EditHealthCoverType} />
            <Route path="/admin/health-cover-type" component={HealthCoverType} />
            <Route path="/admin/providers/new" component={ProviderForm} />
            <Route path="/admin/providers/:id/edit" component={ProviderForm} />
            <Route path="/admin/providers" component={Provider} />
            <Route path="/admin/health-covers/new" component={HealthCoverForm} />
            <Route path="/admin/health-covers/:id/edit" component={HealthCoverForm} />
            <Route path="/admin/health-covers" component={HealthCover} />
            <Route path="/admin/feature-categories/new" component={FeatureCategoryForm} />
            <Route path="/admin/feature-categories/:id/edit" component={FeatureCategoryForm} />
            <Route path="/admin/feature-categories" component={FeatureCategory} />
            <Route path="/admin/features/new" component={FeatureForm} />
            <Route path="/admin/features/:id/edit" component={FeatureForm} />
            <Route path="/admin/features" component={Feature} />

            <Route path="/admin/health-cover-features/new" component={HealthCoverFeatureForm} />
            <Route path="/admin/health-cover-features/:id/edit" component={HealthCoverFeatureForm} />
            <Route path="/admin/health-cover-features" component={HealthCoverFeature} />

            <Route path="/admin/applications/:id/details" component={ApplicationDetails} />
            <Route path="/admin/applications" component={Application} />

            <Route path="/admin/users/new" component={UserForm} />
            <Route path="/admin/users/:id/edit" component={UserForm} />
            <Route path="/admin/users" component={User} />

            <Route path="/admin/accounts/new" component={AccountForm} />
            <Route path="/admin/accounts/:id/edit" component={AccountForm} />
            <Route path="/admin/accounts/:id/configure-commission" component={ConfigureCommission} />
            <Route path="/admin/accounts/:id/health-cover-urls" component={HealthCoverUrl} />
            <Route path="/admin/accounts" component={Account} />

            <Route path="/admin/messages/:id/details" component={MessageDetails} />
            <Route path="/admin/messages" component={Message} />

            <Route path="/admin/faqs/new" component={FaqForm} />
            <Route path="/admin/faqs/:id/edit" component={FaqForm} />
            <Route path="/admin/faqs" component={Faq} />

            <Route path="/admin/exchange-rates/new" component={ExchangeRateForm} />
            <Route path="/admin/exchange-rates/:id/edit" component={ExchangeRateForm} />
            <Route path="/admin/exchange-rates" component={ExchangeRate} />

            <Route path="/admin/reviews" component={Review} />

            <Route path="/admin/leads/:id" component={LeadDetails} />
            <Route path="/admin/leads" component={Lead} />

            <Route path="/admin/my-profile" component={MyProfile} />
            <Route path="/admin/my-profile" component={MyProfile} />
            <Route path="/admin/purchase-orders" component={PurchaseOrder} />
            <Route path="/admin" component={Dashboard} />
            
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}
export default Admin;