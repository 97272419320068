import client from '../../../../client';

const apiSlug = `applications`;

function getOne({id}) {
    return client.get(`${apiSlug}/${id}`);
}

function getAll({limit, offset, filter}) {
    let query  = `offset=${offset}&limit=${limit}`;
    if(filter.status) {
        query = `${query}&status=${filter.status}`;
    }
    if(filter.accountId) {
        query = `${query}&accountId=${filter.accountId}`;
    }
    if(filter.query) {
        query = `${query}&query=${filter.query}`;
    }
    if(filter.isArchived) {
        query = `${query}&isArchived=${filter.isArchived}`;
    }
    return client.get(`${apiSlug}?${query}`);
}

function getStats({filter}) {
    let url = `${apiSlug}/stats`;
    if(filter.accountId) {
        url = `${url}?accountId=${filter.accountId}`;
    }
    return client.get(url);
}

function update({data}) {
    return client.put(`${apiSlug}/${data.id}/admin`, data);
}

function add({data}) {
    return client.post(apiSlug, data);
}

function del({id}) {
    return client.delete(`${apiSlug}/${id}`);
}

function delCertificate({id}) {
    return client.delete(`${apiSlug}/${id}/certificate`);
}

function assignUser({id, assigneeId}) {
    return client.put(`${apiSlug}/${id}/assign`, {assigneeId});
}



export { getAll, update,  add, del, getOne, delCertificate, assignUser, getStats };