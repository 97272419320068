import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions'
import Message from './Message';

class MessageContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.actions.getAll({limit: 10, offset: 0}); 
  }

  render() {
    const { authUser, total, data, actions: { deleteItem, getAll } } = this.props;
    
    return (
      <Message
        {...this.props}
        data={data}
        deleteItem={deleteItem}
        total={total}
        getAll={getAll}
        authUser={authUser}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.Admin.Message,
    authUser: state.Admin.AuthUser.authUser
  }
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedMessage = connect(mapStateToProps, mapDispatchToProps)(MessageContainer);

export { ConnectedMessage as Message };

