import React, { useState } from 'react';
import {

  Container,
  Row,
  Col
} from "reactstrap";
import { useEffect } from 'react';


const Contact = (props) => {
  const { data } = props;
  const [faqs, setFaqs] = useState(data);

  useEffect(() => {
    setFaqs(data);
  }, [data]);

  var toggle = (index) => {
    setFaqs(faqs.map((faq, i) => {
      return {
        ...faq,
        open: index === i ? true: false
      }
    }));
  }

  return (
    <>

<main>
        <div className="position-relative">
          <section className="section section-shaped pb-16">
            <div className="shape shape-primary">
              &nbsp;
            </div>
          </section>
        </div>
        <section className="section section-lg" id="faqs">
          <Container>
            <Row className="row-grid justify-content-center">
              <Col className="text-center" lg="8">
                <h2 className="display-3">
                  FAQs (Frequently Asked Questions)
                </h2>
                <p className="lead">
                  We have answers to your common questions here. If you don't find answers to your questions, feel free to contact us or email us at <a href="support@oshccompare.com.au">support@oshccompare.com.au</a>.
                </p>
                </Col>
            </Row>

            <Row className="row-grid">
                <Col lg="12">
                    {
                        faqs && faqs.map( (faq, index) => (
                            <div className="faq-item" key={faq.id}>
                                <h4 className="d-flex align-items-center" onClick={() => toggle(index)}>
                                { faq.open ? (<i className="ni ni-fat-delete mr-1" />) : (<i className="ni ni-fat-add mr-1" />)  } {faq.question}
                                </h4>
                                {
                                    faq.open ? 
                                    (
                                        <div  className="ml-4" dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                                    ) : null
                                }
                            </div>
                        ))
                    }
                </Col>
            </Row>
          </Container>
        </section>
        
      </main>


      
    </>
  );
}

export default Contact;
