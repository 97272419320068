import cogoToast from 'cogo-toast';
import moment from 'moment';

import * as c from './constants';
import * as purchaseOrderService from './services';

const getAllStart = () => ({
  type: c.GET_PURCHASE_ORDERS_START
})

const getAllSuccess = ({data, total}) => ({
  type: c.GET_PURCHASE_ORDERS_SUCCESS,
  data,
  total
})

const getAllFail = ({error}) => ({
  type: c.GET_PURCHASE_ORDERS_FAIL,
  error,
})

export const getAll = ({limit, offset}) => async (dispatch) => {
  dispatch(getAllStart())
  try {
      const {data} = await purchaseOrderService.getAll({limit, offset});
      dispatch(getAllSuccess({data: data.data, total: data.total}));
  } catch (error) {
    dispatch(getAllFail({error}))
  }
}


const deleteStart = () => ({
  type: c.DELETE_PURCHASE_ORDER_START
})
const deleteSuccess = ({id}) => ({
  type: c.DELETE_PURCHASE_ORDER_SUCCESS,
  id,
})
const deleteFail = ({error}) => ({
  type: c.DELETE_PURCHASE_ORDER_FAIL,
  error,
})
export const deleteItem = ({id}) => async (dispatch) => {
  dispatch(deleteStart())
  try {
      await purchaseOrderService.del({id});
      dispatch(deleteSuccess({id}));
  } catch (error) {
    dispatch(deleteFail({error}))
  }
}


const submitStart = () => ({
  type: c.SUBMIT_PURCHASE_ORDER_START
})
const submitSuccess = ({data}) => ({
  type: c.SUBMIT_PURCHASE_ORDER_SUCCESS,
  data,
})
const submitFail = ({error}) => ({
  type: c.SUBMIT_PURCHASE_ORDER_FAIL,
  error,
})
export const submit = ({data}) => async (dispatch) => {
  dispatch(submitStart())
  try {
      let response;
      if(data.id) {
        response = await purchaseOrderService.update({data});
        cogoToast.success('Purchase Order updated successfully!');
      }
      else {
        let updatedData = {
          ...data,
          startDate: moment(data.startDate).format('YYYY-MM-DD'),
          endDate: moment(data.endDate).format('YYYY-MM-DD')
        }
        response = await purchaseOrderService.add({data: updatedData});
        cogoToast.success('Purchase Order generated successfully!');
      }
      dispatch(submitSuccess({data: response.data.data}));
  } catch (error) {
    cogoToast.error(error.response?.data.message);
    dispatch(submitFail({error}))
  }
}



const sendEmailStart = () => ({
  type: c.SEND_EMAIL_START
})
const sendEmailSuccess = ({data}) => ({
  type: c.SEND_EMAIL_SUCCESS,
  data,
})
const sendEmailFail = ({error}) => ({
  type: c.SEND_EMAIL_FAIL,
  error,
})
export const sendEmail = ({id}) => async (dispatch) => {
  dispatch(sendEmailStart())
  try {
      
      let response = await purchaseOrderService.sendEmail({id});
      cogoToast.success('Email sent to agent!');
      dispatch(sendEmailSuccess({data: response.data.data}));
  } catch (error) {
    dispatch(sendEmailFail({error}))
  }
}



const exportBillStart = () => ({
  type: c.EXPORT_BILL_START
})
const exportBillSuccess = () => ({
  type: c.EXPORT_BILL_SUCCESS,
})
const exportBIllFail = ({error}) => ({
  type: c.EXPORT_BILL_FAIL,
  error,
})
export const exportBill = ({data}) => async (dispatch) => {
  dispatch(exportBillStart())
  try {
      
      let response = await purchaseOrderService.exportBill({data});
      cogoToast.success('Bill exported successfully!');
      window.location.href = response.data.url; 
      dispatch(exportBillSuccess());
  } catch (error) {
    cogoToast.error(error.response.data);
    dispatch(exportBIllFail({error}))
  }
}