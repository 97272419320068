import client from '../../../../client';

const apiSlug = `messages`;

function getOne({id}) {
    return client.get(`${apiSlug}/${id}`);
}

function getAll({limit, offset}) {
    return client.get(`${apiSlug}?offset=${offset}&limit=${limit}`);
}

function add({data}) {
    return client.post(apiSlug, data);
}

function update({data}) {
    return client.put(`${apiSlug}/${data.id}`, data);
}

function del({id}) {
    return client.delete(`${apiSlug}/${id}`);
}


export { getAll, add,  update, del, getOne };