import React from 'react';
import { Debounce } from 'react-throttle';
import {

    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input
  } from "reactstrap";


const Search = ({getAll, setQuery}) => {

    
    const handleKeyPress = (event) => {
        getAll({limit: 10, offset: 0, filter: {query: event.target.value}});
        setQuery && setQuery(event.target.value)
      }

    return (
        <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto search-bar">
              <FormGroup className="mb-0" >
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Debounce time="400" handler="onChange">
                    <Input placeholder="Search..." onChange={handleKeyPress}  type="text" />
                  </Debounce>
                </InputGroup>
                
              </FormGroup>
            </Form>
    );
}

export default Search;