import client from '../../../../client';

const apiSlug = 'accounts';

function getOne({ id }) {
  return client.get(`${apiSlug}/${id}`);
}
function getAll({ limit, offset, filter }) {
  let query = `${apiSlug}?offset=${offset}&limit=${limit}`;
    if(filter.query) {
        query = `${query}&query=${filter.query}`;
    }
    return client.get(query);
}


function update({ data }) {
  return client.put(`${apiSlug}/${data.id}`, data);
}

function add({ data }) {
  return client.post(apiSlug, data);
}

function del({ id }) {
  return client.delete(`${apiSlug}/${id}`);
}


export {
  getAll, update, add, del, getOne,
};
