import * as c from './constants';

const initialState = {
  loadingAll: false,
  error: null,
  data: null,
};


const actionHandlers = {
  [c.GET_FAQS_START]: (state) => {
    return {
      ...state,
      error: null,
      loadingAll: true,
    }
  },
  [c.GET_FAQS_SUCCESS]: (state, action) => {
    
    return {
      ...state,
      error: null,
      loadingAll: false,
      data: action.data,
    }
  },
  [c.GET_FAQS_FAIL]: (state, action) => {
    return {
      ...state,
      loadingAll: false,
      error: action.error
    }
  },
}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
