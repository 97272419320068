import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions'
import ExchangeRateForm from './ExchangeRateForm';

class ExchangeRateFormContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {}
      }
    
      componentDidMount() {
        const {  id } = this.props.match.params;
        const { actions: { getCurrent, resetCurrentItem } } = this.props;
        
        if(id) {
          getCurrent({id});
        }
        else {
          resetCurrentItem();
        }
      }

      componentDidUpdate(prevProps) {
        if(prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
          const {history}  = this.props;
          history.push('/admin/exchange-rates');
        }
      }

      render() {
        const { currentItem, actions: { submit }  } = this.props;
        
        return (
          <ExchangeRateForm
            {...this.props}
            currentItem={currentItem}
            submit={submit}
          />
        )
      }
}

const mapStateToProps = state => {
    return state.Admin.ExchangeRate;
  }
  
  const mapDispatchToProps = dispatch => {
      return {
        actions: bindActionCreators(actions, dispatch)
      }
    }
    
  
  const ConnectedExchangeRateForm = connect(mapStateToProps, mapDispatchToProps)(ExchangeRateFormContainer);
  
  export { ConnectedExchangeRateForm as ExchangeRateForm };