import * as c from './constants';
import * as leadService from './services';

const getAllStart = () => ({
  type: c.GET_LEADS_START
})

const getAllSuccess = ({data, total}) => ({
  type: c.GET_LEADS_SUCCESS,
  data,
  total
})

const getAllFail = ({error}) => ({
  type: c.GET_LEADS_FAIL,
  error,
})

export const getAll = ({limit, offset, filter}) => async (dispatch) => {
  dispatch(getAllStart())
  try {
      const {data} = await leadService.getAll({limit, offset, filter});
      dispatch(getAllSuccess({data: data.data, total: data.total}));
  } catch (error) {
    dispatch(getAllFail({error}))
  }
}


const deleteStart = () => ({
  type: c.DELETE_LEAD_START
})
const deleteSuccess = ({id}) => ({
  type: c.DELETE_LEAD_SUCCESS,
  id,
})
const deleteFail = ({error}) => ({
  type: c.DELETE_LEAD_FAIL,
  error,
})
export const deleteItem = ({id}) => async (dispatch) => {
  dispatch(deleteStart())
  try {
      await leadService.del({id});
      dispatch(deleteSuccess({id}));
  } catch (error) {
    dispatch(deleteFail({error}))
  }
}



const getCurrentStart = () => ({
  type: c.GET_CURRENT_LEAD_START
})

const getCurrentSuccess = ({currentItem}) => ({
  type: c.GET_CURRENT_LEAD_SUCCESS,
  currentItem,
})

const getCurrentFail = ({error}) => ({
  type: c.GET_CURRENT_LEAD_FAIL,
  error,
})

export const getCurrent = ({id}) => async (dispatch) => {
  dispatch(getCurrentStart())
  try {
      const { data } = await leadService.getOne({id});
      dispatch(getCurrentSuccess({currentItem: data.data}));
  } catch (error) {
    dispatch(getCurrentFail({error}))
  }
}



const submitStart = () => ({
  type: c.SUBMIT_LEAD_START
})
const submitSuccess = ({data}) => ({
  type: c.SUBMIT_LEAD_SUCCESS,
  data,
})
const submitFail = ({error}) => ({
  type: c.SUBMIT_LEAD_FAIL,
  error,
})
export const submit = ({data}) => async (dispatch) => {
  dispatch(submitStart())
  try {
      let response = await leadService.update({data});
     
      dispatch(submitSuccess({data: response.data.data}));
  } catch (error) {
    dispatch(submitFail({error}))
  }
}



const deleteLeadCertificteStart = () => ({
  type: c.DELETE_LEAD_CERTIFICATE_START
})
const deleteLeadCertificateSuccess = ({data}) => ({
  type: c.DELETE_LEAD_CERTIFICATE_SUCCESS,
  currentItem: data,
})
const deleteLeadCertificateFail = ({error}) => ({
  type: c.DELETE_LEAD_CERTIFICATE_FAIL,
  error,
})
export const deleteLeadCertificate = ({id}) => async (dispatch) => {
  dispatch(deleteLeadCertificteStart())
  try {
      let response =  await leadService.delLeadCertificate({id});
      dispatch(deleteLeadCertificateSuccess({data: response.data.data}));
  } catch (error) {
    dispatch(deleteLeadCertificateFail({error}))
  }
}