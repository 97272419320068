import React from 'react';
import Chart from "chart.js";
import { Line } from "react-chartjs-2";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";
import moment from 'moment';
import {
    chartOptions,
    parseOptions,
    options,
  } from "./variables/charts.js";


  class Sales extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        activeNav: 1,
        chartExample1Data: "data1"
      };
      if (window.Chart) {
        parseOptions(Chart, chartOptions());
      }
    }
    toggleNavs = (e, index) => {
      e.preventDefault();
      this.setState({
        activeNav: index,
        chartExample1Data:
          this.state.chartExample1Data === "data1" ? "data2" : "data1"
      });
    }; 
    
    render () {
      const { monthSales } = this.props;
        return (
            <Container className="mt--7" fluid>
              <Row> 
              <Col className="mb-5 mb-xl-0" xl="8">
                  <Card className="bg-gradient-default shadow">
                    <CardHeader className="bg-transparent">
                      <Row className="align-items-center">
                        <div className="col">
                          <h6 className="text-uppercase text-light ls-1 mb-1">
                            Overview
                          </h6>
                          <h2 className="text-white mb-0">Sales</h2>
                        </div>
                        
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {/* Chart */}
                      <div className="chart">
                        <Line
                          data={
                            {
                              labels: monthSales.map(item => moment(item.createdAt).format('YYYY/MM/DD')),
                              datasets: [
                                {
                                  label: "Performance",
                                  data: monthSales.map(item => item.premium / 1000)
                                }
                              ]
                            }
                          }
                          options={options}
                          getDatasetAtEvent={e => console.log(e)}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
        );
    }
}

export default Sales;