import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import * as actions from '../../actions';
import ApplicationForm from './ApplicationForm';

class ApplicationFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { actions: { getCurrent, resetCurrentItem } } = this.props;

    if (id) {
      getCurrent({ id });
    } else {
      resetCurrentItem();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
      cogoToast.success('Application updated successfully!')
      .then(() => {
        window.location.href = '/admin/applications/';
      });;
    }
  }

  render() {
    const { currentItem, actions: { submit } } = this.props;
    return (
      <ApplicationForm
        {...this.props}
        currentItem={currentItem}
        submit={submit}
      />
    );
  }
}

const mapStateToProps = (state) => state.Admin.Application;

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});


const ConnectedApplicationForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationFormContainer));

export { ConnectedApplicationForm as ApplicationForm };
