import client from '../../../../client';

const apiSlug = `leads`;

function getOne({id}) {
    return client.get(`${apiSlug}/${id}`);
}

function getAll({limit, offset, filter}) {
    let query = `${apiSlug}?offset=${offset}&limit=${limit}`;
    if(filter.query) {
        query = `${query}&query=${filter.query}`;
    }
    if(filter.status) {
        query = `${query}&status=${filter.status}`;
    }
    return client.get(query);
}

function update({data}) {
    return client.put(`${apiSlug}/${data.id}`, data);
}

function add({data}) {
    return client.post(apiSlug, data);
}

function del({id}) {
    return client.delete(`${apiSlug}/${id}`);
}

function delLeadCertificate({id}) {
    return client.delete(`${apiSlug}/${id}/certificate`);
}


export { getAll, update,  add, del, getOne , delLeadCertificate};