import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions'
import ForgotPassword from './ForgotPassword';

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { successMessage: null, errorMessage: null}
  }

  componentDidUpdate(prevProps) {
    if(prevProps.submitting && !this.props.submitting && !this.props.error) {
      this.setState({
        errorMessage: null,
        successMessage: 'Please check your email for reset password link.'
      });
    }
    else if(prevProps.submitting && !this.props.submit && this.props.error) {
      this.setState({
        successMessage: null,
        errorMessage: 'Email not found in our system. Please make sure you enter correct email address.'
      });
    }
  }

  render() {
    const { actions: {submit}, error } = this.props;
    const { successMessage, errorMessage } = this.state;
    return (
      <ForgotPassword
        {...this.props}
        submit={submit}
        error={error}
        successMessage={successMessage}
        errorMessage={errorMessage}
      />
    )
  }
}

const mapStateToProps = state => {
  return state.Auth.ForgotPassword
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}


const ConnectedForgotPassword = connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer)

export { ConnectedForgotPassword as ForgotPassword }

