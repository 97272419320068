import React from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
  } from "reactstrap";
import { withRouter } from 'react-router-dom';
import Avatar from 'react-avatar';

import Header from "../../Headers/SlimHeader";
import {MyProfileForm} from './MyProfileForm';
import { MFA } from './MFA';

const MyProfile = (props) => {
    
    const {currentItem, enableMFA, barcode, generateCode, removeMFA} = props;
    
    return (
        <>
            <Header />
            <Container fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image mt-4">
                        <Avatar name={`${currentItem.firstName} ${currentItem.lastName}`} size="100" round={true} />
                    </div>
                  </Col>
                </Row>
                <CardBody className="pt-0 pt-md-4">
                  <div className="text-center">
                    <h3>
                     {currentItem.firstName} {currentItem.lastName}
                    </h3>
                    <div className="h5 font-weight-300">
                      {currentItem.email}
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                           {currentItem.Account && currentItem.Account.name}
                    </div>

                    <MFA removeMFA={removeMFA} currentUser={currentItem} generateCode={generateCode} barcode={barcode} enableMFA={enableMFA}/>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-white shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My Profile</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <MyProfileForm {...props} />
                 </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        </>
    );
}

export default withRouter(MyProfile);