import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions'
import * as accountActions from '../../Account/actions';
import * as healthCoverActions from '../../HealthCover/actions';
import GeneratePurchaseOrder from './GeneratePurchaseOrder';

class GeneratePurchaseOrderContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: null
    }
  }

  componentDidMount(){
    const { actions: { getAccounts } } = this.props;
    getAccounts({ limit: 1000, offset: 0, filter: {} });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
      this.setState({showModal: false});
    }
  }

  render() {
    const { submittingForm,  authUser, accounts, type, actions: {submit} } = this.props;
    const { showModal } = this.state;

    return (
      <GeneratePurchaseOrder
        {...this.props}
        submit={submit}
        submittingForm={submittingForm}
        showModal={showModal}
        type={type}
        accounts={accounts}
        authUser={authUser}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.Admin.PurchaseOrder,
    accounts: state.Admin.Account.data,
  }
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators({...actions, getAccounts: accountActions.getAll, getAllHealthCovers: healthCoverActions.getAllHealthCovers}, dispatch)
    }
  }
  

const ConnectedGeneratePurchaseOrder = connect(mapStateToProps, mapDispatchToProps)(GeneratePurchaseOrderContainer);

export { ConnectedGeneratePurchaseOrder as GeneratePurchaseOrder };

