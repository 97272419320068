import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions';
import Review from './Review';

class ReviewContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {formSuccess: false}
  }

  componentDidMount() {
    const { actions: { getAll } } = this.props;
    getAll();
  }


  render() {
    const { data, loadingAll } = this.props;
    
    return (
      <Review
        {...this.props}
        data={data}
        loadingAll={loadingAll}
      />
    )
  }
}

const mapStateToProps = state => {
  return state.Front.Review
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedReview = connect(mapStateToProps, mapDispatchToProps)(ReviewContainer);

export { ConnectedReview as Review };

