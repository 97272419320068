import * as c from './constants';

const initialState = {
  loading: false,
  error: null,
  authUser: null,
  deleting: false,
  deleteError: null,
  submittingForm: false,
  enablingMFA: false,
  barcode: null,
  generatingCode: false,
  removingMFA: false,
};


const actionHandlers = {
  [c.GET_AUTH_USER_START]: (state) => {
    return {
      ...state,
      loading: true,
      error: null
    }
  },
  [c.GET_AUTH_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      authUser: action.authUser,
      error: null
    }
  },
  [c.GET_AUTH_USER_FAIL]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error
    }
  },
  [c.DELETE_AUTH_USER_START]: (state) => {
    return {
      ...state,
      deleting: true,
    }
  },
  [c.DELETE_AUTH_USER_SUCCESS]: (state) => {
    return {
      ...state,
      authUser: null,
      deleting: false,
    }
  },
  [c.DELETE_AUTH_USER_FAIL]: (state, action) => {
    return {
      ...state,
      deleting: false,
      deleteError: action.error,
    }
  },

  
  [c.SUBMIT_AUTH_USER_START]: (state) => {
    return {
      ...state,
      submittingForm: true,
      error: null
    }
  },
  [c.SUBMIT_AUTH_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      submittingForm: false,
      authUser: action.data,
      error: null
    }
  },
  [c.SUBMIT_AUTH_USER_FAIL]: (state, action) => {
    return {
      ...state,
      submittingForm: false,
      error: action.error
    }
  },

  [c.GENERATE_CODE_START]: (state) => {
    return {
      ...state,
      generatingCode: true,
      error: null
    }
  },
  [c.GENERATE_CODE_SUCCESS]: (state, action) => {
    
    return {
      ...state,
      generatingCode: false,
      barcode: action.barcode
    }
  },
  [c.GENERATE_CODE_FAIL]: (state, action) => {
    return {
      ...state,
      generatingCode: false,
      error: action.error
    }
  },

  [c.ENABLE_MFA_START]: (state) => {
    return {
      ...state,
      enablingMFA: true,
      error: null
    }
  },
  [c.ENABLE_MFA_SUCCESS]: (state, action) => {
    
    return {
      ...state,
      enablingMFA: false,
      authUser: action.user
    }
  },
  [c.ENABLE_MFA_FAIL]: (state, action) => {
    return {
      ...state,
      enablingMFA: false,
      error: action.error
    }
  },


  [c.REMOVE_MFA_START]: (state) => {
    return {
      ...state,
      removingMFA: true,
      error: null
    }
  },
  [c.REMOVE_MFA_SUCCESS]: (state, action) => {
    
    return {
      ...state,
      removingMFA: false,
      authUser: action.user
    }
  },
  [c.REMOVE_MFA_FAIL]: (state, action) => {
    return {
      ...state,
      removingMFA: false,
      error: action.error
    }
  },
}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
