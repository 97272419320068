import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  InputGroup,
  Label,
  Input,
  Form,
  Alert,
} from 'reactstrap';
import useForm from 'react-hook-form';
import * as yup from 'yup';
import moment from 'moment';

import { Loader } from '../../../Common';

const formSchema = yup.object().shape({
  dateOfBirth: yup.string().required('Date of birth is required'),
  email: yup
    .string()
    .required('Email address is required')
    .email('Invalid email address'),
  passportNumber: yup.string().required('Passport number is required'),
});

const MyCoverForm = (props) => {
  const { myCoverResponse, submitting } = props;

  const { register, handleSubmit, clearError, setValue, errors } = useForm({
    validationSchema: formSchema,
  });

  const handleInputChange = (event) => {
    const { target } = event;
    let name = target.name;
    const value = target.value;
    setValue(name, value);
    clearError(name);
  };

  const onSubmit = (data) => {
    const { submitForm } = props;
    submitForm({
      data: {
        ...data,
        dateOfBirth: moment(data.dateOfBirth).format('DD/MM/YYYY'),
      },
    });
  };

  useEffect(() => {
    register({ name: 'dateOfBirth' });
    register({ name: 'email' });
    register({ name: 'passportNumber' });
  }, [register]);

  return (
    <section className="section section-lg mt--200">
      <Container>
        <Row className="justify-content-center">
          <Col lg="12" className="offset-lg-2">
            <Row className="row-grid">
              <Col lg="5" className="offset-lg-3">
                <Card className="shadow border-0">
                  <CardBody className="py-5">
                    {myCoverResponse && (
                      <>
                        {myCoverResponse.message && (
                          <Alert color="success">
                            {myCoverResponse.message}
                          </Alert>
                        )}

                        {myCoverResponse.error && (
                          <Alert color="danger">{myCoverResponse.error}</Alert>
                        )}

                        {myCoverResponse.applicationId && (
                          <Button
                            className="mt-4 bg-gradient-primary"
                            color="primary"
                            href={`${window.location.origin}/cover-type/oshc/application/${myCoverResponse.applicationId}/payment`}
                            style={{ borderRadius: 30 }}
                          >
                            Make payment
                          </Button>
                        )}
                      </>
                    )}
                    {(!myCoverResponse || myCoverResponse.error) && (
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                          <Col lg="12">
                            <FormGroup className="form-item">
                              <Label>Email address*</Label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  className="form-control-alternative"
                                  name="email"
                                  type="email"
                                  onChange={handleInputChange}
                                />
                              </InputGroup>
                              {errors.email && (
                                <span className="form-error">
                                  {errors.email.message}
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="12">
                            <FormGroup className="form-item">
                              <Label>Passport number*</Label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  className="form-control-alternative"
                                  name="passportNumber"
                                  type="text"
                                  onChange={handleInputChange}
                                />
                              </InputGroup>
                              {errors.passportNumber && (
                                <span className="form-error">
                                  {errors.passportNumber.message}
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="12">
                            <FormGroup className="form-item">
                              <Label>Date of birth*</Label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  className="form-control-alternative"
                                  name="dateOfBirth"
                                  type="text"
                                  onChange={handleInputChange}
                                />
                              </InputGroup>
                              {errors.dateOfBirth && (
                                <span className="form-error">
                                  {errors.dateOfBirth.message}
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>

                        <div className="text-center">
                          <Button
                            className="mt-4 bg-gradient-primary"
                            color="primary"
                            type="submit"
                            style={{ borderRadius: 30 }}
                            disabled={submitting}
                          >
                            {submitting ? (
                              <Loader color="#ffffff" />
                            ) : (
                              <span>Email my cover </span>
                            )}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MyCoverForm;
