import * as c from './constants';

const initialState = {
  submitting: false,
  error: null,
  paymentType: null,
  paymentData: null,
  gettingPaymentStatus: false,
  paymentStatus: null
};


const actionHandlers = { 
  [c.SUBMIT_PAYMENT_START]: (state) => {
    return {
      ...state,
      submitting: true,
      error: null,
    }
  },
  [c.SUBMIT_PAYMENT_SUCCESS]: (state, action) => {
    return {
      ...state,
      error: null,
      submitting: false,
      paymentType: action.paymentType,
      paymentData: action.data
    }
  },
  [c.SUBMIT_PAYMENT_FAIL]: (state, action) => {
    return {
      ...state,
      submitting: false,
      error: action.error
    }
  },

  [c.GET_PAYMENT_STATUS_START]: (state) => {
    return {
      ...state,
      gettingPaymentStatus: true,
      error: null,
    }
  },
  [c.GET_PAYMENT_STATUS_SUCCESS]: (state, action) => {
    return {
      ...state,
      error: null,
      gettingPaymentStatus: false,
      paymentStatus: action.paymentStatus
    }
  },
  [c.GET_PAYMENT_STATUS_FAIL]: (state, action) => {
    return {
      ...state,
      gettingPaymentStatus: false,
      error: action.error
    }
  },
}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
