import client from '../../../client';

function addApplication({application}) {
    return client.post(`applications`, application);
}

function updateApplication({application}) {
    return client.put(`applications/${application.id}`, application);
}


function getApplication({id}) {
    return client.get(`applications/${id}`);
}


function downloadInvoice({id, currency =  'aud'}) {
    return client.get(`applications/${id}/invoice?currency=${currency}`);
}



export { addApplication, updateApplication, getApplication, downloadInvoice };