import React, { useEffect } from 'react';
import * as yup from 'yup';
import {  Button,
    FormGroup,
    Form,
    InputGroup,
    Modal,
    Row,
    Col,
    Label,
} from "reactstrap";
import useForm from 'react-hook-form';
import { StyledDropdown } from '../../../Front/common';

const Assign = ({application, setAssignApplication, users, assignUser, submittingForm}) => {
    const formSchema = {
        assigneeId: yup.string().required('Assignee field is required.'),
    };

    const { register, handleSubmit, clearError, setValue, errors } = useForm({
        validationSchema: yup.object().shape(formSchema),

    });

    const onSubmit = data => {
       assignUser({assigneeId: data.assigneeId, id: application.id});
    }

    const handleSelectChange = async (selectedOption, name) => {
        const value = selectedOption.value ? selectedOption.value : selectedOption.name;
        setValue(name, value);
        clearError(name)
        
    };

    useEffect(() => {
        register({ name: 'assigneeId' });
    }, [register]);

    return (
        <>
            

            <Modal
            className="modal-dialog-centered"
            isOpen={true}
            
          >
            <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header">
              <h2 className="modal-title" id="exampleModalLabel">
                Assign user to the application
              </h2>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={()=>setAssignApplication(null)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
                <Row>
                  <Col lg="12">
                    <div className="application-sidebar">
                        <p className="m-0 p-0">
                        Name: {application.firstName} {application.lastName} <br />
                        Email: {application.email}<br />
                        Premium: ${application.premium}<br />
                        Type: {application.clientType}<br />
                        </p>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                      <FormGroup className="form-item">
                          <Label>Assignee<span className="form-error">*</span></Label>
                          <InputGroup className="input-group-alternative">
                                <StyledDropdown
                                    onChange={(selected) => handleSelectChange(selected, 'assigneeId')} 
                                    name="assigneeId"
                                    options={users.map(user => ({label: `${user.firstName} ${user.lastName}`, value: user.id}))}
                                    placeholder='Select' />
                            </InputGroup>
                            {errors.assigneeId && (<span className="form-error">{errors.assigneeId.message}</span>)}
                          
                      </FormGroup>
                  </Col>
                </Row>
            </div>
            <div className="modal-footer" style={{borderTop:'1px solid #e9ecef'}}>
              <Button
                color="link"
                data-dismiss="modal"
                type="button"
               
                onClick={() => setAssignApplication(null)}
              >
                Close
              </Button>
              <Button color='primary'  disabled={submittingForm}  type="submit">
                Submit
              </Button>
            </div>
            </Form>
        </Modal>
        </>
    );
}

export default Assign;