import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions'
import ExportBill from './ExportBill';

class ExportBillContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: null
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.exporting && !this.props.exporting && !this.props.error) {
      this.setState({showModal: false});
    }
  }

  render() {
    const { exporting,  authUser, type, actions: {exportBill} } = this.props;
    const { showModal } = this.state;

    return (
      <ExportBill
        {...this.props}
        exportBill={exportBill}
        exporting={exporting}
        showModal={showModal}
        type={type}
        authUser={authUser}
      />
    )
  }
}

const mapStateToProps = state => {
  return state.Admin.PurchaseOrder;
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedExportBill = connect(mapStateToProps, mapDispatchToProps)(ExportBillContainer);

export { ConnectedExportBill as ExportBill };

