import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../Application/actions';
import Confirmation from './Confirmation';

class ConfirmationContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const {  applicationId } = this.props.match.params;
    const { actions: { getApplication } } = this.props;
    if(applicationId) {
      getApplication({id: applicationId});
    }
  }



  render() {
    const { application } = this.props;
 

    return (
      <Confirmation
        {...this.props}
        application= {application}
      />
    )
  }
}

const mapStateToProps = state => {
  return state.Front.Application;
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedConfirmation = connect(mapStateToProps, mapDispatchToProps)(ConfirmationContainer);

export { ConnectedConfirmation as Confirmation };

