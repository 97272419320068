import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions'
import FeatureCategory from './FeatureCategory';

class FeatureCategoryContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.actions.getAll(); 
  }

  render() {
    const { data, actions: { deleteItem } } = this.props;
    return (
      <FeatureCategory
        {...this.props}
        data={data}
        deleteItem={deleteItem}
      />
    )
  }
}

const mapStateToProps = state => {
  return state.Admin.FeatureCategory
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedFeatureCategory = connect(mapStateToProps, mapDispatchToProps)(FeatureCategoryContainer);

export { ConnectedFeatureCategory as FeatureCategory };

