import React, { useState } from 'react';
import Select from 'react-select';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Header from '../../../Headers/SlimHeader';

const HealthCoverUrl = ({currentItem, submittingForm, history, healthCovers, submit, loggedInUser}) => {
  const [formData, setFormData] = useState(currentItem.healthCoverUrls);
  const healthCoverOptions = healthCovers.map(item => ({value: item.id, label: `${item.HealthCoverType.name} - ${item.name} ${item.category ? ' - ' +  item.category : ''} - ${(item.HealthCoverType.name === 'OVHC') ? ((item.excess === '0' || !item.excess) ? 'NO EXCESS' : item.excess + ' EXCESS') : '' } (${item.Provider.name})`}));

  const handleSubmit = () => {
    submit({ data: { ...currentItem, healthCoverUrls: formData } });
  };

  const handleInputChange = (event, id) => {
    const { target: {value} } = event;
    setFormData(formData.map((item) => {
      if(item.id === id) {
        return {...item, agentUrl: value}
      }
      return item;
    }));
  };

  const selectInput = (selected, id) => {
    setFormData(formData.map((item) => {
      if(item.id === id) {
        return {...item, healthCoverId: selected.value}
      }
      return item;
    }));
  };

  const addNew = () => {
    setFormData([...formData, {healthCoverId: '', id:uuidv4(),  agentUrl: ""}])
  }

  const deleteRow = (id) => {
    setFormData(formData.filter(item => item.id !== id));
  }
  
  return (
    <>
      <Header />
      <Container fluid>
        <Row>
          <Col className="order-xl-1" lg="8">
            <Card className="bg-white shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Configure Health Cover Urls for <em>{currentItem.name}</em></h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="grey"
                      onClick={history.goBack}
                      size="sm"
                    >
                        Back
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form role="form">
                  <div className="pl-lg-4">
                    {
                      formData.map((item) => (
                        <Row key={item.id}>
                          
                          <Col lg="5">
                          <FormGroup>
                                  <label
                                      className="form-control-label"
                                    >
                                  Health Cover
                                    </label>
                                  <Select
                                      name="healthCoverId"
                                      options={healthCoverOptions}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      defaultValue={healthCoverOptions.find(healthCover => healthCover.value === item.healthCoverId)}
                                      onChange={(event) => selectInput(event, item.id)}
                                    />

                                </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                              >
                                    Agent Url
                              </label>

                              <Input
                                className="form-control-alternative"
                                type="text"
                                name="agentUrl"
                                defaultValue={item.agentUrl}
                                onChange={event => handleInputChange(event, item.id)}
                                style={{height: 37}}
                              />
                            </FormGroup>
                          </Col>
                          {
                            (formData.length > 1) && (loggedInUser.role === 'superadmin') &&  (
                              <Col lg="1" className="text-right">
                                <Button  color="grey" style={{marginTop: 30, lineHeight: '1.2em'}}
                                    type="button" onClick={() => deleteRow(item.id)}> <i className="fa fa-trash"></i></Button>
                              </Col>
                            ) 
                          }
                          
                        </Row>
                      ))
                    }
                    

                    <Row>
                      <Col lg="12">
                      <Button  color="grey" style={{marginTop: 30, lineHeight: '1.2em'}}
                                type="button" onClick={addNew}> <i className="fa fa-plus"></i> ADD NEW</Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <hr className="my-4" />
                        <div className="text-right">
                          <Button
                            color="grey"
                            type="button"
                            onClick={history.goBack}
                          >
                                    Cancel
                          </Button>
                          <Button
                            color="primary"
                            type="submit"
                            disabled={!!submittingForm}
                            onClick={handleSubmit}
                          >
                            SUBMIT
                          </Button>

                        </div>
                      </Col>
                    </Row>
                  </div>

                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(HealthCoverUrl);
