import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions'
import HealthCover from './HealthCover';

class HealthCoverContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.actions.getAllHealthCovers({limit:100, offset:0, filter:{}}); 
  }

  render() {
    const { data, actions: { deleteHealthCover } } = this.props;
    return (
      <HealthCover
        {...this.props}
        data={data}
        deleteHealthCover={deleteHealthCover}
      />
    )
  }
}

const mapStateToProps = state => {
  return state.Admin.HealthCover
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedHealthCover = connect(mapStateToProps, mapDispatchToProps)(HealthCoverContainer);

export { ConnectedHealthCover as HealthCover };

