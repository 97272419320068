import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cogoToast from 'cogo-toast';

import * as actions from '../../../ApplicationComment/actions';
import ApplicationComment from './ApplicationComment';

class ApplicationCommentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {comment: ''}
    };
  }


  componentDidMount() {
    const { id } = this.props.match.params;
    const { actions: { getAll } } = this.props;
    if (id) {
        getAll({applicationId: id, offset: 0, limit: 100});
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
      this.setState({formData: {comment: ''}});
      cogoToast.success('Comment added successfully!');
    }
  }

  render() {
    const { data, submittingForm,  actions: { submit } } = this.props;
    const { id } = this.props.match.params;
    const { formData } = this.state;
    return (
      <ApplicationComment
        {...this.props}
        submit={submit}
        submittingForm={submittingForm}
        comments={data}
        applicationId={id}
        formData={formData}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Admin.ApplicationComment,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});


const ConnectedApplicationComment = connect(mapStateToProps, mapDispatchToProps)(ApplicationCommentContainer);

export { ConnectedApplicationComment as ApplicationComment };
