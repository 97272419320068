import client from '../../../../client';

const apiSlug = `report`;

function getReport() {
    return client.get(apiSlug);
}



export { getReport };