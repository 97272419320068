import React from 'react';
import { Container, Row, Col  } from "reactstrap";


const Steps = ( props ) => {
  const { type, currentStep } = props;

  return (
    <div className="position-relative">
          <section className="section section-shaped pb-0">
            <div className={type === 'oshc' ? "shape shape-primary" : "shape shape-secondary" }>
              &nbsp;
            </div>
            <Container className="shape-container d-flex align-items-center">
              <div className="col px-0">
                <Row>
                  <Col lg="12">
                      <div className="steps">
                          <div className="step-item done-item">
                            <span className="step-count">1</span>
                            <span className="step-description">Compare Insurance Providers</span>
                          </div>
                          <div className={currentStep === 2 ? 'step-item current-item': 'step-item done-item'}>
                            <span className="step-count">2</span>
                            <span className="step-description">Enter your personal details</span>
                          </div>
                          <div className={currentStep === 3 ? 'step-item current-item': currentStep < 3 ? 'step-item' : 'step-item done-item'}>
                            <span className="step-count">3</span>
                            <span className="step-description">Make secure payment</span>
                          </div>
                          <div className={currentStep === 4 ? 'step-item current-item last-item': currentStep < 4 ? 'step-item last-item' : 'step-item done-item last-item'}>
                            <span className="step-count">4</span>
                            <span className="step-description">Receive your certificate</span>
                          </div>
                      </div>
                    </Col>
                </Row>
              </div>
            </Container>
          </section>
        </div>
  );
}

export default Steps;
