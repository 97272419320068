import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';
import Select from 'react-select';

import Header from '../../../Headers/SlimHeader';

const FeatureCategoryForm = (props) => {
  const {
    register, handleSubmit, errors, setValue,
  } = useForm();
  const {
    currentItem, submittingForm, history, healthCoverTypes
  } = props;

  const [formData, setFormData] = useState(currentItem);


  useEffect(() => {
    setFormData(currentItem);
  }, [currentItem]);


  const onSubmit = async (updatedItem) => {
    const { submit } = props;
    submit({ data: { ...currentItem, ...updatedItem } });
  };

  const handleInputChange = (event) => {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    setFormData({ ...formData, [name]: value });
  };

  const selectInput = (selected, name) => {
    setFormData({ ...formData, [name]: selected.id });
    setValue([name], selected.id, true);
  };

  const { id, name, healthCoverTypeId, description } = formData;

  return (
    <>
      <Header />
      <Container fluid>
        <Row>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-white shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{id ? 'Edit feature category' : 'Add new feature category'}</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="grey"
                      onClick={history.goBack}
                      size="sm"
                    >
                        Back
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                              <label
                                className="form-control-label"
                              >
                              Name
                              </label>
                              <div className={errors.name && 'has-danger'}>
                                <Input
                                className="form-control-alternative"
                                name="name"
                                type="text"
                                innerRef={register({ required: true })}
                                value={name}
                                onChange={handleInputChange}
                              />
                              </div>
                              {errors.name && (<small className="formError">This field is required</small>)}
                            </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                          <FormGroup className={errors.description && "has-danger" }>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Description
                            </label>
                            <Input
                                className="form-control-alternative"
                                rows="4"
                                type="textarea"
                                name="description"
                                innerRef={register}
                                value={description}
                                onChange={handleInputChange}
                                />
                          </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormGroup className={errors.healthCoverTypeId && 'has-danger'}>
                              <label
                                className="form-control-label"
                              >
                              Health Cover type
                              </label>

                              <Select
                                value={healthCoverTypes ?  healthCoverTypes.find((item) => item.id === healthCoverTypeId) : ''}
                                getOptionLabel={(option) => `${option.name} (${option.fullName})`}
                                getOptionValue={(option) => `${option.id}`}
                                name="healthCoverTypeId"
                                options={healthCoverTypes}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                ref={() => register({ name: 'healthCoverTypeId', required: true })}
                                onChange={(event) => selectInput(event, 'healthCoverTypeId')}
                              />
                              {errors.healthCoverTypeId && (<small className="formError">This field is required</small>)}

                            </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <hr className="my-4" />
                        <div className="text-right">
                              <Button
                                color="grey"
                                type="button"
                                onClick={history.goBack}
                              >
                                    Cancel
                              </Button>
                              <Button
                                color="primary"
                                type="submit"
                                disabled={!!submittingForm}
                              >
                                {id ? 'UPDATE' : 'ADD'}
                              </Button>

                            </div>
                      </Col>
                    </Row>
                  </div>

                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(FeatureCategoryForm);
