const getServerUrl = () => {
  const { hostname } = window.location;
  let serverUrl;

  switch (hostname) {
    case 'localhost':
      serverUrl = 'http://localhost:8181';
      break;
    case 'oshccompare.com.au':
      serverUrl = 'https://api.oshccompare.com.au';
      break;
    case 'www.oshccompare.com.au':
        serverUrl = 'https://api.oshccompare.com.au';
        break;
    case 'staging.oshccompare.com.au':
      serverUrl = 'http://stagingapi.oshccompare.com.au';
      break;
    case 'oshccompare.com.au.s3-website-ap-southeast-2.amazonaws.com':
      serverUrl = 'http://apioshccomparecomau-env.wwpuhwtmch.ap-southeast-2.elasticbeanstalk.com';
      break;
    default:
      serverUrl = 'localhost:8181';
  }

  return serverUrl;
};

const getStripeApiKey = () => {
  const { hostname } = window.location;
  let apiKey;

  switch (hostname) {
    case 'localhost':
      apiKey = 'pk_test_Q2NPXqxhJ9rci6PVeoBuDVGi00lhdcUm83';
      break;
    case 'oshccompare.com.au':
      apiKey = 'pk_live_Z8awqdc1Pj2Fxi90UNbf0blT00v49As5im';
      break;
    default:
      apiKey = 'pk_test_Q2NPXqxhJ9rci6PVeoBuDVGi00lhdcUm83';
  }
  return apiKey;
}

export default {
  serverUrl: getServerUrl(),
  stripeApiKey: getStripeApiKey(),
};
