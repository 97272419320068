import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions';
import * as healthCoverTypeActions from '../../HealthCoverType/actions';
import * as providerActions from '../../Provider/actions';
import HealthCoverForm from './HealthCoverForm';

class HealthCoverFormContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {}
      }

      clearCurrent() {
        const { actions: {clearCurrentHealthCover} } = this.props;
        clearCurrentHealthCover();
      }

      componentDidMount() {
        const {  id } = this.props.match.params;
        const { actions: { getCurrent, getAllHealthCoverTypes,  getAllProviders }} = this.props;
        if(id) {
          getCurrent({id});
        }
        else {
          this.clearCurrent()
        }
        getAllHealthCoverTypes();
        getAllProviders();
      }

      componentDidUpdate(prevProps) {
        if(prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
          const {history}  = this.props;
          history.push('/admin/health-covers');
        }
      }
      
      render() {
        const { currentHealthCover, submittingForm, actions: { submitHealthCoverForm }, healthCoverTypes, providers, premium } = this.props;
       
        return (
          <HealthCoverForm
            {...this.props}
            currentHealthCover={currentHealthCover}
            
            submitHealthCoverForm={submitHealthCoverForm}
            submittingForm={submittingForm}
            healthCoverTypes={healthCoverTypes}
            providers={providers}
            premium={premium}
          />
        )
      }
}

const mapStateToProps = state => {
    return {
      ...state.Admin.HealthCover,
      healthCoverTypes: state.Admin.HealthCoverType.data,
      providers: state.Admin.Provider.data, 
    };
  }
  
  const mapDispatchToProps = dispatch => {
      return {
        actions: bindActionCreators(
          { ...actions, 
            getAllHealthCoverTypes: healthCoverTypeActions.getAllHealthCoverTypes, 
            getAllProviders: providerActions.getAllProviders
          }, dispatch)
      }
    }
    
  
  const ConnectedHealthCoverForm = connect(mapStateToProps, mapDispatchToProps)(HealthCoverFormContainer);
  
  export { ConnectedHealthCoverForm as HealthCoverForm };