import React, {useState} from 'react';
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Row,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Button,
    Col,
    DropdownItem
  } from "reactstrap";
  import { Alert } from "reactstrap";
  import { Link } from "react-router-dom";
  import DeleteModal from '../../DeleteModal';


import Header from "../../Headers/SlimHeader";

const Feature = (props) =>{
    const { data, deleteItem } = props;
    const [deleteItemId, setDeleteItemId] = useState();

    const confirmDelete = ({event, id}) => {
      event.preventDefault();
      setDeleteItemId(id)
    }

    const del = () => {
        deleteItem({id: deleteItemId});
        setDeleteItemId(null);
    }

    
    return (
        <>
             <Header />
             <Container fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col sm={6}>
                      <h3 className="mb-0">All Features</h3> 
                    </Col>
                    <Col sm={6} className="text-right">
                        <Button className="btn-icon " outline size="md" href="/admin/features/new"  color="primary">
                          <span className="btn-inner--icon">
                            <i className="ni ni-fat-add" /> ADD
                          </span>
                        </Button>
                    </Col>
                  </Row>
                  
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Category</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data && data.map(({id, name, FeatureCategory}) => (
                        <tr key={id}>
                          <th scope="row">
                            {name}
                          </th>
                          <td>
                            {FeatureCategory && FeatureCategory.name}
                          </td>
                          <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-dark"
                                    role="button"
                                    href="#"
                                    size="sm"
                                    color=""
                                    onClick={e => e.preventDefault()}
                                  >
                                    <i className="fa fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    <Link 
                                      className="dropdown-item"
                                      to={`/admin/features/${id}/edit`}
                                    >
                                      Edit
                                    </Link>
                                    <DropdownItem 
                                      onClick={(event) => confirmDelete({event, id})}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                </Table>
                <CardFooter className="py-4">
                  
                {
                  (!data || !data.length) && (
                      <Alert color="info">
                        <strong>No data</strong> You don't have any data yet!
                      </Alert>
                  )
                }

                {
                  deleteItemId && (<DeleteModal showModal={deleteItemId} toggleModal={() => setDeleteItemId()} confirmAction={del} title="Delete this feature category?" details="Are you sure you want to delete this feature category? All data related to this data will also be deleted" />)
                  
                }
                    
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        </>
    );
}

export default Feature;