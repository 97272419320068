import client from '../../../../client';

const apiSlug = 'activities';

function getAll({ applicationId,  limit, offset }) {
  return client.get(`${apiSlug}?offset=${offset}&limit=${limit}&applicationId=${applicationId}`);
}


export {
  getAll
};
