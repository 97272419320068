import client from '../../../../client';

function getOne({id}) {
    return client.get(`health-cover-types/${id}`);
}

function getAllHealthCoverTypes() {
    return client.get(`health-cover-types`);
}

function updateHealthCoverType({healthCoverType}) {
    return client.put(`health-cover-types/${healthCoverType.id}`, healthCoverType);
}

export { getAllHealthCoverTypes, updateHealthCoverType, getOne };