import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  Input,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import useForm from 'react-hook-form';
import * as yup from 'yup';
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'react-datepicker/dist/react-datepicker.css';

import {
  countries,
  titleOptions,
  genderOptions,
  visaTypesOption,
} from './constants';
import { StyledDropdown } from '../../../../common';

const applicationFormSchema = {
  title: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  gender: yup.string().required(),
  dateOfBirth: yup.string().required(),
  email: yup.string().required(),
  mobile: yup
    .string()
    .required('Mobile is required')
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      'Invalid mobile number',
    ),
  country: yup.string().required(),
  passportNumber: yup.string().required(),
  visaType: yup.string().required(),
};
// const applicationPartnerSchema = {
//     partnerTitle: yup.string().required(),
//     partnerFirstName: yup.string().required(),
//     partnerLastName: yup.string().required(),
//     partnerGender: yup.string().required(),
//     partnerDateOfBirth: yup.string().required(),
// }

const ApplicationFormOSHC = (props) => {
  const { application, accounts, submittingForm, authUser } = props;
  let applicationChildrenSchema = {};
  const accountOptions = accounts.map((account) => ({
    value: account.id,
    label: `${account.name} (${account.address})`,
  }));

  Array.from(Array(Number(application.children)).keys()).forEach((index) => {
    applicationChildrenSchema[`children${index}Title`] = yup
      .string()
      .required();
    applicationChildrenSchema[`children${index}FirstName`] = yup
      .string()
      .required();
    applicationChildrenSchema[`children${index}LastName`] = yup
      .string()
      .required();
    applicationChildrenSchema[`children${index}Gender`] = yup
      .string()
      .required();
    applicationChildrenSchema[`children${index}DateOfBirth`] = yup
      .string()
      .required();
    if (!application.id) {
      application[`children${index}Title`] = '';
      application[`children${index}FirstName`] = '';
      application[`children${index}LastName`] = '';
      application[`children${index}Gender`] = '';
      application[`children${index}DateOfBirth`] = null;
      application[`children${index}PassportNumber`] = '';
    } else {
      application[`children${index}Title`] =
        application.ApplicationChildren[index].title;
      application[`children${index}FirstName`] =
        application.ApplicationChildren[index].firstName;
      application[`children${index}LastName`] =
        application.ApplicationChildren[index].lastName;
      application[`children${index}Gender`] =
        application.ApplicationChildren[index].gender;
      application[`children${index}DateOfBirth`] =
        application.ApplicationChildren[index].dateOfBirth;
      application[`children${index}PassportNumber`] =
        application.ApplicationChildren[index].passportNumber;
    }
  });
  if (application.ApplicationPartner) {
    application['partnerTitle'] = application.ApplicationPartner.title;
    application['partnerFirstName'] = application.ApplicationPartner.firstName;
    application['partnerLastName'] = application.ApplicationPartner.lastName;
    application['partnerPassportNumber'] =
      application.ApplicationPartner.passportNumber;
    application['partnerGender'] = application.ApplicationPartner.gender;
    application['partnerDateOfBirth'] =
      application.ApplicationPartner.dateOfBirth;
  }
  const [formData, setFormData] = useState(application);

  const { register, handleSubmit, clearError, setValue, errors } = useForm({
    validationSchema:
      formData.clientType === 'Single'
        ? yup.object().shape(applicationFormSchema)
        : yup
            .object()
            .shape({ ...applicationFormSchema, ...applicationChildrenSchema }),
    defaultValues: application,
  });

  const handleSelectChange = async (selectedOption, name) => {
    const value = selectedOption.value
      ? selectedOption.value
      : selectedOption.name;
    const changeData = { [name]: value };
    setFormData({ ...formData, ...changeData });
    setValue(name, value);
    clearError(name);
  };

  const selectInput = (selected, name) => {
    let value;
    
    if (selected && selected.value) {
      value = selected.value;
    } else {
      value = selected ? JSON.stringify(selected) : null;
    }

    setValue([name], value);
    setFormData({ ...formData, [name]: value });
  };

  const handleInputChange = (event) => {
    const { target } = event;
    let name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setFormData({ ...formData, [name]: value });
    setValue(name, value);
    clearError(name);
  };

  // const handleAddressChange = (data) => {
  //     const value = data.description;
  //     setFormData({...formData, 'australianAddress':  value })
  //     setValue('australianAddress', value);
  //     clearError('australianAddress')
  // }

  // var validDateOfBirth = ( current ) => {
  //     var today = Datetime.moment();
  //     return current.isBefore( today );
  // };

  const onSubmit = (data) => {
    const { submit } = props;
    submit({ data: { ...application, ...data } });
  };

  useEffect(() => {
    register({ name: 'accountId' });
    register({ name: 'title' });
    register({ name: 'firstName' });
    register({ name: 'lastName' });
    register({ name: 'gender' });
    register({ name: 'dateOfBirth' });
    register({ name: 'email' });
    register({ name: 'mobile' });
    register({ name: 'country' });
    register({ name: 'passportNumber' });
    register({ name: 'visaType' });
    register({ name: 'college' });
    register({ name: 'studentNumber' });
    register({ name: 'australianAddress' });
    register({ name: 'currentHealthProviderName' });
    register({ name: 'currentHealthProviderNumber' });
    if (formData.clientType === 'Couple' || formData.clientType === 'Family') {
      register({ name: 'partnerTitle' });
      register({ name: 'partnerFirstName' });
      register({ name: 'partnerLastName' });
      register({ name: 'partnerPassportNumber' });
      register({ name: 'partnerGender' });
      register({ name: 'partnerDateOfBirth' });
    }
    if (parseInt(formData.children) > 0) {
      Array.from(Array(Number(formData.children)).keys()).forEach((index) => {
        register({ name: `children${index}Title` });
        register({ name: `children${index}FirstName` });
        register({ name: `children${index}LastName` });
        register({ name: `children${index}Gender` });
        register({ name: `children${index}DateOfBirth` });
        register({ name: `children${index}PassportNumber` });
      });
    }
  }, [register, formData.clientType, formData.children]);

  console.log({accountOptions});

  return (
    <Container>
      <Row>
        <Col lg="12">
          <Row>
            <Col lg="6">
              &nbsp;
            </Col>
            <Col lg="6" className="text-right">
              <span className="form-info">
                {' '}
                <span className="form-error">*</span> Indicates required field
              </span>
            </Col>
          </Row>
          <Form onSubmit={handleSubmit(onSubmit)}>

        
          {authUser.role === 'superadmin' && (
          <Row>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">
                  Account
                </label>

                <Select
                  value={
                    accountOptions &&
                    accountOptions.find(
                      (item) => item.value === formData.accountId,
                    )
                  }
                  name="accountId"
                  options={accountOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(event) =>
                    selectInput(event, 'accountId')
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        )}

<Row>
            <Col lg="6">
              <h2>Personal Details</h2>
            </Col>
            
          </Row>
            <Row>
              <Col lg="3">
                <FormGroup className="form-item">
                  <Label>
                    Title<span className="form-error">*</span>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <StyledDropdown
                      selected={titleOptions.find(
                        (item) => item.value === formData.title,
                      )}
                      onChange={(selected) =>
                        handleSelectChange(selected, 'title')
                      }
                      name="title"
                      options={titleOptions}
                      placeholder="Select"
                    />
                  </InputGroup>
                  {errors.title && (
                    <span className="form-error">Title is required</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup className="form-item">
                  <Label>
                    First name<span className="form-error">*</span>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      className="form-control-alternative"
                      name="firstName"
                      type="text"
                      value={formData.firstName}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                  {errors.firstName && (
                    <span className="form-error">First name is required</span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup className="form-item">
                  <Label>
                    Last name<span className="form-error">*</span>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      className="form-control-alternative"
                      name="lastName"
                      type="text"
                      value={formData.lastName}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                  {errors.lastName && (
                    <span className="form-error">Last name is required</span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="6">
                <FormGroup className="form-item">
                  <Label>
                    Gender<span className="form-error">*</span>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <StyledDropdown
                      selected={genderOptions.find(
                        (item) => item.value === formData.gender,
                      )}
                      onChange={(selected) =>
                        handleSelectChange(selected, 'gender')
                      }
                      name="gender"
                      options={genderOptions}
                      placeholder="Select"
                    />
                  </InputGroup>
                  {errors.gender && (
                    <span className="form-error">Gender is required</span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup className="form-item">
                  <Label>
                    Date of birth{' '}
                    <span style={{ fontSize: 12, color: '#cccccc' }}>
                      (DD/MM/YYYY)
                    </span>{' '}
                    <span className="form-error">*</span>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      className="form-control-alternative"
                      name="dateOfBirth"
                      type="text"
                      value={formData.dateOfBirth}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                  {errors.dateOfBirth && (
                    <span className="form-error">
                      Date of birth is required
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="6">
                <FormGroup className="form-item">
                  <Label>
                    Email address<span className="form-error">*</span>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      className="form-control-alternative"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                  <span className="form-info">
                    We will send your certificate to this email. Please make
                    sure you have access to this email address.
                  </span>
                  {errors.email && (
                    <span className="form-error">
                      Email address is required
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup className="form-item">
                  <Label>
                    Mobile number<span className="form-error">*</span>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      className="form-control-alternative"
                      name="mobile"
                      type="text"
                      value={formData.mobile}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                  {errors.mobile && (
                    <span className="form-error">{errors.mobile.message}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <hr />
              </Col>
            </Row>

            <Row>
              <Col lg="6">
                <FormGroup className="form-item">
                  <Label>
                    Country of citizenship<span className="form-error">*</span>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <StyledDropdown
                      selected={
                        formData.country
                          ? countries.find(
                              (item) => item.name === formData.country,
                            )
                          : ''
                      }
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => `${option.name}`}
                      onChange={(selected) =>
                        handleSelectChange(selected, 'country')
                      }
                      name="country"
                      options={countries}
                      placeholder="Select"
                    />
                  </InputGroup>
                  {errors.country && (
                    <span className="form-error">
                      Country of citizenship is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup className="form-item">
                  <Label>
                    Passport number<span className="form-error">*</span>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      className="form-control-alternative"
                      name="passportNumber"
                      type="text"
                      value={formData.passportNumber}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                  {errors.passportNumber && (
                    <span className="form-error">
                      Passport number is required
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="6">
                <FormGroup className="form-item">
                  <Label>
                    Visa type<span className="form-error">*</span>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <StyledDropdown
                      selected={visaTypesOption.find(
                        (item) => item.value === formData.visaType,
                      )}
                      onChange={(selected) =>
                        handleSelectChange(selected, 'visaType')
                      }
                      name="visaType"
                      options={visaTypesOption}
                      placeholder="Select"
                    />
                  </InputGroup>
                  {errors.visaType && (
                    <span className="form-error">Visa type is required</span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <hr />
              </Col>
            </Row>

            <Row>
              <Col lg="6">
                <FormGroup className="form-item">
                  <Label>School, college or university name</Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      className="form-control-alternative"
                      name="college"
                      type="text"
                      value={formData.college}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup className="form-item">
                  <Label>Student number</Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      className="form-control-alternative"
                      name="studentNumber"
                      type="text"
                      value={formData.studentNumber}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <hr />
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <FormGroup className="form-item">
                  <Label>Australian address</Label>
                  <InputGroup className="input-group-alternative">
                    {/* <GooglePlacesAutocomplete
                                                onSelect={handleAddressChange}
                                                initialValue={formData.australianAddress}
                                                renderInput={(props) => (
                                                    <Input
                                                    value={formData.australianAddress}
                                                    className="form-control-alternative"
                                                    {...props}
                                                    />
                                                )}
                                                /> */}
                    <Input
                      className="form-control-alternative"
                      name="australianAddress"
                      type="text"
                      value={formData.australianAddress}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <hr />
              </Col>
            </Row>

            <Row>
              <Col lg="6">
                <FormGroup className="form-item">
                  <Label>Current health provider name</Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      className="form-control-alternative"
                      name="currentHealthProviderName"
                      type="text"
                      value={formData.currentHealthProviderName}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup className="form-item">
                  <Label>Current health provider number</Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      className="form-control-alternative"
                      name="currentHealthProviderNumber"
                      type="text"
                      value={formData.currentHealthProviderNumber}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>

            {(formData.clientType === 'Couple' ||
              formData.clientType === 'Family') && (
              <>
                <Row>
                  <Col lg="12">
                    <hr />
                  </Col>
                </Row>
                <div style={{ backgroundColor: '#efefef' }} className="p-sm">
                  <Row>
                    <Col lg="12">
                      <h5>Partner details</h5>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="3">
                      <FormGroup className="form-item">
                        <Label>
                          Title<span className="form-error">*</span>
                        </Label>
                        <InputGroup className="input-group-alternative">
                          <StyledDropdown
                            selected={titleOptions.find(
                              (item) => item.value === formData.partnerTitle,
                            )}
                            onChange={(selected) =>
                              handleSelectChange(selected, 'partnerTitle')
                            }
                            name="partnerTitle"
                            options={titleOptions}
                            placeholder="Select"
                          />
                        </InputGroup>
                        {errors.partnerTitle && (
                          <span className="form-error">
                            Partner title is required
                          </span>
                        )}
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup className="form-item">
                        <Label>
                          First name<span className="form-error">*</span>
                        </Label>
                        <InputGroup className="input-group-alternative">
                          <Input
                            className="form-control-alternative"
                            name="partnerFirstName"
                            type="text"
                            value={formData.partnerFirstName}
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                        {errors.partnerFirstName && (
                          <span className="form-error">
                            Partner first name is required
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="5">
                      <FormGroup className="form-item">
                        <Label>
                          Last name<span className="form-error">*</span>
                        </Label>
                        <InputGroup className="input-group-alternative">
                          <Input
                            className="form-control-alternative"
                            name="partnerLastName"
                            type="text"
                            value={formData.partnerLastName}
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                        {errors.partnerLastName && (
                          <span className="form-error">
                            Partner last name is required
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="3">
                      <FormGroup className="form-item">
                        <Label>
                          Gender<span className="form-error">*</span>
                        </Label>
                        <InputGroup className="input-group-alternative">
                          <StyledDropdown
                            selected={genderOptions.find(
                              (item) => item.value === formData.partnerGender,
                            )}
                            onChange={(selected) =>
                              handleSelectChange(selected, 'partnerGender')
                            }
                            name="partnerGender"
                            options={genderOptions}
                            placeholder="Select"
                          />
                        </InputGroup>
                        {errors.partnerGender && (
                          <span className="form-error">
                            Partner gender is required
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup className="form-item">
                        <Label>
                          Date of birth{' '}
                          <span style={{ fontSize: 12, color: '#cccccc' }}>
                            (DD/MM/YYYY)
                          </span>
                          <span className="form-error">*</span>
                        </Label>
                        <InputGroup className="input-group-alternative">
                          <Input
                            className="form-control-alternative"
                            name="partnerDateOfBirth"
                            type="text"
                            value={formData.partnerDateOfBirth}
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                        {errors.partnerDateOfBirth && (
                          <span className="form-error">
                            Partner date of birth is required
                          </span>
                        )}
                      </FormGroup>
                    </Col>

                    <Col lg="5">
                      <FormGroup className="form-item">
                        <Label>Passport number</Label>
                        <InputGroup className="input-group-alternative">
                          <Input
                            className="form-control-alternative"
                            name="partnerPassportNumber"
                            type="text"
                            value={formData.partnerPassportNumber}
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                        {errors.partnerPassportNumber && (
                          <span className="form-error">
                            Partner passport number is required
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </>
            )}

            {formData.clientType === 'Family' && formData.children > 0 && (
              <>
                <div
                  style={{ backgroundColor: '#efefef' }}
                  className="p-sm mt-sm"
                >
                  <Row>
                    <Col lg="12">
                      <h5>Children details</h5>
                    </Col>
                  </Row>
                  {Array.from(Array(Number(formData.children)).keys()).map(
                    (index) => (
                      <div key={`children${index}`}>
                        <Row>
                          <Col lg="3">
                            <FormGroup className="form-item">
                              <Label>
                                Title<span className="form-error">*</span>
                              </Label>
                              <InputGroup className="input-group-alternative">
                                <StyledDropdown
                                  selected={titleOptions.find(
                                    (item) =>
                                      item.value ===
                                      formData[`children${index}Title`],
                                  )}
                                  onChange={(selected) =>
                                    handleSelectChange(
                                      selected,
                                      `children${index}Title`,
                                    )
                                  }
                                  name={`children${index}Title`}
                                  options={titleOptions}
                                  placeholder="Select"
                                />
                              </InputGroup>
                              {errors[`children${index}Title`] && (
                                <span className="form-error">
                                  Children title is required
                                </span>
                              )}
                            </FormGroup>
                          </Col>

                          <Col lg="4">
                            <FormGroup className="form-item">
                              <Label>
                                First name<span className="form-error">*</span>
                              </Label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  className="form-control-alternative"
                                  name={`children${index}FirstName`}
                                  type="text"
                                  value={formData[`children${index}FirstName`]}
                                  onChange={handleInputChange}
                                />
                              </InputGroup>
                              {errors[`children${index}FirstName`] && (
                                <span className="form-error">
                                  Children first name is required
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="5">
                            <FormGroup className="form-item">
                              <Label>
                                Last name<span className="form-error">*</span>
                              </Label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  className="form-control-alternative"
                                  name={`children${index}LastName`}
                                  type="text"
                                  value={formData[`children${index}LastName`]}
                                  onChange={handleInputChange}
                                />
                              </InputGroup>
                              {errors[`children${index}LastName`] && (
                                <span className="form-error">
                                  Children last name is required
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="3">
                            <FormGroup className="form-item">
                              <Label>
                                Gender<span className="form-error">*</span>
                              </Label>
                              <InputGroup className="input-group-alternative">
                                <StyledDropdown
                                  selected={genderOptions.find(
                                    (item) =>
                                      item.value ===
                                      formData[`children${index}Gender`],
                                  )}
                                  onChange={(selected) =>
                                    handleSelectChange(
                                      selected,
                                      `children${index}Gender`,
                                    )
                                  }
                                  name={`children${index}Gender`}
                                  options={genderOptions}
                                  placeholder="Select"
                                />
                              </InputGroup>
                              {errors[`children${index}Gender`] && (
                                <span className="form-error">
                                  Children gender is required
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup className="form-item">
                              <Label>
                                Date of birth{' '}
                                <span
                                  style={{ fontSize: 12, color: '#cccccc' }}
                                >
                                  (DD/MM/YYYY)
                                </span>
                                <span className="form-error">*</span>
                              </Label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  className="form-control-alternative"
                                  name={`children${index}DateOfBirth`}
                                  type="text"
                                  value={
                                    formData[`children${index}DateOfBirth`]
                                  }
                                  onChange={handleInputChange}
                                />
                              </InputGroup>
                              {errors[`children${index}DateOfBirth`] && (
                                <span className="form-error">
                                  Children date of birth is required
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="5">
                            <FormGroup className="form-item">
                              <Label>
                                Passport Number{' '}
                                <span className="form-error">*</span>
                              </Label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  className="form-control-alternative"
                                  name={`children${index}PassportNumber`}
                                  type="text"
                                  value={
                                    formData[`children${index}PassportNumber`]
                                  }
                                  onChange={handleInputChange}
                                />
                              </InputGroup>
                              {errors[`children${index}PassportNumber`] && (
                                <span className="form-error">
                                  Children passport number is required
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>

                        {index + 1 < Number(formData.children) && (
                          <Row>
                            <Col lg="12">
                              <hr />
                            </Col>
                          </Row>
                        )}
                      </div>
                    ),
                  )}
                </div>
              </>
            )}

            {Object.keys(errors).length ? (
              <Row>
                <Col lg="12" className="text-right">
                  <div className="form-error">
                    Please make sure all required fields are filled.{' '}
                  </div>
                </Col>
              </Row>
            ) : null}

            <Row>
              <Col lg="12">
                <hr />
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <div className="form-action text-right">
                  <Button
                    className="bg-gradient-primary"
                    color="primary"
                    size="lg"
                    type="submit"
                    disabled={
                      Object.keys(errors).length > 0 ||
                      submittingForm ||
                      application.status === 'certificateIssued'
                    }
                    style={{ marginLeft: 15 }}
                  >
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ApplicationFormOSHC;
