import client from '../../../../client';

const apiSlug = 'quotes';

function emailQuote({ data }) {
  return client.post(`${apiSlug}/send-email`, data);
}


export {
  emailQuote
};
