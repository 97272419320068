import * as c from './constants';

const initialState = {
  loadingAll: false,
  error: null,
  data: [],
};

const actionHandlers = {
  [c.GET_ACTIVITIES_START]: (state) => ({
    ...state,
    loadingAll: true,
  }),
  [c.GET_ACTIVITIES_SUCCESS]: (state, action) => ({
    ...state,
    loadingAll: false,
    data: action.data,
    total: action.total,
  }),
  [c.GET_ACTIVITIES_FAIL]: (state, action) => ({
    ...state,
    loadingAll: false,
    error: action.error,
  }),
};

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
};
