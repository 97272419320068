import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions'
import * as applicationActions from '../../Application/actions'
import ComparisionTableOVHC from './ComparisionTableOVHC';

class ComparisionTableContainerOVHC extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  loadQuotes() {
    const { clientType, children, startDate } = this.props.application;
    const { coverType } = this.props.match.params;
    this.props.actions.getQuotes({coverType,   clientType, children, startDate}); 
  }

  componentDidMount() {
    this.loadQuotes();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.application !== this.props.application) {
      this.loadQuotes();
    }
  }

  
  render() {
    const { quotes, featureCategories, loadingQuotes, actions: {updateApplication} } = this.props;
    return (
      <ComparisionTableOVHC
        {...this.props}
        quotes={quotes}
        featureCategories={featureCategories}
        updateApplication={updateApplication}
        loadingQuotes={loadingQuotes}
      />
    )
  }
}

const mapStateToProps = state => {
  return {...state.Front.Quote, ...state.Front.Application}
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators({...actions, ...applicationActions}, dispatch)
    }
  }
  

const ConnectedComparisionTableOVHC = connect(mapStateToProps, mapDispatchToProps)(ComparisionTableContainerOVHC);

export { ConnectedComparisionTableOVHC as ComparisionTableOVHC };

