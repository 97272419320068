import React, { useState } from 'react';
import { TabContent, Nav, NavItem, NavLink, Badge } from 'reactstrap';
import classnames from 'classnames';

const ApplicationTabs = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    
    const toggle = tab => {
      const { limit, setOffset, accountId, setStatus} = props;
      if(activeTab !== tab){
        const filter = {}
        if(accountId) {
          filter.accountId = accountId;
        }
        
        switch(tab) {
          case '1':
            props.getAll({limit, offset: 0, filter});
            break;
          case '2':
            filter.status = 'inProgress';
            props.getAll({limit, offset: 0, filter});
            break;
          case '3':
            filter.status = 'paymentMade';
            props.getAll({limit, offset: 0, filter});
            break;
          case '4':
            filter.status = 'insuranceRequested';
            props.getAll({limit, offset: 0, filter});
            break;
          case '5':
            filter.status = 'certificateIssued';
            props.getAll({limit, offset: 0, filter});
            break;
          case '6':
            filter.isArchived = true;
            props.getAll({limit, offset: 0, filter});
            break;
          default: 
            props.getAll({limit, offset: 0, filter});
        }
        setStatus(filter.status);
        setOffset(0);
        setActiveTab(tab);
      };
    }
    return (
      <div>
        <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            All <Badge color="danger" pill className="ml-1">{props.stats.all}</Badge>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            In Progress <Badge color="danger" pill className="ml-1">{props.stats.inProgress}</Badge>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
            Payment Made <Badge color="danger" pill className="ml-1">{props.stats.paymentMade}</Badge>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => { toggle('4'); }}
          >
            Insurance Requested <Badge color="danger" pill className="ml-1">{props.stats.insuranceRequested}</Badge>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '5' })}
            onClick={() => { toggle('5'); }}
          >
            Certificate Issued <Badge color="danger" pill className="ml-1">{props.stats.certificateIssued}</Badge>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '6' })}
            onClick={() => { toggle('6'); }}
          >
            Archived <Badge color="danger" pill className="ml-1">{props.stats.archived}</Badge>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        {props.children}
      </TabContent>
      </div>
    );
}

export default ApplicationTabs;