import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions'
import EditHealthCoverType from './EditHealthCoverType';

class EditHealthCoverTypeContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {}
      }
    
      componentDidMount() {
        const {  id } = this.props.match.params;
        const { actions: {getCurrent}} = this.props;
        if(id) {
          getCurrent({id});
        }
      }

      
      render() {
        const { currentHealthCoverType, healthCoverTypeUpdated, actions: { updateCurrentHealthCoverType } } = this.props;
        
        return (
          <EditHealthCoverType
            {...this.props}
            currentHealthCoverType={currentHealthCoverType}
            updateCurrentHealthCoverType={updateCurrentHealthCoverType}
            healthCoverTypeUpdated={healthCoverTypeUpdated}
          />
        )
      }
}

const mapStateToProps = state => {
    return state.Admin.HealthCoverType
  }
  
  const mapDispatchToProps = dispatch => {
      return {
        actions: bindActionCreators(actions, dispatch)
      }
    }
    
  
  const ConnectedEditHealthCoverType = connect(mapStateToProps, mapDispatchToProps)(EditHealthCoverTypeContainer);
  
  export { ConnectedEditHealthCoverType as EditHealthCoverType };