import * as c from './constants';
import * as reviewService from './services';


const submitReviewStart = () => ({
  type: c.SUBMIT_REVIEW_START
})

const submitReviewSuccess = () => ({
  type: c.SUBMIT_REVIEW_SUCCESS,
})

const submitReviewFail = ({error}) => ({
  type: c.SUBMIT_REVIEW_FAIL,
  error,
})

export const submitReview = ({review}) => async (dispatch) => {
  dispatch(submitReviewStart())
  try {
      await reviewService.add({review});
      dispatch(submitReviewSuccess());
  } catch (error) {
    dispatch(submitReviewFail({error}))
  }
}

const getAllStart = () => ({
    type: c.GET_REVIEWS_START
  })
  
  const getAllSuccess = ({data}) => ({
    type: c.GET_REVIEWS_SUCCESS,
    data,
  })
  
  const getAllFail = ({error}) => ({
    type: c.GET_REVIEWS_FAIL,
    error,
  })
  
  export const getAll = () => async (dispatch) => {
    dispatch(getAllStart())
    try {
        const {data} = await reviewService.getAll();
        dispatch(getAllSuccess({data: data.data}));
    } catch (error) {
      dispatch(getAllFail({error}))
    }
  }
  