import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Badge,
  TabPane,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import Avatar from 'react-avatar';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { Cookies } from 'react-cookie';

import config from '../../../../../config';
import Header from '../../../Headers/SlimHeader';
import ApplicationTabs from './ApplicationTabs';
import ApplicationActivity from './ApplicationActivity';
import { ApplicationComment } from './ApplicationComment';
import { ApplicationForm } from './ApplicationForm';
import ApplicationFormOSHC from './ApplicationFormOSHC';

const ApplicationDetails = (props) => {
  const {
    currentItem,
    history,
    authUser,
    getCurrent,
    deletingCertificate,
    activities,
    deleteCertificate,
  } = props;

  const removeCertificate = (event) => {
    event.preventDefault();

    deleteCertificate({ id: currentItem.id });
  };
  const cookies = new Cookies();

  return (
    <>
      <Header />
      <Container fluid>
        <Row className="align-items-center" style={{ marginTop: -34 }}>
          <Col xs="12">
            <ApplicationTabs>
              <TabPane tabId="1">
                <Row>
                  <Col className="order-xl-1" lg="12" xl="9">
                    <Card className="bg-white shadow">
                      <CardHeader className="bg-white border-0">
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">
                              Application details &nbsp;
                              {currentItem.status === 'inProgress' && (
                                <Badge color="info">In progress</Badge>
                              )}
                              {currentItem.status === 'paymentMade' && (
                                <Badge color="danger">Payment made</Badge>
                              )}
                              {currentItem.status === 'certificateIssued' && (
                                <Badge color="success">
                                  Certificate issued
                                </Badge>
                              )}
                            </h3>{' '}
                            <br />
                            {currentItem.Account
                              ? `Account: ${currentItem.Account.name}`
                              : ''}
                          </Col>

                          <Col className="text-right" xs="4">
                            <Button
                              color="grey"
                              onClick={history.goBack}
                              size="sm"
                            >
                              Back
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col lg="8">
                            <ApplicationFormOSHC application={currentItem} />
                          </Col>
                          <Col lg="4">
                            <h6 className="heading-small text-muted mb-4">
                              Cover details
                            </h6>
                            <div className="application-sidebar">
                              <div className="sidebar-provider-logo">
                                <img
                                  src={
                                    currentItem &&
                                    currentItem.HealthCover.Provider.logo
                                  }
                                  alt={
                                    currentItem && currentItem.HealthCover.name
                                  }
                                />
                              </div>
                              <h2>
                                <strong>${currentItem.premium}</strong>
                              </h2>
                              <h3>
                                {currentItem && currentItem.HealthCover.name}
                              </h3>
                              <div>
                                <p>
                                  Type: <span>{currentItem.clientType}</span>{' '}
                                  <br />
                                  Childrens: <span>
                                    {currentItem.children}
                                  </span>{' '}
                                  <br />
                                  Cover start date:
                                  <span>
                                    {moment(
                                      currentItem.startDate,
                                      'YYYY-MM-DD',
                                    ).format('DD/MM/YYYY')}
                                  </span>{' '}
                                  <br />
                                  Cover end date:{' '}
                                  <span>
                                    {moment(
                                      currentItem.endDate,
                                      'YYYY-MM-DD',
                                    ).format('DD/MM/YYYY')}
                                  </span>
                                </p>
                              </div>
                            </div>

                            {currentItem.status !== 'inProgress' &&
                              currentItem.ApplicationPayment && (
                                <>
                                  <h5>Payment details</h5>
                                  <div className="application-sidebar">
                                    {currentItem.ApplicationPayment
                                      .paymentType === 'Poli' && (
                                      <div className="sidebar-provider-logo">
                                        <img
                                          src="https://resources.apac.paywithpoli.com/poli-logo-43.png"
                                          alt="Poli"
                                        />
                                      </div>
                                    )}

                                    {currentItem.ApplicationPayment
                                      .paymentType === 'stripe' && (
                                      <div className="sidebar-provider-logo">
                                        <a
                                          href="https://stripe.com"
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          <img
                                            alt="Powered by stripe"
                                            src="https://insurance-compare.s3-ap-southeast-2.amazonaws.com/powered_by_stripe.png"
                                          />
                                        </a>
                                      </div>
                                    )}

                                    {currentItem.ApplicationPayment
                                      .paymentType === 'bankTransfer' && (
                                      <h3>Bank Transfer</h3>
                                    )}

                                    <div>
                                      <div>
                                        {currentItem.ApplicationPayment
                                          .paymentType === 'bankTransfer' ? (
                                          <div className="sidebar-provider-logo">
                                            <a
                                              href={
                                                currentItem.ApplicationPayment
                                                  .transactionId
                                              }
                                              rel="noopener noreferrer"
                                              target="_blank"
                                            >
                                              Download payment receipt
                                            </a>
                                          </div>
                                        ) : (
                                          <>
                                            Transaction ID:{' '}
                                            <span>
                                              {
                                                currentItem.ApplicationPayment
                                                  .transactionId
                                              }
                                            </span>{' '}
                                            <br />
                                          </>
                                        )}
                                        Description:{' '}
                                        <span>
                                          {
                                            currentItem.ApplicationPayment
                                              .description
                                          }
                                        </span>{' '}
                                        <br />
                                        Date:{' '}
                                        <span>
                                          {moment(
                                            currentItem.ApplicationPayment
                                              .createdAt,
                                          )
                                            .tz(authUser.timezone)
                                            .format('LLL')}
                                        </span>{' '}
                                        <br />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                            <div>
                              {currentItem.User && (
                                <>
                                  <h5>Assignee</h5>
                                  <div className="application-sidebar">
                                    <div style={{ display: 'flex' }}>
                                      <Avatar
                                        name={`${currentItem.User.firstName} ${currentItem.User.firstName}`}
                                        size="50"
                                        round={true}
                                      />
                                      <div className="ml-2">
                                        <strong>
                                          {currentItem.User.firstName}{' '}
                                          {currentItem.User.firstName}{' '}
                                        </strong>
                                        <br />
                                        <small>{currentItem.User.email}</small>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {currentItem.StartedBy && (
                                <>
                                  <h5>Added By</h5>
                                  <div className="application-sidebar">
                                    <div style={{ display: 'flex' }}>
                                      <Avatar
                                        name={`${currentItem.StartedBy.firstName} ${currentItem.StartedBy.firstName}`}
                                        size="50"
                                        round={true}
                                      />
                                      <div className="ml-2">
                                        <strong>
                                          {currentItem.StartedBy.firstName}{' '}
                                          {currentItem.StartedBy.firstName}{' '}
                                        </strong>
                                        <br />
                                        <small>{currentItem.StartedBy.email}</small>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {!currentItem.certificate &&
                                currentItem.status === 'insuranceRequested' &&
                                currentItem.User &&
                                currentItem.User.id === authUser.id && (
                                  <>
                                    <h5>
                                      Upload certificate{' '}
                                      <span className="text-danger">*</span>
                                    </h5>
                                    <FilePond
                                      allowMultiple={false}
                                      server={{
                                        url: `${config.serverUrl}/api/applications/${currentItem.id}/upload`,
                                        process: {
                                          headers: {
                                            'x-access-token':
                                              cookies.get('token'),
                                          },
                                        },
                                      }}
                                      accept="image/png, image/jpeg, image/gif, application/pdf"
                                      onprocessfile={() =>
                                        getCurrent({ id: currentItem.id })
                                      }
                                    />

                                    <ApplicationForm />
                                  </>
                                )}

                              {currentItem.certificate &&
                                currentItem.status === 'certificateIssued' && (
                                  <>
                                    <h5>
                                      Certificate{' '}
                                      <span className="text-danger">*</span>
                                    </h5>
                                    <Card
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                      }}
                                      className="p-sm"
                                    >
                                      <a
                                        href={currentItem.certificate}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <i
                                          className="fa fa-download"
                                          style={{ marginRight: 10 }}
                                        />{' '}
                                        Download certificate
                                      </a>
                                    </Card>
                                    {currentItem.User &&
                                      currentItem.User.id === authUser.id && (
                                        <div className="text-right">
                                          {deletingCertificate ? (
                                            <p>...</p>
                                          ) : (
                                            <Button
                                              onClick={removeCertificate}
                                              className="mt-4"
                                              size="sm"
                                            >
                                              Remove certificate
                                            </Button>
                                          )}
                                        </div>
                                      )}
                                    <ApplicationForm />
                                  </>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <ApplicationActivity
                  history={history}
                  activities={activities}
                />
              </TabPane>
              <TabPane tabId="3">
                <ApplicationComment {...props} />
              </TabPane>
            </ApplicationTabs>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(ApplicationDetails);
