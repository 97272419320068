import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {  Button,
    FormGroup,
    Form,
    InputGroup,
    Modal,
    Row,
    Col,
    Label,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";
import useForm from 'react-hook-form';
import * as yup from 'yup';
import * as Datetime from 'react-datetime';

const formSchema = {
    startDate: yup.string().required('Start date field is required.'),
    endDate: yup.string().required('End date field is required.'),
};


const ExportBill = ({exportBill, exporting, showModal}) => {
    const defaultFormData = {accountId: null, startDate: null, endDate: null};
    const [formData, setFormData] = useState(defaultFormData);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const { register, handleSubmit, setValue, errors } = useForm({
        validationSchema: yup.object().shape(formSchema),
        defaultValues: defaultFormData,
    });

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    }
  
    const onSubmit = data => {
      exportBill(
        {
          data: {
            startDate: moment(data.startDate).format('YYYY-MM-DD'),
            endDate: moment(data.endDate).format('YYYY-MM-DD')
          }
        }
      );
    }
  
    const validStartDate = (current) => {
        const today = Datetime.moment();
        return current.isBefore(today);
    };

    const validEndDate = (current) => {
        return current.isAfter(Datetime.moment(formData.startDate).subtract(1, 'day')) && current.isBefore(Datetime.moment());
    };

    const handleDateChange = (selectedDate, name) => {
        setFormData({ ...formData, [name]: selectedDate });
        setValue([name], selectedDate);
      };

      useEffect(() => {
        register({ name: 'startDate' });
        register({ name: 'endDate' });
      }, [register]);
  
    useEffect(() => {
      setIsModalOpen(showModal);
    }, [showModal]);

    return(
        <>
            
                <Button onClick={toggleModal} className="btn-icon btn-3" color="primary" outline type="button">
                    <span className="btn-inner--icon">
                        <i className="ni ni-curved-next" />
                    </span>
                    <span className="btn-inner--text">Export  Bill</span>
                </Button>
           

            <Modal
            className="modal-dialog-centered"
            isOpen={isModalOpen}
            
          >
            <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header">
              <h2 className="modal-title" id="exampleModalLabel">
                Export Bill
              </h2>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={()=>setIsModalOpen(!isModalOpen)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">

              <Row>
                <Col lg="6">
                    <FormGroup className="form-item">
                        <Label>PO Start date<span className="form-error">*</span></Label>
                        <InputGroup className="input-group-alternative">
                            <Datetime
                                inputProps={{
                                placeholder: 'Pick start date',
                                }}
                                className="date-time-input"
                                timeFormat={false}
                                isValidDate={validStartDate}
                                closeOnSelect
                                name="startDate"
                                
                                dateFormat="YYYY-MM-DD"
                                onChange={(selectedDate) => handleDateChange(selectedDate, 'startDate')}
                            />

                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                                </InputGroupText>
                            </InputGroupAddon>
                            </InputGroup>
                            {errors.startDate && (<small className="form-error">This field is required</small>)}
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup className="form-item">
                        <Label>PO End date<span className="form-error">*</span></Label>
                        <InputGroup className="input-group-alternative">
                        <Datetime
                            inputProps={{
                            placeholder: 'Pick end date',
                            }}
                            className="date-time-input"
                            timeFormat={false}
                            isValidDate={validEndDate}
                            closeOnSelect
                            name="endDate"
                            dateFormat="YYYY-MM-DD"
                            onChange={(selectedDate) => handleDateChange(selectedDate, 'endDate')}
                        />
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                        </InputGroupAddon>
                        </InputGroup>
                        {errors.endDate && (<small className="form-error">This field is required</small>)}
                    </FormGroup>
                </Col>
              </Row>

             

            </div>
            <div className="modal-footer" style={{borderTop:'1px solid #e9ecef'}}>
              <Button
                color="link"
                data-dismiss="modal"
                type="button"
               
                onClick={toggleModal}
              >
                Close
              </Button>
              <Button color='primary'  disabled={exporting}  type="submit">
                Export
              </Button>
            </div>
            </Form>
          </Modal>
        </>
    );
}

export default ExportBill;