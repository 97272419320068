export const GET_HEALTH_COVER_TYPE_START = 'GET_HEALTH_COVER_TYPE_START';
export const GET_HEALTH_COVER_TYPE_SUCCESS = 'GET_HEALTH_COVER_TYPE_SUCCESS';
export const GET_HEALTH_COVER_TYPE_FAIL = 'GET_HEALTH_COVER_TYPE_FAIL';

export const  GET_CURRENT_HEALTH_COVER_TYPE_START = 'GET_CURRENT_HEALTH_COVER_TYPE_START';
export const GET_CURRENT_HEALTH_COVER_TYPE_SUCCESS = 'GET_CURRENT_HEALTH_COVER_TYPE_SUCCESS';
export const GET_CURRENT_HEALTH_COVER_TYPE_FAIL = 'GET_CURRENT_HEALTH_COVER_TYPE_FAIL';

export const UPDATE_HEALTH_COVER_TYPE_START = 'UPDATE_HEALTH_COVER_TYPE_START';
export const UPDATE_HEALTH_COVER_TYPE_SUCCESS = 'UPDATE_HEALTH_COVER_TYPE_SUCCESS';
export const UPDATE_HEALTH_COVER_TYPE_FAIL = 'UPDATE_HEALTH_COVER_TYPE_FAIL';