import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../AuthUser/actions';

import MyProfile from './MyProfile';

class MyProfileContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {}
      }
      
      componentDidMount() {
        const { actions: { getAuthUser } } = this.props;
        getAuthUser();
      }

      
      render() {
        const { authUser, barcode, actions: { submit, enableMFA, generateCode, removeMFA }} = this.props;
        if(!authUser) return null;
        return (
          <MyProfile
            {...this.props}
            currentItem={authUser}
            submit={submit}
            enableMFA={enableMFA}
            barcode={barcode}
            generateCode={generateCode}
            removeMFA={removeMFA}
          />
        )
      }
}

const mapStateToProps = state => {
    return ({
      ...state.Admin.AuthUser,
    })
  }
  
  const mapDispatchToProps = dispatch => {
      return {
        actions: bindActionCreators(actions, dispatch)
      }
    }
    
  
  const ConnectedMyProfile = connect(mapStateToProps, mapDispatchToProps)(MyProfileContainer);
  
  export { ConnectedMyProfile as MyProfile };