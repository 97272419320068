import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cogoToast from 'cogo-toast';

import * as actions from '../../actions'
import ApplicationFormOSHC from './ApplicationFormOSHC';
import * as accountActions from '../../../Account/actions';

class ApplicationFormContainerOSHC extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { actions: { getAccounts } } = this.props;
    getAccounts({ limit: 1000, offset: 0, filter: {} });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
      cogoToast.success('Application updated successfully!')
        .then(() => {
          window.location.href = '/admin/applications/';
        });
    }
  }


  render() {
    const { application, authUser, accounts,  submittingForm,  actions: {submit} } = this.props;
    
    return (
      <ApplicationFormOSHC
        {...this.props}
        application={application}
        submit={submit}
        submittingForm={submittingForm}
        authUser={authUser}
        accounts={accounts}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.Admin.Application, 
    authUser: state.Admin.AuthUser.authUser,
    accounts: state.Admin.Account.data,
}}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators({...actions, getAccounts: accountActions.getAll}, dispatch)
    }
  }
  

const ConnectedApplicationFormOSHC = connect(mapStateToProps, mapDispatchToProps)(ApplicationFormContainerOSHC);

export { ConnectedApplicationFormOSHC as ApplicationFormOSHC };

