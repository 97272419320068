import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions'
import MyCoverForm from './MyCoverForm';

class MyCoverContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { myCoverResponse: null}
  }

  componentDidUpdate(prevProps) {
    if(prevProps.submitting && !this.props.submitting && this.props.myCoverResponse) {
      this.setState({myCoverResponse: this.props.myCoverResponse});
    }
  }

  render() {
    const { submitting, message, actions: { submitForm } } = this.props;
    const { myCoverResponse } = this.state;
    
    return (
      <MyCoverForm
        {...this.props}
        message={message}
        submitForm={submitForm}
        myCoverResponse={myCoverResponse}
        submitting={submitting}
      />
    )
  }
}

const mapStateToProps = state => {
  return state.Front.MyCover
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedMyCover = connect(mapStateToProps, mapDispatchToProps)(MyCoverContainer);

export { ConnectedMyCover as MyCoverForm };

