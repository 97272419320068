import client from '../../../client';

function logIn(data) {
    return client.post(`auth/login`, data);
}

function verifyMFA(data) {
    return client.post(`auth/verify-mfa`, data);
}

export { logIn, verifyMFA };