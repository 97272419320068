import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions'
import ProviderForm from './ProviderForm';

class ProviderFormContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {}
      }
    
      loadCurrent({id}) {
        const { actions: {selectCurrentProvider} } = this.props;
        selectCurrentProvider({id});
      }

      clearCurrent() {
        const { actions: {clearCurrentProvider} } = this.props;
        clearCurrentProvider();
      }

      componentDidMount() {
        const {  id } = this.props.match.params;
        if(id) {
          const { actions: {getCurrent} } = this.props;
          getCurrent({id});
        }
        else {
          this.clearCurrent()
        }
      }

      componentDidUpdate(prevProps) {
        if(prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
          const {history}  = this.props;
          history.push('/admin/providers');
        }
      }

      
      render() {
        const { currentProvider, submittingForm, actions: { submitProviderForm } } = this.props;
        
        return (
          <ProviderForm
            {...this.props}
            currentProvider={currentProvider || {}}
            submitProviderForm={submitProviderForm}
            submittingForm={submittingForm}
          />
        )
      }
}

const mapStateToProps = state => {
    return state.Admin.Provider
  }
  
  const mapDispatchToProps = dispatch => {
      return {
        actions: bindActionCreators(actions, dispatch)
      }
    }
    
  
  const ConnectedProviderForm = connect(mapStateToProps, mapDispatchToProps)(ProviderFormContainer);
  
  export { ConnectedProviderForm as ProviderForm };