import * as c from './constants';
import * as messageService from './services';


const submitMessageStart = () => ({
  type: c.SUBMIT_MESSAGE_START
})

const submitMessageSuccess = () => ({
  type: c.SUBMIT_MESSAGE_SUCCESS,
})

const submitMessageFail = ({error}) => ({
  type: c.SUBMIT_MESSAGE_FAIL,
  error,
})

export const submitMessage = ({message}) => async (dispatch) => {
  dispatch(submitMessageStart())
  try {
      await messageService.add({message});
      dispatch(submitMessageSuccess());
  } catch (error) {
    dispatch(submitMessageFail({error}))
  }
}