import React, { useState } from 'react';
import { 
    Modal,
    Row,
    Col,
    FormGroup,
    Button,
} from "reactstrap";
import { FilePond  } from 'react-filepond';

import config from '../../../../config';

const ProofOfPayment = ({application, setModal, makePayment}) => {
    const [proofOfPayment, setProofOfPayment] = useState('');

    const assignImage = (file) => {
      setProofOfPayment(file);
    }

    const submit = () => {
        makePayment({applicationId: application.id, type: 'bankTransfer', paymentToken: proofOfPayment});
    }

    return (
         <Modal
            className="modal-dialog-centered"
            isOpen={true} 
          >
           <div className="modal-header">
              <h2 className="modal-title">
                Upload proof of payment
              </h2>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={()=>setModal()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
                <FormGroup>
                    <label
                        className="form-control-label"
                    >
                        Proof of payment
                    </label>
                    <FilePond
                        allowMultiple={false}
                        server= {`${config.serverUrl}/api/images`}
                        accept="image/png, image/jpeg, image/gif"
                        onprocessfile={(error, file) => assignImage(file.serverId)}
                        />
                </FormGroup>

                <Row>
                      <Col lg="12">
                        <hr className="my-4" />
                        <div className="text-right">
                          <Button
                            color="grey"
                            type="button"
                            onClick={()=>setModal()}
                          >
                                    Cancel
                          </Button>
                          <Button
                            color="primary"
                            type="submit"
                            disabled={!proofOfPayment}
                            onClick={submit}
                          >
                            Submit
                          </Button>

                        </div>
                      </Col>
                    </Row>

            </div>
        </Modal>
    );
}

export default ProofOfPayment;