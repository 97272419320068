import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import cogoToast from 'cogo-toast';

import * as actions from '../actions';
import {getAllHealthCovers} from '../../HealthCover/actions';
import HealthCoverUrl from './HealthCoverUrl';

class HealthCoverUrlContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { actions: { getCurrent, getAllHealthCovers } } = this.props;

    if (id) {
      getCurrent({ id });
    }
    getAllHealthCovers({offset: 0, limit: 100, filter: {}});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
      cogoToast.success('Health cover urls updated successfully!');
      const { history } = this.props;
      history.push('/admin/accounts');
    }
  }

  render() {
    const { currentItem, loadingAll, healthCovers, loadingHealthCovers, loggedInUser,  actions: { submit } } = this.props;
    
    if(loadingAll || loadingHealthCovers) return null;

    return (
      <HealthCoverUrl
        {...this.props}
        currentItem={{...currentItem, healthCoverUrls: currentItem.healthCoverUrls ? JSON.parse(currentItem.healthCoverUrls) : [{healthCoverId: '', id:uuidv4(), agentUrl: ''}]}}
        submit={submit}
        healthCovers={healthCovers}
        loggedInUser={loggedInUser}
      />
    );
  }
}

const mapStateToProps = (state) => { 
  return {
    ...state.Admin.Account,
    loadingHealthCovers: state.Admin.HealthCover.loadingHealthCovers,
    healthCovers: state.Admin.HealthCover.data,
    loggedInUser: state.Admin.AuthUser.authUser
  }
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({...actions, getAllHealthCovers}, dispatch),
});


const HealthCoverUrlForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(HealthCoverUrlContainer));

export { HealthCoverUrlForm as HealthCoverUrl };
