import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions'
import ResetPassword from './ResetPassword';

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { successMessage: null, errorMessage: null}
  }

  componentDidUpdate(prevProps) {
    if(prevProps.submitting && !this.props.submitting && !this.props.error) {
      this.setState({
        errorMessage: null,
        successMessage: 'Password updated successfully! Please login using your new password.'
      });
    }
    else if(prevProps.submitting && !this.props.submit && this.props.error) {
      this.setState({
        successMessage: null,
        errorMessage: 'Invalid token. Please request forgot password again.'
      });
    }
  }

  render() {
    const { actions: {submit}, error } = this.props;
    const { successMessage, errorMessage } = this.state;
    console.log({ successMessage, errorMessage });
    return (
      <ResetPassword
        {...this.props}
        submit={submit}
        error={error}
        successMessage={successMessage}
        errorMessage={errorMessage}
      />
    )
  }
}

const mapStateToProps = state => {
  return state.Auth.ResetPassword
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}


const ConnectedResetPassword = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer)

export { ConnectedResetPassword as ResetPassword }

