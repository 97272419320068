import * as c from './constants';

const initialState = {
  submitting: false,
  error: null,
  myCoverResponse: null
}


const actionHandlers = { 
  [c.SUBMIT_MY_COVER_START]: (state) => {
    return {
      ...state,
      submitting: true,
      myCoverResponse: null,
      error: null,
    }
  },
  [c.SUBMIT_MY_COVER_SUCCESS]: (state, action) => {
    return {
      ...state,
      error: null,
      myCoverResponse: action.data,
      submitting: false,
    }
  },
  [c.SUBMIT_MY_COVER_FAIL]: (state, action) => {
    return {
      ...state,
      submitting: false,
      myCoverResponse: null,
      error: action.error
    }
  },
}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
