import * as c from './constants';
import * as quoteService from './services';



const submitStart = () => ({
    type: c.SUBMIT_QUOTE_START,
  });
  const submitSuccess = () => ({
    type: c.SUBMIT_QUOTE_SUCCESS,
  });
  const submitFail = ({ error }) => ({
    type: c.SUBMIT_QUOTE_FAIL,
    error,
  });
  export const submit = ({ data }) => async (dispatch) => {
    dispatch(submitStart());
    try {
      await quoteService.emailQuote({ data });
  
      dispatch(submitSuccess());
    } catch (error) {
      dispatch(submitFail({ error }));
    }
  };
  