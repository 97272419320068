import * as c from './constants';
import * as AccountService from './services';

const getCurrentStart = () => ({
    type: c.GET_CURRENT_ACCOOUNT_START
  })
  
  const getCurrentSuccess = ({currentItem}) => ({
    type: c.GET_CURRENT_ACCOUNT_SUCCESS,
    currentItem,
  })
  
  const getCurrentFail = ({error}) => ({
    type: c.GET_CURRENT_ACCOUNT_FAIL,
    error,
  })
  
  export const getCurrent = ({slug}) => async (dispatch) => {
    dispatch(getCurrentStart());
    try {
        const { data } = await AccountService.getOneBySlug({slug});
        
        dispatch(getCurrentSuccess({currentItem: data.data}));
    } catch (error) {
      dispatch(getCurrentFail({error}))
    }
  }