import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import * as actions from './actions'
import PurchaseOrder from './PurchaseOrder';

class PurchaseOrderContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.actions.getAll({limit: 10, offset: 0}); 
  }

  render() {
    const { data, total, authUser,  actions: { deleteItem, getAll, sendEmail } } = this.props;
    return (
      <PurchaseOrder
        {...this.props}
        data={data}
        deleteItem={deleteItem}
        getAll={getAll}
        total={total}
        authUser={authUser}
        sendEmail={sendEmail}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.Admin.PurchaseOrder,
    authUser: state.Admin.AuthUser.authUser
  }
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
}

const ConnectedPurchaseOrder = connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderContainer);

export { ConnectedPurchaseOrder as PurchaseOrder };

