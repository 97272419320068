import React, { useEffect } from 'react';
import * as yup from 'yup';
import {
  Button,
  FormGroup,
  Form,
  Input,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';

const formSchema = yup.object().shape({
  policyNo: yup.string().required('Policy number is required'),
});


const ApplicationForm = (props) => {
  const {
    currentItem, submittingForm
  } = props;
  

  const {
    register, handleSubmit, errors, setValue, reset
  } = useForm({
    validationSchema: formSchema,
    defaultValues: currentItem,
  });


  useEffect(() => {
    reset(currentItem);
  }, [currentItem, reset]);

  const onSubmit = async (updatedItem) => {
    const { submit } = props;
    submit({ data: { id: currentItem.id, policyNo: updatedItem.policyNo }});
  };

  const handleInputChange = (event) => {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    setValue([name], value, true);
  };

  return (
    <Form role="form" onSubmit={handleSubmit(onSubmit)}>
      <div >

      <FormGroup>
          <label
            className="form-control-label"
          >
                Policy no <span  className="text-danger">*</span>
          </label>

          <Input
            className="form-control-alternative"
            innerRef={register}
            type="text"
            name="policyNo"
            onChange={handleInputChange}
          />
          {errors.policyNo && <span className="formError">{errors.policyNo.message}</span>}
        </FormGroup>
        

        <div className="text-right" style={{marginTop: 15}}>
              <Button
                color="primary"
                type="submit"
                disabled={!!submittingForm}
              >
                UPDATE
              </Button>

        </div>

      </div>

    </Form>
  );
};

export default withRouter(ApplicationForm);
