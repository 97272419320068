export const GET_LEADS_START = 'GET_LEADS_START';
export const GET_LEADS_SUCCESS = 'GET_LEADS_SUCCESS';
export const GET_LEADS_FAIL = 'GET_LEADS_FAIL';

export const DELETE_LEAD_START = 'DELETE_LEAD_START';
export const DELETE_LEAD_SUCCESS = 'DELETE_LEAD_SUCCESS';
export const DELETE_LEAD_FAIL = 'DELETE_LEAD_FAIL';

export const GET_CURRENT_LEAD_START = 'GET_CURRENT_LEAD_START';
export const GET_CURRENT_LEAD_SUCCESS = 'GET_CURRENT_LEAD_SUCCESS';
export const GET_CURRENT_LEAD_FAIL = 'GET_CURRENT_LEAD_FAIL';

export const SUBMIT_LEAD_START = 'SUBMIT_LEAD_START';
export const SUBMIT_LEAD_SUCCESS = 'SUBMIT_LEAD_SUCCESS';
export const SUBMIT_LEAD_FAIL = 'SUBMIT_LEAD_FAIL';


export const DELETE_LEAD_CERTIFICATE_START = 'DELETE_LEAD_CERTIFICATE_START';
export const DELETE_LEAD_CERTIFICATE_SUCCESS = 'DELETE_LEAD_CERTIFICATE_SUCCESS';
export const DELETE_LEAD_CERTIFICATE_FAIL = 'DELETE_LEAD_CERTIFICATE_FAIL';