import * as c from './constants';
import * as reportService from './services';


const getReportStart = () => ({
  type: c.GET_REPORT_START
})

const getReportSuccess = ({report}) => ({
  type: c.GET_REPORT_SUCCESS,
  report,
})

const getReportFail = ({error}) => ({
  type: c.GET_REPORT_FAIL,
  error,
})

export const getReport = () => async (dispatch) => {
  dispatch(getReportStart())
  try {
      const {data} = await reportService.getReport();
      
      dispatch(getReportSuccess({report: data.data}));
  } catch (error) {
    dispatch(getReportFail({error}))
  }
}
