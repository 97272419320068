import * as c from './constants';

const initialState = {
  loadingAll: false,
  error: null,
  data: [],
  deletingItem: false,
  sendingEmail: false,
};


const actionHandlers = {
  [c.GET_PURCHASE_ORDERS_START]: (state) => {
    return {
      ...state,
      loadingAll: true,
    }
  },
  [c.GET_PURCHASE_ORDERS_SUCCESS]: (state, action) => {
    return {
      ...state,
      loadingAll: false,
      data: action.data,
      total: action.total
    }
  },
  [c.GET_PURCHASE_ORDERS_FAIL]: (state, action) => {
    return {
      ...state,
      loadingAll: false,
      error: action.error
    }
  },


  [c.SUBMIT_PURCHASE_ORDER_START]: (state) => ({
    ...state,
    submittingForm: true,
  }),
  [c.SUBMIT_PURCHASE_ORDER_SUCCESS]: (state, action) => {
    return {
      ...state,
      submittingForm: false,
      data: [...action.data, ...state.data],
    };
  },
  [c.SUBMIT_PURCHASE_ORDER_FAIL]: (state, action) => ({
    ...state,
    submittingForm: false,
    error: action.error,
  }),


  [c.DELETE_PURCHASE_ORDER_START]: (state) => {
    return {
      ...state,
      deletingItem: true
    }
  },
  [c.DELETE_PURCHASE_ORDER_SUCCESS]: (state, action) => {
    return {
      ...state,
      deletingItem: false,
      data: state.data.filter(item => item.id !== action.id)
    }
  },
  [c.DELETE_PURCHASE_ORDER_FAIL]: (state) => {
    return {
      ...state,
      deletingItem: false
    }
  },


  [c.EXPORT_BILL_START]: (state) => {
    return {
      ...state,
      exporting: true
    }
  },
  [c.EXPORT_BILL_SUCCESS]: (state, action) => {
    return {
      ...state,
      exporting: false,
    }
  },
  [c.EXPORT_BILL_FAIL]: (state) => {
    return {
      ...state,
      exporting: false
    }
  },


  [c.SEND_EMAIL_START]: (state) => {
    return {
      ...state,
      sendingEmail: true
    }
  },
  [c.SEND_EMAIL_SUCCESS]: (state, action) => {
    return {
      ...state,
      sendingEmail: false,
      data: state.data.map((item) => {
        if (item.id === action.data.id) {
          return action.data;
        }
        return item;
      })
    }
  },
  [c.SEND_EMAIL_FAIL]: (state) => {
    return {
      ...state,
      sendingEmail: false
    }
  },
}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
