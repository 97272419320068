import React from 'react';
import { getNames } from 'country-list';
import Select from 'react-select';


const CountriesDropdown = (props) => {
  const data = getNames();

  const countries = data.map((item) => ({
    value: item, label: item,
  }));

  const selectedCountry = countries.find((country) => country.value === props.defaultSelectedCountry);

  const customStyles = {
    container: () => ({
      flexGrow: 1,
      padding: 5,
      marginLeft: -45,
    }),
    control: () => ({
      border: 0,
      paddingLeft: 32,
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
    placeholder: () => ({
      fontSize: 16,
      color: '#adb5bd',
    }),
    singleValue: () => ({
      fontSize: 16,
      color: '#000000',
    }),
  };

  return (
    <Select defaultValue={selectedCountry} onChange={props.onChange} ref={props.innerRef} name={props.name} placeholder={props.placeholder} options={countries} styles={customStyles} />
  );
};

export default CountriesDropdown;
