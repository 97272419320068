import * as c from './constants';

const initialState = {
    submittingForm: false,
  error: null,
};

const actionHandlers = {
  [c.EXPORT_APPLICATION_START]: (state) => ({
    ...state,
    submittingForm: true,
    error: null,
  }),
  [c.EXPORT_APPLICATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      submittingForm: false,
    };
  },
  [c.EXPORT_APPLICATION_FAIL]: (state, action) => ({
    ...state,
    submittingForm: false,
    error: action.error,
  }),

};

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
};
