import * as c from './constants';

const initialState = {
  loadingAll: false,
  error: null,
  data: [],
  currentItem: { firstName: '', lastName: '', email: '', password: '', accountId: '', role:'staff' },
  submittingForm: false,
  deletingItem: false,
};


const actionHandlers = {
  [c.GET_USERS_START]: (state) => {
    return {
      ...state,
      loadingAll: true,
      error: null
    }
  },
  [c.GET_USERS_SUCCESS]: (state, action) => {
    
    return {
      ...state,
      loadingAll: false,
      data: action.data,
      total: action.total,
      error: null
    }
  },
  [c.GET_USERS_FAIL]: (state, action) => {
    return {
      ...state,
      loadingAll: false,
      error: action.error
    }
  },

  [c.GET_CURRENT_USER_START]: (state) => {
    return {
      ...state,
      loadingAll: true,
      error: null
    }
  },
  [c.GET_CURRENT_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      loadingAll: false,
      currentItem: action.currentItem,
      error: null
    }
  },
  [c.GET_CURRENT_USER_FAIL]: (state, action) => {
    return {
      ...state,
      loadingAll: false,
      error: action.error
    }
  },

  [c.SUBMIT_USER_START]: (state) => {
    return {
      ...state,
      submittingForm: true,
      error: null
    }
  },
  [c.SUBMIT_USER_SUCCESS]: (state, action) => {
    const alreadyExists = state.data.find(item => item.id === action.data.id);
    let udpatedData;
    if(!alreadyExists) {
      udpatedData = [action.data, ...state.data];
    }
    else {
      udpatedData = state.data.map(item => {
        if(item.id === action.data.id) {
          return action.data;
        }
        else {
          return item;
        }
      });
    }
    
    return {
      ...state,
      submittingForm: false,
      data: udpatedData,
      currentItem: initialState.currentItem,
      error: null
    }
  },
  [c.SUBMIT_USER_FAIL]: (state, action) => {
    return {
      ...state,
      submittingForm: false,
      error: action.error
    }
  },

  [c.DELETE_USER_START]: (state) => {
    return {
      ...state,
      deletingItem: true
    }
  },
  [c.DELETE_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      deletingItem: false,
      data: state.data.filter(item => item.id !== action.id)
    }
  },
  [c.DELETE_USER_FAIL]: (state) => {
    return {
      ...state,
      deletingItem: false
    }
  },
  [c.RESET_CURRENT_USER_START]: (state) => {
    return {
      ...state,
      currentItem: initialState.currentItem
    }
  },
}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
