import React, { useState, useEffect } from 'react';
import {
  Button,
  FormGroup,
  Form,
  InputGroup,
  Modal,
  Row,
  Col,
  Label,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import useForm from 'react-hook-form';
import * as yup from 'yup';
import * as Datetime from 'react-datetime';

import { StyledDropdown } from '../../../Front/common';

const applicationStatusOptions = [
  { label: 'All', value: 'all' },
  { label: 'In Progress', value: 'inProgress' },
  { label: 'Payment Made', value: 'paymentMade' },
  { label: 'Certificate Issued', value: 'certificateIssued' },
];

const formSchema = {
  status: yup.string().required('Application status field is required.'),
  startDate: yup.string().required('Start date field is required.'),
  endDate: yup.string().required('End date field is required.'),
};

const ExportApplication = ({
  submit,
  submittingForm,
  showModal,
  accounts,
  authUser,
  healthCovers,
}) => {
  const defaultFormData = {
    status: 'certificateIssued',
    accountId: '',
    startDate: null,
    endDate: null,
    healthCoverId: '',
  };
  const [formData, setFormData] = useState(defaultFormData);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const healthCoverOptions = [
    { label: 'All', value: '' },
    healthCovers.map((healthCover) => ({
      label: healthCover.name,
      value: healthCover.id,
    })),
  ];
  const accountOptions = [
    { label: 'All', value: '' },
    ...accounts.map((account) => ({
      label: account.name,
      value: account.id,
    })),
  ];
  const { register, handleSubmit, clearError, setValue, errors } = useForm({
    validationSchema: yup.object().shape(formSchema),
    defaultValues: defaultFormData,
  });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSelectChange = async (selectedOption, name) => {
    const value = selectedOption.value
      ? selectedOption.value
      : selectedOption.name;
    setValue(name, value);
    setFormData({ ...formData, [name]: value });
    clearError(name);
  };

  const onSubmit = (data) => {
    submit({ data });
  };

  const validStartDate = (current) => {
    const today = Datetime.moment();
    return current.isBefore(today);
  };

  const validEndDate = (current) => {
    return (
      current.isAfter(Datetime.moment(formData.startDate).subtract(1, 'day')) &&
      current.isBefore(Datetime.moment())
    );
  };

  const handleDateChange = (selectedDate, name) => {
    setFormData({ ...formData, [name]: selectedDate });
    setValue([name], selectedDate);
  };

  useEffect(() => {
    register({ name: 'status' });
    register({ name: 'healthCoverId' });
    register({ name: 'accountId' });
    register({ name: 'startDate' });
    register({ name: 'endDate' });
  }, [register]);

  useEffect(() => {
    setIsModalOpen(showModal);
  }, [showModal]);

  return (
    <>
      <Col sm={6} className="text-right">
        <Button
          onClick={toggleModal}
          className="btn-icon btn-3"
          color="primary"
          outline
          type="button"
        >
          <span className="btn-inner--icon">
            <i className="ni ni-curved-next" />
          </span>
          <span className="btn-inner--text">Export</span>
        </Button>
      </Col>

      <Modal className="modal-dialog-centered" isOpen={isModalOpen}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLabel">
              Export applications
            </h2>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setIsModalOpen(!isModalOpen)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col lg="12">
                <FormGroup className="form-item">
                  <Label>Application status</Label>
                  <InputGroup className="input-group-alternative">
                    <StyledDropdown
                      selected={applicationStatusOptions.find(
                        (item) => item.value === formData.status,
                      )}
                      onChange={(selected) =>
                        handleSelectChange(selected, 'status')
                      }
                      name="status"
                      options={applicationStatusOptions}
                      placeholder="Select"
                    />
                  </InputGroup>
                  {errors.status && (
                    <span className="form-error">{errors.status.message}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup className="form-item">
                  <Label>Health Cover</Label>
                  <InputGroup className="input-group-alternative">
                    <StyledDropdown
                      selected={healthCoverOptions.find(
                        (item) => item.value === formData.healthCoverId,
                      )}
                      onChange={(selected) =>
                        handleSelectChange(selected, 'healthCoverId')
                      }
                      name="healthCoverId"
                      options={healthCoverOptions}
                      placeholder="Select"
                    />
                  </InputGroup>
                  {errors.healthCoverId && (
                    <span className="form-error">
                      {errors.healthCoverId.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            {authUser.role === 'superadmin' && (
              <Row>
                <Col lg="12">
                  <FormGroup className="form-item">
                    <Label>Account</Label>
                    <InputGroup className="input-group-alternative">
                      <StyledDropdown
                        selected={accountOptions.find(
                          (item) => item.value === formData.accountId,
                        )}
                        onChange={(selected) =>
                          handleSelectChange(selected, 'accountId')
                        }
                        name="accountId"
                        options={accountOptions}
                        placeholder="Select"
                      />
                    </InputGroup>
                    {errors.accountId && (
                      <span className="form-error">
                        {errors.accountId.message}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            )}

            <Row>
              <Col lg="6">
                <FormGroup className="form-item">
                  <Label>
                    Start date<span className="form-error">*</span>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <Datetime
                      inputProps={{
                        placeholder: 'Pick start date',
                      }}
                      className="date-time-input"
                      timeFormat={false}
                      isValidDate={validStartDate}
                      closeOnSelect
                      name="startDate"
                      dateFormat="YYYY-MM-DD"
                      onChange={(selectedDate) =>
                        handleDateChange(selectedDate, 'startDate')
                      }
                    />

                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {errors.startDate && (
                    <small className="form-error">This field is required</small>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup className="form-item">
                  <Label>
                    End date<span className="form-error">*</span>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <Datetime
                      inputProps={{
                        placeholder: 'Pick end date',
                      }}
                      className="date-time-input"
                      timeFormat={false}
                      isValidDate={validEndDate}
                      closeOnSelect
                      name="endDate"
                      dateFormat="YYYY-MM-DD"
                      onChange={(selectedDate) =>
                        handleDateChange(selectedDate, 'endDate')
                      }
                    />
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {errors.endDate && (
                    <small className="form-error">This field is required</small>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div
            className="modal-footer"
            style={{ borderTop: '1px solid #e9ecef' }}
          >
            <Button
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={toggleModal}
            >
              Close
            </Button>
            <Button color="primary" disabled={submittingForm} type="submit">
              EXPORT
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ExportApplication;
