export const GET_PURCHASE_ORDERS_START = 'GET_PURCHASE_ORDERS_START';
export const GET_PURCHASE_ORDERS_SUCCESS = 'GET_PURCHASE_ORDERS_SUCCESS';
export const GET_PURCHASE_ORDERS_FAIL = 'GET_PURCHASE_ORDERS_FAIL';

export const DELETE_PURCHASE_ORDER_START = 'DELETE_PURCHASE_ORDER_START';
export const DELETE_PURCHASE_ORDER_SUCCESS = 'DELETE_PURCHASE_ORDER_SUCCESS';
export const DELETE_PURCHASE_ORDER_FAIL = 'DELETE_PURCHASE_ORDER_FAIL';

export const SUBMIT_PURCHASE_ORDER_START = 'SUBMIT_PURCHASE_ORDER_START';
export const SUBMIT_PURCHASE_ORDER_SUCCESS = 'SUBMIT_PURCHASE_ORDER_SUCCESS';
export const SUBMIT_PURCHASE_ORDER_FAIL = 'SUBMIT_PURCHASE_ORDER_FAIL';

export const SEND_EMAIL_START = 'SEND_EMAIL_START';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAIL = 'SEND_EMAIL_FAIL';

export const EXPORT_BILL_START = 'EXPORT_BILL_START';
export const EXPORT_BILL_SUCCESS = 'EXPORT_BILL_SUCCESS';
export const EXPORT_BILL_FAIL = 'EXPORT_BILL_FAIL';