import React, {useState} from 'react';
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Row,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Col,
    DropdownItem
  } from "reactstrap";
  import { Alert } from "reactstrap";
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Avatar from 'react-avatar';

import DeleteModal from '../../DeleteModal';
import Header from "../../Headers/SlimHeader";
import { GeneratePurchaseOrder } from './GeneratePurchaseOrder'; 
import { ExportBill } from './ExportBill'; 

const PurchaseOrder = (props) =>{
    const { total, data, deleteItem, authUser, getAll, sendEmail } = props;
    const [deleteItemId, setDeleteItemId] = useState();
    const limit = 10;
    const pageCount = Math.ceil(total / limit);

    const confirmDelete = ({event, id}) => {
      event.preventDefault();
      setDeleteItemId(id)
    }

    const del = () => {
        deleteItem({id: deleteItemId});
        setDeleteItemId(null);
    }

    const paginationChange = (data) => {
      getAll({limit, offset: data.selected})
    }
    
    return (
        <>
             <Header />
             <Container fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col sm={6}>
                      <h3 className="mb-0">All Purchase Orders</h3> 
                    </Col>
                    <Col sm={6} className="text-right">
                      {
                          (authUser.role === 'staff' || authUser.role === 'superadmin') && (<>
                            <ExportBill authUser={authUser} />
                            <GeneratePurchaseOrder authUser={authUser} />
                          </>)
                      }
                    </Col>
                  </Row>
                  
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">PO Number</th>
                      <th scope="col">Type</th>
                      <th scope="col">Generated Date</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      
                      <th scope="col">Account</th>
                      
                      <th scope="col">
                        Added by
                      </th>

                      <th scope="col" className="text-center">Email Sent</th>
                      <th scope="col" className="text-center">Paid</th>
                      
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data && data.map((item) => (
                        <tr key={item.id}>
                          <td>
                            PO-{item.poNumber}
                          </td>
                          <td>{item.type}</td>
                          <td>
                            {moment(item.createdAt).format('DD/MM/YYYY')}
                          </td>
                          <td>
                            
                          {moment(item.startDate).format('DD/MM/YYYY')}
                          </td>
                          <td>
                          {moment(item.endDate).format('DD/MM/YYYY')}
                          </td>
                          
                          <td>
                            {item.accountId ? (
                              <>
                                <strong>{item.Account.name}</strong> <br />
                                {item.Account.email}
                              </>
                            ): '-'}
                          </td>
                         
                          
                          <td>
                            <div style={{display: 'flex'}}>
                                <Avatar name={`${item.User.firstName} ${item.User.lastName}`} size="40" round={true} />
                                <div className="ml-2">
                                <strong>{item.User.firstName}  {item.User.lastName} </strong><br />
                                {item.User.email}
                                </div>
                            </div>
                        </td>
                        <td className="text-center">
                            {item.emailSent &&   (<i className="fa fa-check text-success" />)}
                          </td>
                         
                          <td className="text-center">
                            {item.isPaid &&   (<i className="fa fa-check text-success" />)}
                          </td>
                         
                          <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-dark"
                                    role="button"
                                    href="#"
                                    size="sm"
                                    color=""
                                    onClick={e => e.preventDefault()}
                                  >
                                    <i className="fa fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    {
                                      item.url && (
                                        <a 
                                          className="dropdown-item"
                                          href={item.url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Download
                                        </a>
                                      )
                                    }
                                    
                                    {
                                      item.url && (authUser.role === 'staff' || authUser.role === 'superadmin') && (
                                        <DropdownItem 
                                        onClick={() => sendEmail({id: item.id})}
                                      >
                                        Send Email
                                      </DropdownItem>
                                      )
                                    }

                                    <DropdownItem 
                                      onClick={(event) => confirmDelete({event, id: item.id})}
                                    >
                                      Delete
                                    </DropdownItem>
                                  
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                    }
                    <tr>
                        <td colSpan={11} style={{height: 100}}></td>
                    </tr>
                    </tbody>
                </Table>
                <CardFooter className="py-4">
                
                {
                  total > limit && (
                    <ReactPaginate
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={pageCount}
                      marginPagesDisplayed={10}
                      pageRangeDisplayed={5}
                      onPageChange={paginationChange}
                      containerClassName={'pagination justify-content-end mb-0'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      activeClassName={'active'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      previousLabel="«"
                      nextLabel="»"
                    />
                  )
                }
                
                  
                {
                  (!data || !data.length) && (
                      <Alert color="info">
                        <strong>No data</strong> You don't have any data yet!
                      </Alert>
                  )
                }

                {
                  deleteItemId && (<DeleteModal showModal={deleteItemId} toggleModal={() => setDeleteItemId()} confirmAction={del} title="Delete this purchase order?" details="Are you sure you want to delete this purchase order?" />)
                  
                }
                    
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        </>
    );
}

export default PurchaseOrder;