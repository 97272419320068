import client from '../../../../client';

const apiSlug = 'health-covers';

function getOne({id}) {
    return client.get(`${apiSlug}/${id}`);
}

function getAllHealthCovers({limit, offset, filter}) {
    let query  = `offset=${offset}&limit=${limit}`;
    if(filter.healthCoverType) {
        query = `${query}&healthCoverType=${filter.healthCoverType}`;
    }
    return client.get(`health-covers?${query}`);
}

function updateHealthCover({healthCover}) {
    return client.put(`health-covers/${healthCover.id}`, healthCover);
}

function addHealthCover({healthCover}) {
    return client.post(`health-covers/`, healthCover);
}

function deleteHealthCover({id}) {
    return client.delete(`health-covers/${id}`);
}


export { getOne, getAllHealthCovers, addHealthCover,  updateHealthCover, deleteHealthCover };