import client from '../../../../client';

const apiSlug = 'applications';

function exportApplication({ data }) {
  return client.post(`${apiSlug}/export`, data);
}


export {
    exportApplication
};
