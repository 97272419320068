import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
  } from "reactstrap";
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Header from "../../../Headers/SlimHeader";

const formSchema = yup.object().shape({
  question: yup.string().required('Question is required'),
});

const getAnswer = (answer) => {
  const contentBlock = htmlToDraft(answer);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }
  else {
    return EditorState.createEmpty();
  }
}

const FaqForm = (props) => {
    const { currentItem, submittingForm, history } = props;
    const [editorState, setEditorState] = useState(getAnswer(currentItem.answer));

    const { register, handleSubmit, errors, setValue, reset } = useForm({
      validationSchema: formSchema,
      defaultValues: currentItem,
    });

    useEffect(() => {
      reset(currentItem);
      setEditorState(getAnswer(currentItem.answer));
    }, [currentItem, reset]);

    const onSubmit = async (updatedItem) => {
      const { submit } = props;
      submit({data: {...currentItem, ...updatedItem}});
    }

    const handleInputChange = (event) => {
      const { target } = event;
      const name = target.name;
      const value = target.value;
      setValue([name], value, true);
    }

    const onEditorStateChange = (editorState) => {
      setEditorState(editorState)
      setValue('answer', draftToHtml(convertToRaw(editorState.getCurrentContent())), true);
    }

    useEffect(() => {
        register({name: 'answer'});
    }, [register]);
    
    return (
        <>
            <Header />
            <Container fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-white shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                        <h3 className="mb-0">{currentItem.id ? 'Edit faq' : 'Add new faq'}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="grey"
                        onClick={history.goBack}
                        size="sm"
                      >
                        Back
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="pl-lg-4">
                      
                      <Row>
                        <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                              >
                                Question
                              </label>
                              
                              <Input
                                  className="form-control-alternative"
                                  innerRef={register}
                                  type="text"
                                  name="question"
                                  onChange={handleInputChange}
                                  />
                                  {errors.question && <span className='formError'>{errors.question.message}</span>}
                            </FormGroup>
                          </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                              >
                                Answer
                              </label>
                                  <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorStateChange}
                                  />
                                  {errors.answer && <span className='formError'>{errors.answer.message}</span>}
                            </FormGroup>
                          </Col>
                      </Row>
                     
                      <Row>
                          <Col lg="12">
                          <hr className="my-4" />
                            <div className="text-right">
                                <Button
                                    color="grey"
                                    type="button"
                                    onClick={history.goBack}
                                    >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={submittingForm? true : false}
                                    >
                                    {currentItem.id ? 'UPDATE' : "ADD"}
                                </Button>
                               
                            </div>
                          </Col>
                      </Row>
                    </div>
                   
                    </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        </>
    );
}

export default withRouter(FaqForm);