export const GET_HEALTH_COVERS_START = 'GET_HEALTH_COVERS_START';
export const GET_HEALTH_COVERS_SUCCESS = 'GET_HEALTH_COVERS_SUCCESS';
export const GET_HEALTH_COVERS_FAIL = 'GET_HEALTH_COVERS_FAIL';

export const GET_CURRENT_HEALTH_COVER_START = 'GET_CURRENT_HEALTH_COVER_START';
export const GET_CURRENT_HEALTH_COVER_SUCCESS = 'GET_CURRENT_HEALTH_COVER_SUCCESS';
export const GET_CURRENT_HEALTH_COVER_FAIL = 'GET_CURRENT_HEALTH_COVER_FAIL';

export const CLEAR_CURRENT_HEALTH_COVER = 'CLEAR_CURRENT_HEALTH_COVER';

export const SUBMIT_HEALTH_COVER_START = 'SUBMIT_HEALTH_COVER_START';
export const SUBMIT_HEALTH_COVER_SUCCESS = 'SUBMIT_HEALTH_COVER_SUCCESS';
export const SUBMIT_HEALTH_COVER_FAIL = 'SUBMIT_HEALTH_COVER_FAIL';

export const DELETE_HEALTH_COVER_START = 'DELETE_HEALTH_COVER_START';
export const DELETE_HEALTH_COVER_SUCCESS = 'DELETE_HEALTH_COVER_SUCCESS';
export const DELETE_HEALTH_COVER_FAIL = 'DELETE_HEALTH_COVER_FAIL';
