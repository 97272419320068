import React from 'react';
import {
    Button,
    FormGroup,
    Form,
    Input,
    InputGroup,
  } from "reactstrap";
import { Link } from "react-router-dom";
import * as yup from 'yup';
import useForm from 'react-hook-form';

const formSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid email address'),
});

const ForgotPassword = (props) => {
    const { successMessage, errorMessage } = props;
    const { register, handleSubmit, errors} = useForm({
      validationSchema: formSchema,
    });

    const onSubmit = async (data) => {
      const { submit } = props;
      submit({data});
    }

    return (
        <>
          <h4 className="text-center mb-4">
            FORGOT PASSWORD?
          </h4>
          {
            successMessage && (<p className="text-success">{successMessage}</p>)
          }
          {
            errorMessage && (
            <p className="text-danger">{errorMessage}</p>)
          }
          <Form role="form" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className="mb-3">
              <label
                className="form-control-label"
              >
                Email
              </label>
              <InputGroup className="input-group-alternative">
                <Input name="email" innerRef={register} type="email" />
              </InputGroup>
              {errors.email && <span className='form-error'>{errors.email.message}</span>}
            </FormGroup>
            
            <div className="text-center">
              <Button
                className="my-4"
                color="secondary"
                type="submit"
              >
                Request reset passsword
              </Button>
            </div>
            <div className=" text-center">
                <small><Link to="/auth/login">Back to Log In</Link></small>
            </div>
          </Form>
        </>
    );
  }

export default ForgotPassword;