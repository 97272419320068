import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions'
import MessageDetails from './MessageDetails';

class MessageDetailsContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {}
      }

      componentDidMount() {
        const {  id } = this.props.match.params;
        const { actions: { getCurrent } } = this.props;
        if(id) {
          getCurrent({id});
        }
      }

      
      render() {
        const { currentItem, actions: { submitForm }  } = this.props;
        
        return (
          <MessageDetails
            {...this.props}
            currentItem={currentItem}
            submitForm={submitForm}
          />
        )
      }
}

const mapStateToProps = state => {
    return {
      ...state.Admin.Message
    }
  }
  
  const mapDispatchToProps = dispatch => {
      return {
        actions: bindActionCreators(actions, dispatch)
      }
    }
    
  
  const ConnectedMessageDetails = connect(mapStateToProps, mapDispatchToProps)(MessageDetailsContainer);
  
  export { ConnectedMessageDetails as MessageDetails };