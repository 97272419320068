import * as c from './constants';
import * as authService from './services';

const submitStart = () => ({
  type: c.SUBMIT_RESET_PASSWORD_START
})
const submitSuccess = () => ({
  type: c.SUBMIT_RESET_PASSWORD_SUCCESS,
})
const submitFail = ({error}) => ({
  type: c.SUBMIT_RESET_PASSWORD_FAIL,
  error,
})
export const submit = ({data}) => async (dispatch) => {
  dispatch(submitStart())
  try {
      await authService.resetPassword({data});
      dispatch(submitSuccess());
  } catch (error) {
    dispatch(submitFail({error: error.message}))
  }
}
