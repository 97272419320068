import React from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Badge
  } from "reactstrap";
import { withRouter } from 'react-router-dom';
import { FilePond  } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { Cookies } from "react-cookie";

import  config from '../../../../../config';
import Header from "../../../Headers/SlimHeader";

import { LeadForm } from './LeadForm';

const LeadDetails = (props) => {
  
    const { currentItem, history, deletingCertificate, deleteLeadCertificate  } = props;
    
    const removeCertificate = (event) => {
      event.preventDefault();
      
      deleteLeadCertificate({id: currentItem.id})
    }

    if(!currentItem) return null;

    const cookies = new Cookies(); 

    return (
        <>
            <Header />
            <Container fluid>
            <Row className="align-items-center" style={{marginTop: -34}}>
                <Col xs="12">
                <Row>
                    <Col className="order-xl-1" lg="12" xl="9">
                        <Card className="bg-white shadow">
                        <CardHeader className="bg-white border-0">
                            
                            <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">
                                    Lead details &nbsp;
                                    {currentItem.status === 'inProgress' && (<Badge color="info">In progress</Badge>)}
                                    {currentItem.status === 'insuranceRequested' && (<Badge color="warning">Insurance requested</Badge>)}
                                    {currentItem.status === 'certificateIssued' && (<Badge color="success">Certificate issued</Badge>)}  
                                </h3> <br />
                                {currentItem.Account ? `Account: ${currentItem.Account.name}` : ''}
                            </Col>
                            
                            <Col className="text-right" xs="4">
                                <Button
                                color="grey"
                                onClick={history.goBack}
                                size="sm"
                                >
                                Back
                                </Button>
                            </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                                <Row>
                                <Col lg="8">
                                <Card className="mb-4 p-4">
                                    <LeadForm  />
                                </Card>
                                </Col>
                                <Col lg="4">
                                    
                                        {
                                        !currentItem.certificate && (currentItem.status === 'insuranceRequested') && (
                                            <>
                                            <h5>Upload certificate <span  className="text-danger">*</span></h5>
                                            <FilePond
                                                allowMultiple={false}
                                                server= {
                                                {
                                                    url: `${config.serverUrl}/api/leads/${currentItem.id}/upload`,
                                                    process: {
                                                        headers: {
                                                            'x-access-token': cookies.get('token')
                                                        },
                                                    }
                                                }
                                                }
                                                accept="image/png, image/jpeg, image/gif, application/pdf"
                                                onprocessfile={() => window.location.reload(false)
                                                    //getCurrent({id: currentItem.id})
                                                }
                                                />

                                            

                                            </>
                                        )
                                        }

                                        

                                        {
                                        currentItem.certificate && (
                                            <>
                                            <h5>Certificate <span  className="text-danger">*</span></h5>
                                            <Card style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} className="p-sm">
                                                <a href={currentItem.certificate} target="_blank" rel="noopener noreferrer"><i className="fa fa-download" style={{marginRight: 10}} /> Download certificate</a>
                                            </Card>
                                            <div className="text-right">
                                                {
                                                deletingCertificate ? (<p>...</p>) : (
                                                    <Button onClick={removeCertificate} className="mt-4" size="sm">Remove certificate</Button>
                                                )
                                                }
                                            </div>
                                            
                                            </>
                                        )
                                        }
                                        
                                   

                                </Col>
                                </Row>

                            
                            </CardBody>
                        </Card>
                    </Col>
                    </Row>
                </Col>
            </Row>
          
        </Container>
        </>
    );
}

export default withRouter(LeadDetails);