import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { Cookies } from "react-cookie";

import * as actions from './CurrentAccount/actions';
import Front from './Front';

class FrontContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  redirectIfNotLoggedIn() {
    const cookies = new Cookies(); 
    if (!cookies.get('token')) {
      window.location.href = '/auth/login';
    }
  }

  componentDidMount() {
    const { data, actions : { getCurrent } } = this.props;
    this.redirectIfNotLoggedIn();
    if(!data) {
      const cookies = new Cookies(); 
      if(cookies.get('agent')) {
        const slug = cookies.get('agent');
        getCurrent({slug});
      }
      else {
        const url = this.props.location.search;
        if(url.indexOf('agent=') > -1) {
            const slug = url.substring(url.indexOf('agent=') + 6);
            const cookies = new Cookies(); 
            cookies.set("agent", slug, { path: "/", maxAge: 60 * 60 * 24 * 365 })
            if(slug) {
                getCurrent({slug});
            }
            
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location.pathname !== this.props.location.pathname) {
      this.redirectIfNotLoggedIn();
    }
  }

  render() {
    const { data } = this.props;
    return (
      <Front
        currentAccount={data}
      />
    )
  }
}


const mapStateToProps = state => {
    return state.Front.CurrentAccount
}
  

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  


const ConnectedFront = connect(mapStateToProps, mapDispatchToProps)(withRouter(FrontContainer));

export { ConnectedFront as Front };

