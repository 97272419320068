import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions'
import ApplicationFormOSHC from './ApplicationFormOSHC';

class ApplicationFormContainerOSHC extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updatingApplication && !this.props.updatingApplication && !this.props.applicationError) {
      const { application } = this.props;
      this.props.history.push(`/cover-type/oshc/application/${application.id}/payment`);
    }
  }

  populatePartner = (partner) => {
    if(partner) {
      const {title, firstName, lastName, gender, dateOfBirth} = partner;
      return {
        partnerTitle: title,
        partnerFirstName: firstName,
        partnerLastName: lastName,
        partnerGender: gender,
        partnerDateOfBirth: dateOfBirth
      }
    }
    return {};
  }

  populateChildren = (children) => {
    const application = {};
    if(children && children.length) {
      for(var i = 0; i < children.length; i++) {
        const currentChildren = children[i];
        const {title, firstName, lastName, gender, dateOfBirth} = currentChildren;
        application[`children${i}Title`] = title;
        application[`children${i}FirstName`] = firstName;
        application[`children${i}LastName`] = lastName;
        application[`children${i}Gender`] = gender;
        application[`children${i}DateOfBirth`] = dateOfBirth;
      }
    }
   
    return application;
  }


  render() {
    const { application, currentAccount, updatingApplication,  actions: {updateApplication} } = this.props;
    
    return (
      <ApplicationFormOSHC
        {...this.props}
        application={{...application, ...this.populatePartner(application.ApplicationPartner), ...this.populateChildren(application.ApplicationChildren), accountId: currentAccount && currentAccount.id}}
        updateApplication={updateApplication}
        updatingApplication={updatingApplication}
        currentAccount={currentAccount}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.Front.Application,
    currentAccount: state.Front.CurrentAccount.data
  }
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedApplicationFormOSHC = connect(mapStateToProps, mapDispatchToProps)(ApplicationFormContainerOSHC);

export { ConnectedApplicationFormOSHC as ApplicationFormOSHC };

