import React from 'react';
import {  
    Container,
    Row,
    Col,
    Card,
    CardBody 
} from "reactstrap";
import { Link } from "react-router-dom";
import Rating from 'react-rating';
import moment from 'moment';

const Reviews = (props) => {
  const { data } = props;
  window.scrollTo(0, 0);
  
  return (
    <section className="section">
        <Container>
            <Row className="align-items-center">
                <Col lg="8">
                    <h2>Real reviews from real customers</h2>
                </Col>
                <Col lg="4" className="text-right align-middle">
                    <Link to="/reviews">View all reviews &raquo;</Link>
                </Col>
            </Row>
            <Row>
                {
                    data.splice(0, 3).map(item => (
                        <Col lg="4" key={item.id}>
                            <Card className="shadow shadow-lg--hover mt-4">
                                <CardBody>
                                    <div className="mb-2"><Rating
                                            stop={5}
                                            emptySymbol={['fa fa-star-o star-active']}
                                            fullSymbol={['fa fa-star  star-active']}
                                            initialRating={item.rating}
                                            readonly={true}
                                            />
                                    </div>
                                    <div>
                                    <p>
                                        {item.review.substring(0, 200)}{item.review.length > 200 && (<span>...</span>)}
                                    </p>
                                    <div>
                                        <strong>{item.Application && item.Application.firstName} {item.Application && item.Application.lastName}</strong>
                                    </div>
                                    <small><em>{moment(item.createdAt).format('MMMM YYYY')}</em></small>
                                    </div>
                                </CardBody>
                            </Card>
                            </Col>
                    ))
                }
                
            </Row>
        </Container>
    </section>
  );
}

export default Reviews;
