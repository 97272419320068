import React, { useState, useEffect } from 'react';
import { Container, Row, Col, TabPane } from 'reactstrap';

import Header from '../../Headers/SlimHeader';
import ApplicationTabs from './ApplicationTabs';
import ApplicationGrid from './ApplicationGrid';
import { Search } from '../../common';

const Application = (props) => {
  const {
    data,
    total,
    deleteItem,
    getAll,
    authUser,
    submit,
    accountId,
    loadingAll,
    stats,
  } = props;
  const [deleteItemId, setDeleteItemId] = useState();
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState();
  const limit = 20;
  const pageCount = Math.ceil(total / limit);
  const confirmDelete = ({ event, id }) => {
    event.preventDefault();
    setDeleteItemId(id);
  };

  useEffect(() => {
    let id = setInterval(() => {
      const filter = {};
      if (status) {
        filter.status = status;
      }
      getAll({ limit, offset: offset, filter });
    }, 60000);
    return () => clearInterval(id);
  });

  const del = () => {
    deleteItem({ id: deleteItemId });
    setDeleteItemId(null);
  };

  const paginationChange = ({ data, status, isArchived = false }) => {
    const filter = {
      isArchived,
    };
    if (status) {
      filter.status = status;
      setStatus(status);
    } else {
      setStatus();
    }
    setOffset(data.selected);
    getAll({ limit, offset: data.selected, filter });
  };

  const updateStatus = ({ event, id, status }) => {
    event.preventDefault();
    submit({ data: { id, status } });
  };

  const unArchive = ({ event, id }) => {
    event.preventDefault();
    submit({ data: { id, isArchived: false } });
  };

  return (
    <>
      <Header />
      <Container fluid>
        <Search getAll={getAll} />
        <Row className="align-items-center" style={{ marginTop: -34 }}>
          <Col xs="12">
            <ApplicationTabs
              stats={stats}
              setStatus={setStatus}
              setOffset={setOffset}
              getAll={getAll}
              limit={limit}
              offset={0}
              accountId={accountId}
            >
              <TabPane tabId="1">
                <ApplicationGrid
                  title="All applications"
                  data={data}
                  updateStatus={updateStatus}
                  authUser={authUser}
                  confirmDelete={confirmDelete}
                  total={total}
                  limit={limit}
                  pageCount={pageCount}
                  paginationChange={paginationChange}
                  deleteItemId={deleteItemId}
                  setDeleteItemId={setDeleteItemId}
                  del={del}
                  offset={offset}
                  loadingAll={loadingAll}
                />
              </TabPane>
              <TabPane tabId="2">
                <ApplicationGrid
                  title="In Progress applications"
                  data={data}
                  updateStatus={updateStatus}
                  authUser={authUser}
                  confirmDelete={confirmDelete}
                  total={total}
                  limit={limit}
                  pageCount={pageCount}
                  paginationChange={paginationChange}
                  deleteItemId={deleteItemId}
                  setDeleteItemId={setDeleteItemId}
                  del={del}
                  status="inProgress"
                  offset={offset}
                  loadingAll={loadingAll}
                />
              </TabPane>
              <TabPane tabId="3">
                <ApplicationGrid
                  title="Payment made applications"
                  data={data}
                  updateStatus={updateStatus}
                  authUser={authUser}
                  confirmDelete={confirmDelete}
                  total={total}
                  limit={limit}
                  pageCount={pageCount}
                  paginationChange={paginationChange}
                  deleteItemId={deleteItemId}
                  setDeleteItemId={setDeleteItemId}
                  del={del}
                  status="paymentMade"
                  offset={offset}
                  loadingAll={loadingAll}
                />
              </TabPane>
              <TabPane tabId="4">
                <ApplicationGrid
                  title="Insurance requested applications"
                  data={data}
                  updateStatus={updateStatus}
                  authUser={authUser}
                  confirmDelete={confirmDelete}
                  total={total}
                  limit={limit}
                  pageCount={pageCount}
                  paginationChange={paginationChange}
                  deleteItemId={deleteItemId}
                  setDeleteItemId={setDeleteItemId}
                  del={del}
                  status="insuranceRequested"
                  offset={offset}
                  loadingAll={loadingAll}
                />
              </TabPane>
              <TabPane tabId="5">
                <ApplicationGrid
                  title="Certificate issued applications"
                  data={data}
                  updateStatus={updateStatus}
                  authUser={authUser}
                  confirmDelete={confirmDelete}
                  total={total}
                  limit={limit}
                  pageCount={pageCount}
                  paginationChange={paginationChange}
                  deleteItemId={deleteItemId}
                  setDeleteItemId={setDeleteItemId}
                  del={del}
                  status="certificateIssued"
                  offset={offset}
                  loadingAll={loadingAll}
                />
              </TabPane>
              <TabPane tabId="6">
                <ApplicationGrid
                  title="Archived applications"
                  data={data.filter((item) => item.isArchived === true)}
                  updateStatus={updateStatus}
                  authUser={authUser}
                  confirmDelete={confirmDelete}
                  total={total}
                  limit={limit}
                  pageCount={pageCount}
                  paginationChange={paginationChange}
                  deleteItemId={deleteItemId}
                  setDeleteItemId={setDeleteItemId}
                  del={del}
                  status="isArchived"
                  offset={offset}
                  loadingAll={loadingAll}
                  unArchive={unArchive}
                  isArchived={true}
                />
              </TabPane>
            </ApplicationTabs>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Application;
