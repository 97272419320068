import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import { StripeProvider } from 'react-stripe-elements';

import reduxStore from './store';
import config from './config';
import './assets/vendor/nucleo/css/nucleo.css';
import './assets/vendor/font-awesome/css/font-awesome.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Routes from './routes';
import { Loader } from './views/Common';


const App = () => {
  return (
    <>
      <StripeProvider apiKey={config.stripeApiKey}>
        <Provider store={reduxStore.store}>
          <Suspense fallback={(<Loader />)}>
            <ToastProvider>
              <Routes />
            </ToastProvider>
          </Suspense>
        </Provider>
      </StripeProvider>
    </>
  );
};

export default App;
