import * as c from './constants';

const initialState = {
  submitting: false,
  error: null,
};


const actionHandlers = {
  [c.SUBMIT_FORGOT_PASSWORD_START]: (state) => {
    return {
      ...state,
      error: null,
      submitting: true,
    }
  },
  [c.SUBMIT_FORGOT_PASSWORD_SUCCESS]: (state) => {
    return {
      ...state,
      error: null,
      submitting: false,
    }
  },
  [c.SUBMIT_FORGOT_PASSWORD_FAIL]: (state, action) => {
    return {
      ...state,
      submitting: false,
      error: action.error
    }
  },
}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
