export const GET_FEATURE_CATEGORIES_START = 'GET_FEATURE_CATEGORIES_START';
export const GET_FEATURE_CATEGORIES_SUCCESS = 'GET_FEATURE_CATEGORIES_SUCCESS';
export const GET_FEATURE_CATEGORIES_FAIL = 'GET_FEATURE_CATEGORIES_FAIL';

export const GET_CURRENT_FEATURE_CATEGORY_START = 'GET_CURRENT_FAQ_START';
export const GET_CURRENT_FEATURE_CATEGORY_SUCCESS = 'GET_CURRENT_FAQ_SUCCESS';
export const GET_CURRENT_FEATURE_CATEGORY_FAIL = 'GET_CURRENT_FAQ_FAIL';

export const CLEAR_CURRENT_FEATURE_CATEGORY = 'CLEAR_CURRENT_FEATURE_CATEGORY';

export const SUBMIT_FEATURE_CATEGORY_START = 'SUBMIT_FEATURE_CATEGORY_START';
export const SUBMIT_FEATURE_CATEGORY_SUCCESS = 'SUBMIT_FEATURE_CATEGORY_SUCCESS';
export const SUBMIT_FEATURE_CATEGORY_FAIL = 'SUBMIT_FEATURE_CATEGORY_FAIL';

export const DELETE_FEATURE_CATEGORY_START = 'DELETE_FEATURE_CATEGORY_START';
export const DELETE_FEATURE_CATEGORY_SUCCESS = 'DELETE_FEATURE_CATEGORY_SUCCESS';
export const DELETE_FEATURE_CATEGORY_FAIL = 'DELETE_FEATURE_CATEGORY_FAIL';
