import React from "react";

import { Card, CardBody, CardTitle, Container, Row, Col, Badge } from "reactstrap";

class Header extends React.Component {
  render() {
    const { report } = this.props;
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            ALL APPLICATIONS
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {report.all.total}
                          </span>
                        </div>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2 font-weight-bold">
                          <Badge color="default">{report.all.thisMonth}</Badge>
                        </span>{" "}
                        <span className="text-nowrap">This month</span> {" "}
                        <span className="text-success ml-2 font-weight-bold">
                        <Badge color="default">{report.all.thisWeek}</Badge>
                        </span>{" "}
                        <span className="text-nowrap">This week</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            In progress
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                          {report.inProgress.total}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="ni ni-user-run" /> 
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-info font-weight-bold mr-2">
                        <Badge color="info">{report.inProgress.thisMonth}</Badge>
                        </span>{" "}
                        <span className="text-nowrap">This month</span>  {" "}
                        <span className="text-success ml-2 font-weight-bold">
                        <Badge color="info">{report.inProgress.thisWeek}</Badge>
                        </span>{" "}
                        <span className="text-nowrap">This week</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            PAYMENT MADE
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0"> {report.paymentMade.total}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="ni ni-credit-card" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning font-weight-bold mr-2">
                        <Badge color="warning">{report.paymentMade.thisMonth}</Badge>
                        </span>{" "}
                        <span className="text-nowrap">This month</span> 
                        <span className="text-success ml-2 font-weight-bold">
                        <Badge color="info">{report.paymentMade.thisWeek}</Badge>
                        </span>{" "}
                        <span className="text-nowrap">This week</span>
                        
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Certificate Issued
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {report.certificateIssued.total}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                            <i className="ni ni-paper-diploma" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success font-weight-bold mr-2">
                          <Badge color="success">{report.certificateIssued.thisMonth}</Badge>
                        </span>{" "}
                        <span className="text-nowrap">This month</span>
                        <span className="text-success ml-2 font-weight-bold">
                        <Badge color="info">{report.paymentMade.thisWeek}</Badge>
                        </span>{" "}
                        <span className="text-nowrap">This week</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}
export default Header;