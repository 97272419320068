import * as c from './constants';
import * as AccountService from './services';

const getAllStart = () => ({
  type: c.GET_ACCOUNTS_START,
});

const getAllSuccess = ({ data, total }) => ({
  type: c.GET_ACCOUNTS_SUCCESS,
  data,
  total,
});

const getAllFail = ({ error }) => ({
  type: c.GET_ACCOUNTS_FAIL,
  error,
});

export const getAll = ({ limit, offset, filter }) => async (dispatch) => {
  dispatch(getAllStart());
  try {
    const { data } = await AccountService.getAll({ limit, offset, filter });
    dispatch(getAllSuccess({ data: data.data, total: data.total }));
  } catch (error) {
    dispatch(getAllFail({ error }));
  }
};


const getCurrentStart = () => ({
  type: c.GET_CURRENT_ACCOOUNT_START,
});

const getCurrentSuccess = ({ currentItem }) => ({
  type: c.GET_CURRENT_ACCOUNT_SUCCESS,
  currentItem,
});

const getCurrentFail = ({ error }) => ({
  type: c.GET_CURRENT_ACCOUNT_FAIL,
  error,
});

export const getCurrent = ({ id }) => async (dispatch) => {
  dispatch(getCurrentStart());
  try {
    const { data } = await AccountService.getOne({ id });

    dispatch(getCurrentSuccess({ currentItem: data.data }));
  } catch (error) {
    dispatch(getCurrentFail({ error }));
  }
};

const resetCurrentItemSuccess = () => ({
  type: c.RESET_CURRENT_ACCOUNT_START,
});

export const resetCurrentItem = () => (dispatch) => {
  dispatch(resetCurrentItemSuccess());
};


const submitStart = () => ({
  type: c.SUBMIT_ACCOUNT_START,
});
const submitSuccess = ({ data }) => ({
  type: c.SUBMIT_ACCOUNT_SUCCESS,
  data,
});
const submitFail = ({ error }) => ({
  type: c.SUBMIT_ACCOUNT_FAIL,
  error,
});
export const submit = ({ data }) => async (dispatch) => {
  dispatch(submitStart());
  try {
    let response;

    if (data.id) {
      response = await AccountService.update({ data });
    } else {
      response = await AccountService.add({ data });
    }

    dispatch(submitSuccess({ data: response.data.data }));
  } catch (error) {
    dispatch(submitFail({ error }));
  }
};


const deleteStart = () => ({
  type: c.DELETE_ACCOUNT_START,
});
const deleteSuccess = ({ id }) => ({
  type: c.DELETE_ACCOUNT_SUCCESS,
  id,
});
const deleteFail = ({ error }) => ({
  type: c.DELETE_ACCOUNT_FAIL,
  error,
});
export const deleteItem = ({ id }) => async (dispatch) => {
  dispatch(deleteStart());
  try {
    await AccountService.del({ id });
    dispatch(deleteSuccess({ id }));
  } catch (error) {
    dispatch(deleteFail({ error }));
  }
};
