export const GET_ACCOUNTS_START = 'GET_ACCOUNTS_START';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_FAIL = 'GET_ACCOUNTS_FAIL';

export const GET_CURRENT_ACCOOUNT_START = 'GET_CURRENT_ACCOOUNT_START';
export const GET_CURRENT_ACCOUNT_SUCCESS = 'GET_CURRENT_ACCOUNT_SUCCESS';
export const GET_CURRENT_ACCOUNT_FAIL = 'GET_CURRENT_ACCOUNT_FAIL';

export const RESET_CURRENT_ACCOUNT_START = 'RESET_CURRENT_ACCOUNT_START';

export const SUBMIT_ACCOUNT_START = 'SUBMIT_ACCOUNT_START';
export const SUBMIT_ACCOUNT_SUCCESS = 'SUBMIT_ACCOUNT_SUCCESS';
export const SUBMIT_ACCOUNT_FAIL = 'SUBMIT_ACCOUNT_FAIL';

export const DELETE_ACCOUNT_START = 'DELETE_ACCOUNT_START';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAIL = 'DELETE_ACCOUNT_FAIL';
