import * as c from './constants';
import * as HealthCoverTypeService from './services';

const getHealthCoverTypeStart = () => ({
  type: c.GET_HEALTH_COVER_TYPE_START
})

const getHealthCoverTypeSuccess = ({healthCoverTypes}) => ({
  type: c.GET_HEALTH_COVER_TYPE_SUCCESS,
  healthCoverTypes,
})

const healthCoverTypeFail = ({error}) => ({
  type: c.GET_HEALTH_COVER_TYPE_FAIL,
  error,
})

export const getAllHealthCoverTypes = () => async (dispatch) => {
  dispatch(getHealthCoverTypeStart())
  try {
      const {data} = await HealthCoverTypeService.getAllHealthCoverTypes();
      dispatch(getHealthCoverTypeSuccess({healthCoverTypes: data.data}));
  } catch (error) {
    dispatch(healthCoverTypeFail({error}))
  }
}


const updateHealthCoverTypeStart = () => ({
  type: c.UPDATE_HEALTH_COVER_TYPE_START
})

const updateHealthCoverTypeSuccess = ({healthCoverType}) => ({
  type: c.UPDATE_HEALTH_COVER_TYPE_SUCCESS,
  healthCoverType,
})

const updateHealthCoverTypeFail = ({error}) => ({
  type: c.UPDATE_HEALTH_COVER_TYPE_FAIL,
  error,
})

export const updateCurrentHealthCoverType = ({healthCoverType}) => async (dispatch) => {
  dispatch(updateHealthCoverTypeStart())
  try {
      const {data} = await HealthCoverTypeService.updateHealthCoverType({healthCoverType});
      
      dispatch(updateHealthCoverTypeSuccess({healthCoverType: data.data}));
  } catch (error) {
    dispatch(updateHealthCoverTypeFail({error}))
  }
}

const getCurrentStart = () => ({
  type: c.GET_CURRENT_HEALTH_COVER_TYPE_START
})

const getCurrentSuccess = ({currentItem}) => ({
  type: c.GET_CURRENT_HEALTH_COVER_TYPE_SUCCESS,
  currentItem,
})

const getCurrentFail = ({error}) => ({
  type: c.GET_CURRENT_HEALTH_COVER_TYPE_FAIL,
  error,
})

export const getCurrent = ({id}) => async (dispatch) => {
  dispatch(getCurrentStart());
  try {
      const { data } = await HealthCoverTypeService.getOne({id});
      
      dispatch(getCurrentSuccess({currentItem: data.data}));
  } catch (error) {
    dispatch(getCurrentFail({error}))
  }
}
