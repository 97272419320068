import client from '../../../../client';

const apiSlug = `users`;


function getOne({id}) {
    return client.get(`${apiSlug}/${id}`);
}


function getAll({limit, offset, filter}) {
    let query  = `offset=${offset}&limit=${limit}`;
    if(filter.role) {
        query = `${query}&role=${filter.role}`;
    }
    if(filter.hideAgent) {
        query = `${query}&hideAgent=true`;
    }
    if(filter.hideInactive) {
        query = `${query}&hideInactive=true`;
    }
    if(filter.query) {
        query = `${query}&query=${filter.query}`;
    }

    return client.get(`${apiSlug}?${query}`);
}

function update({data}) {
    return client.put(`${apiSlug}/${data.id}`, data);
}

function add({data}) {
    return client.post(apiSlug, data);
}

function del({id}) {
    return client.delete(`${apiSlug}/${id}`);
}


export { getAll, update,  add, del, getOne };