import * as c from './constants';
import * as myCoverService from './services';


const submitMyCoverStart = () => ({
  type: c.SUBMIT_MY_COVER_START
})

const submitMyCoverSuccess = ({data}) => ({
  type: c.SUBMIT_MY_COVER_SUCCESS,
  data
})

const submitMyCoverFail = ({error}) => ({
  type: c.SUBMIT_MY_COVER_FAIL,
  error,
})

export const submitForm = ({data}) => async (dispatch) => {
  dispatch(submitMyCoverStart())
  try {
      const response = await myCoverService.check({data});
      dispatch(submitMyCoverSuccess({data: response.data}));
  } catch (error) {
    dispatch(submitMyCoverFail({error}))
  }
}