export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const VERIFY_MFA_START = 'VERIFY_MFA_START';
export const VERIFY_MFA_SUCCESS = 'VERIFY_MFA_SUCCESS';
export const VERIFY_MFA_FAIL = 'VERIFY_MFA_FAIL';