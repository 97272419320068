import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';

import Header from '../../../Headers/SlimHeader';

const HealthCoverFeatureForm = (props) => {
  const {
    register, handleSubmit, errors, setValue,
  } = useForm();
  const {
    currentItem, submittingForm, history, features, healthCovers,
  } = props;

  const [formData, setFormData] = useState(currentItem);


  useEffect(() => {
    setFormData(currentItem);
  }, [currentItem]);


  const onSubmit = async (updatedItem) => {
    const { submit } = props;
    console.log({ ...currentItem, ...updatedItem });
    submit({ data: { ...currentItem, ...updatedItem } });
  };

  const handleInputChange = (event) => {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    setFormData({ ...formData, [name]: value });
  };

  const selectInput = (selected, name) => {
    setFormData({ ...formData, [name]: selected.id });
    setValue([name], selected.id, true);
  };

  const {
    id, text, featureId, healthCoverId, details
  } = formData;

  return (
    <>
      <Header />
      <Container fluid>
        <Row>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-white shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{id ? 'Edit health cover feature' : 'Add new health cover feature'}</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="grey"
                      onClick={history.goBack}
                      size="sm"
                    >
                        Back
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="pl-lg-4">

                    <Row>
                      <Col lg="12">
                        <FormGroup className={errors.text && 'has-danger'}>
                              <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                Text
                                </label>

                              <Input
                                  className="form-control-alternative"
                                  rows="2"
                                  type="textarea"
                                  name="text"
                                  innerRef={register({ required: true })}
                                  value={text}
                                  onChange={handleInputChange}
                                />
                            </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormGroup className={errors.details && 'has-danger'}>
                              <label
                                  className="form-control-label"
                                  htmlFor="details"
                                >
                                Details
                                </label>

                              <Input
                                  className="form-control-alternative"
                                  rows="4"
                                  type="textarea"
                                  name="details"
                                  innerRef={register({ required: false })}
                                  value={details || ''}
                                  onChange={handleInputChange}
                                />
                            </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormGroup className={errors.healthCoverId && 'has-danger'}>
                              <label
                                  className="form-control-label"
                                >
                              Health Cover
                                </label>

                              <Select
                                  value={healthCovers && healthCovers.find((item) => item.id === healthCoverId)}
                                  getOptionLabel={(option) => `${option.name} ${option.category ? '-' +  option.category : ''} - ${option.excess === '0' ? 'NO EXCESS' : option.excess + ' EXCESS' } (${option.Provider.name})`}
                                  getOptionValue={(option) => `${option.id}`}
                                  name="healthCoverId"
                                  options={healthCovers}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  ref={() => register({ name: 'healthCoverId', required: true })}
                                  onChange={(event) => selectInput(event, 'healthCoverId')}
                                />
                              {errors.healthCoverId && (<small className="formError">This field is required</small>)}

                            </FormGroup>
                      </Col>
                    </Row>


                    <Row>
                      <Col lg="12">
                        <FormGroup className={errors.featureId && 'has-danger'}>
                              <label
                                  className="form-control-label"
                                >
                              Feature
                                </label>

                              <Select
                                  value={features && features.find((item) => item.id === featureId)}
                                  getOptionLabel={(option) => `${option.name} (${option.FeatureCategory.name} - ${option.FeatureCategory.HealthCoverType.name})`}
                                  getOptionValue={(option) => `${option.id}`}
                                  name="featureId"
                                  options={features}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  ref={() => register({ name: 'featureId', required: true })}
                                  onChange={(event) => selectInput(event, 'featureId')}
                                />
                              {errors.featureId && (<small className="formError">This field is required</small>)}

                            </FormGroup>
                      </Col>
                    </Row>


                    <Row>
                      <Col lg="12">
                        <hr className="my-4" />
                        <div className="text-right">
                              <Button
                                  color="grey"
                                  type="button"
                                  onClick={history.goBack}
                                >
                                    Cancel
                                </Button>
                              <Button
                                  color="primary"
                                  type="submit"
                                  disabled={!!submittingForm}
                                >
                                  {id ? 'UPDATE' : 'ADD'}
                                </Button>

                            </div>
                      </Col>
                    </Row>
                  </div>

                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(HealthCoverFeatureForm);
