import React from 'react';

import Header from "../../Headers/AdminHeader";
import Sales from './Sales';

const Dashboard = ({report}) =>{
    if(!report) return null;
    return (
        <>
             {
                 report ? <Header report={report} />  : null
             }
             <Sales monthSales={report.monthSales}  />
        </>
    );
}

export default Dashboard;