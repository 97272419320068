import moment from 'moment';
import client from '../../../client';

function getQuotes({coverType, clientType, children, startDate, endDate}) {
    const currentStartDate = moment(startDate).format('DD/MM/YYYY');
    const currentEndDate = moment(endDate).format('DD/MM/YYYY');
    let url;
    if(coverType === 'oshc') {
        url = client.get(`quotes?cover-type=${coverType}&client-type=${clientType}&children=${children}&start-date=${currentStartDate}&end-date=${currentEndDate}`);
    }
    else if(coverType === 'ovhc') {
        url = client.get(`quotes?cover-type=${coverType}&client-type=${clientType}&children=${children}&end-date=${currentEndDate}`);
    }
    return url;
}



export { getQuotes };