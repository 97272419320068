import React from 'react';
import {
    Container,
    Row,
    Col
} from 'reactstrap';

import Hero from './Hero';

const PrivacyPolicy = (props) => {
    window.scrollTo(0, 0);
  return (
    <main>
      <Hero />

      <section className="section section-lg">
        <Container>
        <Row className="row-grid align-items-center">
            <Col className="order-md-2 nested-list" md="126">
                <ol>
                    <li>
                        <h4>General</h4>
                        <ol>
                            <li>OSHC Compare is committed to being responsive to any complaints offered by our clients and customers, our employees or by other persons that we deal with. We endeavour to reach an outcome to all complaints which is satisfactory to all parties.</li>
                            <li>OSHC Compare may change this Complaints Policy from time to time by publishing changes to it on the Website. OSHC Compare encourages You to check the Website periodically to ensure that You are aware of OSHC Compare’s current Complaints Policy.</li>
                            <li>By using the Website after changes have been made to the Complaints Policy, You are deemed to have consented to those changes. </li>
                        </ol>
                    </li>
                    <li>
                        <h4>Definitions</h4>
                        <p>In this Complaints Policy:</p>
                        <ol>
                            <li><strong>complaint</strong> means an expression of dissatisfaction by You relating to a service or good provided by us;</li>
                            <li><strong>Complaints Policy</strong> means this complaints policy (as amended from time to time);</li>
                            <li><strong>OSHC Compare</strong> or <strong>we</strong> or <strong>us</strong> means the Trustee for Ramandeep Kaur Family Trust ABN 41 639 195 725 legal name OSHC Options Pty Ltd</li>
                            <li><strong>Privacy Policy</strong> means the privacy policy of OSHC Compare (as amended from time to time); </li>
                            <li><strong>Website means</strong> the website provided by OSHC Compare which allows You to view or order or otherwise deal with services, products or goods provided or offered by OSHC Compare; which includes  <a rel="noopener noreferrer" href="https://oshccompare.com.au/" target="_blank">https://oshccompare.com.au/</a> or any other web service, software, application or media managed by OSHC Compare or its agents or representatives; </li>
                            <li><strong>You</strong> or <strong>Your</strong> means the person, firm or organisation (including you) entering into, or that has entered into, a contract, transaction or arrangement to obtain or make use of services, products or goods provided or offered by OSHC Compare or its agents or representatives.</li>
                        </ol>
                    </li>
                    <li>
                        <h4>Making a complaint</h4>
                        <ol>
                            <li>If You wish to make a complaint, then You must lodge a complaint in one of the following ways:
                                <ol>
                                    <li>By telephoning us,</li>
                                    <li>By writing to us,</li>
                                    <li>By emailing us,</li>
                                    <li>In person by speaking to any of our staff members.</li>
                                </ol>
                            </li>
                            <li>
                                OSHC Compare’s contact details are contained on the Website. 
                            </li>
                            <li>If we receive Your complaint verbally, we may ask You to put Your complaint in writing before we respond that complaint.</li>
                            <li>You will need to provide us with the following information when You make Your complaint: 
                                <ol>
                                    <li>Your name, email address and telephone number; </li>
                                    <li>a clear description of Your complaint;</li>
                                    <li>the name of the person You have been dealing with;</li>
                                    <li>copies of any documentation which supports Your complaint; and</li>
                                    <li>such other information that we may request from You.</li>
                                </ol>
                            </li>
                            <li>OSHC Compare will respond to You regarding Your complaint within a reasonable period of time and in accordance with this Complaints Policy. </li>
                        </ol>
                    </li>
                    <li>
                        <h4>Personal Information </h4>
                        <ol>
                            <li>When You lodge a complaint with us, we will record:
                                <ol>
                                    <li>all details of Your complaint (including the outcome and any actions taken following receipt of Your complaint); </li>
                                    <li>Your name and contact details; and</li>
                                    <li>such other personal information that You provide us in relation to your Complaint. </li>
                                </ol>
                            </li>
                            <li>If You lodge a complaint, we will record Your personal information for the purpose of addressing Your complaint and improving our services. Your personal information will be dealt with in accordance with our Privacy Policy.</li>
                        </ol>
                    </li>
                    <li>
                        <h4>Dealing with complaints  </h4>
                        <ol>
                            <li>OSHC Compare is committed to:
                                <ol>
                                    <li>responding to Your complaint within 5 business days of receiving Your complaint; and</li>
                                    <li>Initiating to resolve your complaint within 15 business days of You lodging Your complaint with us.</li>
                                </ol>
                            </li>
                            <li>However, satisfying these timeframes may not always be possible on every occasion. </li>
                            <li>If we are unable to resolve Your complaint within the period referred to in clause 5.1.2, we will inform You of the reason for the delay and specify a date when we believe that we will be in a position to finalise Your complaint.</li>
                            <li>Whilst we investigate Your complaint we may require further clarification or documentation from You to assist us in resolving Your complaint. </li>
                            <li>We may not be able to resolve Your complaint within the period referred to in clause 5.1.2  if we require clarification or documentation from You in relation to Your complaint. If we are waiting on You to provide this information, or the information You provide is incorrect or incomplete then this may further delay the resolution of Your complaint. </li>
                            <li>Upon receipt of the required clarification or documentation we will indicate to You when we expect to finalise Your complaint.</li>
                            <li>If Your complaint is:
                                <ol>
                                    <li>in relation to an employee of OSHC Compare, we will treat Your complaint confidentially and impartially;</li>
                                    <li>currently being investigated by a relevant federal, state or territory consumer protection regulator or law enforcement agency we may cease to take further action in relation to Your complaint pending finalisation of their investigation. We will assist any agency with their investigation and disclose Your personal information to that agency in accordance with our Privacy Policy.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h4>Finalisation</h4>
                        <ol>
                            <li>If You are satisfied with the outcome of Your complaint, we will close Your complaint and maintain a record of Your complaint and the findings for our continuous improvement.</li>
                            <li>If You are not satisfied with the outcome of Your complaint, You may pursue your rights under Australian Consumer Law. We will maintain a record of Your complaint for our continuous improvement.</li>
                        </ol>
                    </li>
                    <li>
                        <h4>Further information</h4>
                        <p>For further information about OSHC Compare’s Complaints Policy or practices, please contact OSHC Compare using the details on our Website. </p>
                    </li>
                </ol>
            </Col>
        </Row>
        </Container>
     </section>
    </main>
  );
}

export default PrivacyPolicy;
